/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
import React from 'react';
import { Modal, Form, Input, Spin } from 'antd';
import { handleApiError } from '../../../../../services/utils/errorHandler';

const EdgeCreationModal = ({ onConfirm, onCancel, visible, loading, t }) => {
  const [form] = Form.useForm();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      form.resetFields();
      onConfirm(values.organizationDescription);
    } catch (error) {
      handleApiError(error, t('validation_error'), t);
    }
  };

  return (
    <Modal
      title={t('modal_edge_creation_title')}
      open={visible}
      onOk={handleOk}
      onCancel={onCancel}
      confirmLoading={loading}
    >
      <Form form={form} name="organizationForm">
        <Form.Item
          name="organizationDescription"
          label="Organization Description"
          rules={[
            {
              required: true,
              message: t('modal_edge_creation_msg')
            }
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder={t('modal_input_org_description')}
          />
        </Form.Item>
      </Form>
      {loading && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin />
        </div>
      )}
    </Modal>
  );
};

export default EdgeCreationModal;
