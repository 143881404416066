/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
import React from 'react';
import { Form, Input, Space } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

class AddedServer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverUrl: this.props.server.serverurl,
      username: this.props.server.username,
      password: this.props.server.password,
      existingServerUrl: this.props.existingServer,
    };
  }

  updateServerUrl = evt => {
    this.setState(
      {
        serverUrl: evt.target.value,
      },
      () => {
        this.props.updateServers(
          this.state.serverUrl,
          this.state.username,
          this.state.password,
          this.state.existingServerUrl,
        );
      },
    );
  };

  updateUserName = evt => {
    this.setState(
      {
        username: evt.target.value,
      },
      () => {
        this.props.updateServers(
          this.state.serverUrl,
          this.state.username,
          this.state.password,
          this.state.existingServerUrl,
        );
      },
    );
  };

  updatePassword = evt => {
    this.setState(
      {
        password: evt.target.value,
      },
      () => {
        this.props.updateServers(
          this.state.serverUrl,
          this.state.username,
          this.state.password,
          this.state.existingServerUrl,
        );
      },
    );
  };

  render() {
    const { t } = this.props;
    return (
      <Space
        style={{
          display: 'flex',
          marginBottom: 8,
          width: '100%',
          justifyContent: 'space-between',
        }}
        align="baseline"
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: t('missing_txt', { label: t('label_serverUrl') }),
            },
          ]}
        >
          <Input
            placeholder={t('label_serverUrl')}
            style={{ width: '140%' }}
            value={this.state.serverUrl}
            onChange={evt => this.updateServerUrl(evt)}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t('missing_txt', { label: t('label_username') }),
            },
          ]}
        >
          <Input
            placeholder={t('label_username')}
            style={{ marginLeft: '20%' }}
            value={this.state.username}
            onChange={evt => this.updateUserName(evt)}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t('missing_txt', { label: t('label_password') }),
            },
          ]}
        >
          <Input.Password
            placeholder={t('label_password')}
            value={this.state.password}
            onChange={evt => this.updatePassword(evt)}
          />
        </Form.Item>
        <MinusCircleOutlined
          onClick={() => {
            this.props.remove(this.state.existingServerUrl);
          }}
        />
      </Space>
    );
  }
}

export default withTranslation()(AddedServer);
