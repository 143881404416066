/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Input, Form, Select } from 'antd';
import { withTranslation } from 'react-i18next';
import 'moment-timezone';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../../../../../services/utils/errorHandler';

const buttonItemLayout = {
  wrapperCol: { span: 24, offset: 19 },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class BillingForm extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tenantDomain: '',
      tenantList: [],
    };
    this.handleDomainChange = this.handleDomainChange.bind(this);
    this.getTenants = this.getTenants.bind(this);
  }

  componentDidMount() {
    this.getTenants();
    if (this.props.values) {
      this.formRef.current.setFieldsValue(this.props.values);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.action === 'Add') {
      this.formRef.current.resetFields();
    }
    this.formRef.current.setFieldsValue(this.props.values);
  }

  handleDomainChange(value) {
    this.setState({ tenantDomain: value });
  }

  onFinish = values => {
    if (this.props.action === 'Add') {
      this.props.callback(values);
    } else if (this.props.action === 'Edit') {
      this.props.callback(values);
    }
  };

  getTenants() {
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
          this.props.config.serverConfig.invoker.uri +
          '/device-mgt-config/v1.0/tenants',
      )
      .then(res => {
        if (res.status === 200) {
          this.setState({
            tenantList: res.data.data,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_tenants') }),
          t,
        );
      });
  }

  render() {
    const { action, t } = this.props;
    return (
      <div>
        <Form
          {...formItemLayout}
          name="billingForm"
          onFinish={this.onFinish}
          ref={this.formRef}
        >
          <Form.Item
            name="tenantDomain"
            label={t('label_tenantDomain')}
            initialValue={this.state.tenantDomain}
            rules={[
              {
                required: true,
                message: t('tenantDomain_infoTxt'),
              },
            ]}
          >
            <Select>
              {this.state.tenantList.map(item => (
                <Select.Option key={item.domain} value={item.domain}>
                  {item.domain}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="cost"
            label={t('label_cost')}
            rules={[
              {
                required: true,
                message: t('cost_infoTxt'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button type="primary" htmlType="submit">
              {t(`label_${action.toLowerCase()}`)}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withTranslation()(BillingForm);
