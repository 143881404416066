/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import react from 'react';
import { withConfigContext } from '../ConfigContext';
import { isAuthorized } from '../../services/utils/authorizationHandler';

class Authorized extends react.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return isAuthorized(this.props.context.scopes, this.props.scope)
      ? this.props.yes
      : this.props.no;
  }
}

Authorized.defaultProps = {
  yes: () => null,
  no: () => null,
};
export default withConfigContext(Authorized);
