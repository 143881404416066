/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
import React from 'react';
import { getBezierPath } from 'reactflow';
import 'reactflow/dist/base.css';
import '.././index.css';

export default function BezierEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {}
}) {
  const xEqual = sourceX === targetX;
  const yEqual = sourceY === targetY;

  const [edgePath] = getBezierPath({
    sourceX: xEqual ? sourceX + 0.0001 : sourceX,
    sourceY: yEqual ? sourceY + 0.0001 : sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });

  return (
    <svg style={{ position: 'absolute', overflow: 'visible' }}>
      <defs>
        <radialGradient id={`edge-gradient-${id}`}>
          <stop offset="100%" stopColor="#2a8af6" />
        </radialGradient>
      </defs>
      <path
        id={id}
        style={{
          fill: 'none',
          strokeWidth: 3,
          animation: 'dashdraw 3s linear infinite',
          stroke: `url(#edge-gradient-${id})`,
          ...style
        }}
        className="react-flow__edge-path"
        d={`${edgePath}`}
      />
      <circle
        cx={targetX}
        cy={targetY}
        r={4}
        fill={`url(#edge-gradient-${id})`}
      />
    </svg>
  );
}
