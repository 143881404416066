/* eslint-disable no-prototype-builtins */
/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Col,
  Collapse,
  Empty,
  notification,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import axios from 'axios';
import { handleApiError } from '../../../../services/utils/errorHandler';
import { RoutesContext } from '../../index';
import { withConfigContext } from '../../../../components/ConfigContext';
import moment from 'moment';
import 'moment-timezone';
import EditOperationStatus from './EditOperationStatus';
import OperationResponseTable from './OperationResponseTable';
import styles from './styles.module.css';
import {
  EyeOutlined,
  ReloadOutlined,
  SearchOutlined,
  SettingOutlined
} from '@ant-design/icons';
import Filter from '../../components/Filter';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BreadcrumbComponent from '../../../../components/BreadCrumb';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home')
    },
    {
      breadcrumbName: t('home_menu_readings')
    },
    {
      breadcrumbName: t('home_menu_meter_operations')
    }
  ];
};

class OperationResponse extends React.Component {
  filterValues = '';
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      activities: [],
      pagination: {},
      loading: false,
      selectedRows: [],
      searchFields: [],
      isModalVisible: false
    };
    const { t } = this.props;
    let link = null;
    this.columns = [
      {
        title: t('label_operationId'),
        dataIndex: 'operationId',
        key: 'operationId'
      },
      {
        title: t('label_operationCode'),
        dataIndex: 'code',
        key: 'code'
      },
      {
        title: t('label_serial'),
        dataIndex: ['deviceIdentifier', 'id'],
        key: 'deviceIdentifier'
      },
      {
        title: t('label_last_updated'),
        dataIndex: 'updatedTimestamp',
        key: 'updatedTimestamp',
        align: 'center',
        render: updatedTimestamp => {
          return (
            <Tooltip
              title={moment(updatedTimestamp.replace('IST', '')).format(
                'YYYY-MM-DD hh:mm:ss A'
              )}
            >
              <Tag>{moment(updatedTimestamp.replace('IST', '')).fromNow()}</Tag>
            </Tooltip>
          );
        }
      },
      {
        title: t('label_initiated_by'),
        dataIndex: 'initiatedBy',
        key: 'initiatedBy'
      },
      {
        title: t('label_created_time'),
        dataIndex: 'createdTimeStamp',
        key: 'createdTimeStamp',
        align: 'center',
        render: createdTimeStamp => {
          return (
            <Tooltip
              title={moment(createdTimeStamp.replace('IST', '')).format(
                'YYYY-MM-DD hh:mm:ss A'
              )}
            >
              <Tag>{moment(createdTimeStamp.replace('IST', '')).fromNow()}</Tag>
            </Tooltip>
          );
        }
      },
      {
        title: t('label_status'),
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (status, row) => {
          if (status === 'COMPLETED' || status === 'ERROR') {
            let color;
            let backGroundColor;
            switch (status) {
              case 'COMPLETED':
                backGroundColor = '#bde4f2';
                color = '#007279';
                break;
              default:
                backGroundColor = '#ff9999';
                color = '#821212';
                break;
            }
            return (
              <span
                style={{
                  display: 'inline-block',
                  width: 120,
                  textAlign: 'center',
                  color: color,
                  background: backGroundColor,
                  padding: '3px 10px 3px 10px',
                  borderRadius: 7
                }}
              >
                {t('label_uppercase_string', {
                  label: t(`label_${status.toLowerCase()}`)
                })}
              </span>
            );
          }
          return (
            <EditOperationStatus
              status={status}
              updateOperationState={(operationId, status) =>
                this.updateOperationState(
                  row.operationId,
                  status,
                  row.deviceIdentifier.id
                )
              }
              deviceType="power-meter"
              deviceIdentifier={row.deviceIdentifier.id}
              operationId={row.operationId}
            />
          );
        }
      },
      {
        title: t('label_action'),
        dataIndex: 'activityId',
        key: 'actions',
        align: 'right',
        width: 25,
        // do not remove unused data variable
        render: (data, row) => {
          if (row.status == 'COMPLETED') {
            const startTimestamp =
              moment(
                row.createdTimeStamp,
                'ddd MMM DD HH:mm:ss ZZ YYYY'
              ).valueOf() - 2;
            const endTimestamp =
              moment(
                row.updatedTimestamp,
                'ddd MMM DD HH:mm:ss ZZ YYYY'
              ).valueOf() - 2;

            const commonQueryParams = `serial=${
              row.deviceIdentifier.id
            }&operationId=${row.activityId.replace('ACTIVITY_', '')}`;

            if (row.code === 'LOAD_PROFILE_RETRIEVE') {
              link = `../load/historical?${commonQueryParams}`;
            } else {
              link = `../operations/readings?${commonQueryParams}&operationCode=${
                row.code
              }&startTimestamp=${startTimestamp}&endTimestamp=${
                endTimestamp - startTimestamp < 60000
                  ? endTimestamp + 60000
                  : endTimestamp
              }`;
            }
            return (
              <Space>
                <Tooltip placement="top" title={t('label_viewReading')}>
                  <Link to={link} target="_blank">
                    <EyeOutlined />
                  </Link>
                </Tooltip>
              </Space>
            );
          }
          return '-';
        }
      }
    ];
  }

  componentDidMount() {
    this.context.setCurrentRoute('allOperationResponse');

    // initial loading state to true
    this.setState({ loading: true });

    // checking for query parameters in the URL
    const queryParams = new URLSearchParams(window.location.search);
    const serial = queryParams.get('serial');
    const operationId = queryParams.get('operationId');
    const status = queryParams.get('status');
    const startTimestamp = queryParams.get('startTimestamp');
    const endTimestamp = queryParams.get('endTimestamp');

    // set the initial state for search fields
    const initialSearchFields = {
      operationStatus: status ? [status] : [],
      operationId: operationId || '',
      serial: serial ? [serial] : [],
      createdDay: startTimestamp ? moment(parseInt(startTimestamp, 10)) : null,
      updatedDay: endTimestamp ? moment(parseInt(endTimestamp, 10)) : null
    };

    const { t } = this.props;
    const fields = [
      {
        name: 'operationStatus',
        label: t('label_operationStatus'),
        placeholder: t('label_operationStatus'),
        type: 'multipleSelect',
        values: Object.keys(this.config.operationStatus).map(
          key => this.config.operationStatus[key]
        )
      },
      {
        name: 'operationId',
        label: t('label_operationId'),
        placeholder: t('label_operationId'),
        type: 'input'
      },
      {
        name: 'serial',
        label: t('label_serials'),
        placeholder: t('label_serials'),
        type: 'creatableSelect',
        mode: 'tags',
        values: []
      },
      {
        name: 'createdDay',
        label: t('label_startDate'),
        placeholder: t('label_startDate'),
        type: 'calender'
      },
      {
        name: 'updatedDay',
        label: t('label_endDate'),
        placeholder: t('label_endDate'),
        type: 'calender'
      }
    ];

    // set the state with the initial search fields
    this.setState({
      searchFields: fields.map(field => ({
        ...field,
        value: initialSearchFields[field.name]
      }))
    });

    // fetch data based on query parameters if has
    this.fetchOperationFilters({}, initialSearchFields);
  }

  calenderDates = (day, propertyName) => {
    let operationFilters = '';
    if (propertyName === 'createdDay') {
      operationFilters =
        operationFilters +
        '&startTimestamp=' +
        Math.floor(day.valueOf() / 1000);

      return operationFilters;
    }

    if (propertyName === 'updatedDay') {
      operationFilters =
        operationFilters + '&endTimestamp=' + Math.floor(day.valueOf() / 1000);

      return operationFilters;
    }
  };

  fetchOperationFilters = (params, values) => {
    let operationFilters = '';

    if (values.hasOwnProperty('operationStatus')) {
      for (let i = 0; i < values.operationStatus.length; i++) {
        operationFilters =
          operationFilters + '&status=' + values.operationStatus[i];
      }
    }
    if (
      values.hasOwnProperty('operationId') &&
      values.operationId.trim() !== ''
    ) {
      // with this values.operationId.trim() !== '' avoided null value set to operationId
      operationFilters =
        operationFilters + '&operationId=' + values.operationId.trim();
    }
    if (values.hasOwnProperty('serial')) {
      for (let i = 0; i < values.serial.length; i++) {
        operationFilters = operationFilters + '&deviceId=' + values.serial[i];
      }
    }

    // eslint-disable-next-line no-unused-vars
    for (let property in values) {
      if (property === 'createdDay' && values[property]) {
        let selectedFromDate = moment(values.createdDay);
        operationFilters += this.calenderDates(selectedFromDate, property);
      }
      if (property === 'updatedDay' && values[property]) {
        // Add this values[property] otherwise when coming with query params this may the timestamp as NaN value
        let selectedToDate = moment(values.updatedDay);
        operationFilters += this.calenderDates(selectedToDate, property);
      }
    }

    this.filterValues = operationFilters;
    this.setState({ pagination: { current: 1 } });
    this.getDeviceOperations(
      {
        page: 1
      },
      operationFilters
    );
  };

  getDeviceOperations = (params = {}, filters = '') => {
    const { t } = this.props;
    this.setState({ loading: true });

    // get current page
    const currentPage =
      params.hasOwnProperty('page') && params.page ? params.page : 1;

    let limit = 10;
    if (params.results) {
      limit = params.results;
    }

    const extraParams = {
      offset: limit * (currentPage - 1), // calculate the offset
      limit: limit
    };

    let encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    // const paramSet = encodedExtraParams;
    if (filters !== '') {
      encodedExtraParams = encodedExtraParams + filters;
    }
    const GET_DEVICE_ACTIVITY_API =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/activities/devices';

    axios
      .get(`${GET_DEVICE_ACTIVITY_API}?${encodedExtraParams}`)
      .then(res => {
        if (res.status === 200) {
          const pagination = { ...this.state.pagination };
          this.setState({
            activities: res.data.data,
            pagination,
            loading: false
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_getOperationError'), t);
      });
  };

  updateOperationState = (operationId, status, deviceIdentifier) => {
    let operation = this.state.activities.activities.find(
      operation => operation.operationId === operationId
    );
    const operationObject = {
      operationId: operationId,
      status: status,
      operationCode: operation.code
    };
    const { t } = this.props;
    axios
      .put(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          '/device-mgt/v1.0' +
          `/devices/power-meter/${deviceIdentifier}/operation`,
        operationObject
      )
      .then(res => {
        if (res.status === 200) {
          notification.success({
            message: t('api_successMsg'),
            duration: 5,
            description: t('api_operationUpdatedMsg')
          });
          const pagination = this.state.pagination;
          this.getDeviceOperations({
            page: pagination.current
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_getOperationError'), t);
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager
    });
    this.getDeviceOperations(
      {
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      },
      this.filterValues
    );
  };

  showModal = () => {
    this.setState({
      isModalVisible: true
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false
    });
  };

  // activity details in expanded row
  getDetailsUI = responses => {
    if (Object.keys(responses[0]).length === 0) {
      return (
        <Empty
          className={styles.customEmpty}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      );
    }

    return (
      <div>
        {responses.map((response, index) => {
          if (Object.keys(responses[0]).length === 0) {
            return (
              <Empty
                className={styles.customEmpty}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            );
          }
          return (
            <div key={index} className={styles.responseRow}>
              <div className={styles.flexContainer}>
                <p className={styles.timestamp}>
                  {moment(
                    response.receivedTimeStamp,
                    'ddd MMM DD HH:mm:ss zzz YYYY'
                  ).format('yyyy-MM-DD hh:mm:ss A')}
                </p>
                <p className={styles.response}>
                  {typeof response.response === 'object'
                    ? JSON.stringify(response.response)
                    : response.response}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  refreshOperationLog = () => {
    const { pagination } = this.state;
    this.getDeviceOperations({
      page: pagination.current
    });
  };

  render() {
    const { activities, pagination, loading } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div>
          <PageHeader
            className={styles.pageHeader}
            title={
              <Title level={1}>
                <span className={styles.icon}>
                  <SettingOutlined />
                </span>
                {t('home_menu_operations')}
              </Title>
            }
            ghost={false}
            breadcrumb={
              <BreadcrumbComponent
                breadcrumbList={routes(this.config.appName, t)}
              />
            }
          >
            <Paragraph className={styles.description}>
              {t('operation_description')}
            </Paragraph>
          </PageHeader>
          <div className={styles.table}>
            <Collapse
              bordered={false}
              expandIcon={() => <SearchOutlined />}
              className={styles.searchCollapse}
            >
              <Panel
                header={t('form_searchOperations')}
                key="1"
                style={{ borderColor: '#fff' }}
              >
                <Filter
                  filters={this.state.searchFields}
                  callback={(params, values) =>
                    this.fetchOperationFilters({}, values)
                  }
                />
              </Panel>
            </Collapse>
            <div>
              <Col span={4} offset={22}>
                <Tooltip
                  placement="bottom"
                  title={t('label_refreshOperationResponse')}
                  autoAdjustOverflow={true}
                >
                  <Button
                    type="link"
                    shape="circle"
                    icon={<ReloadOutlined />}
                    onClick={this.getDeviceOperations}
                    size={'default'}
                    style={{ margin: '2px' }}
                  >
                    {t('label_refresh')}
                  </Button>
                </Tooltip>
              </Col>
              {loading && (
                <div style={{ textAlign: 'center' }}>
                  <Spin />
                </div>
              )}
              {!loading && (
                <OperationResponseTable
                  activities={activities}
                  columns={this.columns}
                  pagination={pagination}
                  loading={loading}
                  handleTableChange={this.handleTableChange}
                  getDetailsUI={this.getDetailsUI}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OperationResponse.contextType = RoutesContext;
export default withConfigContext(withTranslation()(OperationResponse));
