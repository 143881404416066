/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Row, Col, Card, Steps, notification } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import { withConfigContext } from '../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import SelectDeviceType from '../../components/SelectDeviceType';
import AddNewDeviceForm from './components/AddNewDeviceForm';
import axios from 'axios';
import EnrollmentResult from './components/EnrollmentResult';

const { Step } = Steps;

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home')
    },
    {
      path: `/${appName}/devices`,
      breadcrumbName: t('home_menu_machines')
    },
    {
      breadcrumbName: t('home_menu_enrollMachine')
    }
  ];
};

class EnrollIoTDevice extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      currentStepIndex: 0,
      deviceTypes: this.config.deviceTypes,
      deviceTypeProperties: {},
      formValues: {},
      enrollmentPayload: {},
      enrollmentResult: {}
    };
  }

  componentDidMount() {
    this.context.setCurrentRoute('enroll');
  }

  onClickDeviceType = payload => {
    this.setState({
      deviceTypeProperties: payload,
      currentStepIndex: this.state.currentStepIndex + 1
    });
  };

  onClickBack = () => {
    this.setState({
      currentStepIndex: this.state.currentStepIndex - 1
    });
  };

  onFinish = values => {
    this.createDevice(values);
  };

  createDevice = values => {
    const propertiesArray = [];
    this.state.deviceTypeProperties.deviceType.properties.forEach(property => {
      if (values.hasOwnProperty(property)) {
        propertiesArray.push({
          name: property,
          value: values[property]
        });
      }
    })

    const payload = {
      name: values.deviceName,
      type: this.state.deviceTypeProperties.deviceType.type,
      description: values.deviceDescription,
      deviceIdentifier: values.deviceIdentifier,
      enrolmentInfo: {
        ownership: "BYOD",
        status: "ACTIVE"
      },
      properties: propertiesArray
    };

    const apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      "/device/agent/enroll";

    axios
      .post(apiUrl, payload)
      .then(res => {
        this.setState({
          enrollmentPayload: payload,
          enrollmentResult: res,
          currentStepIndex: this.state.currentStepIndex + 1
        });
        this.downloadConfig(payload.type, payload.deviceIdentifier);
      })
      .catch(error => {
        if (error.response.status === 400) {
          notification.error({
            message: "Bad Request",
            description: error.response.data.data || "Something went wrong."
          });
        } else if (error.response.status === 500) {
          notification.error({
            message: "Server Error",
            description:
              "Something went wrong on the server. Please try again later."
          });
        } else {
          notification.error({
            message: "Error",
            description: error.response.data.data || "Something went wrong."
          });
        }
      });
  }

  downloadConfig = (deviceType, deviceId) => {
    this.getDeviceConfig(deviceType, deviceId);
  }

  getDeviceConfig = (deviceType, deviceId) => {
    const apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      `/devices/${deviceType}/${deviceId}/config`;
    axios
      .get(apiUrl)
      .then(res => {
        this.downloadConfigJson(res.data.data);
      })
      .catch(error => {
        if (error.response.status === 400) {
          notification.error({
            message: "Bad Request",
            description: error.response.data.data || "Something went wrong."
          });
        } else if (error.response.status === 500) {
          notification.error({
            message: "Server Error",
            description:
              "Something went wrong on the server. Please try again later."
          });
        } else {
          notification.error({
            message: "Error",
            description: error.response.data.data || "Something went wrong."
          });
        }
      });
  }

  downloadConfigJson = (data) => {
    const jsonStr = JSON.stringify(data);
    const fileName = `${data.deviceId}.json`;
    const blob = new Blob([jsonStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {
    const { currentStepIndex, deviceTypeProperties } = this.state;
    const { t } = this.props;
    const formProps = {
      onFinish: this.onFinish,
      onClickBack: this.onClickBack,
      onClickNext: this.onClickNext,
      deviceTypeProperties: deviceTypeProperties,
      enrollmentResult: {}
    };
    const resultProps = {
      payload: this.state.enrollmentPayload,
      config: this.config
    };

    return (
      <div>
        <div className={styles.container}>
          <PageHeader
            style={{ paddingTop: 0, backgroundColor: '#fff', marginTop: 10 }}
            breadcrumb={
              <BreadcrumbComponent
                breadcrumbList={routes(this.config.appName, t)}
              />
            }
          />
          <Row>
            <Col span={22} offset={1}>
              <Card className={styles.cardContainer}>
                <div>
                  <Steps style={{ minHeight: 32 }} current={currentStepIndex}>
                    <Step
                      key="DeviceType"
                      title={t("label_selectMachineType")}
                    />
                    <Step
                      key="Properties"
                      title={t("label_machineProperties")}
                    />
                    <Step key="Finish" title={t("label_finish")} />
                  </Steps>
                </div>
              </Card>
              <Card className={styles.cardContainer}>
                <div>
                  {currentStepIndex === 0 && (
                    <SelectDeviceType
                      config={this.config}
                      onDeviceTypeClick={this.onClickDeviceType}
                    />
                  )}
                  {currentStepIndex === 1 && (
                    <AddNewDeviceForm {...formProps} />
                  )}
                  {currentStepIndex === 2 && (
                    <EnrollmentResult {...resultProps} />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

EnrollIoTDevice.contextType = RoutesContext;
export default withConfigContext(withTranslation()(EnrollIoTDevice));
