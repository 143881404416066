/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Modal,
  notification,
  Popconfirm,
  Tooltip,
  Tree,
  Typography,
} from 'antd';
import axios from 'axios';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import RoleForm from '../RoleForm';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import { splitUserDomain } from '../../../../../../../../services/utils/commonHandler';
const { Text } = Typography;
const { TreeNode } = Tree;

class RoleAction extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.selected = [];
    this.expandKeys = [];
    this.state = {
      roleData: {},
      nodeList: [],
      isNodeList: false,
      users: [],
      isEditRoleModalVisible: false,
      isEditPermissionModalVisible: false,
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
    };
  }

  roleData = {};

  openEditRoleModal = () => {
    const { t } = this.props;
    let userDomain = splitUserDomain(this.props.data);
    let apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/roles/' +
      userDomain[0] +
      '?user-store=' +
      userDomain[1];

    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            isEditRoleModalVisible: true,
            roleData: res.data.data,
          });
          this.roleData = res.data.data;
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_role') }),
          t,
        );
      });
  };

  openEditPermissionModal = () => {
    this.loadPermissionList();
    this.setState({
      isEditPermissionModalVisible: true,
    });
  };

  loadPermissionList = () => {
    const { t } = this.props;
    let userDomain = splitUserDomain(this.props.data);
    let apiURL =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/roles/' +
      userDomain[0] +
      '/permissions?user-store=' +
      userDomain[1];

    axios
      .get(apiURL)
      .then(res => {
        if (res.status === 200) {
          this.getCheckedPermissions(res.data.data.nodeList);
          this.setState({
            nodeList: res.data.data.nodeList,
            isNodeList: true,
            checkedKeys: this.selected,
            expandedKeys: this.expandKeys,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_permissions') }),
          t,
        );
      });
  };

  getCheckedPermissions = data => {
    data.forEach(item => {
      if (item !== null) {
        this.expandKeys.push(item.resourcePath);
        if (item.isSelected) {
          this.selected.push(item.resourcePath);
        }
        this.getCheckedPermissions(item.nodeList);
      } else {
        return null;
      }
    });
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onCheck = checkedKeys => {
    this.setState({ checkedKeys });
  };

  renderTreeNodes = data => {
    const { t } = this.props;
    return data.map(item => {
      if (item !== null) {
        if (item.hasOwnProperty('nodeList')) {
          return (
            <TreeNode
              title={t(`permission_${item.displayName.split('-').join('_')}`)}
              key={item.resourcePath}
              dataRef={item}
            >
              {this.renderTreeNodes(item.nodeList)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.resourcePath} {...item} />;
      }
      // eslint-disable-next-line react/jsx-key
      return <TreeNode />;
    });
  };

  onCancelHandler = e => {
    this.setState({
      isEditRoleModalVisible: false,
      isEditPermissionModalVisible: false,
    });
  };

  onConfirmUpdateRole = value => {
    const { t } = this.props;
    let userDomain = splitUserDomain(this.props.data);
    const roleData = {
      roleName: value.userStoreDomain + '/' + value.roleName,
      users: value.users,
    };
    axios
      .put(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/roles/' +
        userDomain[0] +
        '?user-store=' +
        userDomain[1],
        roleData,
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.props.fetchRoles();
          this.setState({
            isEditRoleModalVisible: false,
          });
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_updateRoleMsg'),
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_addError', { label: t('home_menu_role') }),
          t,
        );
      });
  };

  onAssignPermission = () => {
    const { t } = this.props;
    let userDomain = splitUserDomain(this.props.data);
    const roleData = {
      roleName: userDomain[1] + '/' + this.props.data,
      permissions: this.state.checkedKeys,
    };
    axios
      .put(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/roles/' +
        userDomain[0] +
        '?user-store=' +
        userDomain[1],
        roleData,
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.props.fetchRoles();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_updatePermissionMsg'),
          });
          this.setState({
            isEditPermissionModalVisible: false,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_addError', { label: t('label_permissions') }),
          t,
        );
      });
  };

  onDeleteRole = () => {
    const { t } = this.props;
    let userDomain = splitUserDomain(this.props.data);
    axios
      .delete(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/roles/' +
        userDomain[0] +
        '?user-store=' +
        userDomain[1],
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.props.fetchRoles();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_deleteRoleMsg'),
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_deleteRoleError'), t);
      });
  };

  fetchUpdatedRole = values => {
    this.onConfirmUpdateRole(values);
  };

  render() {
    const { t } = this.props;
    const isAdminRole = this.props.data === 'admin';
    return (
      <div>
        <div style={{ display: isAdminRole ? 'none' : 'inline' }}>
          <HtmlComments
            permission={'/permission/admin/device-mgt/roles/manage'}
          />
          {isAuthorized(this.config.scopes, ['perm:roles:update']) && (
            <span>
              <Tooltip placement="top" title={t('label_editRole')}>
                <a>
                  <EditOutlined onClick={this.openEditRoleModal} />
                </a>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip placement="top" title={t('label_editPermissions')}>
                <a>
                  <FileAddOutlined onClick={this.openEditPermissionModal} />
                </a>
              </Tooltip>
            </span>
          )}
          {isAuthorized(this.config.scopes, ['perm:roles:delete']) && (
            <span>
              <Divider type="vertical" />
              <Tooltip placement="bottom" title={t('label_removeRole')}>
                <Popconfirm
                  placement="top"
                  title={t('label_areYouSure')}
                  onConfirm={this.onDeleteRole}
                  okText={t('label_ok')}
                  cancelText={t('label_cancel')}
                >
                  <a>
                    <Text type="danger">
                      <DeleteOutlined />
                    </Text>
                  </a>
                </Popconfirm>
              </Tooltip>
            </span>
          )}
        </div>

        <div>
          <Modal
            title={t('title_editRole')}
            width="40%"
            open={this.state.isEditRoleModalVisible}
            onCancel={this.onCancelHandler}
            footer={null}
            maskClosable={false}
          >
            <div style={{ alignItems: 'center' }}>
              <HtmlComments
                permission={'/permission/admin/device-mgt/users/view'}
              />
              <p>{t('createNewUse_descriptionTxt')}</p>
              <RoleForm
                action={'Update'}
                values={this.state.roleData}
                modalVisibility={this.state.isEditRoleModalVisible}
                callback={values => this.fetchUpdatedRole(values)}
              />
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            title={t('title_changeRolePermission')}
            width="40%"
            open={this.state.isEditPermissionModalVisible}
            onOk={this.onAssignPermission}
            onCancel={this.onCancelHandler}
            maskClosable={false}
            footer={[
              <Button key="cancel" onClick={this.onCancelHandler}>
                {t('label_cancel')}
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={this.onAssignPermission}
              >
                {t('label_assign')}
              </Button>,
            ]}
            bodyStyle={{
              overflowY: 'scroll',
              maxHeight: '500px',
              marginLeft: '10px',
            }}
          >
            <div>
              {this.state.isNodeList && (
                <Tree
                  checkable
                  onExpand={this.onExpand}
                  expandedKeys={this.state.expandedKeys}
                  autoExpandParent={this.state.autoExpandParent}
                  onCheck={this.onCheck}
                  checkedKeys={this.state.checkedKeys}
                >
                  {this.renderTreeNodes(this.state.nodeList)}
                </Tree>
              )}
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(RoleAction));
