/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Form, Input, Modal, notification } from 'antd';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import { IdcardTwoTone } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

const buttonItemLayout = {
  wrapperCol: { span: 24, offset: 19 },
};

class AssignDEPProfile extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isSupervised: false,
    };
  }

  assignDEPProfile = values => {
    const config = this.props.context;
    const { t } = this.props;
    let apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/ios/v1.0/dep/devices/assign-profile';

    // send request to the invoker
    axios
      .post(apiUrl, values, { headers: { 'Content-Type': 'application/json' } })
      .then(res => {
        if (res.status === 200) {
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_assignDEPProfileMsg'),
          });
          this.setState({ isModalVisible: false });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_assignDEPProfileError'), t);
      });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  showUpdateModal = () => {
    this.setState({ isModalVisible: true });
  };

  onFinish = values => {
    const payload = { devices: [this.props.serial], ...values };
    this.assignDEPProfile(payload);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <IdcardTwoTone onClick={this.showUpdateModal} />
        <Modal
          title={t('title_updateDEPProfile')}
          open={this.state.isModalVisible}
          onCancel={this.handleCancel}
          footer={null}
          maskClosable={false}
        >
          <div>
            <Form name="depForm" onFinish={this.onFinish} ref={this.formRef}>
              <Form.Item
                style={{ display: 'block' }}
                name="profile_uuid"
                label={t('label_DEPProfileUUID')}
              >
                <Input placeholder={t('form_typeDEPProfileUUIDMsg')} />
              </Form.Item>
              <Form.Item {...buttonItemLayout}>
                <Button type="primary" htmlType="submit">
                  {t('label_save')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(AssignDEPProfile));
