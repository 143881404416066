/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { CheckCircleOutlined, ContainerOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';

class PolicyOperations extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
  }

  render() {
    const { isPoliciesExist, t } = this.props;
    return (
      <div>
        <div style={{ background: '#ffffff' }}>
          <div>
            <Link to={`/${this.config.appName}/policy/priority`}>
              <Button
                type="primary"
                icon={<ContainerOutlined />}
                size={'default'}
                style={{ marginBottom: '10px', marginLeft: '5px' }}
                disabled={!isPoliciesExist}
              >
                {t('label_policyPriority')}
              </Button>
            </Link>
            <Tooltip placement="bottom" title={t('label_applyChangesToDevice')}>
              <Popconfirm
                placement="topLeft"
                title={t('applyPolicyChanges_confirmTxt')}
                onConfirm={this.props.applyChanges}
                okText={t('label_yes')}
                cancelText={t('label_no')}
              >
                <Button
                  type="primary"
                  icon={<CheckCircleOutlined />}
                  size={'default'}
                  style={{ marginBottom: '10px', marginLeft: '5px' }}
                  disabled={!isPoliciesExist}
                >
                  {t('label_applyChangesToDevice')}
                </Button>
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(PolicyOperations));
