/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Divider, Form, Row, Select, Tooltip, Typography } from 'antd';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import CustomAlerts from '../CustomAlerts';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import { withTranslation } from 'react-i18next';
const { Title, Text } = Typography;
import styles from './styles.module.css';

const { Option } = Select;

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    const config = this.props.context;
    const { itemData } = this.props;
    if (itemData.select.apiUrl) {
      const apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        itemData.select.apiUrl;
      this.fetchData(apiUrl);
    }
  }

  onChange = value => {
    const item = { ...this.props.itemData };
    item.value = value;
    this.props.callback(item);
  };

  // fetch data from api
  fetchData = apiUrl => {
    // send request to the invokerss
    const { itemData, t } = this.props;
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data;
          const dataArray = this.objectByString(
            data,
            itemData.select.arrayPath,
          );
          const options = dataArray.map((item, index) => (
            <Option key={index} value={item[itemData.select.defineValueKey]}>
              {item[itemData.select.displayValueKey]}
            </Option>
          ));
          this.setState({
            options,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_data') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  // get nested object value when get key as string
  objectByString = (o, s) => {
    let a = s.split('/');
    for (let i = 0, n = a.length; i < n; ++i) {
      let k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  };

  render() {
    const { itemData, defaultValue, t } = this.props;
    const { value } = itemData;
    let selectDefaultValue = null;
    // Change below logic if there's any other data types. (default - String)
    if (defaultValue) {
      if (itemData.select.valueType === 'Integer') {
        selectDefaultValue = parseInt(defaultValue, 10);
      } else {
        selectDefaultValue = defaultValue;
      }
    } else {
      selectDefaultValue = value;
    }
    const options = itemData.select.options
      ? itemData.select.options.map((item, index) => (
          <Option
            key={index}
            value={
              itemData.select.valueType !== 'Integer'
                ? item.definedValue
                : parseInt(item.definedValue, 10)
            }
          >
            {t(item.displayingValue)}
          </Option>
        ))
      : this.state.options;
    return (
      <div>
        {itemData.divider && (
          <Divider plain>
            <Text type="secondary">{t(`${itemData.divider}`)}</Text>
          </Divider>
        )}
        {itemData.subTitle && <Title level={4}> {t(itemData.subTitle)} </Title>}
        <CustomAlerts notifications={itemData.notifications} />
        <div className={styles.selectContainer}>
          <Row>
            <span
              style={{
                display: itemData.label && itemData.tooltip ? 'inline' : 'none',
              }}
            >
              {t(`${itemData.label}`)}&nbsp;
              {itemData.tooltip && (
                <Tooltip
                  title={
                    <div>
                      <div>{t(`${itemData.tooltip}`)}</div>
                      <div style={{ textAlign: 'right' }}>
                        {itemData.docLink && (
                          <a
                            onClick={() => {
                              window.open(itemData.docLink, '_blank');
                            }}
                          >
                            {' '}
                            {t('label_SeeMoreDetails')}
                          </a>
                        )}
                      </div>
                    </div>
                  }
                  placement="right"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </span>
          </Row>
          <Form>
            <Form.Item name={itemData.key}>
              <Select
                mode={itemData.select.mode ? itemData.select.mode : ''}
                key={itemData.key}
                defaultValue={selectDefaultValue}
                onChange={this.onChange}
                disabled={this.props.disabled}
                dropdownMatchSelectWidth={false}
              >
                {options}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(CustomSelect));
