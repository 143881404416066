/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext, useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import {
    DesktopOutlined,
    EditOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import { Button, Select, Form, Input, Space, Switch, Tooltip, notification } from 'antd';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { useTranslation } from 'react-i18next';
import { RoutesContext } from "../../../../../../../../index";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './styles.module.css';
import styles from './styles.module.css';
import { handleApiError } from "../../../../../../../../../../services/utils/errorHandler";
import ConfigContext from "../../../../../../../../../../components/ConfigContext";

const { TextArea } = Input;

const CreateDeviceType = (props) => {
    const { config } = useContext(ConfigContext);
    const routesContext = useContext(RoutesContext);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [deviceTypeName, setDeviceTypeName] = useState("");
    const [eventForm] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);
        const propertyValues = values.properties && values.properties.map(item => item.property);
        let deviceTypePayload = {
            "name": values.deviceTypeName,
            "deviceTypeMetaDefinition": {
                "mqttEventTopicStructures": values.mqttEventTopicStructures,
                "longLivedToken": values.longLivedToken,
                "description": values.description,
                "properties": propertyValues,
                "features": values.features
            }
        }
        if (values.pushNotificationTransport === "mqtt") {
            deviceTypePayload.deviceTypeMetaDefinition.pushNotificationConfig = {
                "scheduled": true,
                "type": "MQTT"
            }
        }

        axios
            .post(
                window.location.origin +
                config.serverConfig.invoker.uri +
                '/device-mgt/v1.0' +
                '/admin/device-types',
                JSON.stringify(deviceTypePayload),
                { headers: { 'Content-Type': 'application-json' } }
            )
            .then(res => {
                if (res.status === 200) {
                    setIsLoading(false);
                    props.deviceTypeCallback(deviceTypePayload);
                }
            })
            .catch(error => {
                handleApiError(error, null, t, `${this.constructor.name}AddUser`);
                setIsLoading(false);
            });
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onBlurDeviceTypeNameInput = (e) => {
        setDeviceTypeName(e.target.value)
    }

    return (
        <Form
            form={eventForm}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label={t('machine_type_name_input_label')}
                name="deviceTypeName"
                rules={[{ required: true, message: t('machine_type_name_input_label_error') }]}
            >
                <Input onBlur={(value) => onBlurDeviceTypeNameInput(value)} />
            </Form.Item>

            <Form.Item
                label={t('device_type_description_input_label')}
                name="description"
                rules={[{ required: true, message: t('device_type_description_input_label_error') }]}
            >
                <TextArea rows={4} />
            </Form.Item>

            <Form.Item
                label={t('device_type_event_topic_select_label')}
                name="mqttEventTopicStructures"
                rules={[{ required: true, message: t('device_type_event_topic_select_label_error') }]}
            >
                <Select
                    mode="tags"
                    style={{
                        width: '100%',
                    }}
                    placeholder={t('device_type_event_topic_select_placeholder')}
                    options={[
                        { label: "data/carbonsuper/" + deviceTypeName + "/${deviceId}", value: "data/carbonsuper/" + deviceTypeName + "/${deviceId}" },
                        { label: "republished/" + deviceTypeName, value: "republished/" + deviceTypeName },
                        { label: "carbon.super/" + deviceTypeName + "/${deviceId}/events", value: "carbon.super/" + deviceTypeName + "/${deviceId}/events" }
                    ]}
                />
            </Form.Item>

            <Form.Item
                label={t('device_type_ll_token_switch_label')}
                name="longLivedToken"
            >
                <Switch />
            </Form.Item>

            <Form.Item
                label={t('device_type_push_noti_transport_select_label')}
                name="pushNotificationTransport"
                rules={[{ required: true, message: t('device_type_push_noti_transport_select_label_error') }]}
            >
                <Select
                    placeholder={t('device_type_push_noti_transport_select_placeholder')}
                    style={{
                        width: 220
                    }}
                    onChange={handleChange}
                    options={[
                        {
                            value: 'none',
                            label: 'NONE',
                        },
                        {
                            value: 'mqtt',
                            label: 'MQTT',
                        },
                    ]}
                />
            </Form.Item>
            <Form.Item
                label={t('device_type_features_label')}
                name="features"
            >
                <Form.List name="features">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        marginBottom: 8,
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('device_type_feature_name_missing')
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t('device_type_feature_name_placeholder')} />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'code']}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('device_type_feature_missing_code'),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t('device_type_feature_code_placeholder')} />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'description']}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('device_type_feature_missing_description'),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t('device_type_feature_description_placeholder')} />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    {t('device_type_add_feature_button_text')}
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form.Item>
            <Form.Item
                // label="Properties"
                label={t('device_type_properties_label')}
                name="properties"
            >
                <Form.List name="properties">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        marginBottom: 8,
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'property']}
                                    >
                                        <Input placeholder={t('device_type_property_input_label')} />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    {t('device_type_add_attribute_button_text')}
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button loading={isLoading} type="primary" htmlType="submit" style={{ float: "right" }}>
                    Next
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateDeviceType;
