/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Modal, notification, Tree, Steps, Spin, Row, Col } from 'antd';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import axios from 'axios';
import RoleForm from '../RoleForm';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { TreeNode } = Tree;
const { Step } = Steps;

class AddRole extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.expandKeys = [];
    this.state = {
      isAddRoleModalVisible: false,
      isAddPermissionModalVisible: false,
      roleName: '',
      users: [],
      nodeList: [],
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      isNodeList: false,
      current: 0,
      loading: false,
      selectedUserStore: 'PRIMARY',
    };
    const { t } = this.props;
    this.steps = [
      {
        title: t('label_createRole'),
      },
      {
        title: t('label_assignPermissions'),
      },
    ];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isAddRoleModalVisible === false) {
      this.changeModalVisibility();
    }
  }

  changeModalVisibility = () => {
    let params = new URL(window.location).searchParams;
    let name = params.get('add-new-role');
    if (JSON.parse(name)) {
      this.setState({
        isAddRoleModalVisible: true,
      });
    }
  };

  onCancelHandler = e => {
    window.history.pushState(null, '', location.href.split('?')[0]);
    this.setState({
      isAddRoleModalVisible: false,
      isAddPermissionModalVisible: false,
      current: 0,
      checkedKeys: [],
    });
  };

  getCheckedPermissionsList = data => {
    data.forEach(item => {
      if (item !== null) {
        this.expandKeys.push(item.resourcePath);
        this.getCheckedPermissionsList(item.nodeList);
      } else {
        return null;
      }
    });
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onCheck = checkedKeys => {
    this.setState({ checkedKeys });
  };

  fetchRoleFormData = values => {
    this.onAddRole(values);
  };

  onAddRole = values => {
    const { t } = this.props;
    const roleData = {
      roleName: values.userStoreDomain + '/' + values.roleName,
      users: values.users,
    };
    this.setState({
      roleName: values.roleName,
      selectedUserStore: values.userStoreDomain,
      loading: true,
    });
    axios
      .post(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/roles',
        roleData,
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        if (res.status === 201) {
          this.props.fetchRoles();
          const current = this.state.current + 1;
          this.setState({
            isAddRoleModalVisible: true,
            isAddPermissionModalVisible: false,
            current,
            loading: false,
          });
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_addRoleMsg'),
          });
          this.loadPermissionList();
        }
      })
      .catch(error => {
        handleApiError(error, null, t, `${this.constructor.name}AddRole`);
        this.setState({ loading: false });
      });
  };

  renderTreeNodes = data => {
    const { t } = this.props;
    return data.map(item => {
      if (item !== null) {
        if (item.hasOwnProperty('nodeList')) {
          return (
            <TreeNode
              title={t(`permission_${item.displayName.split('-').join('_')}`)}
              key={item.resourcePath}
              dataRef={item}
            >
              {this.renderTreeNodes(item.nodeList)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.resourcePath} {...item} />;
      }

      // eslint-disable-next-line react/jsx-key
      return <TreeNode />;
    });
  };

  onAssignPermissions = () => {
    const { t } = this.props;
    const roleData = {
      roleName: this.state.selectedUserStore + '/' + this.state.roleName,
      permissions: this.state.checkedKeys,
    };
    this.setState({ loading: true });
    axios
      .put(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/roles/' +
        this.state.roleName +
        '?user-store=' +
        this.state.selectedUserStore,
        roleData,
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.props.fetchRoles();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_updatePermissionMsg'),
          });
          this.setState({
            isAddPermissionModalVisible: false,
            isAddRoleModalVisible: false,
            loading: false,
            checkedKeys: [],
            current: 0,
          });
          window.history.pushState(null, '', location.href.split('?')[0]);
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_addError', { label: t('label_permissions') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  loadPermissionList = () => {
    const { t } = this.props;
    let apiURL =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/roles/' +
      this.state.roleName +
      '/permissions?user-store=' +
      this.state.selectedUserStore;

    axios
      .get(apiURL)
      .then(res => {
        if (res.status === 200) {
          this.getCheckedPermissionsList(res.data.data.nodeList);
          this.setState({
            nodeList: res.data.data.nodeList,
            isNodeList: true,
            expandedKeys: this.expandKeys,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_permissions') }),
          t,
        );
      });
  };

  render() {
    const { current } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div>
          <Modal
            title={t('title_addNewRole')}
            open={this.state.isAddRoleModalVisible}
            onCancel={this.onCancelHandler}
            footer={null}
            maskClosable={false}
          >
            <Steps current={current}>
              {this.steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div style={{ marginTop: 20 }}>
              {current === 0 ? (
                <div>
                  <HtmlComments
                    permission={'/permission/admin/device-mgt/users/view'}
                  />
                  <RoleForm
                    action={'Add'}
                    loading={this.state.loading}
                    modalVisibility={this.state.isAddRoleModalVisible}
                    callback={values => this.fetchRoleFormData(values)}
                  />
                </div>
              ) : (
                <div style={{ alignItems: 'center' }}>
                  {this.state.isNodeList ? (
                    <Row>
                      <Col span={20}>
                        <Tree
                          checkable
                          onExpand={this.onExpand}
                          expandedKeys={this.state.expandedKeys}
                          autoExpandParent={this.state.autoExpandParent}
                          onCheck={this.onCheck}
                          checkedKeys={this.state.checkedKeys}
                        >
                          {this.renderTreeNodes(this.state.nodeList)}
                        </Tree>
                      </Col>
                      <Col span={4}>
                        <div style={{ float: 'right' }}>
                          <Button
                            type="primary"
                            onClick={this.onAssignPermissions}
                            loading={this.state.loading}
                          >
                            {t('label_assign')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <Spin />
                    </div>
                  )}
                </div>
              )}
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            title={t('title_changeRolePermission')}
            width="40%"
            open={this.state.isAddPermissionModalVisible}
            onOk={this.onAssignPermissions}
            onCancel={this.onCancelHandler}
            maskClosable={false}
            bodyStyle={{
              overflowY: 'scroll',
              maxHeight: '500px',
              marginLeft: '10px',
            }}
            footer={[
              <Button key="cancel" onClick={this.onCancelHandler}>
                {t('label_cancel')}
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={this.onAssignPermissions}
              >
                {t('label_assign')}
              </Button>,
            ]}
          >
            <div style={{ alignItems: 'center' }}>
              <div>
                {this.state.isNodeList && (
                  <Tree
                    checkable
                    onExpand={this.onExpand}
                    expandedKeys={this.state.expandedKeys}
                    autoExpandParent={this.state.autoExpandParent}
                    onCheck={this.onCheck}
                    checkedKeys={this.state.checkedKeys}
                  >
                    {this.renderTreeNodes(this.state.nodeList)}
                  </Tree>
                )}
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(AddRole));
