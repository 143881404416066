import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Button,
  Col,
  Collapse,
  Empty,
  Result,
  Row,
  Spin
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import axios from 'axios';
import { handleApiError } from '../../../../services/utils/errorHandler';
import { withConfigContext } from '../../../../components/ConfigContext';
import moment from 'moment';
import 'moment-timezone';
import ProfileReadingTable from './ProfileReadingTable';
import styles from './styles.module.css';
import { SearchOutlined } from '@ant-design/icons';
import Filter from '../../components/Filter';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import {
  getBranchName,
  getDepotName,
  getSubtypeId,
  getSubTypeName,
  meterReadings
} from '../../../../services/utils/functions';
import { SUBTYPE_MAPPING } from '../../../../constant/TableData';
import { CSVLink } from 'react-csv';
import HtmlComments from '../../../../components/ReactHtmlComments/HtmlComments';
import Authorized from '../../../../components/Authorized';
import { RoutesContext } from '../../index';
import dayjs from 'dayjs';

const ProfileHistoricalReading = props => {
  const config = props.context;
  const { t } = props;
  const routesContext = useContext(RoutesContext);
  const { Panel } = Collapse;
  const csvLinkEl = useRef(null);

  const routes = appName => [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home')
    },
    {
      breadcrumbName: t('home_menu_profileHistoricalReading')
    }
  ];

  const [devices, setDevices] = useState([]);
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState({
    pagination: {
      current: 1,
      pageSize: 10
    }
  });
  const [loading, setLoading] = useState(false);
  const [searchFields, setSearchFields] = useState([]);
  const [exportDataList, setExportDataList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const filterValuesRef = useRef('');
  const renderSerialLink = serial => (
    <Link
      to={`/sub-metering-mgt/load/profile-data?serial=${serial}`}
      target="_blank"
    >
      {serial}
    </Link>
  );
  const columns = [
    // {
    //     title: t('label_meter_name'),
    //     dataIndex: 'name',
    //     key: 'name',
    // },
    {
      title: t('label_serial'),
      dataIndex: 'serial',
      key: 'serial',
      render: renderSerialLink
    },
    {
      title: t('label_facility'),
      dataIndex: 'branch',
      key: 'branch',
      render: branch => getBranchName(branch)
    },
    {
      title: t('label_loadProfileTypes'),
      dataIndex: 'obis',
      key: 'obis'
    },

    {
      title: t('label_date'),
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: t('label_time'),
      dataIndex: 'time',
      key: 'time'
    }
  ];

  const startTimestamp = moment().startOf('day');
  const endTimestamp = moment();

  useEffect(() => {
    routesContext.setCurrentRoute('allProfileHistoricalReading');
    const fields = [
      {
        name: 'subTypeId',
        label: t('label_subTypeId'),
        placeholder: t('label_subTypeId'),
        type: 'multipleSelect',
        values: Object.keys(config.subTypeId).map(key => config.subTypeId[key])
      },
      {
        name: 'serial',
        label: t('label_serials'),
        placeholder: t('label_serials'),
        type: 'creatableSelect',
        mode: 'tags',
        values: []
      },
      {
        name: 'operationId',
        label: t('label_operationId'),
        placeholder: t('label_operationId'),
        type: 'input'
      },
      {
        name: 'createdDay',
        label: t('label_startDate'),
        placeholder: t('label_startDate'),
        type: 'calender',
        defaultValue: moment().startOf('day')
      },
      {
        name: 'updatedDay',
        label: t('label_endDate'),
        placeholder: t('label_endDate'),
        type: 'calender',
        defaultValue: moment()
      }
    ];
    setSearchFields(fields);

    // fetch data based on query parameters if has
    fetchOperationFilters(
      {},
      {
        createdDay: moment(startTimestamp),
        updatedDay: moment(endTimestamp)
      }
    );
  }, []);

  const calenderDates = (day, propertyName) => {
    let readingFilters = '';
    if (propertyName === 'createdDay') {
      const selectedFromDate = day || moment().startOf('day');
      readingFilters =
        readingFilters + '&startTimestamp=' + selectedFromDate.valueOf();
      return readingFilters;
    }
    if (propertyName === 'updatedDay') {
      const selectedToDate = day || moment();
      readingFilters =
        readingFilters + '&endTimestamp=' + selectedToDate.valueOf();
      return readingFilters;
    }
  };

  const fetchOperationFilters = (params, values) => {
    let readingFilters = '';
    if (Object.prototype.hasOwnProperty.call(values, 'subTypeId')) {
      for (let i = 0; i < values.subTypeId.length; i++) {
        const subtypeKey = SUBTYPE_MAPPING[values.subTypeId[i]];
        readingFilters = readingFilters + '&subTypeId=' + subtypeKey;
      }
    }
    if (Object.prototype.hasOwnProperty.call(values, 'serial')) {
      for (let i = 0; i < values.serial.length; i++) {
        readingFilters = readingFilters + '&serial=' + values.serial[i];
      }
    }
    if (
      Object.prototype.hasOwnProperty.call(values, 'operationId') &&
      values.operationId.trim() !== ''
    ) {
      readingFilters =
        readingFilters + '&operationId=' + values.operationId.trim();
    }
    if (!Object.prototype.hasOwnProperty.call(values, 'createdDay')) {
      const startTimestamp = moment()
        .startOf('day')
        .valueOf();
      readingFilters += `&startTimestamp=${startTimestamp}`;
    }
    if (!Object.prototype.hasOwnProperty.call(values, 'updatedDay')) {
      const endTimestamp = moment().valueOf();
      readingFilters += `&endTimestamp=${endTimestamp}`;
    }
    // eslint-disable-next-line no-unused-vars
    for (let property in values) {
      if (property === 'createdDay' && values[property]) {
        let selectedFromDate = moment(values.createdDay);
        readingFilters += calenderDates(selectedFromDate, property);
      }
      if (property === 'updatedDay' && values[property]) {
        let selectedToDate = moment(values.updatedDay);
        readingFilters += calenderDates(selectedToDate, property);
      }
    }
    filterValuesRef.current = readingFilters;
    setPagination({ current: 1, pageSize: 10 });
    getMeters({ current: 1, pageSize: 10 }, readingFilters);
  };

  const exportData = () => {
    setLoading(true);
    getData();
  };

  const getData = () => {
    setLoading(true);

    const getAllRecords = async (offset = 0, limit = 100) => {
      try {
        const response = await axios.get(
          window.location.origin +
            '/entgra-ui-request-handler/invoke' +
            // eslint-disable-next-line max-len
            `/device-mgt/power-meter-dlms/v1.0/admin/devices/load-profiles?${filterValuesRef.current}&offset=${offset}&limit=${limit}`
        );
        if (response.status === 200) {
          const exportData = response.data.data;
          let data = [];
          if (
            exportData.profileReadings &&
            exportData.profileReadings.length > 0
          ) {
            exportData.profileReadings.forEach((profileReading, index) => {
              if (profileReading.profileData) {
                const profileData = profileReading.profileData;
                data.push({
                  serial: profileReading.serial,
                  customerRef: profileReading?.customerRef,
                  date: profileData['0.9.2_0'],
                  time: profileData['0.9.1_0'],
                  timestamp: profileReading.timestamp,
                  obis: profileReading.obis,
                  importkWh: profileData['LP_1.8.0'],
                  exportkWh: profileData['LP_2.8.0'],
                  Importkvarh: profileData['LP_3.8.0'],
                  Exportkvarh: profileData['LP_4.8.0'],
                  PhaseAInstCurrent: profileData['LP_31.7.0'],
                  PhaseBInstCurrent: profileData['LP_51.7.0'],
                  PhaseCInstCurrent: profileData['LP_71.7.0'],
                  PhaseAInstVoltage: profileData['CO_32.7.0'],
                  PhaseBInstVoltage: profileData['LP_52.7.0'],
                  PhaseCInstVoltage: profileData['CO_72.7.0'],
                  PhaseABCPowerfactor: profileData['LP_13.5.0'],
                  AvgImportkW: profileData['LP_1.5.0'],
                  AvgExportkW: profileData['LP_2.5.0'],
                  AvgCurrent: profileData['LP_11.25.0'],
                  AvgVoltage: profileData['LP_12.25.0'],
                  AvgImportkVA: profileData['LP_9.5.0'],
                  AvgExportkVA: profileData['LP_10.5.0']
                });
              }
            });
          }
          return data;
        }
      } catch (error) {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_Usage') }),
          t
        );
        throw error;
      }
    };

    const fetchData = async () => {
      let allRecords = [];
      let offset = 0;
      const limit = 100;
      try {
        while (true) {
          const records = await getAllRecords(offset, limit);
          if (records.length === 0) {
            break;
          }
          allRecords = [...allRecords, ...records];
          offset += limit;
        }
        setExportDataList(allRecords);
        setLoading(false);
        setTimeout(() => {
          csvLinkEl.current.link.click();
        });
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  };

  const getMeters = (params = {}, filters = '') => {
    setLoading(true);
    const currentPage =
      Object.prototype.hasOwnProperty.call(params, 'page') && params.page
        ? params.page
        : 1;
    let limit = 10;
    if (params.results) {
      limit = params.results;
    }
    const offset = limit * (currentPage - 1);
    const extraParams = { offset: offset, limit: limit, ...params };
    let encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');
    if (filters !== '') {
      encodedExtraParams = encodedExtraParams + filters;
    }
    axios
      .get(
        window.location.origin +
          '/entgra-ui-request-handler/invoke' +
          '/device-mgt/power-meter-dlms/v1.0/admin/devices/load-profiles?' +
          encodedExtraParams
      )
      .then(res => {
        if (res.status === 200) {
          const metersData = res.data.data.profileReadings.map(
            profileReading => ({
              key: profileReading.id,
              serial: profileReading.serial,
              branch: profileReading?.branch,
              customerRef: profileReading?.customerRef,
              obis: profileReading?.obis,
              hasEbilling: profileReading.hasEbilling,
              hasExport: profileReading.hasExport,
              subTypeId: profileReading.subTypeId,
              timestamp: profileReading.timestamp,
              profileData: profileReading.profileData,
              date: profileReading.profileData['0.9.2_0'],
              time: profileReading.profileData['0.9.1_0']
            })
          );
          setDevices(metersData);
          setCount(res.data.data.count);
          setPagination({
            ...pagination,
            current: currentPage,
            pageSize: limit
          });
          setLoading(false);
          setExportDataList(metersData);
        }
      })
      .catch(error => {
        handleApiError(error, t('api_getOperationError'), t);
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    setPagination({ current, pageSize });
    // setPagination(pager);
    getMeters(
      {
        page: current,
        results: pageSize,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      },
      filterValuesRef.current
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getDetailsUI = profileData => {
    if (!profileData || Object.keys(profileData).length === 0) {
      return (
        <Empty
          className={styles.customEmpty}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      );
    }
    return (
      <div>
        <div className={styles.responseRow}>
          <div className={styles.flexContainer}>
            {Object.keys(meterReadings)
              .filter(key => profileData[key] !== undefined)
              .map((key, index) => (
                <p key={index}>
                  <strong>{meterReadings[key].text}&nbsp;: </strong>
                  {profileData[key]}
                </p>
              ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <HtmlComments
        permission={
          '/permission/admin/device-mgt/rmr/device/admin/view-meter-reading'
        }
      />
      <Authorized
        scope={['perm:rmr:admin:view-meter-reading']}
        yes={
          <div>
            <PageHeader
              className={styles.pageHeader}
              ghost={false}
              breadcrumb={
                <BreadcrumbComponent breadcrumbList={routes(config.appName)} />
              }
            />
            <div className={styles.table}>
              <Row gutter={[16, 16]} justify="space-between">
                <Col xs={12} sm={12} md={16} lg={16}>
                  <Collapse
                    bordered={false}
                    expandIcon={() => <SearchOutlined />}
                    className={styles.searchCollapse}
                  >
                    <Panel
                      header={t('form_searchReadings')}
                      key="1"
                      style={{ borderColor: '#fff', fontSize: '12px' }}
                    >
                      <Filter
                        filters={searchFields}
                        callback={(params, values) =>
                          fetchOperationFilters({}, values)
                        }
                      />
                    </Panel>
                  </Collapse>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%'
                  }}
                >
                  <div style={{ textAlign: 'right', width: '100%' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={exportData}
                      disabled={count === 0}
                      style={{ fontSize: '12px' }}
                    >
                      Export CSV
                    </Button>
                    <CSVLink
                      filename={`ProfileReadings_${moment().format(
                        'YYYY-MM-DD'
                      )}.csv`}
                      data={exportDataList}
                      headers={[
                        { label: 'SERIAL', key: 'serial', width: 100 },
                        { label: 'CUSTOMER_REF', key: 'customerRef' },
                        { label: 'TIMESTAMP', key: 'timestamp' },
                        { label: 'OBIS', key: 'obis' },
                        { label: 'Date', key: 'date' },
                        { label: 'Time', key: 'time' },
                        { label: 'Import kWh (kWh)', key: 'importkWh' },
                        { label: 'Export kWh (kWh)', key: 'exportkWh' },
                        { label: 'Import kvarh (kvarh)', key: 'Importkvarh' },
                        { label: 'Export kvarh (kvarh)', key: 'Exportkvarh' },
                        {
                          label: 'Phase A Inst. Current (A)',
                          key: 'PhaseAInstCurrent'
                        },
                        {
                          label: 'Phase B Inst. Current (A)',
                          key: 'PhaseBInstCurrent'
                        },
                        {
                          label: 'Phase C Inst. Current (A)',
                          key: 'PhaseCInstCurrent'
                        },
                        {
                          label: 'Phase A Inst. Voltage (V)',
                          key: 'PhaseAInstVoltage'
                        },
                        {
                          label: 'Phase B Inst. Voltage (V)',
                          key: 'PhaseBInstVoltage'
                        },
                        {
                          label: 'Phase C Inst. Voltage (V)',
                          key: 'PhaseCInstVoltage'
                        },
                        {
                          label: 'Phase ABC Power factor',
                          key: 'PhaseABCPowerfactor'
                        },
                        { label: 'Avg. Import kW (kW)', key: 'AvgImportkW' },
                        { label: 'Avg. Export kW (kW)', key: 'AvgExportkW' },
                        { label: 'Avg. Current (V)', key: 'AvgCurrent' },
                        { label: 'Avg. Voltage (V)', key: 'AvgVoltage' },
                        { label: 'Avg. Import kVA (kVA)', key: 'AvgImportkVA' },
                        { label: 'Avg. Export kVA (kVA)', key: 'AvgExportkVA' }
                      ]}
                      ref={csvLinkEl}
                    />
                  </div>
                </Col>
              </Row>
              {loading && (
                <div style={{ textAlign: 'center' }}>
                  <Spin />
                </div>
              )}
              {!loading > 0 && (
                <ProfileReadingTable
                  devices={devices}
                  count={count}
                  columns={columns}
                  pagination={pagination}
                  loading={loading}
                  handleTableChange={handleTableChange}
                  getDetailsUI={getDetailsUI}
                />
              )}
            </div>
          </div>
        }
        no={
          <Result
            status="403"
            title={t('noPerm_accessPageTitle')}
            subTitle={t('noPerm_contactSysAdmin')}
          />
        }
      />
    </div>
  );
};

export default withConfigContext(withTranslation()(ProfileHistoricalReading));
