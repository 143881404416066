import React from 'react';
import { Row, Col, Divider } from 'antd';

const DefaultViewEditor = ({
  t,
  maxRoots,
  maxDepth,
  updateMaxRoots,
  updateMaxDepth,
  resetValues,
  loadingOrganizations,
  selectedEdgeType,
  handleEdgeTypeChange,
  handleKeypress,
  onLayout
}) => {
  const onLayoutChange = value => {
    if (value === 'VL') {
      onLayout({ direction: 'DOWN' });
    } else if (value === 'HL') {
      onLayout({ direction: 'RIGHT' });
    }
  };
  return (
    <>
      <h3>Search Filters</h3>
      <Row
        gutter={[16, 0]}
        justify="space-between"
        align="middle"
        style={{ marginBottom: '20px' }}
      >
        <Col span={12}>
          <label htmlFor="maxRoots">{t('label_max_roots')}</label>
        </Col>
        <Col span={12}>
          <input
            id="maxRoots"
            className="viewport-panel-input"
            addonBefore={t('label_max_roots')}
            placeholder="Number"
            onKeyDown={handleKeypress}
            type="number"
            min={1}
            max={99}
            enterKeyHint="search"
            value={maxRoots}
            onChange={e => updateMaxRoots(e.target.value)}
            disabled={loadingOrganizations}
          />
        </Col>
      </Row>
      <Row
        gutter={[16, 0]}
        justify="space-between"
        align="middle"
        style={{ marginBottom: '20px' }}
      >
        <Col span={12}>
          <label htmlFor="maxDepth">{t('label_max_depth')}</label>
        </Col>
        <Col span={12}>
          <input
            id="maxDepth"
            className="viewport-panel-input"
            addonBefore={t('label_max_depth')}
            placeholder="Number"
            onKeyDown={handleKeypress}
            type="number"
            min={1}
            max={99}
            enterKeyHint="search"
            value={maxDepth}
            onChange={e => updateMaxDepth(e.target.value)}
            disabled={loadingOrganizations}
          />
        </Col>
      </Row>
      <Row
        gutter={[16, 0]}
        justify="space-between"
        align="middle"
        style={{ marginBottom: '20px' }}
      >
        <Col span={12}></Col>
        <Col span={6}>
          <button
            className="viewport-panel-input"
            onClick={resetValues}
            title={t('label_reset')}
            aria-label="Reset"
            style={{ width: '60px' }}
          >
            {t('label_reset')}
          </button>
        </Col>
      </Row>
      <Divider />
      <h3>Display Options</h3>
      <Row align="middle" gutter={[16, 0]} style={{ marginTop: '20px' }}>
        <Col span={12}>
          <label htmlFor="edgeType">{t('label_edge_type')}</label>
        </Col>
        <Col span={12}>
          <select
            id="edgeType"
            className="viewport-panel-select"
            value={selectedEdgeType}
            onChange={handleEdgeTypeChange}
          >
            <option
              value="bezier"
              title={t('label_bezier')}
              aria-label="bezier edge"
            >
              Bezier Edge
            </option>
            <option
              value="smooth"
              title={t('label_smooth_step')}
              aria-label="smooth step edge"
            >
              Smooth Step Edge
            </option>
          </select>
        </Col>
      </Row>
      <Row align="middle" gutter={[16, 0]} style={{ marginTop: '20px' }}>
        <Col span={12}>
          <label htmlFor="layoutType">{t('label_layout_type')}</label>
        </Col>
        <Col span={12}>
          <select
            id="layoutType"
            className="viewport-panel-select"
            defaultValue="VL"
            onChange={e => {
              onLayoutChange(e.target.value);
            }}
          >
            <option
              value="VL"
              title={t('label_vl')}
              aria-label="vertical layout"
            >
              Vertical Layout
            </option>
            <option
              value="HL"
              title={t('label_hl')}
              aria-label="horizontal layout"
            >
              Horizontal Layout
            </option>
          </select>
        </Col>
      </Row>
    </>
  );
};

export default DefaultViewEditor;
