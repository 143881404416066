/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  Tooltip,
  Checkbox,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 10 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
  },
};

const buttonFormItem = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 16 },
    lg: { span: 22 },
    xl: { span: 22 },
  },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 18 },
};

class PolicyConfiguration extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
  }

  componentDidMount() {
    const { platformConfigData } = this.props;
    let initialValues = {};
    for (let config of platformConfigData) {
      if (config.name === 'deviceAppList') {
        initialValues.deviceAppList = config.value;
      }
      if (config.name === 'isCustomDeviceNameRequired') {
        initialValues.isCustomDeviceNameRequired = config.value;
      }
    }
    this.formRef.current.setFieldsValue(initialValues);
  }

  onFinish = values => {
    const payloadValues = {
      deviceAppList: values.deviceAppList,
      isCustomDeviceNameRequired: values.isCustomDeviceNameRequired,
    };
    this.props.callback(payloadValues);
  };

  onFinishFailed = () => {
    console.log('Finish Failed .......');
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <Divider>
            <PageHeader
              className="site-page-header-responsive"
              subTitle={t('label_androidPolicyConfigs')}
            />
          </Divider>
        </div>
        <div>
          <Form
            {...formItemLayout}
            name="AndroidPolicyConfigForm"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            ref={this.formRef}
          >
            <Form.Item
              label={
                <span>
                  {t('label_allowingApplicationList')}&nbsp;
                  <Tooltip title={t('allowingApplicationList_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="deviceAppList"
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              {...tailLayout}
              name="isCustomDeviceNameRequired"
              valuePropName="checked"
            >
              <Checkbox>
                <span>
                  {t('label_enableForceDeviceRenaming')}&nbsp;
                  <Tooltip title={t('enableForceDeviceRenaming_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              </Checkbox>
            </Form.Item>
            <Form.Item {...buttonFormItem}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: 'right' }}
              >
                {t('label_configure')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PolicyConfiguration);
