/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState, useEffect } from 'react';
import DateInput from '../Elements/DateInput';
import {
  subHours,
  subDays,
  fromUnixTime,
  startOfDay,
  endOfDay,
  startOfWeek,
  startOfMonth,
  subWeeks,
  subMonths,
  endOfMonth,
  endOfWeek,
  startOfQuarter,
  endOfQuarter,
} from 'date-fns';
import { getUnixTime } from '../../../../../services/utils/functions';
import styles from './styles.module.css';
import { Button } from 'antd';
import { CalendarOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

function DateRangeDropdown({
  getRange,
  alignDropdown,
  closeOnBackground,
  reset,
  startTimestamp,
  endTimestamp,
  disabled,
  range,
}) {
  const [startDate, setStartDate] = useState(
    new Date(fromUnixTime(startTimestamp)),
  );
  const [endDate, setEndDate] = useState(new Date(fromUnixTime(endTimestamp)));
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [currentDateRange, setCurrentDateRange] = useState('Custom');

  const rightNow = new Date();
  const currentMonth = rightNow.getMonth();
  let startOfFiscalQuarter;
  let endOfFiscalQuarter;
  let startOfPreviousFiscalQuarter;
  let endOfPreviousFiscalQuarter;
  const yesterday = subDays(rightNow, 1);
  const startOfWeekDate = startOfWeek(rightNow);
  const startOfMonthDate = startOfMonth(rightNow);
  const startOfPreviousWeek = startOfWeek(subWeeks(rightNow, 1));
  const endOfPreviousWeek = endOfWeek(subWeeks(rightNow, 1));
  const startOfPreviousMonth = startOfMonth(subMonths(rightNow, 1));
  const endOfPreviousMonth = endOfMonth(subMonths(rightNow, 1));

  // Determine fiscal quarter start and end dates based on current month
  if (currentMonth >= 3 && currentMonth <= 5) {
    // April, May, June
    startOfFiscalQuarter = startOfMonth(new Date(rightNow.getFullYear(), 3, 1)); // April 1st
    endOfFiscalQuarter = endOfMonth(new Date(rightNow.getFullYear(), 5, 30)); // June 30th
  } else if (currentMonth >= 6 && currentMonth <= 8) {
    // July, August, September
    startOfFiscalQuarter = startOfMonth(new Date(rightNow.getFullYear(), 6, 1)); // July 1st
    endOfFiscalQuarter = endOfMonth(new Date(rightNow.getFullYear(), 8, 30)); // September 30th
  } else if (currentMonth >= 9 && currentMonth <= 11) {
    // October, November, December
    startOfFiscalQuarter = startOfMonth(new Date(rightNow.getFullYear(), 9, 1)); // October 1st
    endOfFiscalQuarter = endOfMonth(new Date(rightNow.getFullYear(), 11, 31)); // December 31st
  } else {
    // January, February, March
    startOfFiscalQuarter = startOfMonth(
      new Date(rightNow.getFullYear() - 1, 9, 1),
    ); // October 1st of previous year
    endOfFiscalQuarter = endOfMonth(
      new Date(rightNow.getFullYear() - 1, 11, 31),
    ); // December 31st of previous year
  }

  // Determine Previous fiscal quarter start and end dates based on current month
  if (currentMonth >= 3 && currentMonth <= 5) {
    // April, May, June
    startOfPreviousFiscalQuarter = startOfMonth(
      new Date(rightNow.getFullYear(), 0, 1),
    ); // January 1st
    endOfPreviousFiscalQuarter = endOfMonth(
      new Date(rightNow.getFullYear(), 2, 31),
    ); // March 31st
  } else if (currentMonth >= 6 && currentMonth <= 8) {
    // July, August, September
    startOfPreviousFiscalQuarter = startOfMonth(
      new Date(rightNow.getFullYear(), 3, 1),
    ); // April 1st
    endOfPreviousFiscalQuarter = endOfMonth(
      new Date(rightNow.getFullYear(), 5, 30),
    ); // June 30th
  } else if (currentMonth >= 9 && currentMonth <= 11) {
    // October, November, December
    startOfPreviousFiscalQuarter = startOfMonth(
      new Date(rightNow.getFullYear(), 6, 1),
    ); // July 1st
    endOfPreviousFiscalQuarter = endOfMonth(
      new Date(rightNow.getFullYear(), 8, 30),
    ); // September 30th
  } else {
    // January, February, March
    startOfPreviousFiscalQuarter = startOfMonth(
      new Date(rightNow.getFullYear() - 1, 9, 1),
    ); // October 1st of previous year
    endOfPreviousFiscalQuarter = endOfMonth(
      new Date(rightNow.getFullYear() - 1, 11, 31),
    ); // December 31st of previous year
  }

  const timeRanges = [
    { label: 'Last 3 hours', start: subHours(rightNow, 3), end: rightNow },
    { label: 'Last 6 hours', start: subHours(rightNow, 6), end: rightNow },
    { label: 'Last 12 hours', start: subHours(rightNow, 12), end: rightNow },
    { label: 'Last 24 hours', start: subHours(rightNow, 24), end: rightNow },
    { label: 'Last 2 days', start: subDays(rightNow, 2), end: rightNow },
    { label: 'Last 7 days', start: subDays(rightNow, 7), end: rightNow },
    { label: 'Last 30 days', start: subDays(rightNow, 30), end: rightNow },
    { label: 'Last 90 days', start: subDays(rightNow, 90), end: rightNow },
    { label: 'Today so far', start: startOfDay(rightNow), end: rightNow },
    {
      label: 'Yesterday',
      start: startOfDay(yesterday),
      end: endOfDay(yesterday),
    },
    { label: 'This week so far', start: startOfWeekDate, end: rightNow },
    { label: 'This month so far', start: startOfMonthDate, end: rightNow },
    {
      label: 'Previous week',
      start: startOfPreviousWeek,
      end: endOfPreviousWeek,
    },
    {
      label: 'Previous month',
      start: startOfPreviousMonth,
      end: endOfPreviousMonth,
    },
    {
      label: 'This fiscal quarter so far',
      start: startOfFiscalQuarter,
      end: rightNow,
    },
    {
      label: 'Previous fiscal quarter',
      start: startOfPreviousFiscalQuarter,
      end: endOfPreviousFiscalQuarter,
    },
  ];

  const makeTimeRange = (label, start, end) => {
    setStartDate(start);
    setEndDate(end);
    setCurrentDateRange(label);
    setToggleDropdown(false);
    getRange({ from: getUnixTime(start), to: getUnixTime(end) });
  };

  const handleToggleDropdown = () => {
    setToggleDropdown(!toggleDropdown);
  };

  const handleCustomRangeApply = () => {
    makeTimeRange('Custom', startDate, endDate);
  };

  let defaultRange;
  if (range) {
    defaultRange = timeRanges[range];
  } else {
    defaultRange = timeRanges[4];
  }

  useEffect(() => {
    makeTimeRange(defaultRange.label, defaultRange.start, defaultRange.end);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reset) {
      makeTimeRange(defaultRange.label, defaultRange.start, defaultRange.end);
    }
  }, [reset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Check if startTimestamp and endTimestamp are provided via URL params
    if (startTimestamp && endTimestamp) {
      setCurrentDateRange('Custom');
      setToggleDropdown(false);
    }
  }, [startTimestamp, endTimestamp]);

  return (
    <div
      className={styles.dateRanger}
      style={{ pointerEvents: disabled ? 'none' : 'auto' }}
    >
      <div className={styles.dateRangerPicker} onClick={handleToggleDropdown}>
        <CalendarOutlined />
        <span className={styles.datePicker.span}>{currentDateRange}</span>
        {!toggleDropdown ? <UpOutlined /> : <DownOutlined />}
      </div>
      {toggleDropdown && (
        <>
          {closeOnBackground && (
            <div
              className={styles.dateRangerOverlay}
              onClick={() => setToggleDropdown(false)}
            ></div>
          )}
          <div className={styles.dateRangerPickerDropdown}>
            <div className={styles.dateRangerPickerColumns}>
              <div className={styles.absoluteTimeRange}>
                <label>
                  <b>Absolute time range</b>
                </label>
                <div className="mb-2 ml-2">
                  <label htmlFor="fromDate">From</label>
                  <DateInput
                    id="fromDate"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    className="form-control"
                  />
                </div>
                <div className="ml-2">
                  <label htmlFor="toDate">To</label>
                  <DateInput
                    id="toDate"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    className="form-control"
                    disabled={disabled}
                  />
                  <Button
                    key="makeTimeRange"
                    type="primary"
                    icon={<CalendarOutlined />}
                    onClick={handleCustomRangeApply}
                    style={{ marginTop: 10 }}
                  >
                    Apply time range
                  </Button>
                </div>
              </div>
              <div className={styles.relativeTimeRanges}>
                <label>
                  <b>Relative time ranges</b>
                </label>
                <ul className={styles.scrollableList}>
                  {timeRanges.map((time, index) => (
                    <li key={`time-range-${index}`}>
                      <Button
                        key="custom"
                        type="default"
                        onClick={() =>
                          makeTimeRange(time.label, time.start, time.end)
                        }
                        style={{ color: '#666f77' }}
                      >
                        {time.label}
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default DateRangeDropdown;
