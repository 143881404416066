/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Select,
  Row,
  Form,
  Input,
  Card,
  Space,
  Col,
  Tooltip,
  DatePicker
} from 'antd';
import {
  PlusOutlined,
  CloseOutlined,
  SearchOutlined,
  ClearOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const { Option } = Select;
const { RangePicker } = DatePicker;

const FilterNew = ({ filters, callback, t }) => {
  const formRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectableItems, setSelectableItems] = useState([]);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [enableClear, setEnableClear] = useState(false);
  const [labelNameMap, setLabelNameMap] = useState({});
  const [selectedItemObjectMap, setSelectedItemObjectMap] = useState({});
  const [initValues, setInitValues] = useState({});
  const formItemMargin = -2;

  useEffect(() => {
    initializeFilters();
  }, []);

  const initializeFilters = () => {
    let tempLabelNameMap = labelNameMap;
    let tempInitValues = {};
    filters.forEach(filter => {
      tempLabelNameMap[filter.label] = filter.name;
      if (filter.defaultValue) {
        tempInitValues[filter.name] = filter.defaultValue;
      }
    });

    setInitValues(tempInitValues);
    setLabelNameMap(tempLabelNameMap);
    setSelectableItems(filters);
  };

  const handleFinish = values => {
    let payload = {};
    values.filters.forEach(value => {
      payload[labelNameMap[value.filter]] = value.value;
    });

    callback({}, payload);
  };

  const handleSelectValue = (key, value) => {
    // temporary fix
    // Check if the selected filter is "a Date"
    const isDate = value === 'Start Date' || value === 'End Date';

    let tempFilters = formRef.current.getFieldsValue();

    // If the selected filter is "a Date," remove the "value" property from the form data
    if (isDate) {
      delete tempFilters.filters[key].value;
    }

    // Reset the form fields to reflect the changes
    formRef.current.setFieldsValue(tempFilters);

    let selectedValue = value;
    filters.forEach(filter => {
      if (filter.label === value) {
        selectedValue = filter.name;
      }
    });

    let tempSelectableItems = [...selectableItems];

    let tempSelectedItems = { ...selectedItems };

    if (Object.keys(tempSelectedItems).length !== 0) {
      filters.forEach(filter => {
        if (filter.name === tempSelectedItems[key]) {
          tempSelectableItems.push(filter);
        }
      });
    }

    tempSelectableItems.forEach((item, index) => {
      if (item.name === selectedValue) {
        tempSelectableItems.splice(index, 1);
      }
    });

    tempSelectedItems[key] = selectedValue;

    let tempSelectedItemObjectMap = { ...selectedItemObjectMap };
    filters.forEach(filter => {
      if (filter.name === selectedValue) {
        tempSelectedItemObjectMap[key] = filter;
      }
    });
    let tempSearchEnabled = Object.keys(tempSelectedItems).length > 0;

    setSelectableItems(tempSelectableItems);
    setSelectedItems(tempSelectedItems);
    setSelectedItemObjectMap(tempSelectedItemObjectMap);
    setSearchEnabled(tempSearchEnabled);
  };

  const handleRemoveItem = key => {
    let tempSelectedItems = { ...selectedItems };
    let tempSelectableItems = [...selectableItems];

    filters.forEach(filter => {
      if (filter.name === tempSelectedItems[key]) {
        tempSelectableItems.push(filter);
      }
    });
    setSelectableItems(tempSelectableItems);

    delete tempSelectedItems[key];
    setSelectedItems(tempSelectedItems);

    setSearchEnabled(Object.keys(tempSelectedItems).length > 0);
    setEnableClear(Object.keys(tempSelectedItems).length > 0);
  };

  const clearForm = () => {
    formRef.current.resetFields();
    setSelectedItems({});
    setSelectableItems(filters);
    setSearchEnabled(false);
    setEnableClear(false);
    callback({}, {});
  };

  const modifyFilters = () => {
    let tempSelectableItems = [...selectableItems];
    let tempSelectedItems = { ...selectedItems };

    tempSelectableItems.forEach((item, index) => {
      if (Object.values(tempSelectedItems).includes(item.name)) {
        tempSelectableItems.splice(index, 1);
      }
    });

    if (tempSelectableItems.length === 0) {
      setSelectableItems(filters);
      setEnableClear(true);
    } else {
      setSelectableItems(tempSelectableItems);
      setEnableClear(true);
    }
  };

  return (
    <Form
      name="dynamic_form_nest_item"
      onFinish={handleFinish}
      autoComplete="off"
      ref={formRef}
      // initialValues={initValues}
    >
      <Row>
        <Col span={22}>
          <div>
            <Form.List name={'filters'}>
              {(fields, { add, remove }) => {
                return (
                  <div>
                    <Row>
                      {fields.map(field => (
                        <Card
                          key={field.key}
                          bodyStyle={{ padding: 3 }}
                          style={{
                            borderRadius: 10,
                            marginLeft: '3px',
                            marginBottom: '3px'
                          }}
                          hoverable
                        >
                          <Space size={'small'}>
                            <Form.Item
                              // {...field}
                              name={[field.name, 'filter']}
                              fieldKey={[field.fieldKey, 'filter']}
                              rules={[
                                {
                                  required: true,
                                  message: t('label_filter_required')
                                }
                              ]}
                              style={{ marginBottom: formItemMargin }}
                            >
                              <Select
                                autoFocus
                                defaultOpen={true}
                                size={'small'}
                                bordered={false}
                                placeholder={t('label_select_folder')}
                                onSelect={value =>
                                  handleSelectValue(field.key, value)
                                }
                                dropdownMatchSelectWidth={false}
                              >
                                {selectableItems.map(filter => {
                                  return (
                                    <Option
                                      key={filter.name}
                                      value={filter.label}
                                    >
                                      {filter.label}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            {selectedItemObjectMap[field.key] &&
                              selectedItemObjectMap[field.key].type ===
                                'select' && (
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'value']}
                                  fieldKey={[field.fieldKey, 'value']}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('label_value_required')
                                    }
                                  ]}
                                  style={{ marginBottom: formItemMargin }}
                                >
                                  <Select
                                    autoFocus
                                    defaultOpen={false}
                                    size={'small'}
                                    bordered={false}
                                    placeholder={
                                      `${t('label_select')} ` +
                                      selectedItemObjectMap[field.key]
                                        .placeholder
                                    }
                                    dropdownMatchSelectWidth={false}
                                  >
                                    {selectedItemObjectMap[
                                      field.key
                                    ].values.map((value, i) => {
                                      return (
                                        <Option key={i} value={value}>
                                          {value}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              )}
                            {selectedItemObjectMap[field.key] &&
                              selectedItemObjectMap[field.key].type ===
                                'creatableSelect' && (
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'value']}
                                  fieldKey={[field.fieldKey, 'value']}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('label_value_required')
                                    }
                                  ]}
                                  style={{ marginBottom: formItemMargin }}
                                >
                                  <Select
                                    autoFocus
                                    defaultOpen={false}
                                    size={'small'}
                                    bordered={false}
                                    mode="tags"
                                    placeholder={
                                      `${t('label_select')} ` +
                                      selectedItemObjectMap[field.key]
                                        .placeholder
                                    }
                                    dropdownMatchSelectWidth={false}
                                  >
                                    {selectedItemObjectMap[
                                      field.key
                                    ].values.map((value, i) => {
                                      return (
                                        <Option key={i} value={value}>
                                          {value}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              )}
                            {selectedItemObjectMap[field.key] &&
                              selectedItemObjectMap[field.key].type ===
                                'calender' && (
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'value']}
                                  fieldKey={[field.fieldKey, 'value']}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('label_value_required')
                                    },
                                    {
                                      validator: async (_, value) => {
                                        if (
                                          !value ||
                                          !moment(value).isValid()
                                        ) {
                                          throw new Error(
                                            t('label_invalid_date')
                                          );
                                        }
                                      }
                                    }
                                  ]}
                                  style={{ marginBottom: formItemMargin }}
                                  // initialValue={field.defaultValue}
                                >
                                  <DatePicker
                                    showTime
                                    renderExtraFooter={() => 'extra footer'}
                                    autoFocus
                                    // defaultValue={
                                    //   selectedItemObjectMap[field.key]
                                    //     .defaultValue
                                    // }
                                    format="YYYY-MM-DD HH:mm:ss"
                                    disabledDate={current =>
                                      current &&
                                      current > moment(new Date(), 'YYYY-MM-DD')
                                    }
                                    placeholder={t('label_select_date')}
                                  />
                                </Form.Item>
                              )}
                            {selectedItemObjectMap[field.key] &&
                              selectedItemObjectMap[field.key].type ===
                                'multipleSelect' && (
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'value']}
                                  fieldKey={[field.fieldKey, 'value']}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('label_value_required')
                                    }
                                  ]}
                                  style={{
                                    marginBottom: formItemMargin,
                                    minWidth: 170
                                  }}
                                >
                                  <Select
                                    autoFocus
                                    mode="multiple"
                                    defaultOpen={false}
                                    size={'small'}
                                    bordered={false}
                                    placeholder={
                                      `${t('label_select')} ` +
                                      selectedItemObjectMap[field.key]
                                        .placeholder
                                    }
                                    dropdownMatchSelectWidth={false}
                                  >
                                    {selectedItemObjectMap[
                                      field.key
                                    ].values.map((value, i) => {
                                      return (
                                        <Option key={i} value={value}>
                                          {value}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              )}

                            {selectedItemObjectMap[field.key] &&
                              selectedItemObjectMap[field.key].type ===
                                'input' && (
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'value']}
                                  fieldKey={[field.fieldKey, 'value']}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('label_value_required')
                                    }
                                  ]}
                                  style={{ marginBottom: formItemMargin }}
                                >
                                  <Input
                                    autoFocus
                                    size={'small'}
                                    style={{ border: 'none' }}
                                    placeholder={
                                      `${t('label_input')} ` +
                                      selectedItemObjectMap[field.key]
                                        .placeholder
                                    }
                                  />
                                </Form.Item>
                              )}
                            <Form.Item
                              fieldKey={[field.fieldKey, 'remove']}
                              style={{ marginBottom: formItemMargin }}
                            >
                              <CloseOutlined
                                onClick={() => {
                                  remove(field.name);
                                  handleRemoveItem(field.key);
                                  if (fields.length === 1) {
                                    callback({}, {});
                                  }
                                }}
                                style={{ marginLeft: 3, marginRight: 10 }}
                              />
                            </Form.Item>
                          </Space>
                        </Card>
                      ))}
                      <Form.Item style={{ marginBottom: -8 }}>
                        <Tooltip title={t('label_add_tag')}>
                          <Button
                            disabled={!(fields.length + 1 <= filters.length)}
                            type="dashed"
                            size={'large'}
                            icon={<PlusOutlined />}
                            style={{
                              marginLeft: '5px',
                              marginBottom: '9px'
                            }}
                            onClick={() => {
                              add();
                              modifyFilters();
                            }}
                          />
                        </Tooltip>
                      </Form.Item>
                      <Form.Item style={{ marginBottom: -8 }}>
                        <Tooltip title={t('label_search')}>
                          <Button
                            disabled={!searchEnabled}
                            htmlType={'submit'}
                            type="primary"
                            icon={<SearchOutlined />}
                            size={'large'}
                            style={{
                              marginLeft: '5px'
                            }}
                          />
                        </Tooltip>
                      </Form.Item>
                    </Row>
                  </div>
                );
              }}
            </Form.List>
          </div>
        </Col>

        <Col span={2}>
          <div style={{ float: 'right' }}>
            <Space>
              <Form.Item style={{ marginBottom: -8 }}>
                <Tooltip title={t('label_clear_tag')}>
                  <Button
                    disabled={!enableClear}
                    type="dashed"
                    danger
                    icon={<ClearOutlined />}
                    size={'large'}
                    style={{ borderRadius: 10, marginLeft: '3px' }}
                    onClick={clearForm}
                  />
                </Tooltip>
              </Form.Item>
            </Space>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default withTranslation()(FilterNew);
