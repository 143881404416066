/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import axios from 'axios';
import { Alert, Layout } from 'antd';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { notification } from 'antd';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import RouteWithSubRoutes from '../../../../../../../../components/RouteWithSubRoutes';
import { Redirect, Switch } from 'react-router-dom';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { Content } = Layout;

class GeneralConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (isAuthorized(this.config.scopes, ['perm:view-configuration'])) {
      this.getGeneralConfigs();
    }
  }

  generalPlatformConfigData = [];

  getGeneralConfigs = () => {
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          '/device-mgt/v1.0/configuration',
      )
      .then(res => {
        if (res.status === 200) {
          let responseData = res.data.data.configuration;
          if (responseData) {
            this.generalPlatformConfigData = responseData;
          }
          this.setState({ loading: true });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_generalConfigs') }),
          t,
        );
      });
  };

  updatePlatformConfig = values => {
    let newConfigs = [];
    const { t } = this.props;
    let existingConfig = false;
    let configPayload = [...this.generalPlatformConfigData];
    for (let key of Object.keys(values)) {
      for (let config of configPayload) {
        if (
          config.name === key &&
          (typeof values[key] === 'boolean' || values[key])
        ) {
          config.value = values[key];
          existingConfig = true;
          break;
        }
      }
      if (
        !existingConfig &&
        (typeof values[key] === 'boolean' || values[key])
      ) {
        newConfigs.push({
          name: key,
          value: values[key],
        });
      }
      existingConfig = false;
    }

    configPayload.push(...newConfigs);
    let requestPayload = {};
    requestPayload.configuration = configPayload;

    let apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/v1.0/configuration';
    axios
      .put(apiUrl, requestPayload, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => {
        if (res.status === 200) {
          notification.success({
            message: t('api_successMsg'),
            description: t('api_updateConfigMsg', {
              label: t('label_generalPlatformConfigs'),
            }),
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_updateConfigError', {
            label: t('label_generalPlatformConfigs'),
          }),
          t,
        );
      });
  };

  onFinish = values => {
    this.updatePlatformConfig(values);
  };

  render() {
    const { t } = this.props;
    return this.state.loading ? (
      <div>
        {!isAuthorized(this.config.scopes, ['perm:manage-configuration']) && (
          <div>
            <HtmlComments
              permission={
                '/permission/admin/device-mgt/platform-configurations/manage'
              }
            />
            <Alert
              message={t('noPerm_manageGeneralPlatform')}
              banner
              style={{ marginBottom: 15 }}
            />
          </div>
        )}
        <HtmlComments
          permission={
            '/permission/admin/device-mgt/platform-configurations/manage'
          }
        />
        <Content
          style={
            isAuthorized(this.config.scopes, ['perm:manage-configuration'])
              ? {
                  background: '#fff',
                  padding: '0 24px',
                  minHeight: 280,
                }
              : {
                  pointerEvents: 'none',
                  opacity: '0.5',
                  padding: '0 24px',
                  minHeight: 280,
                }
          }
        >
          <Switch>
            <Redirect
              exact
              from={`/${this.config.appName}/configurations/platform-configuration/general-config`}
              to={`/${this.config.appName}/configurations/platform-configuration/general-config/general`}
            />
            {this.props.routes.map(route => (
              <RouteWithSubRoutes
                key={route.path}
                {...route}
                context={this.config}
                platformConfigData={this.generalPlatformConfigData}
                callback={values => {
                  this.updatePlatformConfig(values);
                }}
                config={this.config}
              />
            ))}
          </Switch>
        </Content>
      </div>
    ) : null;
  }
}

export default withConfigContext(withTranslation()(GeneralConfiguration));
