/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Modal, notification } from 'antd';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import DEPProfileForm from '../DEPProfileForm';
import { withTranslation } from 'react-i18next';

class AddDEPProfiles extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isSupervised: false,
    };
  }

  componentDidMount() {
    this.changeModalVisibility();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isModalVisible === false) {
      this.changeModalVisibility();
    }
  }

  changeModalVisibility = () => {
    let params = new URL(window.location).searchParams;
    let name = params.get('add-dep-profile');
    if (JSON.parse(name)) {
      this.setState({
        isModalVisible: true,
      });
    }
  };

  addDEPProfile = values => {
    const config = this.props.context;
    const { t } = this.props;
    let apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/ios/v1.0/dep/devices/profile';
    // send request to the invoker
    axios
      .post(apiUrl, values, { headers: { 'Content-Type': 'application/json' } })
      .then(res => {
        if (res.status === 200) {
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_addDEPProfileMsg'),
          });
          this.setState({ isModalVisible: false });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_addError', { label: t('home_menu_depProfile') }),
          t,
        );
      });
  };

  handleCancel = () => {
    window.history.pushState(null, '', location.href.split('?')[0]);
    this.setState({
      isModalVisible: false,
    });
  };

  fetchDEPProfileData = values => {
    this.addDEPProfile(values);
  };

  render() {
    const { t } = this.props;
    return (
      <Modal
        title={t('title_addNewDEPProfile')}
        width="40%"
        open={this.state.isModalVisible}
        onCancel={this.handleCancel}
        footer={null}
        maskClosable={false}
      >
        <div>
          <DEPProfileForm
            modalVisibility={this.state.isModalVisible}
            callback={values => this.fetchDEPProfileData(values)}
          />
        </div>
      </Modal>
    );
  }
}

export default withConfigContext(withTranslation()(AddDEPProfiles));
