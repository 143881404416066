/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

export function combinePolicyDevicePayloadWithUIPayload(
  policyUIPayload,
  devicePayloadFeatureList,
) {
  const policyUIPayloadClone = [...policyUIPayload];
  for (let i = 0; i < devicePayloadFeatureList.length; i++) {
    for (let j = 0; j < policyUIPayloadClone.length; j++) {
      let featureIndex = policyUIPayloadClone[j].features.findIndex(
        x => x.featureCode === devicePayloadFeatureList[i].featureCode,
      );
      if (featureIndex !== -1) {
        policyUIPayloadClone[j].isActivePolicy = true;
        policyUIPayloadClone[j].features[featureIndex].correctiveActions =
          devicePayloadFeatureList[i].correctiveActions;
        let content = JSON.parse(devicePayloadFeatureList[i].content);
        let uiPayloadContent =
          policyUIPayloadClone[j].features[featureIndex].content;
        setItemValues(content, uiPayloadContent);
        break;
      }
    }
  }
  return policyUIPayloadClone;
}

function setItemValues(content, uiPayloadContent) {
  for (const key in content) {
    if (content.hasOwnProperty(key)) {
      if (typeof content[key] === 'object' && !Array.isArray(content[key])) {
        let subPanelIndex = uiPayloadContent.subContents.findIndex(
          x => x.key === key,
        );
        setItemValues(
          content[key],
          uiPayloadContent.subContents[subPanelIndex],
        );
      } else {
        let contentIndex = uiPayloadContent.items.findIndex(x => x.key === key);
        if (contentIndex !== -1) {
          uiPayloadContent.items[contentIndex].value = content[key];
        }
      }
    }
  }
}

export function checkCondition(itemNeedToCheck, contentNeedToCheckWith) {
  if ('conditions' in itemNeedToCheck) {
    let conditionsOfItems = itemNeedToCheck.conditions;

    for (let i = 0, len = conditionsOfItems.length; i < len; i++) {
      let isConditionSatisfied = false;
      let condition = conditionsOfItems[i];
      for (let j = 0, valLen = condition.values.length; j < valLen; j++) {
        let value = condition.values[j];
        let itemIndex = contentNeedToCheckWith.items.findIndex(
          x => x.key === condition.key,
        );

        if (
          typeof contentNeedToCheckWith.items[itemIndex].value === 'object' &&
          Array.isArray(contentNeedToCheckWith.items[itemIndex].value)
        ) {
          if (contentNeedToCheckWith.items[itemIndex].value.includes(value)) {
            isConditionSatisfied = true;
            break;
          }
        } else if (
          contentNeedToCheckWith.items[itemIndex].value.toString() === value
        ) {
          isConditionSatisfied = true;
          break;
        }
      }
      if (isConditionSatisfied === false) {
        return false;
      }
    }
    return true;
  }
  return true;
}
