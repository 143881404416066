/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Row, Col, Card, Steps, message, Result, Button, Alert } from 'antd';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import PublishDevices from '../../../../components/PublishDevices';
import SelectPolicyType from '../../../../components/SelectPolicyType';
import AssignGroups from '../../../../components/AssignGroups';
import ConfigureProfile from '../../../../components/ConfigureProfile';
import axios from 'axios';
import SelectPlatform from '../../../../../../components/SelectPlatform';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
const { Step } = Steps;

class AddPolicy extends React.Component {
  devicePayloadData = {};
  deviceType = null;

  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      currentStepIndex: 0,
      isSuccess: false,
      policyUIConfigurations: [],
    };
  }

  setPolicyPayloadData = data => {
    this.devicePayloadData = { ...this.devicePayloadData, ...data };
  };

  setPublishData = data => {
    this.devicePayloadData = { ...this.devicePayloadData, ...data };
    this.createPayload();
  };

  createPayload = () => {
    const {
      publishDevicesData,
      selectedPlatformData,
      configureProfileData,
      policyTypeData,
      groupData,
    } = this.devicePayloadData;
    const profile = {
      profileName: publishDevicesData.policyName,
      deviceType: selectedPlatformData.deviceType,
      profileFeaturesList: configureProfileData,
    };

    const payload = {
      policyName: publishDevicesData.policyName,
      description: publishDevicesData.description,
      compliance: 'enforce',
      ownershipType: null,
      active: publishDevicesData.active,
      ...policyTypeData,
      profile: profile,
      ...groupData,
    };
    this.onAddNewPolicy(JSON.stringify(payload));
  };

  getPolicyConfigJson = type => {
    const { t } = this.props;
    let apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/' +
      type +
      '/v1.0/configuration/policy-ui';
    // send request to the invokers
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const policyUIConfigurations = res.data.data.policies.map(policy => {
            policy.isActivePolicy = false;
            return policy;
          });
          this.setState({
            currentStepIndex: 1,
            policyUIConfigurations: policyUIConfigurations,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_policyDetails') }),
          t,
        );
      });
  };

  onAddNewPolicy = value => {
    const { t } = this.props;
    axios
      .post(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/policies/',
        value,
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        if (res.status === 201) {
          this.setState({
            isSuccess: true,
            currentStepIndex: 5,
          });
        }
      })
      .catch(error => {
        if (error.hasOwnProperty('response') && error.response.status === 401) {
          // todo display a popop with error
          message.error(t('api_notLoggedInError'));
          window.location.href =
            window.location.origin + `/${this.config.appName}/login`;
        } else {
          this.setState({
            currentStepIndex: 5,
          });
        }
      });
  };

  setNextStep = () => {
    const currentStepIndex = this.state.currentStepIndex + 1;
    this.setState({ currentStepIndex });
  };

  setPrevStep = () => {
    const currentStepIndex = this.state.currentStepIndex - 1;
    this.setState({ currentStepIndex });
  };

  getDeviceType = deviceType => {
    if (this.deviceType != deviceType.toLowerCase()) {
      this.deviceType = deviceType.toLowerCase();
      this.setState({ policyUIConfigurations: [] });
      if (
        isAuthorized(this.config.scopes, [
          [
            'perm:android:view-configuration',
            'perm:ios:view-configuration',
            'perm:windows:view-configuration',
          ],
        ])
      ) {
        this.getPolicyConfigJson(deviceType.toLowerCase());
      }
      let selectedPlatformData = {
        selectedPlatformData: {
          deviceType: deviceType.toLowerCase(),
        },
      };
      this.setPolicyPayloadData(selectedPlatformData);
    } else {
      this.setState({ currentStepIndex: 1 });
    }
  };

  render() {
    const { currentStepIndex, isSuccess, policyUIConfigurations } = this.state;
    const { t } = this.props;
    const { configureProfileData } = this.devicePayloadData;
    return (
      <div>
        <Row>
          <Col span={22} offset={1}>
            <Steps style={{ minHeight: 32 }} current={currentStepIndex}>
              <Step key="Platform" title={t('label_selectPlatform')} />
              <Step
                key="ProfileConfigure"
                title={t('label_configureProfile')}
              />
              <Step key="PolicyType" title={t('label_selectPolicyType')} />
              <Step key="AssignGroups" title={t('label_assignToGroups')} />
              <Step key="Publish" title={t('label_publishToDevices')} />
              <Step key="Finish" title={t('label_finish')} />
            </Steps>
          </Col>
          <Col span={22} offset={1}>
            <Card className={styles.cardContainer}>
              <div
                style={{ display: currentStepIndex === 0 ? 'unset' : 'none' }}
              >
                <HtmlComments
                  permission={
                    '/permission/admin/device-mgt/platform-configurations/view OR\n' +
                    '/permission/admin/device-mgt/devices/enroll/android'
                  }
                />
                <SelectPlatform getDeviceType={this.getDeviceType} />
              </div>
              <div
                style={{
                  display: currentStepIndex === 1 ? 'unset' : 'none',
                  textAlignLast: 'left',
                }}
              >
                {!isAuthorized(this.config.scopes, [
                  [
                    'perm:android:view-configuration',
                    'perm:ios:view-configuration',
                    'perm:windows:view-configuration',
                  ],
                ]) && (
                    <div>
                      <HtmlComments
                        permission={
                          '/permission/admin/device-mgt/platform-configurations/view OR\n' +
                          '/permission/admin/device-mgt/devices/enroll/android'
                        }
                      />
                      <Alert
                        message={t('noPerm_policyConfigurations')}
                        banner
                        style={{ marginBottom: 15 }}
                      />
                    </div>
                  )}
                {policyUIConfigurations.length !== 0 && (
                  <ConfigureProfile
                    deviceType={this.deviceType}
                    policyUIConfigurationsList={policyUIConfigurations}
                    setPolicyPayloadData={this.setPolicyPayloadData}
                    setPrevStep={this.setPrevStep}
                    setNextStep={this.setNextStep}
                    disabled={false}
                  />
                )}
              </div>
              <div
                style={{ display: currentStepIndex === 2 ? 'unset' : 'none' }}
              >
                {configureProfileData && (
                  <SelectPolicyType
                    setPolicyPayloadData={this.setPolicyPayloadData}
                    setPrevStep={this.setPrevStep}
                    setNextStep={this.setNextStep}
                    activeFeatures={configureProfileData}
                  />
                )}
              </div>
              <div
                style={{ display: currentStepIndex === 3 ? 'unset' : 'none' }}
              >
                <HtmlComments
                  permission={
                    '/permission/admin/device-mgt/roles/view,\n' +
                    '/permission/admin/device-mgt/admin/groups/view OR\n' +
                    '/permission/admin/device-mgt/groups/view'
                  }
                />
                <AssignGroups
                  setPolicyPayloadData={this.setPolicyPayloadData}
                  setPrevStep={this.setPrevStep}
                  setNextStep={this.setNextStep}
                />
              </div>
              <div
                style={{ display: currentStepIndex === 4 ? 'unset' : 'none' }}
              >
                <PublishDevices
                  setPublishData={this.setPublishData}
                  setPrevStep={this.setPrevStep}
                />
              </div>
              <div
                style={{ display: currentStepIndex === 5 ? 'unset' : 'none' }}
              >
                {isSuccess && (
                  <Result
                    status="success"
                    title={t('api_policyAddMsg')}
                    extra={[
                      <Link
                        to={`/${this.config.appName}/policies/`}
                        key="btnPolicies"
                      >
                        <Button type="primary">
                          {t('label_gotoPolicies')}
                        </Button>
                      </Link>,
                    ]}
                  />
                )}
                {!isSuccess && (
                  <Result
                    status="error"
                    title={t('api_errorMsg')}
                    subTitle={t('api_policyAddError')}
                    extra={[
                      <Button
                        type="primary"
                        key="btnBack"
                        onClick={this.setPrevStep}
                      >
                        {t('label_tryAgain')}
                      </Button>,
                    ]}
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(AddPolicy));
