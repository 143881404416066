// ProfileDataSection.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProfileDataSection = ({ data, formatDecimalNumber }) => {
  const { t } = useTranslation();
  return (
    <>
      {data.showProfileData &&
        data.showProfileData === true &&
        data.profileData && (
          <>
            {data.profileData['0.9.2_0'] && (
              <div className="timerow">
                {data.profileData['0.9.2_0']}
                &nbsp;&nbsp;
                {data.profileData['0.9.1_0'] && data.profileData['0.9.1_0']}
              </div>
            )}

            {data.profileData['LP_1.8.0'] && (
              <div className="row">
                <div className="profileData">{t('label_import_kWh')}</div>
                <div className="floatRight">{` ${formatDecimalNumber(
                  data.profileData['LP_1.8.0'],
                  2
                )}`}</div>
              </div>
            )}

            {(data.profileData['LP_1.7.0'] ||
              data.profileData['LP_1.7.0'] === 0) && (
              <div className="row">
                <div className="profileData">{t('label_import_kW')}</div>
                <div className="floatRight">{` ${formatDecimalNumber(
                  data.profileData['LP_1.7.0']
                )}`}</div>
              </div>
            )}

            {(data.profileData['LP_13.7.0'] ||
              data.profileData['LP_13.7.0'] === 0) && (
              <div className="row">
                <div className="profileData">{t('label_power_factor')}</div>
                <div className="floatRight">{` ${formatDecimalNumber(
                  data.profileData['LP_13.7.0']
                )}`}</div>
              </div>
            )}
          </>
        )}
    </>
  );
};

export default ProfileDataSection;
