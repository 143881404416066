/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { Fragment } from 'react';
import {
  Circle,
  Marker,
  TileLayer,
  Popup,
  Tooltip,
  Polygon,
} from 'react-leaflet';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import styles from './styles.module.css';
import GeoFenceActions from '../GeoFenceActions';
import { Typography, Divider, Tag, Row } from 'antd';
import L from 'leaflet';
import { getRandomColor } from '../../../../../../../../services/utils/commonHandler';
import { withTranslation } from 'react-i18next';

const { Text } = Typography;

// Icons for the location markers
const geoFenceMarker = new L.Icon({
  iconUrl: require('../../geo-fence.svg'),
  iconRetinaUrl: require('../../geo-fence.svg'),
  shadowAnchor: [22, 22],
  popupAnchor: [0, -22],
  iconSize: [50, 50],
  tooltipAnchor: [0, -22],
});

class GeoFencesMap extends React.Component {
  customMap = React.createRef();

  constructor(props) {
    super(props);
    this.config = this.props.context;
  }

  setGeoFences = fences => {
    let markers = [];
    const { isAllNamesVisible, groups, t } = this.props;
    fences.map((fence, index) => {
      let color = getRandomColor();
      let marker;
      const tagColors = ['#6ab04c', '#9b59b6', '#3498db'];
      let groupsName = [];
      for (var key in fence.groupNames) {
        if (fence.groupNames.hasOwnProperty(key)) {
          groupsName.push(
            <Tag key={key}>
              <a
                href={`/${this.config.appName}/groups?name=${fence.groupNames[key]}`}
              >
                {fence.groupNames[key]}
              </a>
            </Tag>,
          );
        }
      }
      if (fence.fenceShape === 'polygon') {
        marker = (
          <Polygon
            key={fence.id}
            positions={JSON.parse(fence.geoJson)}
            color={color}
            fillOpacity={0.5}
          >
            <Tooltip
              key={isAllNamesVisible ? fence.id : index}
              direction="center"
              permanent={isAllNamesVisible}
            >
              <Text className={styles.tooltipText}>{fence.fenceName}</Text>
            </Tooltip>
            <Popup>
              <Text level={4} className={styles.popupText}>
                {fence.fenceName}
              </Text>
              <Divider
                style={{ marginTop: 4, marginBottom: 4 }}
                orientation="left"
                plain
              >
                {t('home_menu_groups')}
              </Divider>
              <div style={{ textAlign: 'center' }}>
                <Row>{groupsName}</Row>
              </div>
              {fence.eventConfig.map((event, index) => {
                return (
                  <div key={index} style={{ textAlign: 'center' }}>
                    <Divider
                      orientation="left"
                      style={{ marginTop: 4, marginBottom: 4 }}
                      plain
                    >
                      {event.eventLogic}
                    </Divider>
                    <Row>
                      {event.actions.map(action => {
                        let eventType = `GEO_FENCE_${event.eventLogic}`.toLowerCase();
                        return (
                          <Tag color={tagColors[index]} key={action.actionType}>
                            <a
                              href={
                                '/' +
                                this.config.appName +
                                '/configurations/platform-configuration/alerting-config/geo?actionType=' +
                                eventType
                              }
                            >
                              {action.actionType}
                            </a>
                          </Tag>
                        );
                      })}
                    </Row>
                  </div>
                );
              })}
              <Divider style={{ marginTop: 4, marginBottom: 4 }} />
              <div style={{ textAlign: 'center' }}>
                <GeoFenceActions
                  data={fence}
                  groups={groups}
                  fetchGeoFenceData={this.props.fetchGeoFenceData}
                />
              </div>
            </Popup>
          </Polygon>
        );
      } else {
        marker = (
          <Circle
            key={fence.id}
            center={{ lat: fence.latitude, lng: fence.longitude }}
            color={color}
            fillOpacity={0.5}
            radius={fence.radius}
          >
            {' '}
            <Marker
              position={{ lat: fence.latitude, lng: fence.longitude }}
              icon={geoFenceMarker}
            >
              <Tooltip direction="center">
                <Text className={styles.tooltipText}>{fence.fenceName}</Text>
              </Tooltip>
            </Marker>
            <Tooltip
              key={isAllNamesVisible ? fence.id : index}
              direction="center"
              permanent={isAllNamesVisible}
            >
              <Text className={styles.tooltipText}>{fence.fenceName}</Text>
            </Tooltip>
            <Popup>
              <Text level={4} className={styles.popupText}>
                {fence.fenceName}
              </Text>
              <Divider plain>Groups</Divider>
              <div style={{ textAlign: 'center' }}>
                <Row>{groupsName}</Row>
              </div>
              {fence.eventConfig.map((event, index) => {
                return (
                  <div key={index} style={{ textAlign: 'center' }}>
                    <Divider plain>{event.eventLogic}</Divider>
                    <Row>
                      {event.actions.map(action => {
                        let eventType = `GEO_FENCE_${event.eventLogic}`.toLowerCase();
                        return (
                          <Tag color={tagColors[index]} key={action.actionType}>
                            <a
                              href={
                                '/' +
                                this.config.appName +
                                '/configurations/platform-configuration/alerting-config/geo?actionType=' +
                                eventType
                              }
                            >
                              {action.actionType}
                            </a>
                          </Tag>
                        );
                      })}
                    </Row>
                  </div>
                );
              })}
              <Divider style={{ marginTop: 4, marginBottom: 4 }} />
              <div style={{ textAlign: 'center' }}>
                <GeoFenceActions
                  data={fence}
                  groups={groups}
                  fetchGeoFenceData={this.props.fetchGeoFenceData}
                />
              </div>
            </Popup>
          </Circle>
        );
      }
      markers.push(marker);
    });

    return (
      <div>
        {markers.map(marker => {
          return marker;
        })}
      </div>
    );
  };

  render() {
    const attribution = this.config.geoMap.attribution;
    const url = this.config.geoMap.url;
    return (
      <div>
        {/* <Map */}
        {/*   ref={this.customMap} */}
        {/*   center={this.config.geoMap.geofences.defaultCenter} */}
        {/*   zoom={this.config.geoMap.geofences.defaultZoomLevel} */}
        {/*   style={{ height: 700 }} */}
        {/* > */}
        {/*   <TileLayer url={url} attribution={attribution} /> */}
        {/*   <Fragment>{this.setGeoFences(this.props.data)}</Fragment> */}
        {/* </Map> */}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(GeoFencesMap));
