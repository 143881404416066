/*
 * Copyright (C) 2022. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { withConfigContext } from '../../../../../../../../../../components/ConfigContext';
import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Tooltip,
  Checkbox,
  notification,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../../../services/utils/errorHandler';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 10 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 8 },
    xl: { span: 12 },
  },
};
const buttonFormItem = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 20 },
    xl: { span: 18 },
  },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

const { Option } = Select;

class General extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.config = this.props.context;

    this.state = {
      isLocationPublishingEnabled: false,
      traccarServer: null,
      traccarLocationPublishUri: null,
      traccarUsername: null,
      traccarPassword: null,
      isLoadingDeviceResync: false,
      isLoadingUserResync: false,
    };
  }

  componentDidMount() {
    let platformConfigData = [...this.props.platformConfigData];

    let state = {};
    for (let platformConfig of platformConfigData) {
      if (platformConfig.name === 'locationPublishing') {
        let locationConfig = JSON.parse(platformConfig.value);
        state.isTrackerEnabled = locationConfig.isTrackerEnabled;
        state.isLocationPublishingEnabled =
          locationConfig.isLocationPublishingEnabled;
        state.traccarServer = locationConfig.traccarServer;
        state.traccarLocationPublishUri =
          locationConfig.traccarLocationPublishUri;
        state.traccarUsername = locationConfig.traccarUsername;
        state.traccarPassword = locationConfig.traccarPassword;
        break;
      }
    }
    this.setState(state);
    this.formRef.current.setFieldsValue(state);
  }

  onFinish = values => {
    let locationConfig = {};
    locationConfig.locationPublishing = JSON.stringify(values);
    this.props.callback(locationConfig);
  };

  resyncTraccarDevices = () => {
    const { t } = this.props;
    this.setState({ isLoadingDeviceResync: true });
    axios
      .post(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          '/traccar-mgt/v1.0/traccar/sync/devices',
      )
      .then(res => {
        if (res.status === 200) {
          notification.success({
            message: t('api_successMsg'),
            description: t('description_traccar_device_sync_done'),
          });
          this.setState({ isLoadingDeviceResync: false });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', {
            label: t('error_traccar_device_resync'),
          }),
          t,
        );
        this.setState({ isLoadingDeviceResync: false });
      });
  };

  resyncTraccarUsers = () => {
    const { t } = this.props;
    this.setState({ isLoadingUserResync: true });
    axios
      .post(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          '/traccar-mgt/v1.0/traccar/sync/users',
      )
      .then(res => {
        if (res.status === 200) {
          notification.success({
            message: t('api_successMsg'),
            description: t('description_traccar_user_sync_done'),
          });
          this.setState({ isLoadingUserResync: false });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', {
            label: t('error_traccar_users_resync'),
          }),
          t,
        );
        this.setState({ isLoadingUserResync: false });
      });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <Divider>
            <PageHeader
              className="site-page-header-responsive"
              subTitle={t('label_locationConfigs')}
            />
          </Divider>
        </div>
        <Form
          {...formItemLayout}
          name="GeneralConfigurationForm"
          onFinish={this.onFinish}
          ref={this.formRef}
        >
          <Form.Item
            {...tailLayout}
            name="isTrackerEnabled"
            valuePropName="checked"
          >
            <Checkbox>
              <span>
                {t('label_EnableDisableTracker')} &nbsp;
                <Tooltip
                  placement="topLeft"
                  title={t('label_EnableDisableTracker')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            </Checkbox>
          </Form.Item>
          <Form.Item
            justify="center"
            align="middle"
            shouldUpdate={(prevValues, currentValues) => {
              return (
                prevValues.isTrackerEnabled !== currentValues.isTrackerEnabled
              );
            }}
          >
            {({ getFieldValue }) => {
              return getFieldValue('isTrackerEnabled') === true ? (
                <div>
                  <Form.Item
                    label={t('label_GPSLocationEnableDisable')}
                    name="isLocationPublishingEnabled"
                    initialValue="false"
                  >
                    <Select>
                      <Option value="false">{t('label_false')}</Option>
                      <Option value="true">{t('label_true')}</Option>
                    </Select>
                  </Form.Item>
                  <div>
                    <Divider>
                      <PageHeader
                        className="site-page-header-responsive"
                        subTitle={t('label_locationServerConfigs')}
                      />
                    </Divider>
                  </div>
                  <Form.Item
                    label={t('label_traccar_server_url')}
                    name="traccarServer"
                    rules={[
                      {
                        required: true,
                        message: t('form_generalRequiredMsg', {
                          label: t('label_trackerServerUri'),
                        }),
                      },
                    ]}
                  >
                    <Input
                      placement="topLeft"
                      placeholder={t('form_enterUri')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('label_traccar_location_publish_url')}
                    name="traccarLocationPublishUri"
                    rules={[
                      {
                        required: true,
                        message: t('form_generalRequiredMsg', {
                          label: t('label_trackerServerUri'),
                        }),
                      },
                    ]}
                  >
                    <Input
                      placement="topLeft"
                      placeholder={t('form_enterUri')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('label_traccar_admin_username')}
                    name="traccarUsername"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder={'Username'} />
                  </Form.Item>
                  <Form.Item
                    label={t('label_traccar_user_password')}
                    name="traccarPassword"
                    rules={[
                      {
                        required: true,
                        message: t('form_generalRequiredMsg', {
                          label: t('label_trackerPassword'),
                        }),
                      },
                    ]}
                  >
                    <Input type="password" />
                  </Form.Item>
                </div>
              ) : null;
            }}
          </Form.Item>
          <Form.Item {...buttonFormItem}>
            <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
              {t('label_save')}
            </Button>
            <Button
              type="primary"
              onClick={this.resyncTraccarUsers}
              style={{ float: 'right', marginRight: 10 }}
              loading={this.state.isLoadingUserResync}
            >
              {t('label_resync_users')}
            </Button>
            <Button
              type="primary"
              onClick={this.resyncTraccarDevices}
              style={{ float: 'right', marginRight: 10 }}
              loading={this.state.isLoadingDeviceResync}
            >
              {t('label_resync_devices')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(General));
