/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
import axios from 'axios';
import React from 'react';
import PowerMeterIcon from './util/PowerMeterIcon.js';
import { handleApiError } from '../../../../services/utils/errorHandler';
import { notification } from 'antd';

export const fetchOrganizations = async (
  apiURI,
  powerMeterURI,
  axiosConfig,
  t,
  nodes,
  edges,
  maxDepth,
  maxRoots,
  rootCount,
  selectedBranch,
) => {
  try {
    // todo get offset from user involved events
    const response = await axios.get(
      apiURI + `/roots?offset=0&limit=${maxRoots}`,
      axiosConfig,
    );
    if (response.status === 200) {
      const organizations = response.data.data;
      // Filter organizations without parentDeviceId
      const rootOrganizations = organizations.filter(
        (org) => !org.parentDeviceId,
      );
      const rootSerialsString = rootOrganizations
        .map((org) => `serial=${org.device.deviceIdentifier}`)
        .join('&');
      const branchDevices = await axios.get(
        powerMeterURI +
          `/devices?${rootSerialsString}&branch=${selectedBranch}`,
        axiosConfig,
      );
      const matchingRootOrganizations = filterMatchingOrganizations(
        rootOrganizations,
        branchDevices,
      );
      const count = matchingRootOrganizations.length;
      // Make a second API call for organizations without parentDeviceId
      const requests = matchingRootOrganizations.map((org) =>
        axios.get(
          apiURI +
            `/children?deviceId=${org.deviceId}&maxDepth=${maxDepth}&includeDevice=true`,
          axiosConfig,
        ),
      );
      const childrenResponses = await axios.all(requests);

      const deviceIdentifiers = [];
      const existingNodeIds = [];
      const existingEdgeIds = [];
      const serials = [];
      const allNodes = [];
      const allEdges = [];
      const nodeIdList = [];
      for (let index = 0; index < childrenResponses.length; index++) {
        const childResponse = childrenResponses[index];

        try {
          if (childResponse.data.data.nodes.length !== 0) {
            const newNodes = processChildren(
              childResponse.data.data.nodes,
              existingNodeIds,
              deviceIdentifiers,
              serials,
            );
            const filteredNodes = newNodes.filter(
              (node) => !nodeIdList.includes(node.id),
            );
            allNodes.push(...filteredNodes);
            const Ids = filteredNodes.map((node) => node.id);
            nodeIdList.push(...Ids);
          }

          if (childResponse.data.data.edges.length !== 0) {
            // Process edges separately
            const newEdges = childResponse.data.data.edges
              .map((child) => {
                const edgeId = `${child.organizationId}`;
                const source = `${child.parentDeviceId}`;
                const target = `${child.deviceId}`;
                // Check if the edge ID doesn't already exist
                if (!existingEdgeIds.includes(edgeId)) {
                  existingEdgeIds.push(edgeId); // Add the edge ID to the existingEdgeIds array
                  return {
                    id: edgeId,
                    source,
                    target,
                    type: 'turbo',
                  };
                }
                return {
                  id: null,
                  source: null,
                  target: null,
                  type: null,
                }; // for existing edges return null
              })
              .filter((edge) => edge.id !== null); // Filter out null values
            allEdges.push(...newEdges);
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            notification.info({
              message: t('label_change'),
              duration: 3,
              description: t('label_request_abort'),
            });
          } else {
            handleApiError(error, t('error_processing_child_response'), t);
          }
        }
      }
      nodes.push(...allNodes);
      edges.push(...allEdges);
      return Promise.resolve({
        deviceIdentifiers,
        serials,
        allNodes,
        allEdges,
        count,
      });
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      notification.info({
        message: t('label_change'),
        duration: 3,
        description: t('label_request_abort'),
      });
    } else {
      handleApiError(error, t('api_retrieveDeviceOrgDetailsError'), t);
    }
  }
};

const processChildren = (
  children,
  existingNodeIds = [],
  deviceIdentifiers = '',
  serials = [],
) => {
  const isArray = Array.isArray(children);
  const newNodes = [];
  const position = { x: 0, y: 0 };
  const processChild = (child) => {
    if (!existingNodeIds.includes(child.deviceId)) {
      const newNode = {
        id: `${child.deviceId}`,
        sourcePosition: 'bottom',
        targetPosition: 'top',
        data: {
          icon: <PowerMeterIcon />,
          title: `${child.device.name}`,
          description: `${
            child.device.description !== undefined
              ? child.device.description
              : ''
          }`,
          deviceIdentifier: `${child.device.deviceIdentifier}`,
          children: child.children,
          showProfileData: true,
          editData: false,
          isProfileApiCalled: false,
        },
        position,
        type: 'turbo',
      };
      if (child.device.deviceIdentifier) {
        if (!serials.includes(child.device.deviceIdentifier)) {
          deviceIdentifiers.push(`serial=${child.device.deviceIdentifier}&`);
          serials.push(child.device.deviceIdentifier);
        }
      }
      newNodes.push(newNode);
      existingNodeIds.push(child.device.deviceId);
      if (child.children && child.children.length > 0) {
        const childNodes = processChildren(
          child.children,
          existingNodeIds,
          deviceIdentifiers,
          serials,
        );
        newNodes.push(...childNodes);
      }
    }
  };
  isArray ? children.forEach(processChild) : processChild(children);
  return newNodes;
};

const filterMatchingOrganizations = (rootOrganizations, branchDevices) => {
  const branchDeviceIdentifiers = branchDevices.data.data.devices.map(
    (device) => device.deviceIdentifier,
  );
  return rootOrganizations.filter((org) =>
    branchDeviceIdentifiers.includes(org.device.deviceIdentifier),
  );
};

export const processLoadProfileRequests = async (
  powerMeterURI,
  axiosConfig,
  deviceIdentifiers,
  serials,
  allNodes,
  allEdges,
  count,
  setNodes,
  setEdges,
  t,
) => {
  try {
    if (count > 0) {
      const serialList = deviceIdentifiers.join('');
      const loadProfileRequests = await axios.get(
        powerMeterURI +
          `/admin/devices/last-load-profiles?${serialList}onlyLast=true&limit=${serials.length}&obis=LP`,
      );

      if (loadProfileRequests.data.data.profileReadings.length !== 0) {
        loadProfileRequests.data.data.profileReadings.forEach((reading) => {
          const matchingNode = allNodes.find(
            (node) => node.data.deviceIdentifier === reading.serial,
          );
          if (matchingNode) {
            matchingNode.data.profileData = reading.profileData;
          }
        });
      }
      allNodes.forEach((node) => {
        node.data.isProfileApiCalled = true;
      });
      setNodes(allNodes);
      setEdges(allEdges);
    }
  } catch (error) {
    notification.info({
      message: t('label_change'),
      duration: 3,
      description: t('label_request_abort'),
    });
  }
};
