/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Col,
  DatePicker,
  Result,
  Row,
  Select,
  Spin,
  Card,
  Button,
  Space,
  Divider,
  Descriptions,
  Typography,
  Statistic,
  Table
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { withConfigContext } from '../../../../components/ConfigContext';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import { timeRanges } from '../../../../services/utils/commonUtils';
import axios from "axios";

const { Title, Text } = Typography;
const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_readings'),
    },
    {
      breadcrumbName: t('home_menu_meterReadingsCurrent'),
    },
  ];
};

class MeterReadingsCurrent extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    const { t } = this.props;
    const currentDate = new Date();
    currentDate.setHours(8, 0, 0, 0);
    this.timeRangeOptions = timeRanges(t);
  }

  componentDidMount() {
    this.context.setCurrentRoute('allMeterReadingsCurrent');
  }

  render() {
    const config = this.props.context;
    const { Option } = Select;
    const { t } = this.props;

    const { Meta } = Card;

    const handleChange = (value) => {
      console.log(`selected ${value}`);
    };

    return (
        <div>
          <Authorized
              scope={['perm:rmr:admin:view-meter-reading']}
              yes={
                <div>
                  <PageHeader className={styles.pageHeader} ghost={false}>
                    <div className={styles.positioning}>
                      <Row justify="space-between" align="middle" gutter={8}>
                        <Col>
                          <BreadcrumbComponent breadcrumbList={routes(config.appName, t)} />
                        </Col>
                      </Row>
                    </div>
                  </PageHeader>
                  <Row>
                    <br />
                  </Row>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <p>Current Readings</p>
                    </Col>
                  </Row>
                  <Divider />
                </div>
              }
              no={
                <Result
                    status="403"
                    title={t('noPerm_accessPageTitle')}
                    subTitle={t('noPerm_contactSysAdmin')}
                />
              }
          />
        </div>
    );
  }
}

MeterReadingsCurrent.contextType = RoutesContext;
export default withConfigContext(withTranslation()(MeterReadingsCurrent));
