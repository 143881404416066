/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Card,
  Col,
  Divider,
  Input,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
// import { SortableContainer, SortableElement } from 'react-sortable-hoc';
// import arrayMove from 'array-move';
import styles from './styles.module.css';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { EditOutlined, MenuOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

const { Paragraph } = Typography;

// const SortableItem = SortableElement(({ value }) => (
//   <li className={styles.customList}>{value}</li>
// ));

// const SortableList = SortableContainer(({ items }) => {
//   return (
//     <ul>
//       {items.map((value, index) => (
//         <SortableItem key={`item-${index}`} index={index} value={value} />
//       ))}
//     </ul>
//   );
// });

class PolicyList extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
  }

  // onSortEnd = ({ oldIndex, newIndex }) => {
  //   const items = [...this.props.policies];
  //   this.props.onChangeList(arrayMove(items, oldIndex, newIndex));
  // };

  onChangeInput = (e, oldIndex) => {
    let newIndex = e.target.value - 1;
    this.onSortEnd({ oldIndex, newIndex });
  };

  render() {
    const { t } = this.props;
    const policies = this.props.policies.map((policy, index) => {
      let deviceTypeIcon = {};
      if (policy.profile.deviceType in this.config.defaultPlatformIcons) {
        deviceTypeIcon = this.config.defaultPlatformIcons[
          policy.profile.deviceType
        ];
      } else {
        deviceTypeIcon = this.config.defaultPlatformIcons.default;
      }
      return (
        <Card
          key={policy.id}
          hoverable={true}
          size="small"
          style={{ marginBottom: '5px', borderTopColor: 'transparent' }}
        >
          <Row justify="space-around" align="middle">
            <Col span={2}>
              <Space size={30}>
                <MenuOutlined />
                <Input
                  onBlur={e => this.onChangeInput(e, index)}
                  defaultValue={`${index + 1}`}
                  style={{ border: 'none', width: 70 }}
                  type={'numbers'}
                  suffix={
                    <Tooltip title={t('label_editPriority')}>
                      <EditOutlined
                        style={{ color: '#1890ff', fontSize: 12 }}
                      />
                    </Tooltip>
                  }
                />
              </Space>
            </Col>
            <Divider type="vertical" style={{ height: 20 }} />
            <Col span={1}>
              <span
                style={{
                  fontSize: 20,
                  color: deviceTypeIcon.color,
                }}
              >
                <LegacyIcon
                  key="device-types"
                  type={deviceTypeIcon.icon}
                  theme={deviceTypeIcon.theme}
                />
              </span>
            </Col>
            <Divider type="vertical" />
            <Col span={8}>
              <div>{policy.policyName}</div>
              <div>
                <Paragraph
                  type="secondary"
                  style={{ flex: 'auto' }}
                  ellipsis={{
                    rows: 1,
                    symbol: 'more',
                    onEllipsis: ellipsis => {},
                  }}
                >
                  {policy.description}
                </Paragraph>
              </div>
            </Col>
            <Divider type="vertical" />
            <Col span={2}>
              {'ownershipType' in policy
                ? policy.ownershipType
                : t('label_none_uppercase')}
            </Col>
            <Divider type="vertical" />
            <Col span={2}>{policy.roles.toString()}</Col>
            <Divider type="vertical" />
            <Col span={2}>{t(`label_${policy.compliance.toLowerCase()}`)}</Col>
          </Row>
        </Card>
      );
    });
    return (
      <div className={styles.policyListContainer}>
        {/* <SortableList items={policies} onSortEnd={this.onSortEnd} /> */}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(PolicyList));
