/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext, useEffect, useState } from 'react';
import { Col, Result, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import IframeWrapper from '../../components/IframeWrapper';
import configs from '../../../../../public/conf/config.json';
import { RoutesContext } from '../../index';
import { grafanaUrlAccessible } from '../../../../services/utils/urlUtils';
import { useSelector } from 'react-redux';

const Home = () => {
  const context = useContext(RoutesContext);
  const selectedBranch = useSelector((state) => state.branch.value);
  const { t } = useTranslation();
  const [isDashboardAccessible, setIsDashboardAccessible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dashboard = `${configs.grafanaConfig.home.landingDashboard}&var-serial=All&var-branch=${selectedBranch}`;
  const [dashboardUrl, setDashboardUrl] = useState(dashboard);
  useEffect(() => {
    // Update the current route's key
    context.setCurrentRoute('home');
    checkDashboardAccess();
    setDashboardUrl(
      `${configs.grafanaConfig.home.landingDashboard}&var-serial=All&var-branch=${selectedBranch}`,
    );
  }, [selectedBranch]);

  const checkDashboardAccess = async () => {
    const dashboard = configs.grafanaConfig.home.landingDashboard;
    const accessible = await grafanaUrlAccessible(
      `${configs.grafanaConfig.reportBase}/${dashboard}`,
    );
    setIsDashboardAccessible(accessible);
    setIsLoading(false);
  };

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? (
            <div style={{ textAlign: 'center', marginTop: 50 }}>
              <Spin size="large" />
            </div>
          ) : isDashboardAccessible ? (
            <IframeWrapper iframeSrc={`${dashboardUrl}`} height={'700px'} />
          ) : (
            <Result status="404" subTitle={t('label_dashboard_error')} />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Home;
