/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Col,
  Result,
  Row,
  Spin,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { withConfigContext } from '../../../../components/ConfigContext';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import { grafanaUrlAccessible } from '../../../../services/utils/urlUtils';
import IframeWrapper from '../../components/IframeWrapper';

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_factory'),
    },
    {
      breadcrumbName: t('home_menu_weekly_summary'),
    },
  ];
};


class WeeklySummary extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    const { t } = this.props;
    this.state = {
      isDashboardAccessible: false,
      isLoading: true,
    };
  }

  checkDashboardAccess = async (dashboardUrl) => {
    const accessible = await grafanaUrlAccessible(`${this.config.grafanaConfig.reportBase}/${dashboardUrl}`);
    this.setState({ isDashboardAccessible: accessible, isLoading: false });
  };

  componentDidMount() {
    this.context.setCurrentRoute('allFactoryFlowlayoutsWeeklySummary');
    this.checkDashboardAccess(`${this.config.grafanaConfig.home.landingDashboard}`);
  }

  handleClick = (machine) => {
    const newTab = window.open(`${window.location.pathname}/../devices/penassembly/${machine}/statistics`, '_blank');
    newTab.focus();
  };

  render() {
    const config = this.props.context;
    const { t } = this.props;
    const { isDashboardAccessible, isLoading } = this.state;
    const dashboardUrl = `${this.config.grafanaConfig.home.landingDashboard}`;

    return (
      <div>
        <Authorized
          scope={['perm:devices:view']}
          yes={
            <div>
              <PageHeader
                className={styles.pageHeader}
                breadcrumb={
                  <BreadcrumbComponent
                    breadcrumbList={routes(config.appName, t)}
                  />
                }
              >
              </PageHeader>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div>
                    {isLoading ? (
                      <div style={{ textAlign: 'center', marginTop: 50 }}>
                        <Spin size="large" />
                      </div>
                    ) : isDashboardAccessible ? (
                      <IframeWrapper iframeSrc={dashboardUrl} height={'700px'} />
                    ) : (
                      <Result status="404" subTitle={t('label_dashboard_error')} />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

WeeklySummary.contextType = RoutesContext;
export default withConfigContext(withTranslation()(WeeklySummary));
