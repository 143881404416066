/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import { RoutesContext } from '../../index';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Result, Row, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { withConfigContext } from '../../../../components/ConfigContext';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import { subDays } from 'date-fns';
import IframeWrapper from '../../components/IframeWrapper';
import { getUnixTime } from '../../../../services/utils/functions';
import configs from '../../../../../public/conf/config.json';
import DateRangeDropdown from '../../components/common/DateRangeDropdown';

const OtherDevicesHistory = (props) => {
  const config = props.context;
  const { t } = props;
  const routesContext = useContext(RoutesContext);
  const rightNow = new Date();
  const [deviceId, setDeviceIdValue] = useState('');
  const [outsidedeviceId, setOutsidedeviceId] = useState(false);
  const [dashboardUrl2, setDashboardUrl2] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const startTimestamp = urlParams.get('startTimestamp');
  const endTimestamp = urlParams.get('endTimestamp');
  const initialState =
    startTimestamp && endTimestamp
      ? {
          from: parseInt(startTimestamp, 10),
          to: parseInt(endTimestamp, 10),
        }
      : {
          from: getUnixTime(subDays(rightNow, 2)),
          to: getUnixTime(rightNow),
        };

  const [range, setRange] = useState(initialState);

  useEffect(() => {
    routesContext.setCurrentRoute('allOtherDevicesHistory');
    const pathSegments = window.location.pathname.split('/');
    const deviceType = pathSegments[3];
    const deviceId = pathSegments[4];

    if (deviceId) {
      setDeviceIdValue(deviceId);
      setOutsidedeviceId(true);
    }

    // Update dashboard URLs based on deviceType
    if (deviceType === 'jht') {
      setDashboardUrl2(
        `${configs.grafanaConfig.temperatureSensorsHistoricalReading}&var-device=${deviceId}
        &from=${range.from}&to=${range.to}`,
      );
    } else if (deviceType === 'jups') {
      setDashboardUrl2(
        `${configs.grafanaConfig.UPSSensorsHistoricalReading}&var-device=${deviceId}
        &from=${range.from}&to=${range.to}`,
      );
    }
  }, [t, range.from, range.to]);

  const routes = (appName) => [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_other_devices'),
    },
    {
      breadcrumbName: t('home_menu_other_devices_view_per_device'),
    },
  ];

  return (
    <div>
      <Authorized
        scope={['perm:rmr:admin:meter-inventory']}
        yes={
          <div>
            <PageHeader className={styles.pageHeader} ghost={false}>
              <div>
                <Row justify="space-between" align="middle" gutter={8}>
                  <Col>
                    <BreadcrumbComponent
                      breadcrumbList={routes(config.appName)}
                    />
                  </Col>
                  <Col>
                    <Row align="middle" gutter={[8, 8]}>
                      <Col>
                        <Space>
                          {startTimestamp && endTimestamp ? (
                            <DateRangeDropdown
                              startTimestamp={range.from}
                              endTimestamp={range.to}
                              getRange={(data) => setRange(data)}
                              closeOnBackground
                              disabled={true}
                              range={6}
                            />
                          ) : (
                            <DateRangeDropdown
                              alignDropdown="right"
                              getRange={(data) => setRange(data)}
                              closeOnBackground
                              range={6}
                            />
                          )}
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </PageHeader>
            <Row gutter={12}>
              <Col xl={24} xs={24}>
                <div className={styles.iframeContainer}>
                  <IframeWrapper iframeSrc={dashboardUrl2} height={'700px'} />
                </div>
              </Col>
            </Row>
          </div>
        }
        no={
          <Result
            status="403"
            title={t('noPerm_accessPageTitle')}
            subTitle={t('noPerm_contactSysAdmin')}
          />
        }
      />
    </div>
  );
};

export default withConfigContext(withTranslation()(OtherDevicesHistory));
