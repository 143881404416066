/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import { message, notification } from 'antd';
import config from '../../../public/conf/config.json';

export const handleApiError = (
  error,
  errorMessage,
  t,
  customizedErrorString = null,
) => {
  if (error.hasOwnProperty('response')) {
    if (error.response.status === 401) {
      message.error(t('api_notLoggedInError'));
      const redirectUrl = encodeURI(window.location.href);
      window.location =
        window.location.origin +
        config.serverConfig.ssoLoginUri +
        `?redirect=${redirectUrl}`;
      return;
    } else if (customizedErrorString) {
      errorMessage = t(`${error.response.status + customizedErrorString}`);
    }
  } else if (!errorMessage) {
    errorMessage = t('api_errorUnknown');
  }

  notification.error({
    message: t('api_errorMsg'),
    duration: 10,
    description: errorMessage,
  });
};

export const handleApiSuccess = (
  successMessage,
  successDescription,
  notificationDuration = 2,
) => {
  notification.success({
    message: successMessage,
    duration: notificationDuration,
    description: successDescription,
  });
};
