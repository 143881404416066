/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import 'antd/dist/reset.css';
import RouteWithSubRoutes from './components/RouteWithSubRoutes';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import axios from 'axios';
import { Layout, message, notification, Result, Spin } from 'antd';
import ConfigContext from './components/ConfigContext';
import { handleApiError } from './services/utils/errorHandler';
import { getUiConfig } from './services/utils/uiConfigHandler';
import { isAuthorized } from './services/utils/authorizationHandler';
import Localizer from './components/Localizer';
import { withTranslation } from 'react-i18next';
import { ImageTypes } from './scenes/Home/scenes/Configurations/scenes/PlatformConfiguration/components/GeneralConfiguration/components/Theme/components/ThemeConfigurationForm';
import { randomizeImageUrlToAvoidLoadingFromCache } from './services/utils/urlUtils';
import * as hubspotChatHandler from './services/utils/hubspotChatHandler';
import configs from '../public/conf/config.json';

const { Content } = Layout;
const loadingView = (
  <Layout>
    <Content
      style={{
        padding: '0 0',
        paddingTop: 300,
        backgroundColor: '#fff',
        textAlign: 'center'
      }}
    >
      <Spin tip="Loading..." />
    </Content>
  </Layout>
);

const errorView = (
  <Result
    style={{
      paddingTop: 200
    }}
    status="500"
    title={'Error occurred while loading the configuration'}
    subTitle={'Please refresh your browser window'}
  />
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      config: {},
      branches: {},
      theme: {
        logo: {},
        favicon: {},
        logoIcon: {}
      }
    };
  }

  componentDidMount() {
    // Clear cache related to billing feature
    localStorage.removeItem('start');
    localStorage.removeItem('end');
    axios
      .get(
        window.location.origin +
          `/${window.location.pathname.split('/')[1]}/public/conf/config.json`
      )
      .then(res => {
        const config = res.data;
        this.checkUserLoggedIn(config);
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: true
        });
      });
  }

  refreshTheme = async () => {
    let { config } = this.state;
    // let theme = await this.getTheme(config);
    // this.setTheme(theme, config);
  };

  async getTheme(config) {
    const themeUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/whitelabel';
    let whitelabelApiUrl =
      window.location.origin + '/api/device-mgt/v1.0/whitelabel';
    let faviconUrl = whitelabelApiUrl + '/' + config.userDomain + '/favicon';
    let logoUrl = whitelabelApiUrl + '/' + config.userDomain + '/logo';
    let logoIconUrl = whitelabelApiUrl + '/' + config.userDomain + '/icon';
    let errorFooterText = 'Entgra Sub Metering | © 2024 , All Rights Reserved.';
    let theme = {
      footerText: errorFooterText,
      favicon: { faviconUrl },
      logo: { logoUrl },
      logoIcon: { logoIconUrl },
      appTitle: config.defaultAppTitle
    };
    try {
      let res = await axios.get(themeUrl);
      let themeData = res.data.data;
      let {
        faviconImage,
        logoImage,
        logoIconImage,
        footerText,
        appTitle
      } = themeData;
      theme.footerText = footerText;
      theme.appTitle = appTitle;
      if (faviconImage.imageLocationType === ImageTypes.URL.type) {
        theme.favicon.faviconUrl = faviconImage.imageLocation;
      } else {
        theme.favicon.faviconName = faviconImage.imageLocation;
      }
      if (logoImage.imageLocationType === ImageTypes.URL.type) {
        theme.logo.logoUrl = logoImage.imageLocation;
      } else {
        theme.logo.logoName = logoImage.imageLocation;
      }
      if (logoIconImage.imageLocationType === ImageTypes.URL.type) {
        theme.logoIcon.logoIconUrl = logoIconImage.imageLocation;
      } else {
        theme.logoIcon.logoIconName = logoIconImage.imageLocation;
      }
      return theme;
    } catch (error) {
      return theme;
    }
  }

  initTheme = config => {
    this.getTheme(config).then(theme => {
      this.setTheme(theme, config);
    });
  };

  setTheme = (theme, config) => {
    this.setState(prevState => {
      return {
        theme: theme,
        prevTheme: prevState.theme
      };
    });
    let appTitle = config.defaultAppTitle;
    if (this.state.prevTheme && this.state.prevTheme.appTitle) {
      appTitle = this.state.prevTheme.appTitle;
    }
    let pageTitle = document.title;
    document.title = pageTitle.replace(appTitle, theme.appTitle);
    this.updateFavicon(theme);
  };

  checkUserLoggedIn = config => {
    const { t } = this.props;
    axios
      .post(
        window.location.origin + config.serverConfig.userUri,
        'platform=entgra'
      )
      .then(async res => {
        config.user = res.data.data;
        config.userDomain = config.user.split('@')[1];
        if (!config.userDomain) {
          config.userDomain = 'carbon.super';
        }
        const username = config.user.split('@')[0];
        // this.loadEvaluateTenants(config, username, domain);
        this.loadUserBranches(config);
        const pageURL = window.location.pathname;
        const lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);
        if (lastURLSegment === 'login') {
          window.location.href = window.location.origin + `/${config.appName}/`;
        }
        // this.initTheme(config);
        this.getUserPermissions(config);
      })
      .catch(error => {
        // eslint-disable-next-line no-prototype-builtins
        if (error.hasOwnProperty('response') && error.response.status === 401) {
          const redirectUrl = encodeURI(window.location.href);
          const pageURL = window.location.pathname;
          const lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);

          getUiConfig(config, t).then(uiConfig => {
            if (uiConfig !== undefined) {
              if (uiConfig.isSsoEnable) {
                window.location =
                  window.location.origin +
                  config.serverConfig.ssoLoginUri +
                  `?redirect=${redirectUrl}`;
              } else if (lastURLSegment !== 'login') {
                window.location.href =
                  window.location.origin +
                  `/${config.appName}/login?redirect=${redirectUrl}`;
              } else {
                this.setState({
                  loading: false,
                  config: config
                });
              }
            } else {
              this.setState({
                loading: false,
                error: true
              });
            }
          });
        } else {
          this.setState({
            loading: false,
            error: true
          });
        }
      });
  };

  updateFavicon = theme => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = randomizeImageUrlToAvoidLoadingFromCache(
      theme.favicon.faviconUrl
    );
    document.getElementsByTagName('head')[0].appendChild(link);
  };

  getUserPermissions = config => {
    // need to set branches also to config.branches like calling the getBranches API
    const { t } = this.props;
    axios
      .get(window.location.origin + config.serverConfig.scopeUri)
      .then(res => {
        let userScopesString = res.data.data.userScopes;
        let userScopesArray = userScopesString.match(/\S+/g);
        config.scopes = userScopesArray;

        if (isAuthorized(config.scopes, ['perm:device-types:features'])) {
          this.fetchDeviceTypes(config);
        } else {
          this.setState({
            config: config,
            loading: false
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_getPermissionError'), t);
      });
  };

  fetchDeviceTypes = config => {
    const { t } = this.props;
    this.setState({ loading: true });
    const apiUrl =
      window.location.origin +
      configs.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/device-types';

    // send request to the invokerss
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          config.deviceTypes = res.data.data.map(deviceType => {
            return deviceType.name;
          });
          config.deviceTypesDetails = res.data.data;
          this.setState({
            config: config,
            loading: false
          });
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-prototype-builtins
        if (error.hasOwnProperty('response') && error.response.status === 401) {
          // todo display a popop with error
          message.error(t('api_notLoggedInError'));
          window.location.href =
            window.location.origin + `/${config.appName}/login`;
        } else {
          notification.error({
            message: t('api_errorMsg'),
            duration: 0,
            description: t('api_loadError', { label: t('label_machineTypes') })
          });
        }
      });
  };

  loadOperationCodes = (config, deviceType) => {
    const { t } = this.props;
    // const deviceType = config.deviceTypes;
    const androidCommands = [];
    const windowsCommands = [];
    const iosCommands = [];
    for (let i = 0; i < deviceType.length; i++) {
      const osType = deviceType[i].name.toLowerCase();
      axios
        .get(
          window.location.origin +
            config.serverConfig.invoker.uri +
            '/device-mgt/v1.0' +
            `/device-types/${osType}/features?featureType=operation`
        )
        .then(res => {
          if (res.status === 200) {
            res.data.data.forEach(obj => {
              if (osType === 'android') {
                androidCommands.push(obj.code);
              }
              if (osType === 'windows') {
                windowsCommands.push(obj.code);
              }
              if (osType === 'ios') {
                iosCommands.push(obj.code);
              }
            });
          }
        })
        .catch(error => {
          handleApiError(error, t('api_getOperationError'), t);
        });
    }
    config.operationCodes = {
      androidOC: androidCommands,
      windowsOC: windowsCommands,
      iosOC: iosCommands
    };
    this.setState({
      config: config,
      loading: false
    });
  };

  loadEvaluateTenants = (config, username, domain) => {
    this.setState({ loading: true });
    const { t } = this.props;
    let evaluateTenants = [];
    const metaKey = 'EVALUATE_TENANTS';
    axios
      .get(
        window.location.origin +
          config.serverConfig.invoker.uri +
          '/device-mgt/v1.0/metadata/' +
          metaKey
      )
      .then(res => {
        if (res.data.data && res.data.data.metaValue) {
          const data = JSON.parse(res.data.data.metaValue);
          data.forEach(mydata => {
            evaluateTenants.push(mydata.tenantDomain);
          });
          if (evaluateTenants.includes(domain)) {
            hubspotChatHandler.checkAndLoadLiveChat(
              config,
              t,
              username,
              domain
            );
          }
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_Evaluate_Tenant') }),
          t
        );
        this.setState({ loading: false });
      });
  };

  loadUserBranches = config => {
    this.setState({ loading: true });
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
          configs.serverConfig.invoker.uri +
          '/device-mgt/power-meter-dlms/v1.0/devices/user-branches'
      )
      .then(res => {
        const branches = res.data.data;
        // Update the component state with the branches data
        this.setState({ branches, loading: false });
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_Evaluate_Tenant') }),
          t
        );
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, error, config, theme, branches } = this.state;
    const applicationView = (
      <Router>
        <ConfigContext.Provider
          value={{
            config,
            refreshTheme: this.refreshTheme,
            theme,
            branches
          }}
        >
            <Switch>
              <Redirect
                exact
                from={`/${config.appName}`}
                to={
                  Object.keys(config).length !== 0 &&
                  config.analyticEnabledDomains.includes(config.userDomain)
                    ? `/${config.appName}/dashboard`
                    : `/${config.appName}/home`
                }
              />
              {this.props.routes.map(route => (
                <RouteWithSubRoutes key={route.path} {...route} />
              ))}
            </Switch>
        </ConfigContext.Provider>
      </Router>
    );

    return (
      <div>
        {loading && loadingView}
        {!loading && !error && applicationView}
        {error && errorView}
      </div>
    );
  }
}

export default withTranslation()(App);
