/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Tooltip,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { withTranslation } from 'react-i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 10 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 8 },
    xl: { span: 8 },
  },
};

const buttonFormItem = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 20 },
    xl: { span: 18 },
  },
};

class General extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { platformConfigData } = this.props;
    let initialValues = {};
    for (let config of platformConfigData) {
      if (config.name === 'notifierFrequency') {
        initialValues.notifierFrequency = config.value;
      } else if (config.name === 'isEventPublishingEnabled') {
        initialValues.isEventPublishingEnabled = config.value;
      }
    }
    initialValues.notifierFrequency = initialValues.notifierFrequency / 1000;
    this.formRef.current.setFieldsValue(initialValues);
  }

  onFinishFailed = err => {
    const { t } = this.props;
    console.log(t('finishFailed_txt'), err);
  };

  onFinish = values => {
    values.notifierFrequency = values.notifierFrequency * 1000;
    this.props.callback(values);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <Divider>
            <PageHeader
              className="site-page-header-responsive"
              subTitle={t('label_generalConfigs')}
            />
          </Divider>
        </div>
        <div>
          <Form
            {...formItemLayout}
            name="GeneralConfigurationForm"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            ref={this.formRef}
          >
            <Form.Item
              label={
                <span>
                  {t('label_monitoringFrequency')}&nbsp;
                  <Tooltip title={t('valueInSec_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="notifierFrequency"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_monitoringFrequency'),
                  }),
                },
                {
                  validator(rule, value) {
                    if (!value || value >= 60) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t('minimumFrequency_txt'));
                  },
                },
              ]}
            >
              <Input placeholder={t('form_enterValueInSeconds')} />
            </Form.Item>
            <Form.Item
              label={t('label_publishForAnalytics')}
              name="isEventPublishingEnabled"
              initialValue="false"
            >
              <Select>
                <Option value="false">{t('label_false')}</Option>
                <Option value="true">{t('label_true')}</Option>
              </Select>
            </Form.Item>
            <Form.Item {...buttonFormItem}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: 'right' }}
              >
                {t('label_save')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(General);
