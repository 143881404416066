/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
import React from 'react';
import { Form, Button, Divider, Spin, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Server from './server';
import { withConfigContext } from '../../../../../../../../../../components/ConfigContext';
import styles from './styles.module.css';
import AddedServer from './addedServers';
import { withTranslation } from 'react-i18next';

class AddServer extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      isAddServerDisabled: true,
      servers: [],
      isServersLoading: true,
      updatedServers: [],
    };
  }

  componentDidMount() {
    let servers = [...this.props.platformConfigData];
    let serverObj = [];
    const { t } = this.props;
    servers.map(server => {
      try {
        serverObj.push(JSON.parse(server.value));
      } catch {
        message.error(t('api_errorMsg2'));
      }
    });
    this.setState({ servers: serverObj, isServersLoading: false }, () => {
      if (this.state.servers.length) {
        this.setState({ isAddServerDisabled: false });
      }
    });
  }

  onFinish = values => {
    let servers = [];
    let server = {};
    if (this.state.updatedServers.length) {
      this.state.servers.map(serverChanged => {
        let changed = false;
        this.state.updatedServers.map(updatedServer => {
          if (serverChanged.serverurl === updatedServer.key) {
            server.serverurl = updatedServer.serverurl;
            server.username = updatedServer.username;
            server.password = updatedServer.password;
            servers.push(JSON.stringify(server));
            changed = true;
          }
        });
        if (changed === false) {
          servers.push(JSON.stringify(serverChanged));
        }
      });
    } else {
      this.state.servers.map(server => {
        servers.push(JSON.stringify(server));
      });
    }
    if (values.servers !== undefined) {
      for (let index = 0; index < values.servers.length; index++) {
        server.serverurl = values.servers[index].serverurl;
        server.username = values.servers[index].username;
        server.password = values.servers[index].password;
        servers.push(JSON.stringify(server));
      }
    }
    this.props.callback(servers);
    this.setState({ updatedServers: [] });
  };

  setDisable = () => {
    this.setState({ isAddServerDisabled: false });
  };

  setFields = fields => {
    if (fields.length === 1) {
      this.setState({ isAddServerDisabled: true });
    }
  };

  updateServers = (serverurl, username, password, key) => {
    let server = {};
    server.serverurl = serverurl;
    server.username = username;
    server.password = password;
    server.key = key;
    let changed = false;
    if (this.state.updatedServers.length) {
      this.state.updatedServers.map(updatedServer => {
        if (key === updatedServer.key) {
          let position = this.state.updatedServers.indexOf(updatedServer);
          this.state.updatedServers.splice(position, 1, server);
          changed = true;
        }
      });
      if (changed === false) {
        this.state.updatedServers.push(server);
      }
    } else {
      this.state.updatedServers.push(server);
    }
  };

  deleteExistingFtpServer = value => {
    let updatedServers = [];
    this.state.servers.map(server => {
      if (server.serverurl !== value) {
        updatedServers.push(server);
      }
    });
    this.setState(
      {
        servers: updatedServers,
        isServersLoading: true,
      },
      () => {
        this.setState({
          isServersLoading: false,
        });
      },
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        {this.state.isServersLoading ? (
          <div className={styles.example}>
            <Spin />
          </div>
        ) : (
          <div>
            <Divider>{t('label_addNewFTPServer')}</Divider>
            <Form
              name="dynamic_form_nest_item"
              onFinish={this.onFinish}
              autoComplete="off"
              style={{ marginTop: '15%', width: '100%' }}
            >
              <Form.List name="servers">
                {(fields, { add, remove }) => (
                  <>
                    {this.state.servers.map(server => {
                      if (server.serverurl) {
                        return (
                          <AddedServer
                            server={server}
                            updateServers={this.updateServers}
                            existingServer={server.serverurl}
                            remove={this.deleteExistingFtpServer}
                          />
                        );
                      }
                    })}
                    {fields.map(field => (
                      // eslint-disable-next-line react/jsx-key
                      <Server
                        field={field}
                        remove={remove}
                        fields={fields}
                        passFields={this.setFields}
                      />
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                          this.setDisable;
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        {t('label_addField')}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              {this.state.isAddServerDisabled ? null : (
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: 'right' }}
                  >
                    {t('label_add')}
                  </Button>
                </Form.Item>
              )}
            </Form>
          </div>
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(AddServer));
