/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { Component, Fragment } from 'react';
import {
  Circle,
  CircleMarker,
  Marker,
  TileLayer,
  Tooltip,
} from 'react-leaflet';
import L from 'leaflet';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import 'leaflet-polylinedecorator';
// import AntPath from 'react-leaflet-ant-path';
import { getRandomColor } from '../../../../../../services/utils/commonHandler';
import AnimatePath from '../AnimatePath';
import { Row, Col, Button, InputNumber, Tooltip as AntdTooltip } from 'antd';
import {
  CaretRightOutlined,
  PauseOutlined,
  ClearOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

class PolylineDecorator extends Component {
  constructor(props) {
    super(props);
    this.polyRef = React.createRef();
  }
  componentDidMount() {
    const polyline = this.polyRef.current.leafletElement; // get native Leaflet polyline
    const { map } = this.polyRef.current.props.leaflet; // get native Leaflet map

    L.polylineDecorator(polyline).addTo(map);
  }

  render() {
    return <Marker ref={this.polyRef} {...this.props} />;
  }
}

class LocationHistoryMap extends Component {
  constructor(props) {
    super(props);
    this.customMap = React.createRef();
    this.state = {
      playAnimation: false,
      animationSpeed: 5,
      animationPause: 1,
      resetAnimation: false,
      restartAnimation: false,
    };
  }

  /**
   * Polyline draw for historical locations
   * @param locationHistorySnapshots - location data object
   * @returns JSX.Element
   */
  polylineMarker = locationHistorySnapshots => {
    const polyLines = [];
    locationHistorySnapshots.forEach((pos, index) => {
      polyLines.push(
        <PolylineDecorator key={index} color="#414042" position={pos} />,
      );
    });

    return (
      <div style={{ display: 'none' }}>
        {polyLines.map(polyLine => {
          return polyLine;
        })}
      </div>
    );
  };

  onClickMarker = (e, position) => {
    this.customMap.current.leafletElement.setZoomAround(position, 19);
  };

  setCircleMarkers = (markerPoints, permanentTooltip) => {
    let markers = [];
    markerPoints.map((point, index) => {
      let marker;
      marker = (
        <Circle
          key={index}
          center={{ lat: point.position[0], lng: point.position[1] }}
          color={'#000'}
          fillOpacity={1}
          radius={2}
        >
          {' '}
          <Tooltip key={index}>
            {moment
              .utc(point.position[2])
              .tz(moment.tz.guess())
              .format('LLLL')}
          </Tooltip>
        </Circle>
      );
      markers.push(marker);
    });

    return (
      <div>
        {markers.map(marker => {
          return marker;
        })}
      </div>
    );
  };

  restartAnimationCallback = () => {
    this.setState({
      playAnimation: false,
      resetAnimation: false,
      restartAnimation: true,
    });
  };

  startAnimationCallback = () => {
    this.setState({ resetAnimation: false });
  };

  selectViewMethod = (markers, method, plainViewEnabled) => {
    const options = { color: 'red', pulseColor: '#FFF', delay: 1000 };
    if (method === 'plain') {
      return (
        <div>
          {/* <AntPath */}
          {/*   positions={this.props.locationHistorySnapshots} */}
          {/*   options={options} */}
          {/* /> */}
        </div>
      );
    } else if (method === 'timestamp') {
      return (
        <div>
          <Fragment>{this.setCircleMarkers(markers, false)}</Fragment>
          {/* <AntPath */}
          {/*   positions={this.props.locationHistorySnapshots} */}
          {/*   options={options} */}
          {/* /> */}
        </div>
      );
    } else if (method === 'animated') {
      return (
        <div>
          <Fragment>{this.setCircleMarkers(markers, false)}</Fragment>
          <AnimatePath
            startAnimation={this.state.playAnimation}
            resetAnimation={this.state.resetAnimation}
            locationHistorySnapshots={this.props.locationHistorySnapshots}
            speed={this.state.animationSpeed}
            pause={this.state.animationPause}
            restartAnimationCallback={() => this.restartAnimationCallback()}
            startAnimationCallback={() => this.startAnimationCallback()}
          />
        </div>
      );
    } else if (method === 'loactionPoint') {
      return <Fragment>{this.setCircleMarkers(markers, false)}</Fragment>;
    }
  };

  playAnimation = () => {
    if (this.state.animationSpeed === 0) {
      this.setState({ animationSpeed: 5, restartAnimation: false });
    } else {
      this.setState({
        playAnimation: true,
        resetAnimation: false,
        restartAnimation: false,
      });
    }
  };

  pauseAnimation = () => {
    this.setState({ animationSpeed: 0, resetAnimation: false });
  };

  onChangeSpeedSlider = value => {
    this.setState({ animationSpeed: value });
  };

  onChangePauseSlider = value => {
    this.setState({ animationPause: value });
  };

  onClickResetButton = () => {
    this.setState({ resetAnimation: true, playAnimation: false });
  };

  onClickRestartButton = () => {
    this.setState({
      resetAnimation: true,
      playAnimation: true,
      restartAnimation: false,
    });
  };

  changePlayButton = () => {
    const { animationSpeed, playAnimation, restartAnimation } = this.state;
    const { t } = this.props;
    if (
      (animationSpeed === 0 || playAnimation === false) &&
      restartAnimation === false
    ) {
      return (
        <AntdTooltip title={t('label_play')} placement="top">
          <Button onClick={this.playAnimation} icon={<CaretRightOutlined />} />
        </AntdTooltip>
      );
    } else if (
      (animationSpeed !== 0 || playAnimation === true) &&
      restartAnimation === false
    ) {
      return (
        <AntdTooltip title={t('label_pause')} placement="top">
          <Button onClick={this.pauseAnimation} icon={<PauseOutlined />} />
        </AntdTooltip>
      );
    }
    return (
      <AntdTooltip title={t('label_replay')} placement="top">
        <Button onClick={this.onClickRestartButton} icon={<RedoOutlined />} />
      </AntdTooltip>
    );
  };

  render() {
    const locationHistorySnapshots = this.props.locationHistorySnapshots;
    const groupLocationHistorySnapshots = this.props
      .groupLocationHistorySnapshots;
    const config = this.props.context;
    const attribution = config.geoMap.attribution;
    const url = config.geoMap.url;
    let lastSnapshot = locationHistorySnapshots
      ? locationHistorySnapshots[locationHistorySnapshots.length - 1]
      : null;

    if (groupLocationHistorySnapshots) {
      // check which device get history snapshot for given time period
      let deviceIndex = groupLocationHistorySnapshots.findIndex(
        x => x.historySnapshot.pathSnapshot.length > 0,
      );
      if (deviceIndex > -1) {
        lastSnapshot =
          groupLocationHistorySnapshots[deviceIndex].historySnapshot
            .pathSnapshot[
            groupLocationHistorySnapshots[deviceIndex].historySnapshot
              .pathSnapshot.length - 1
          ];
      }
    }

    let endPoint =
      lastSnapshot !== null
        ? [lastSnapshot[0], lastSnapshot[1]]
        : [7.863382, 80.603342];
    const zoom = config.geoMap.defaultZoomLevel;

    let markers;
    if (locationHistorySnapshots) {
      markers = locationHistorySnapshots.map((pos, index) => ({
        id: index,
        iconColor: 'blue',
        position: pos,
        popup: pos[2],
        popupOpen: true, // if popup has to be open by default
        tooltip: pos[2],
      }));
    }

    const { animationPause } = this.state;
    const { t } = this.props;
    return (
      <div style={{ backgroundColor: '#ffffff', borderRadius: 5, padding: 5 }}>
        {this.props.view === 'animated' && (
          <div style={{ marginBottom: 20 }}>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <p style={{ marginTop: 5 }}>{t('label_playbackSpeed')}: </p>
                  </Col>
                  <Col>
                    <InputNumber
                      min={0}
                      max={10}
                      style={{ margin: '0 16px' }}
                      value={animationPause}
                      onChange={this.onChangePauseSlider}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <div style={{ marginRight: 5 }}>{this.changePlayButton()}</div>
              </Col>
              <Col>
                <div style={{ marginRight: 5 }}>
                  <AntdTooltip title="Clear">
                    <Button
                      onClick={this.onClickResetButton}
                      icon={<ClearOutlined />}
                    />
                  </AntdTooltip>
                </div>
              </Col>
            </Row>
          </div>
        )}
        {/* <Map */}
        {/*   ref={this.customMap} */}
        {/*   center={endPoint} */}
        {/*   zoom={zoom} */}
        {/*   style={{ height: 700 }} */}
        {/*   preferCanvas={true} */}
        {/* > */}
        {/*   <TileLayer url={url} attribution={attribution} /> */}
        {/*   {locationHistorySnapshots && ( */}
        {/*     <div> */}
        {/*       {this.selectViewMethod( */}
        {/*         markers, */}
        {/*         this.props.view, */}
        {/*         this.props.plainViewEnabled, */}
        {/*       )} */}
        {/*     </div> */}
        {/*   )} */}
        {/*   <div> */}
        {/*     {groupLocationHistorySnapshots && ( */}
        {/*       <div> */}
        {/*         {groupLocationHistorySnapshots.map((device, index) => { */}
        {/*           if (device.historySnapshot.pathSnapshot.length === 0) { */}
        {/*             return; */}
        {/*           } */}
        {/*           const color = getRandomColor(); */}
        {/*           const options = { */}
        {/*             color: color, */}
        {/*             pulseColor: '#FFF', */}
        {/*             delay: 1000, */}
        {/*           }; */}
        {/*           return ( */}
        {/*             <div key={index}> */}
        {/*               <CircleMarker */}
        {/*                 key={device.deviceIdentification} */}
        {/*                 center={ */}
        {/*                   device.historySnapshot.pathSnapshot[ */}
        {/*                     Math.floor( */}
        {/*                       Math.random() * */}
        {/*                         device.historySnapshot.pathSnapshot.length, */}
        {/*                     ) */}
        {/*                   ] */}
        {/*                 } */}
        {/*                 radius={2} */}
        {/*                 fillOpacity={1} */}
        {/*                 opacity={0.5} */}
        {/*                 fillColor={color} */}
        {/*                 color={color} */}
        {/*                 weight={10} */}
        {/*                 stroke={true} */}
        {/*               > */}
        {/*                 <Tooltip permanent={true} direction={'bottom'}> */}
        {/*                   {device.name} */}
        {/*                 </Tooltip> */}
        {/*               </CircleMarker> */}
        {/*               <AntPath */}
        {/*                 positions={device.historySnapshot.pathSnapshot} */}
        {/*                 options={options} */}
        {/*               /> */}
        {/*             </div> */}
        {/*           ); */}
        {/*         })} */}
        {/*       </div> */}
        {/*     )} */}
        {/*   </div> */}
        {/* </Map> */}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(LocationHistoryMap));
