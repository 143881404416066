/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Drawer, Typography } from 'antd';

const { Title, Paragraph, Text } = Typography;

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Drawer
        width={640}
        placement="right"
        title={<Title level={3}>PRIVACY POLICY</Title>}
        onClose={this.props.onClose}
        open={this.props.visible}
      >
        <Title level={3}>About Entgra IoT Server</Title>
        <Paragraph>
          Entgra IoT Server is a complete solution that enables device
          manufacturers and enterprises to connect and manage their devices,
          build apps, manage events, secure devices and data, and visualize
          sensor data in a scalable manner.
        </Paragraph>
        <Paragraph>
          It also offers a complete and secure Enterprise Mobility Management
          (EMM/MDM) solution that aims to address mobile computing challenges
          faced by enterprises today. Supporting iOS, Android, and Windows
          devices, it helps organizations deal with both Corporate Owned,
          Personally Enabled (COPE) and employee-owned devices with the Bring
          Your Own Device (BYOD) concept.
        </Paragraph>
        <Paragraph>
          Entgra IoT Server comes with advanced analytics, enabling users to
          analyze speed, proximity, and geo-fencing information of devices
          including details of those in motion and stationary state.
        </Paragraph>

        <Title level={2}>Privacy Policy</Title>
        <Paragraph>
          This policy describes how Entgra IoT Server 4.0.0 captures your
          personal information, the purposes of collection, and information
          about the retention of your personal information.
        </Paragraph>
        <Paragraph>
          Please note that this policy is for reference only, and is applicable
          for the software as a product. Entgra and its developers have no
          access to the information held within Entgra IoT Server 4.0.0.Please
          see the Disclaimer section for more information. Entities,
          organisations or individuals controlling the use and administration of
          Entgra IoT Server 4.0.0 should create their own privacy policies
          setting out the manner in which data is controlled or processed by the
          respective entity, organisation or individual.
        </Paragraph>

        <Title level={3}>What is personal information?</Title>
        <Paragraph>
          Entgra IoT Server 4.0.0 considers anything related to you and by which
          you may be identified as your personal information.
        </Paragraph>

        <Text strong>Signing in to Entgra IoT Server 4.0.0</Text>
        <ol>
          <li>
            Your user name (except in cases where the user name created by your
            employer is under contract)
          </li>
          <li>IP address used to log in</li>
          <li>Email address</li>
        </ol>

        <Text strong>Enrolling a device with Entgra IoT Server 4.0.0</Text>
        <ul>
          <li>
            Your device ID (e.g., phone or tablet), mobile number, IMEI number,
            and IMSI number
          </li>
          <li>Your device’s location</li>
          <li>Your device’s application list, and memory usage</li>
          <li>
            Your device’s usage statistics, such as RAM, battery level,
            connected hotspot details, and memory usage
          </li>
        </ul>

        <Paragraph>
          However, Entgra IoT Server 4.0.0 also collects the following
          information that is not considered personal information, but is used
          only for <Text strong>statistica</Text>l purposes. The reason for this
          is that this information can not be used to track you.
        </Paragraph>

        <ul>
          <li>City/Country from which you originated the TCP/IP connection</li>
          <li>
            Time of the day that you logged in (year, month, week, hour or
            minute)
          </li>
          <li>
            Type of device that you used to log in (e.g., phone or tablet)
          </li>
          <li>Operating system and generic browser information</li>
        </ul>

        <Title level={3}>Collection of personal information</Title>
        <Paragraph>
          Entgra IoT Server 4.0.0 collects your information only to serve your
          access requirements. For example:
        </Paragraph>
        <ul>
          <li>
            Entgra IoT Server 4.0.0 uses your IP address to detect any
            suspicious login attempts to your account.
          </li>
          <li>
            Entgra IoT Server 4.0.0 uses attributes like your first name, last
            name, etc., to provide a rich and personalized user experience.
          </li>
          <li>
            Entgra IoT Server 4.0.0 uses your security questions and answers
            only to allow account recovery.
          </li>
        </ul>

        <Text strong underline>
          Tracking Technologies
        </Text>
        <Paragraph>
          Entgra IoT Server 4.0.0 collects your information by:
        </Paragraph>
        <ul>
          <li>
            Collecting information from the user profile page where you enter
            your personal data.
          </li>
          <li>
            Tracking your IP address with HTTP request, HTTP headers, and
            TCP/IP.
          </li>
          <li>Tracking your geographic information with the IP address.</li>
          <li>
            Tracking your login history with browser cookies. Please see our
            cookie policy for more information.
          </li>
        </ul>

        <Title level={3}>Use of personal information</Title>
        <Paragraph>
          Entgra IoT Server 4.0.0 will only use your personal information for
          the purposes for which it was collected (or for a use identified as
          consistent with that purpose).
        </Paragraph>
        <Paragraph>
          Entgra IoT Server 4.0.0 uses your personal information only for the
          following purposes.
        </Paragraph>
        <ul>
          <li>
            To provide you with a personalized user experience. Entgra IoT
            Server 4.0.0 uses your name and uploaded profile pictures for this
            purpose.
          </li>
          <li>
            To protect your account from unauthorized access or potential
            hacking attempts. Entgra IoT Server 4.0.0 uses HTTP or TCP/IP
            Headers for this purpose.
          </li>
          <li>
            This includes:
            <ol>
              <li>IP address</li>
              <li>Browser fingerprinting</li>
              <li>Cookies</li>
            </ol>
          </li>
          <li>
            Derive statistical data for analytical purposes on system
            performance improvements. Entgra IoT Server 4.0.0 will not keep any
            personal information after statistical calculations. Therefore, the
            statistical report has no means of identifying an individual person.
          </li>
          <li>
            Entgra IoT Server 4.0.0 may use:
            <ol>
              <li>IP Address to derive geographic information</li>
              <li>
                Browser fingerprinting to determine the browser technology
                or/and version
              </li>
            </ol>
          </li>
        </ul>

        <Title level={3}>Disclosure of personal information</Title>
        <Paragraph>
          Entgra IoT Server 4.0.0 only discloses personal information to the
          relevant applications (also known as “Service Providers”) that are
          registered with Entgra IoT Server 4.0.0. These applications are
          registered by the identity administrator of your entity or
          organization. Personal information is disclosed only for the purposes
          for which it was collected (or for a use identified as consistent with
          that purpose) as controlled by such Service Providers, unless you have
          consented otherwise or where it is required by law.
        </Paragraph>
        <Title level={4} underline>
          Legal process
        </Title>
        <Paragraph>
          Please note that the organisation, entity or individual running Entgra
          IoT Server 4.0.0 may be compelled to disclose your personal
          information with or without your consent when it is required by law
          following due and lawful process.
        </Paragraph>

        <Title level={3}>Storage of personal information</Title>

        <Title level={4} underline>
          Where your personal information is stored
        </Title>
        <Paragraph>
          Entgra IoT Server 4.0.0 stores your personal information in secured
          databases. Entgra IoT Server 4.0.0 exercises proper industry accepted
          security measures to protect the database where your personal
          information is held.Entgra IoT Server 4.0.0 as a product does not
          transfer or share your data with any third parties or locations.
        </Paragraph>
        <Paragraph>
          Entgra IoT Server 4.0.0 may use encryption to keep your personal data
          with an added level of security.
        </Paragraph>

        <Title level={4} underline>
          How long your personal information is retaineds
        </Title>
        <Paragraph>
          Entgra IoT Server 4.0.0 retains your personal data as long as you are
          an active user of our system. You can update your personal data at any
          time using the given self-care user portals.
        </Paragraph>
        <Paragraph>
          Entgra IoT Server 4.0.0 may keep hashed secrets to provide you with an
          added level of security. This includes:
        </Paragraph>
        <ul>
          <li>Current password</li>
          <li>Previously used passwords</li>
        </ul>

        <Title level={4} underline>
          How to request removal of your personal information
        </Title>
        <Paragraph>
          You can request the administrator to delete your account. The
          administrator is the administrator of the tenant you are registered
          under, or the super-administrator if you do not use the tenant
          feature.
        </Paragraph>
        <Paragraph>
          Additionally, you can request to anonymize all traces of your
          activities that Entgra IoT Server 4.0.0 may have retained in logs,
          databases or analytical storage.
        </Paragraph>

        <Title level={3}>More information</Title>

        <Title level={4} underline>
          Changes to this policy
        </Title>
        <Paragraph>
          Upgraded versions of Entgra IoT Server 4.0.0 may contain changes to
          this policy. Revisions to this policy will be packaged within such
          upgrades and would only apply to users who choose to use upgraded
          versions.
        </Paragraph>

        <Title level={4} underline>
          Your choices
        </Title>
        <Paragraph>
          If you are already have an user account within Entgra IoT Server 4.0.0
          ; you have the right to deactivate your account if you find that this
          privacy policy is unacceptable to you.
        </Paragraph>
        <Paragraph>
          If you do not have an account and you do not agree with our privacy
          policy, you can chose not to create one.
        </Paragraph>

        <Title level={4} underline>
          Contact us
        </Title>
        <Paragraph>
          Please contact Entgra if you have any question or concerns regarding
          this privacy policy.
        </Paragraph>
        <a href={'https://entgra.io/contact'}>contact</a>

        <Title level={3}>Disclaimer</Title>
        <Paragraph>
          Entgra, its employees, partners, and affiliates do not have access to
          and do not require, store, process or control any of the data,
          including personal data contained in Entgra IoT Server 4.0.0. All
          data, including personal data is controlled and processed by the
          entity or individual running Entgra IoT Server 4.0.0. Entgra, its
          employees partners and affiliates are not a data processor or a data
          controller within the meaning of any data privacy regulations. Entgra
          does not provide any warranties or undertake any responsibility or
          liability in connection with the lawfulness or the manner and purposes
          for which Entgra IoT Server 4.0.0 is used by such entities or persons.
        </Paragraph>
        <Paragraph>
          This privacy policy is for the informational purposes of the entity or
          persons running Entgra IoT Server 4.0.0 and sets out the processes and
          functionality contained within Entgra IoT Server 4.0.0 regarding
          personal data protection. It is the responsibility of entities and
          persons running Entgra IoT Server 4.0.0 to create and administer its
          own rules and processes governing users’ personal data, Please note
          that the creation of such rules and processes may change the use,
          storage and disclosure policies contained herein. Therefore users
          should consult the entity or persons running Entgra IoT Server 4.0.0
          for its own privacy policy for details governing users’ personal data.
        </Paragraph>
      </Drawer>
    );
  }
}

export default PrivacyPolicy;
