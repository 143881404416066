/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Result, Space, Table, Tooltip } from 'antd';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import EditDEPProfile from './components/EditDEPProfile';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import AssignDEPDevice from './components/AssignDEPDevice';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

class DepProfileTable extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      data: [],
      pagination: {},
      loading: false,
    };
    const { t } = this.props;
    this.columns = [
      {
        title: t('label_profileName'),
        key: 'profileName',
        dataIndex: 'profileName',
      },
      {
        title: t('label_uuid'),
        key: 'uuid',
        dataIndex: 'UUID',
      },
      {
        title: t('label_department'),
        key: 'department',
        dataIndex: 'profileContent',
        render: profileContent => (
          <p>{JSON.parse(profileContent.replace(/&quot;/g, '"')).department}</p>
        ),
      },
      {
        title: t('label_removable'),
        key: 'removable',
        dataIndex: 'profileContent',
        render: profileContent => {
          if (
            JSON.parse(profileContent.replace(/&quot;/g, '"')).is_mdm_removable
          ) {
            return <CheckCircleTwoTone twoToneColor={'#6ab04c'} />;
          }
          return <CloseCircleTwoTone twoToneColor={'#ff7979'} />;
        },
      },
      {
        title: t('label_mandatory'),
        key: 'mandatory',
        dataIndex: 'profileContent',
        render: profileContent => {
          if (JSON.parse(profileContent.replace(/&quot;/g, '"')).is_mandatory) {
            return <CheckCircleTwoTone twoToneColor={'#6ab04c'} />;
          }
          return <CloseCircleTwoTone twoToneColor={'#ff7979'} />;
        },
      },
    ];
  }

  componentDidMount() {
    this.setPermittedActions();
    this.fetch();
  }

  setPermittedActions = () => {
    const { t } = this.props;
    if (isAuthorized(this.config.scopes, ['perm:ios:dep-add'])) {
      this.columns.push({
        title: t('label_actions'),
        key: 'actions',
        dataIndex: 'profileContent',
        render: (profileContent, row) => (
          <Space>
            <div>
              <Tooltip placement="top" title={t('label_editEnrollment')}>
                <HtmlComments
                  permission={'/permission/admin/device-mgt/devices/dep/add'}
                />
                <a>
                  <EditDEPProfile
                    values={JSON.parse(profileContent.replace(/&quot;/g, '"'))}
                    uuid={row.UUID}
                  />
                </a>
              </Tooltip>
            </div>
            <div style={{ marginLeft: 10 }}>
              <Tooltip placement="top" title={t('label_assignDEPProfile')}>
                <HtmlComments
                  permission={'/permission/admin/device-mgt/devices/dep/add'}
                />
                <a>
                  <AssignDEPDevice profile={row} />
                </a>
              </Tooltip>
            </div>
          </Space>
        ),
      });
    }
  };

  // fetch data from api
  fetch = (params = {}) => {
    this.setState({ loading: true });
    const { t } = this.props;
    // get current page
    const currentPage = params.hasOwnProperty('page') ? params.page : 1;

    const extraParams = {
      offset: 10 * (currentPage - 1), // calculate the offset
      limit: 10,
    };

    const encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    // send request to the invoker
    axios
      .get(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          '/device-mgt/ios/v1.0/dep/profiles?' +
          encodedExtraParams,
      )
      .then(res => {
        if (res.status === 200) {
          const pagination = { ...this.state.pagination };
          this.setState({
            loading: false,
            data: res.data.data,
            pagination,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_depProfiles') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  render() {
    const { data, pagination, loading } = this.state;
    const { t } = this.props;
    return (
      <div className={styles.tableContainer}>
        {data.count > 0 && (
          <div>
            <Table
              columns={this.columns}
              rowKey={record => record.id}
              dataSource={data.profiles}
              pagination={{
                ...pagination,
                size: 'small',
                // position: "top",
                total: data.count,
                disabled: loading,
                showTotal: (total, range) =>
                  t('table_pagination', {
                    range1: range[0],
                    range2: range[1],
                    total: total,
                    data: t('home_menu_devices'),
                  }),
                // showQuickJumper: true
              }}
              loading={loading}
              onChange={this.handleTableChange}
            />
          </div>
        )}
        {data.count === 0 && ( // todo Change Result icon #issue592
          <div>
            <HtmlComments
              permission={'/permission/admin/device-mgt/devices/dep/add'}
            />
            <Result
              status="404"
              title={t('noDEPProfile_Txt')}
              subTitle={
                isAuthorized(this.config.scopes, ['perm:ios:dep-add'])
                  ? t('addDEPProfile_Txt')
                  : null
              }
              extra={
                isAuthorized(this.config.scopes, ['perm:ios:dep-add']) && (
                  <div>
                    <HtmlComments
                      permission={
                        '/permission/admin/device-mgt/devices/dep/add'
                      }
                    />
                    <Link
                      to={`/${this.config.appName}/devices/dep-profiles?add-dep-profile=true`}
                    >
                      <Button type="primary">
                        {t('label_createDEPProfile')}
                      </Button>
                    </Link>
                  </div>
                )
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(DepProfileTable));
