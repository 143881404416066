/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
/* eslint-disable no-async-promise-executor */
import { create } from 'zustand';
import { applyNodeChanges, applyEdgeChanges } from 'reactflow';
import axios from 'axios';
import { handleApiError } from './../../../../services/utils/errorHandler';
// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useStore = create((set, get) => ({
  nodes: [],
  edges: [],
  rootCount: 0,
  setNodes: newNodes => {
    set({ nodes: newNodes });
    get().onNodesChange(newNodes);
  },
  setEdges: newEdges => {
    set({ edges: newEdges });
    get().onEdgesChange(newEdges);
  },
  onNodesChange: changes => {
    set({
      nodes: applyNodeChanges(changes, get().nodes)
    });
  },
  onEdgesChange: changes => {
    set({
      edges: applyEdgeChanges(changes, get().edges)
    });
  },
  onConnect: async (source, target, apiURI, organizationDescription, t) => {
    return new Promise(async resolve => {
      try {
        const requestBody = {
          parentDeviceId: source,
          deviceId: target,
          deviceOrganizationMeta: organizationDescription,
          isCheckCyclicRelationship: true
        };
        const response = await axios.post(apiURI, requestBody);
        if (response.status === 201 && response.data.data.isSuccess === true) {
          const orgURI = apiURI + `/organization/${target}/${source}`;
          const organization = await axios.get(orgURI);
          if (organization.status === 200) {
            const edgeId = `${organization.data.data.organizationId}`;

            // Check if the edge ID is not already present
            if (!get().edges.some(edge => edge.id === edgeId)) {
              const newEdge = {
                id: edgeId,
                target: `${organization.data.data.deviceId}`,
                source: `${organization.data.data.parentDeviceId}`,
                type: 'turbo'
              };

              set({
                edges: [...get().edges, newEdge]
              });
              resolve();
            }
          }
        }
        resolve();
      } catch (error) {
        handleApiError(error, t('api_retrieveDeviceOrgDetailsError'), t);
      }
    });
  },
  onNodeDelete: nodeId => {
    set({
      nodes: get().nodes.filter(node => node.id !== nodeId),
      edges: get().edges.filter(
        edge => edge.source !== nodeId && edge.target !== nodeId
      )
    });
  }
}));

export default useStore;
