/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

export const INVENTORY_STATUS_NOT_ALLOWED_TO_BRANCH_USERS = [
  'CONFIGURED',
  'CREATED',
  'DEFECTIVE',
  'UNCLAIMED',
  'REMOVED',
  'RETURNED',
  'WARRANTY_PENDING'
];

export const INVENTORY_STATUS = [
  'ACTIVE',
  'ASSIGNED',
  'BLOCKED',
  'CONFIGURED',
  'CREATED',
  'DEFECTIVE',
  'DISENROLLMENT_REQUESTED',
  'INACTIVE',
  'READY_TO_CONNECT',
  'REMOVED',
  'RETURNED',
  'RETURN_PENDING',
  'SUSPENDED',
  'UNCLAIMED',
  'UNREACHABLE',
  'WARRANTY_PENDING',
  'WARRANTY_REPLACED',
  'WARRANTY_SENT'
];

export const SIM_INVENTORY_STATUS = [
  'ACTIVE',
  'CREATED',
  'INACTIVE',
  'READY_TO_CONNECT',
  'REMOVED'
];

export const SIM_INVENTORY_STATUS_CHANGE = [
  { from: 'CREATED', to: ['REMOVED', 'READY_TO_CONNECT'] },
  { from: 'REMOVED', to: ['CREATED'] },
  { from: 'READY_TO_CONNECT', to: ['CREATED'] }
];

export const COM_INVENTORY_STATUS_NOT_ALLOWED_TO_BRANCH_USERS = [
  'CONFIGURED',
  'CREATED',
  'DEFECTIVE',
  'REMOVED',
  'RETURNED',
  'WARRANTY_PENDING'
];

export const COM_INVENTORY_STATUS = [
  'ACTIVE',
  'ASSIGNED',
  'CONFIGURED',
  'CREATED',
  'DEFECTIVE',
  'INACTIVE',
  'REMOVED',
  'RETURNED',
  'RETURN_PENDING',
  'UNREACHABLE',
  'WARRANTY_PENDING'
];

export const checkAllBranchAccess = userBranches => {
  return Object.keys(BRANCHES).length === userBranches.length;
};

export const onlyAccessisbleStatus = (
  allStatus,
  removeStatus,
  userBranches = null
) => {
  return userBranches !== null && !checkAllBranchAccess(userBranches)
    ? allStatus.filter(function(el) {
        return removeStatus.indexOf(el) < 0;
      })
    : allStatus;
};

export const COM_INVENTORY_STATUS_CHANGE = [
  { from: 'CREATED', to: ['DEFECTIVE'] },
  { from: 'REMOVED', to: ['CREATED'] },
  { from: 'RETURNED', to: ['CONFIGURED', 'DEFECTIVE'] },
  { from: 'DEFECTIVE', to: ['REMOVED', 'WARRANTY_PENDING'] },
  { from: 'WARRANTY_PENDING', to: ['CREATED', 'REMOVED'] }
];

export const POWER_METER_INVENTORY_STATUS_CHANGE = [
  { from: 'CREATED', to: ['DEFECTIVE'] },
  { from: 'DEFECTIVE', to: ['REMOVED', 'WARRANTY_PENDING'] },
  { from: 'READY_TO_CONNECT', to: ['ASSIGNED'] },
  { from: 'REMOVED', to: ['CREATED'] },
  { from: 'RETURNED', to: ['CONFIGURED', 'DEFECTIVE'] },
  { from: 'UNCLAIMED', to: ['CONFIGURED'] },
  { from: 'WARRANTY_PENDING', to: ['CREATED', 'REMOVED'] }
];

export const OPERATION_STATUS_CHANGE = [
  { from: 'NOTNOW', to: ['ERROR'] },
  { from: 'PENDING', to: ['ERROR'] }
];

export const METER_TESTING_STATUS = [
  'COMPLETED',
  'ERROR',
  'IN_PROGRESS',
  'NOT ENROLLED',
  'PENDING',
  'UNREACHABLE'
];

export const NODE_TYPES = ['INTERNAL', 'BILLING'];

export const METER_ALLOCATION_PURPOSE_TYPES = [
  { make: 'BULK', model: 'INSTALLATION', value: 'Bulk' },
  { make: 'STREETLIGHT', model: 'INSTALLATION', value: '3Phase' },
  { make: 'NETWORK', model: 'MONITORING', value: 'NMD' },
  { make: 'GENERAL', model: '', value: 'Phase' }
];

export const OBIS_MAP = [
  { label: 'Load Profile', value: 'LP' },
  { label: 'Power Failure Event Log', value: 'PFEL' },
  { label: 'Class 1 disturbance events', value: 'C1DE' },
  { label: 'Class 2 disturbance events', value: 'C2DE' },
  { label: 'Class 3 disturbance events', value: 'C3DE' }
];

export const STATUSES = [
  'ACCEPTED_1',
  'ACCEPTED_2',
  'ASSIGNED',
  'CANCELLED',
  'CREATED',
  'DEVICE_ADDED',
  'DISPATCHED',
  'RECEIVED',
  'REJECTED_1',
  'REJECTED_2'
];

export const REQUEST_METER_RETURN_STATUS = [
  'ASSIGNED',
  'CANCELLED',
  'CREATED',
  'DEVICE_ADDED',
  'DISPATCHED',
  'RECEIVED'
];

export const REQUEST_READOUT_LOADPROFILE_STATUS = [
  'ASSIGNED',
  'CANCELLED',
  'COMPLETED',
  'CREATED',
  'EXECUTED'
];

export const REQUEST_READOUT_FIELDTEST_STATUS = [
  'ASSIGNED',
  'CANCELLED',
  'CREATED',
  'DISPATCHED'
];

export const REQUEST_DISCONNECTION_STATUS = [
  'ACCEPTED_1',
  'ASSIGNED',
  'CANCELLED',
  'COMPLETED',
  'CREATED',
  'EXECUTED',
  'REJECTED_1'
];

export const REQUEST_FIELDTEST_STATUS = [
  'ASSIGNED',
  'CANCELLED',
  'COMPLETED',
  'CREATED',
  'DISPATCHED'
];

export const PAGINATION_OPTIONS = [10, 25, 50, 100];

export const TEST_HISTORY_STATUS = [
  'COMPLETED',
  'ERROR',
  'IN_PROGRESS',
  'NOTNOW',
  'PENDING',
  'REPEATED'
];

export const OPERATION_RESPONSE_STATUS = [
  'COMPLETED',
  'ERROR',
  'IN_PROGRESS',
  'NOTNOW',
  'PENDING',
  'REPEATED'
];

export const REQUEST_ACTIONS = [
  {
    name: 'Meter Allocation Request',
    actions: [
      {
        key: 'First Acceptance',
        value: ['CREATED'],
        permission: 'perm:meter-allocation:first-review'
      },
      {
        key: 'Second Acceptance',
        value: ['ACCEPTED_1'],
        permission: 'perm:meter-allocation:second-review'
      },
      {
        key: 'Assign Devices',
        value: ['ACCEPTED_2', 'DEVICE_ADDED'],
        permission: 'perm:meter-allocation:assign'
      },
      {
        key: 'Dispatch Devices',
        value: ['ASSIGNED'],
        permission: 'perm:meter-allocation:dispatch'
      },
      {
        key: 'Mark Recipient',
        value: ['DISPATCHED'],
        permission: 'perm:meter-allocation:receive'
      }
    ],
    permission: 'perm:meter-allocation:view',
    value: '/request/meter'
  },
  {
    name: 'Meter Return Request',
    actions: [
      {
        key: 'Assign Devices',
        value: ['CREATED', 'DEVICE_ADDED'],
        permission: 'perm:meter-return:assign'
      },
      {
        key: 'Dispatch Devices',
        value: ['ASSIGNED'],
        permission: 'perm:meter-return:dispatch'
      },
      {
        key: 'View Pending Requests',
        value: ['DEVICE_ADDED'],
        permission: 'perm:meter-allocation:first-review'
      },
      {
        key: 'Mark Recipient',
        value: ['DISPATCHED'],
        permission: 'perm:meter-return:receive'
      }
    ],
    permission: 'perm:meter-return:view',
    value: '/request/returnmeter'
  },
  {
    name: 'Readout Request',
    actions: [
      {
        key: 'Execute Request',
        value: ['ASSIGNED'],
        permission: 'perm:readout-request:execute'
      }
    ],
    permission: 'perm:readout-request:view',
    value: '/request/readout'
  },
  {
    name: 'Load Profile Request',
    actions: [
      {
        key: 'Execute Request',
        value: ['ASSIGNED'],
        permission: 'perm:load-profile-request:execute'
      }
    ],
    permission: 'perm:load-profile-request:view',
    value: '/request/loadprofile'
  },
  {
    name: 'Field Test Request',
    actions: [
      {
        key: 'Assign Team',
        value: ['CREATED'],
        permission: 'perm:field-test-request:assign'
      },
      {
        key: 'Dispatch Team',
        value: ['ASSIGNED'],
        permission: 'perm:field-test-request:dispatch'
      },
      {
        key: 'Submit Report',
        value: ['DISPATCHED'],
        permission: 'perm:field-test-request:complete'
      }
    ],
    permission: 'perm:field-test-request:view',
    value: '/request/fieldtest'
  },
  {
    name: 'Remote Disconnection Request',
    actions: [
      {
        key: 'Approval',
        value: ['ASSIGNED'],
        permission: 'perm:disconnection-request:first-review'
      },
      {
        key: 'Execute Request',
        value: ['ACCEPTED_1'],
        permission: 'perm:disconnection-request:execute'
      }
    ],
    permission: 'perm:disconnection-request:view',
    value: '/request/disconnection'
  },
  {
    name: 'Remote Reconnection Request',
    actions: [
      {
        key: 'Approval',
        value: ['ASSIGNED'],
        permission: 'perm:reconnection-request:first-review'
      },
      {
        key: 'Execute Request',
        value: ['ACCEPTED_1'],
        permission: 'perm:reconnection-request:execute'
      }
    ],
    permission: 'perm:reconnection-request:view',
    value: '/request/reconnection'
  },
  {
    name: 'COM Allocation Request',
    actions: [
      {
        key: 'First Acceptance',
        value: ['CREATED'],
        permission: 'perm:com-allocation:first-review'
      },
      {
        key: 'Second Acceptance',
        value: ['ACCEPTED_1'],
        permission: 'perm:com-allocation:second-review'
      },
      {
        key: 'Assign Devices',
        value: ['ACCEPTED_2', 'DEVICE_ADDED'],
        permission: 'perm:com-allocation:assign'
      },
      {
        key: 'Dispatch Devices',
        value: ['ASSIGNED'],
        permission: 'perm:com-allocation:dispatch'
      },
      {
        key: 'Mark Recipient',
        value: ['DISPATCHED'],
        permission: 'perm:com-allocation:receive'
      }
    ],
    permission: 'perm:com-allocation:view',
    value: '/request/com'
  },
  {
    name: 'COM Return Request',
    actions: [
      {
        key: 'View Pending requests',
        value: ['DEVICE_ADDED'],
        permission: 'perm:com-return-request:cancel'
      },
      {
        key: 'Assign Devices',
        value: ['CREATED', 'DEVICE_ADDED'],
        permission: 'perm:com-return-request:assign'
      },
      {
        key: 'Dispatch Devices',
        value: ['ASSIGNED'],
        permission: 'perm:com-return-request:dispatch'
      },
      {
        key: 'Mark Recipient',
        value: ['DISPATCHED'],
        permission: 'perm:com-return-request:receive'
      }
    ],
    permission: 'perm:com-return-request:view',
    value: '/request/returncom'
  },
  {
    name: 'Field Operation Request',
    actions: [
      {
        key: 'Create field operation requests',
        value: ['CREATED'],
        permission: 'perm:field-operation-request:create'
      },
      // {
      //   key: 'Complete field operation requests',
      //   value: ['COMPLETED'],
      //   permission: 'perm:field-operation-request:create',
      // },
      {
        key: 'Cancel field operation requests',
        value: ['CANCEL'],
        permission: 'perm:field-operation-request:cancel'
      }
    ],
    permission: 'perm:field-operation-request:view',
    value: '/request/field-operation'
  }
];

export const ADD_DEVICES_PURPOSES = ['NEW_INSTALLATION', 'REPLACEMENT'];

export const CHANGE_STATE_ACTIONS_ALLOWED = [
  'CREATED',
  'DEFECTIVE',
  'READY_TO_CONNECT',
  'REMOVED',
  'RETURNED',
  'UNCLAIMED',
  'WARRANTY_PENDING'
];

export const CHANGE_OPERATION_STATE_ACTIONS_ALLOWED = ['NOTNOW', 'PENDING'];

export const BREADCRUMB_URL_CHECK = [
  '/sim/inventory',
  '/com/inventory',
  '/meter/inventory',
  '/assign-meter/bulk',
  '/replace-meter/bulk',
  '/remove-meter/bulk',
  '/testing/perform',
  '/reachability/overview',
  '/read/current',
  '/request/search',
  '/reports/daily-evaluation-report',
  '/events',
  '/operations'
];

export const PT_CT_RATIOS = [
  {
    CT: [
      '1/1',
      '100/5',
      '200/5',
      '300/5',
      '400/5',
      '500/5',
      '600/5',
      '750/5',
      '800/5',
      '1000/5',
      '1500/5'
    ],
    PT: ['11000/110']
  }
];

export const OPERATION_CODES = [
  'BILLING_REGISTERS_RETRIEVE',
  'REMOTE_RELAY_ON',
  'REMOTE_RELAY_OFF',
  'TIME_SYNC',
  'STATUS_RETRIEVE'
];

export const MONTHS = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' }
];

export const OPERATION_CODES_DATA_VISUALIZAION = [
  'BILLING_REGISTERS_RETRIEVE',
  'REMOTE_RELAY_ON',
  'REMOTE_RELAY_OFF',
  'SELF_TEST',
  'STATUS_RETRIEVE'
];

export const PROFILE_DATA_OPTIONS = ['LP'];

export const SUB_TYPE_ID = {
  '2': 'MicroStar - DLMS Bulk',
  '101': 'ME-3D3Y',
  '102': 'Schneider - PM2100'
};
export const BRANCHES = {
  '00': {
    name: 'INTERNAL_TESTING'
  },
  '01': {
    name: 'HEADOFFICE'
  },
  '02': {
    name: 'KAHATHUDUWA'
  }
};

export const SUBTYPE_MAPPING = {
  'MicroStar - DLMS Bulk': '2',
  'ME-3D3Y': '101',
  'Schneider - PM2100': '102'
};

export const SAMPLE_CSV_UPLOAD = {
  imsiList: [
    ['IMSI'],
    ['413027037784168'],
    ['413027037784169'],
    ['413027037784170'],
    ['413027037784171'],
    ['413027037784172']
  ],
  ipAddressList: [
    ['IP_ADDRESS'],
    ['10.101.15.100'],
    ['10.101.15.101'],
    ['10.101.15.102'],
    ['10.101.15.103'],
    ['10.101.15.104']
  ],
  mobileNoList: [
    ['MOBILE_NO'],
    ['+94776111110'],
    ['+94776111111'],
    ['+94776111112'],
    ['+94776111113'],
    ['+94776111114']
  ],
  imeiList: [
    ['IMEI'],
    ['864495036762400'],
    ['864495036762401'],
    ['864495036762402'],
    ['864495036762403'],
    ['864495036762404']
  ],
  serialList: [
    ['SERIAL'],
    ['000020400000'],
    ['000020400001'],
    ['000020400002'],
    ['000020400003'],
    ['000020400004'],
    ['000020400005']
  ],
  customerRefList: [
    ['ACCOUNT_NO'],
    ['0000000001'],
    ['0000000002'],
    ['0000000003'],
    ['0000000004'],
    ['0000000005']
  ],
  addBulkSimList: [
    ['MOBILE_NO', 'IMSI', 'APN', 'IP_ADDRESS'],
    ['+94776111111', '413027037784169', 'LECOSMSTC', '10.101.15.101'],
    ['+94776111112', '413027037784170', 'LECOSMSTC', '10.101.15.102'],
    ['+94776111113', '413027037784171', 'LECOSMSTC', '10.101.15.103']
  ],
  addBulkComList: [
    ['IMEI', 'FWVERSION', 'PIN'],
    ['864495036762401', '1', '1234'],
    ['864495036762402', '2', '1234'],
    ['864495036762403', '3', '1234']
  ],
  attachBulkSIMList: [
    ['IMSI', 'IMEI'],
    ['413027037784169', '864495036762401'],
    ['413027037784170', '864495036762402'],
    ['413027037784171', '864495036762403']
  ],
  addCredentialsList: [
    ['USER', 'SERIAL', 'PASSWORD'],
    ['1', '000020400003', '00000001'],
    ['2', '000020400003', '00000002'],
    ['3', '000020400003', '00000003'],
    ['1', '000020400004', '00000001'],
    ['2', '000020400004', '00000002'],
    ['3', '000020400004', '00000003'],
    ['1', '000020400005', '00000001'],
    ['2', '000020400005', '00000002'],
    ['3', '000020400005', '00000003']
  ],
  comAttachToMetersList: [
    ['SERIAL', 'IMEI'],
    ['000020400003', '864495036762401'],
    ['000020400004', '864495036762402'],
    ['000020400005', '864495036762403']
  ],
  AssignMetersToCustomersList: [
    [
      'SERIAL',
      'ACCOUNT_NO',
      'LATITUDE',
      'LONGITUDE',
      'NODE_TYPE',
      'CATEGORY',
      'BRANCH',
      'DEPOT',
      'SUBSTATION_ID',
      'POLE_ID',
      'HAS_EXPORT',
      'HAS_EBILLING',
      'IS_FEEDEREND'
    ],
    [
      '000020400003',
      '0000000003',
      '6.9271',
      '79.8612',
      'INTERNAL',
      'INTERNAL_TESTING',
      'GALLE',
      'GALLE',
      'A123123',
      '123',
      true,
      false,
      true
    ],
    [
      '000020400004',
      '0000000004',
      '6.9272',
      '79.8612',
      'BILLING',
      'INTERNAL_TESTING',
      'GALLE',
      'GALLE',
      'A123123',
      '123',
      true,
      true,
      true
    ]
  ],
  replaceMeterList: [
    ['OLD_METER_SERIAL', 'NEW_METER_SERIAL', 'ACCOUNT_NO'],
    ['000020400003', '000020400005', '0000000005'],
    ['000020400004', '000020400006', '0000000006']
  ],
  uninstallMeterList: [
    ['SERIAL', 'ACCOUNT_NO'],
    ['000020400003', '000020400003'],
    ['000020400004', '000020400004']
  ],
  AddBulkPowerMeterList: [
    ['SERIAL', 'IP_ADDRESS', 'IMEI'],
    ['000020400003', '10.101.15.103', '864495036762401'],
    ['000020400004', '10.101.15.104', '864495036762402'],
    ['000020400005', '10.101.15.105', '864495036762403']
  ]
};

export const checkboxOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
  { label: 'Any', value: '' }
];

export const customerAssignementCheckboxOptions = [
  { label: 'All', value: '' },
  { label: 'Assigned', value: 'true' },
  { label: 'Unassigned', value: 'false' }
];

export const perChunkOnCSVUpload = 100;
export const progressBarOnCSVUploadComplete = 100;

export const ReadMeterMaxUploadLimit = 50;
export const LoadProfileMaxUploadLimit = 50;
export const TestMeterMaxUploadLimit = 100;

export const REQUEST_FIELD_OPERATION_STATUS = ['CREATED', 'CANCELLED'];

export const fieldOperationTypes = [
  { label: 'Meter Install', value: 'METER_INSTALL' },
  { label: 'Meter Replacement', value: 'METER_REPLACEMENT' },
  { label: 'Meter Removal', value: 'METER_REMOVAL' },
  { label: 'Convert to RMR', value: 'CONVERT_TO_RMR' },
  { label: 'Com Check', value: 'COM_CHECK' }
];

export const fieldOperationUserPermissions = [
  {
    label: 'METER_INSTALL',
    value:
      '/device-mgt/rmr/device/validate,' +
      '/device-mgt/rmr/device/assign,' +
      '/device-mgt/rmr/device/view,' +
      '/device-mgt/rmr/device/admin/operations/self-test-individual,' +
      '/device-mgt/rmr/device/admin/view-meter-reading,' +
      '/device-mgt/rmr/device/view-installation,' +
      '/device-mgt/rmr/customer/view-installation,' +
      '/device-mgt/rmr/global-registers/view,' +
      '/device-mgt/devices/operations/view'
  },
  {
    label: 'METER_REPLACEMENT',
    value:
      '/device-mgt/rmr/device/validate,' +
      '/device-mgt/rmr/global-registers/view,' +
      '/device-mgt/devices/operations/view'
  },
  { label: 'METER_REMOVAL', value: '/device-mgt/rmr/device/validate' },
  {
    label: 'COM_CHECK',
    value:
      '/device-mgt/rmr/device/view,' +
      '/device-mgt/rmr/device/admin/operations/billing-registers-retrieve-individual,' +
      '/device-mgt/rmr/device/admin/view-meter-reading,' +
      '/device-mgt/rmr/device/view-installation,' +
      '/device-mgt/rmr/customer/view-installation,' +
      '/device-mgt/rmr/global-registers/view,' +
      '/device-mgt/devices/operations/view'
  },
  {
    label: 'CONVERT_TO_RMR',
    value:
      '/device-mgt/rmr/device/assign,' +
      '/device-mgt/rmr/device/view,' +
      '/device-mgt/rmr/device/admin/operations/self-test-individual,' +
      '/device-mgt/rmr/device/admin/view-meter-reading,' +
      '/device-mgt/rmr/device/view-installation,' +
      '/device-mgt/rmr/customer/view-installation,' +
      '/device-mgt/rmr/global-registers/view,' +
      '/device-mgt/devices/operations/view'
  }
];
