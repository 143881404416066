/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { BellOutlined } from '@ant-design/icons';
import {
  message,
  notification,
  Button,
  Radio,
  Typography,
  Result,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { withConfigContext } from '../../../../components/ConfigContext';
import axios from 'axios';
import NotificationsTable from './Components/NotificationsTable';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import HtmlComments from '../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';

const { Title } = Typography;

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('label_notifications'),
    },
  ];
};

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      notificationType: 'all',
    };
  }

  componentDidMount() {
    this.context.setCurrentRoute('notifications');
  }

  handleModeChange = e => {
    const notificationType = e.target.value;
    this.setState({ notificationType });
  };

  clearNotifications = () => {
    const config = this.props.context;
    const { t } = this.props;
    axios
      .put(
        window.location.origin +
        config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/notifications/clear-all',
        { 'Content-Type': 'application/json; charset=utf-8' },
      )
      .then(res => {
        if (res.status === 200) {
          notification.success({
            message: t('api_successMsg'),
            duration: 0,
            description: t('api_clearNotificationsMsg'),
          });
        }
      })
      .catch(error => {
        if (error.hasOwnProperty('response') && error.response.status === 401) {
          // todo display a popop with error
          message.error(t('api_notLoggedInError'));
          window.location.href =
            window.location.origin + `/${config.appName}/login`;
        } else {
          notification.error({
            message: t('api_errorMsg'),
            duration: 0,
            description: t('api_clearNotificationsError'),
          });
        }
      });
  };

  render() {
    const { notificationType } = this.state;
    const { t } = this.props;
    const config = this.props.context;
    return (
      <div>
        <HtmlComments
          permission={'/permission/admin/device-mgt/notifications/view'}
        />
        <Authorized
          scope={['perm:notifications:view']}
          yes={
            <div>
              <PageHeader
                className={styles.pageHeader}
                title={
                  <Title level={1}>
                    <span className={styles.icon}>
                      <BellOutlined />
                    </span>
                    {t('home_menu_deviceNotification')}
                  </Title>
                }
                ghost={false}
                extra={[
                  <div key={'notification'}>
                    <Radio.Group
                      onChange={this.handleModeChange}
                      defaultValue={'all'}
                      value={notificationType}
                      style={{ marginBottom: 8, marginRight: 5 }}
                    >
                      <Radio.Button
                        value={'all'}
                        style={{ borderRadius: '20px 0 0 20px' }}
                      >
                        {t('label_allNotifications')}
                      </Radio.Button>
                      <Radio.Button
                        value={'unread'}
                        style={{ borderRadius: '0 20px 20px 0' }}
                      >
                        {t('label_unreadNotifications')}
                      </Radio.Button>
                    </Radio.Group>
                    <Button
                      type="primary"
                      style={{
                        marginRight: 10,
                        marginBottom: 8,
                        display:
                          notificationType === 'unread' ? 'inline' : 'none',
                      }}
                      onClick={this.clearNotifications}
                    >
                      {t('label_clearAllNotifications')}
                    </Button>
                  </div>,
                ]}
                breadcrumb={
                  <BreadcrumbComponent
                    breadcrumbList={routes(config.appName, t)}
                  />
                }
              />
              <div className={styles.table}>
                <NotificationsTable notificationType={notificationType} />
              </div>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}
Notifications.contextType = RoutesContext;
export default withConfigContext(withTranslation()(Notifications));
