/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  SettingOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Input,
  Modal,
  notification,
  Popconfirm,
  Select,
  Tooltip,
  Typography,
  Alert,
  Space,
} from 'antd';
import axios from 'axios';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import GroupForm from '../GroupForm';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import AddRole from '../../../../../Roles/components/RolesTable/components/AddRole';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DeviceOperationsDrawer from '../DeviceOperationsDrawer';

const { Text } = Typography;

class GroupActions extends React.Component {
  ownerships;

  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      editModalVisible: false,
      shareModalVisible: false,
      name: this.props.data.name,
      description: this.props.data.description,
      groupDataObject: {},
      rolesData: [],
      shareRolesData: [],
      loading: false,
      popoverVisible: false,
      isOperationsVisible: false,
    };
  }

  onClickNewRole = () => {
    this.setState({ shareModalVisible: true });
    this.context.router.history.push(
      '/${this.config.appName}/groups?add-new-role=true',
    );
  };

  onConfirmDeleteGroup = () => {
    const config = this.props.context;
    const { t } = this.props;
    // send request to the invoker
    axios
      .delete(
        window.location.origin +
        config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/groups/id/' +
        this.props.data.id,
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.props.fetchGroups();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_deleteGroupMsg'),
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_deleteGroupError'), t);
      });
  };

  onConfirmUpdateGroup = values => {
    const config = this.props.context;
    const { t } = this.props;
    const data = {
      name: values.name.trim(),
      description: values.description,
      id: this.props.data.id,
      owner: this.props.data.owner,
      groupProperties: this.props.data.groupProperties,
    };
    // send request to the invoker
    axios
      .put(
        window.location.origin +
        config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/groups/id/' +
        this.props.data.id,
        data,
      )
      .then(res => {
        if (res.status === 200) {
          this.props.fetchGroups();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_updateGroupMsg'),
          });
          this.setState({ editModalVisible: false });
        }
      })
      .catch(error => {
        if (error.response.status === 409) {
          handleApiError(error, error.response.data.data.replace('.', ''), t);
        } else {
          handleApiError(
            error,
            t('api_updateError', { label: t('home_menu_group') }),
            t,
          );
        }
      });
  };

  fetchSharedRoles = (params = {}) => {
    const config = this.props.context;
    const { t } = this.props;
    const apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/groups/id/' +
      this.props.data.id +
      '/roles';

    // send request to the invokerss
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            shareRolesData: res.data.data.roles,
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_loadShareRolesError'), t);
      });
  };

  fetchUserRoles = (params = {}) => {
    const config = this.props.context;
    const { t } = this.props;
    const apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/roles';

    // send request to the invokerss
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            rolesData: res.data.data.roles,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_roles') }),
          t,
        );
      });
  };

  onConfirmShareGroup = data => {
    const config = this.props.context;
    const { t } = this.props;
    // send request to the invoker
    axios
      .post(
        window.location.origin +
        config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/groups/id/' +
        this.props.data.id +
        '/share',
        data,
      )
      .then(res => {
        if (res.status === 200) {
          this.props.fetchGroups();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_groupShareMsg'),
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_shareGroupError'), t);
      });
  };

  openEditModal = () => {
    this.setState({
      editModalVisible: true,
    });
  };

  openShareModal = () => {
    if (isAuthorized(this.config.scopes, ['perm:roles:view'])) {
      this.fetchUserRoles();
    }
    if (isAuthorized(this.config.scopes, ['perm:groups:roles'])) {
      this.fetchSharedRoles();
    }
    this.setState({
      shareModalVisible: true,
    });
  };

  handleEditCancel = e => {
    this.setState({
      editModalVisible: false,
    });
  };

  handleShareOk = e => {
    this.setState({
      shareModalVisible: false,
    });
    this.onConfirmShareGroup(this.state.shareRolesData);
  };

  handleShareCancel = e => {
    this.setState({
      shareModalVisible: false,
    });
  };

  handleRolesDropdownChange = value => {
    this.setState({
      shareRolesData: value,
    });
  };

  shareGroupFromSelection = () => {
    const config = this.props.context;
    const { t } = this.props;
    this.setState({ loading: true });

    const btn = (
      <Button type="primary" size="small" onClick={this.onClickGoToRoles}>
        {t('label_yes')}
      </Button>
    );
    // send request to the invoker
    axios
      .post(
        window.location.origin +
        config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        `/roles/create-combined-role/${this.customSelectionName}`,
        this.state.shareRolesData,
      )
      .then(res => {
        if (res.status === 201) {
          notification.success({
            message: t('api_createRoleMsg'),
            duration: 4,
            description: t('userManage_confirmTxt'),
            btn,
          });
          this.setState({
            loading: false,
            popoverVisible: false,
            shareModalVisible: false,
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_shareGroupError'), t);
        this.setState({ loading: false, shareModalVisible: false });
      });
  };

  onClickGoToRoles = () => {
    const config = this.props.context;
    window.location.href = window.location.origin + `/${config.appName}/roles`;
  };

  customSelectionName = null;

  onBlur = value => {
    this.customSelectionName = value.target.value;
  };

  onClickAddRoleFromSelection = () => {
    this.shareGroupFromSelection();
  };

  groupSelectionPopoverContent = () => {
    const { t } = this.props;
    return (
      <div>
        <Input
          onBlur={this.onBlur}
          placeholder={t('form_enterGroupSharingRoleName')}
        />
        <Button
          size="small"
          type="primary"
          style={{ marginTop: 3, display: 'block' }}
          onClick={this.onClickAddRoleFromSelection}
          disabled={
            !isAuthorized(this.config.scopes, [
              'perm:roles:create-combined-role',
            ])
          }
          block
          loading={this.state.loading}
        >
          <HtmlComments
            permission={'/permission/admin/device-mgt/roles/manage'}
          />
          {t('label_add')}
        </Button>
      </div>
    );
  };

  onClickNewRoleSelection = () => {
    this.setState({ popoverVisible: true });
  };

  handleVisibleChange = visible => {
    this.setState({ popoverVisible: visible });
  };

  fetchNewGroupData = values => {
    this.onConfirmUpdateGroup(values);
  };

  onAddOperations = () => {
    this.setState({
      isOperationsVisible: true,
    });
  };

  onOperationsClose = () => {
    this.setState({
      isOperationsVisible: false,
    });
  };

  render() {
    const { t } = this.props;
    const isAdminGroups = this.props.data.id === 1 || this.props.data.id === 2;
    let item = this.state.rolesData.map(data => (
      <Select.Option value={data} key={data}>
        {data}
      </Select.Option>
    ));
    const values = {
      name: this.props.data.name,
      description: this.props.data.description,
    };
    return (
      <div>
        <div style={{ display: isAdminGroups ? 'none' : 'inline' }}>
          <HtmlComments
            permission={
              '/permission/admin/device-mgt/groups/share,\n' +
              '/permission/admin/device-mgt/groups/update,\n' +
              '/permission/admin/device-mgt/groups/remove'
            }
          />
          {isAuthorized(this.config.scopes, ['perm:groups:share']) && (
            <span>
              <HtmlComments
                permission={
                  '/permission/admin/device-mgt/roles/view OR\n' +
                  '/permission/admin/device-mgt/groups/roles/view'
                }
              />
              <Tooltip placement="top" title={t('label_shareGroup')}>
                <a>
                  <ShareAltOutlined onClick={this.openShareModal} />
                </a>
              </Tooltip>
            </span>
          )}
          {isAuthorized(this.config.scopes, ['perm:groups:update']) && (
            <span>
              <Divider type="vertical" />
              <Tooltip placement="top" title={t('label_editGroup')}>
                <a>
                  <EditOutlined onClick={this.openEditModal} />
                </a>
              </Tooltip>
            </span>
          )}
          {isAuthorized(this.config.scopes, ['perm:groups:remove']) && (
            <span>
              <Divider type="vertical" />
              <Tooltip placement="bottom" title={t('label_deleteGroup')}>
                <Popconfirm
                  placement="top"
                  title={t('label_areYouSure')}
                  onConfirm={this.onConfirmDeleteGroup}
                  okText={t('label_ok')}
                  cancelText={t('label_cancel')}
                >
                  <a>
                    <Text type="danger">
                      <DeleteOutlined />
                    </Text>
                  </a>
                </Popconfirm>
              </Tooltip>
            </span>
          )}
          <span>
            <Divider type="vertical" />
            <Tooltip
              placement="bottom"
              title={t('label_add _operation_to_this_group')}
            >
              <Button
                type="link"
                shape="circle"
                icon={<SettingOutlined />}
                size={'default'}
                onClick={this.onAddOperations}
              />
            </Tooltip>
          </span>
        </div>
        <div>
          <Modal
            title={t('label_updateGroup')}
            open={this.state.editModalVisible}
            onCancel={this.handleEditCancel}
            footer={null}
            maskClosable={false}
          >
            <p>{t('newGroupDescription_infoTxt')}</p>
            <GroupForm
              action={'Update'}
              modalVisibility={this.state.editModalVisible}
              values={values}
              callback={values => this.fetchNewGroupData(values)}
            />
          </Modal>
        </div>
        <div>
          <Modal
            title={t('label_shareGroup')}
            width="500px"
            open={this.state.shareModalVisible}
            onOk={this.handleShareOk}
            onCancel={this.handleShareCancel}
            maskClosable={false}
            footer={[
              // eslint-disable-next-line react/jsx-key
              <Space>
                <Link
                  key="add-new-role"
                  to={`/${this.config.appName}/groups?add-new-role=true`}
                >
                  <Button key="new-role" onClick={this.onClickNewRole}>
                    {t('label_newRole')}
                  </Button>
                </Link>
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.handleShareOk}
                >
                  {t('label_share')}
                </Button>
              </Space>,
            ]}
          >
            <div style={{ background: '#f0f2f5' }}>
              <AddRole fetchRoles={this.fetchUserRoles} />
            </div>
            {(!isAuthorized(this.config.scopes, [
              'perm:roles:create-combined-role',
            ]) ||
              !isAuthorized(this.config.scopes, [
                'perm:roles:view',
                'perm:groups:roles',
              ])) && (
                <div>
                  <HtmlComments
                    permission={
                      '/permission/admin/device-mgt/roles/manage OR\n' +
                      '/permission/admin/device-mgt/roles/view\n' +
                      '/permission/admin/device-mgt/groups/roles/view'
                    }
                  />
                  <Alert
                    message={t('noPerm_someFields')}
                    banner
                    style={{ marginBottom: 15 }}
                  />
                </div>
              )}
            <p>{t('form_searchUserRoles')}</p>
            <Select
              mode="multiple"
              value={this.state.shareRolesData}
              style={{ width: '100%' }}
              onChange={this.handleRolesDropdownChange}
            >
              {item}
            </Select>
          </Modal>
        </div>
        {this.state.isOperationsVisible && (
          <DeviceOperationsDrawer
            groupIDList={[this.props.data.id]}
            onOperationsClose={this.onOperationsClose}
          />
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(GroupActions));
