/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Divider,
  Form,
  Tooltip,
  Upload,
  Dropdown,
  Menu,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import DownloadOutlined from '@ant-design/icons/es/icons/DownloadOutlined';
import { withTranslation } from 'react-i18next';

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 8 },
    xl: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
  },
};

const buttonFormItem = {
  wrapperCol: {
    lg: { span: 8 },
    xl: { span: 21 },
  },
};

class Enrollment extends React.Component {
  formRef = React.createRef();

  enrollmentConfiguration = '';
  enrollmentConfigOriginalFile = '';
  enrollmentConfigFileName = '';

  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
    };
  }

  componentDidMount() {
    let policyUIPayload = [...this.props.platformConfigData];
    let initialValues = {};

    for (let policyConfig of policyUIPayload) {
      switch (policyConfig.name) {
        case 'enrollmentConfigOriginalFile':
          this.enrollmentConfigOriginalFile = policyConfig.value;
          break;
        case 'enrollmentConfiguration':
          this.enrollmentConfiguration = policyConfig.value;
          break;
        case 'enrollmentConfigFileName':
          this.enrollmentConfigFileName = policyConfig.value;
          if (this.enrollmentConfigFileName) {
            initialValues.fileList = {
              fileList: [
                {
                  name: policyConfig.value,
                },
              ],
            };
            this.setState({
              fileList: [
                {
                  uid: '1',
                  name: policyConfig.value,
                },
              ],
            });
          }
          break;
      }
    }
    this.formRef.current.setFieldsValue(initialValues);
  }

  onFinish = values => {
    values.enrollmentConfigFileName = this.enrollmentConfigFileName;
    values.enrollmentConfigOriginalFile = this.enrollmentConfigOriginalFile;
    values.enrollmentConfiguration = this.enrollmentConfiguration;
    delete values.fileList;
    this.props.callback(values);
  };

  onFinishFailed = err => {
    const { t } = this.props;
    console.log(t('finishFailed_txt'), err);
  };

  processFile = async (rule, value) => {
    const { t } = this.props;
    if (value && value.file && value.file.status !== 'removed') {
      await this.readFile(value.file)
        .then(fileContent => {
          this.enrollmentConfigOriginalFile = fileContent;
          if (this.csvToJson(fileContent)) {
            return Promise.resolve();
          }
          throw new Error();
        })
        .catch(err => {
          console.log(err);
          this.setDefaultValues();
          return Promise.reject(t('form_enrollmentConfigFileInValidMsg'));
        });
    } else {
      return Promise.resolve();
    }
  };

  readFile = file => {
    const { t } = this.props;
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(t('fileReading_errorTxt'));
      };
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsText(file);
    });
  };

  handleFileChange = info => {
    let fileList = [...info.fileList];

    // Limit the number of uploaded files
    fileList = fileList.slice(-1);
    this.setState({ fileList });
    if (fileList.length === 0) {
      this.setDefaultValues();
    } else {
      this.enrollmentConfigFileName = fileList[0].name;
    }
  };

  downloadFile = e => {
    let fileType = e.key;
    const element = document.createElement('a');
    let payloadToDownload = this.enrollmentConfigOriginalFile;
    if ('json' === fileType) {
      payloadToDownload = this.enrollmentConfiguration;
    }
    const fileToDownload = new Blob([payloadToDownload], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(fileToDownload);
    element.download =
      this.enrollmentConfigFileName.substring(
        0,
        this.enrollmentConfigFileName.indexOf('.') + 1,
      ) + fileType;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  setDefaultValues = () => {
    this.enrollmentConfigFileName = '';
    this.enrollmentConfiguration = '';
    this.enrollmentConfigOriginalFile = '';
  };

  csvToJson = fileContent => {
    let lines = fileContent.split('\n');
    let enrollmentConfig = {};
    if (lines.length === 1) {
      enrollmentConfig.serialNumbers = lines[0].split(',');
    } else {
      let userConfigList = [];
      let groupConfigList = [];
      let userSerials;
      let groupSerials;
      let groupUserSerialList;
      let noneGroupNoneUserSerialList = [];
      let groupNoneUserSerialList;
      let userConfig;
      let groupConfig;
      lines.forEach(line => {
        userSerials = line.split(':');
        userConfig = {};
        if (userSerials[0]) {
          userConfig.username = userSerials[0];
        }
        if (userSerials[1].includes('(')) {
          groupSerials = userSerials[1].split(',');
          groupUserSerialList = [];
          groupNoneUserSerialList = [];
          groupSerials.forEach(groupSerial => {
            let groupSplit = groupSerial.split('(');
            if (groupSplit.length === 1) {
              groupNoneUserSerialList.push(groupSplit[0]);
            } else {
              groupConfig = {};
              groupConfig.groupName = groupSplit[0];
              let serials = groupSplit[1]
                .substring(0, groupSplit[1].length - 1)
                .split('|');
              groupConfig.serialNumbers = serials;
              groupConfigList.push(groupConfig);
              groupUserSerialList.push(...serials);
            }
          });
          if (userSerials[0]) {
            userConfig.serialNumbers = groupUserSerialList;
            userConfig.serialNumbers.push(...groupNoneUserSerialList);
          } else {
            noneGroupNoneUserSerialList.push(...groupNoneUserSerialList);
          }
          enrollmentConfig.groupConfigurations = groupConfigList;
        } else if (userSerials[0]) {
          userConfig.serialNumbers = userSerials[1].split(',');
        } else {
          noneGroupNoneUserSerialList.push(...userSerials[1].split(','));
        }
        if (userSerials[0]) {
          userConfigList.push(userConfig);
        }
      });
      if (userConfigList.length !== 0) {
        enrollmentConfig.userConfigurations = userConfigList;
      }
      if (noneGroupNoneUserSerialList.length !== 0) {
        enrollmentConfig.serialNumbers = noneGroupNoneUserSerialList;
      }
    }
    if (!enrollmentConfig) {
      return false;
    }
    let enrollmentConfigsJsonString = JSON.stringify(enrollmentConfig);
    JSON.parse(enrollmentConfigsJsonString);
    this.enrollmentConfiguration = enrollmentConfigsJsonString;
    return true;
  };

  getItem = (label, key, icon, children, className, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
      className,
    };
  };

  render() {
    const { fileList } = this.state;
    const { t } = this.props;
    const items = [this.getItem('JSON', 'json'), this.getItem('CSV', 'csv')];
    return (
      <div>
        <div>
          <Divider>
            <PageHeader
              className="site-page-header-responsive"
              subTitle={t('label_enrollmentConfigurations')}
            />
          </Divider>
        </div>
        <div>
          <Form
            {...formLayout}
            labelAlign="right"
            layout="horizontal"
            name="EnrolmentConfigForm"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            ref={this.formRef}
          >
            <Form.Item
              label={
                <span>
                  {t('label_enrollmentConfigFile')}&nbsp;
                  <Tooltip title={t('form_uploadEnrollmentConfigFile')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[
                {
                  validator: this.processFile,
                },
              ]}
              name="fileList"
              style={{ marginBottom: '20px' }}
            >
              <Upload.Dragger
                beforeUpload={() => false}
                onChange={this.handleFileChange}
                fileList={fileList}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  {t('uploadEnrollmentConfigFile_infoTxt')}
                </p>
              </Upload.Dragger>
            </Form.Item>
            <Form.Item label={<span />} colon={false}>
              <Dropdown
                disabled={fileList.length === 0}
                overlay={<Menu onClick={this.downloadFile} items={items} />}
              >
                <Button type="primary" style={{ float: 'right' }}>
                  {t('label_downloadEnrollmentConfigFile')} <DownloadOutlined />
                </Button>
              </Dropdown>
            </Form.Item>
            <Form.Item {...buttonFormItem}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: 'right' }}
              >
                {t('label_save')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Enrollment);
