/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext, useEffect } from 'react';
import { Alert, Card, Col } from 'antd';
import ConfigureProfile from '../../../../components/ConfigureProfile';
import { PolicyDetailsContext } from '../../index';
import styles from '../../../AddNewPolicy/components/AddPolicy/styles.module.css';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import ConfigContext from '../../../../../../../../components/ConfigContext';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { useTranslation } from 'react-i18next';

const PolicyInfo = () => {
  const policyDetails = useContext(PolicyDetailsContext);
  const { t } = useTranslation();
  useEffect(() => {
    // Update the current route's key
    policyDetails.setCurrentRoute('policy-information');
  }, []);

  const { config } = useContext(ConfigContext);
  return (
    <div style={{ marginTop: 20 }}>
      {!isAuthorized(config.scopes, [
        [
          'perm:android:view-configuration',
          'perm:ios:view-configuration',
          'perm:windows:view-configuration',
        ],
      ]) && (
        <div>
          <HtmlComments
            permission={
              '/permission/admin/device-mgt/platform-configurations/view OR\n' +
              '/permission/admin/device-mgt/devices/enroll/android'
            }
          />
          <Alert
            message={t('noPerm_policyConfigurations')}
            banner
            style={{
              marginBottom: 15,
              marginLeft: '12.5%',
              marginRight: '12%',
            }}
          />
        </div>
      )}
      <Col span={18} offset={3}>
        <Card className={styles.cardContainer}>
          {policyDetails.policyUIConfigurations && (
            <ConfigureProfile
              policyUIConfigurationsList={policyDetails.policyUIConfigurations}
              disabled={true}
            />
          )}
        </Card>
      </Col>
    </div>
  );
};

export default PolicyInfo;
