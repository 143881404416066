/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Divider, Row, Select, Tooltip, Typography } from 'antd';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import moment from 'moment';
import CustomAlerts from '../CustomAlerts';
import { withTranslation } from 'react-i18next';
const { Option } = Select;
const { Title, Text } = Typography;

class CustomTimeSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = value => {
    const item = { ...this.props.itemData };
    item.value = value;
    this.props.callback(item);
  };

  // get Option value from start Time, end Time and time difference between 2 values
  getOptionForTimeSelectors = (startTimeValue, endTimeValue, timeIncrement) => {
    let timeOptions = [];
    let time = new Date(
      moment()
        .startOf('day')
        .format('YYYY/MM/DD'),
    );
    let tempValue = startTimeValue;
    time.setMinutes(time.getMinutes() + tempValue);
    let startOption = (
      <Option value={String(tempValue)} key={tempValue}>
        {this.timeConverter(
          `${String(time)
            .split(' ')[4]
            .substring(0, 5)}`,
        )}
      </Option>
    );
    timeOptions.push(startOption);

    while (tempValue !== endTimeValue) {
      time = new Date(
        moment()
          .startOf('day')
          .format('YYYY/MM/DD'),
      );
      tempValue += timeIncrement;
      if (tempValue > 1440) {
        tempValue = 0;
        continue;
      }
      time.setMinutes(time.getMinutes() + tempValue);
      let option = (
        <Option value={String(tempValue)} key={tempValue}>
          {this.timeConverter(
            `${String(time)
              .split(' ')[4]
              .substring(0, 5)}`,
          )}
        </Option>
      );
      timeOptions.push(option);
    }
    return timeOptions;
  };

  // convert time from 24h format to 12h format
  timeConverter = time => {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' AM' : ' PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  };

  render() {
    const { itemData, t } = this.props;
    const { value } = itemData;
    return (
      <div>
        {itemData.divider && (
          <Divider plain>
            <Text type="secondary">{t(itemData.divider)}</Text>
          </Divider>
        )}
        {itemData.subTitle && <Title level={4}> {t(itemData.subTitle)} </Title>}
        <CustomAlerts notifications={itemData.notifications} />
        <div style={{ margin: 5 }}>
          <Row>
            <span
              style={{
                display: itemData.label && itemData.tooltip ? 'inline' : 'none',
              }}
            >
              {t(`${itemData.label}`)}&nbsp;
              {itemData.tooltip && (
                <Tooltip
                  title={
                    <div>
                      <div>{t(`${itemData.tooltip}`)}</div>
                      <div style={{ textAlign: 'right' }}>
                        {itemData.docLink && (
                          <a
                            onClick={() => {
                              window.open(itemData.docLink, '_blank');
                            }}
                          >
                            {' '}
                            {t('label_SeeMoreDetails')}
                          </a>
                        )}
                      </div>
                    </div>
                  }
                  placement="right"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </span>
          </Row>
          <Row>
            <Select
              disabled={this.props.disabled}
              defaultValue={value}
              style={{ width: 120 }}
              onChange={this.onChange}
            >
              {this.getOptionForTimeSelectors(
                itemData.timeSelector.startOptionValue,
                itemData.timeSelector.lastOptionValue,
                itemData.timeSelector.valueDifference,
              )}
            </Select>
          </Row>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(CustomTimeSelector));
