/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Tooltip,
} from 'antd';
//ToDo : entrga-icons-react is not installing correctly. So currently removed from package json
// import { EntgraIcon } from 'entgra-icons-react';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import styles from '../../styles.module.css';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

class DeviceFeature extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      operations: null,
      isModalActive: false,
      uiParams: [],
    };
  }

  onClickOperation = (e, uiParams, isEnabled) => {
    if (isEnabled) {
      const updatedUIParams = this.modifyUIParams(uiParams);
      this.setState({
        isModalActive: true,
        uiParams: updatedUIParams,
      });
    }
  };

  // modify uiParams adding default values
  modifyUIParams = uiParams => {
    let updatedUIParams = [];
    for (let i = 0, len = uiParams.length; i < len; i++) {
      if (uiParams[i].type === 'checkbox') {
        uiParams[i].defaultValue = !uiParams[i].defaultValue
          ? false
          : uiParams[i].defaultValue === 'true';
        updatedUIParams.push(uiParams[i]);
      } else if (uiParams[i].type === 'radio') {
        //  convert radio type uiParam to radio group
        let newUiParam = {
          id: uiParams[i].id,
          type: 'radioGroup',
          key: uiParams[i].key,
          payloadKey: uiParams[i].payloadKey,
          defaultValue: uiParams[i].defaultValue,
          values: [
            {
              key: uiParams[i].payloadValue[0],
              value: uiParams[i].value[0],
            },
          ],
        };
        for (let j = i + 1, len = uiParams.length; j < len; j++) {
          if (uiParams[i].key === uiParams[j].key) {
            newUiParam.values.push({
              key: uiParams[j].payloadValue[0],
              value: uiParams[j].value[0],
            });
          } else {
            i = j - 1;
            break;
          }
        }
        updatedUIParams.push(newUiParam);
      } else if (uiParams[i].type !== 'info' || uiParams[i].type !== 'hidden') {
        uiParams[i].defaultValue = !uiParams[i].defaultValue
          ? ''
          : uiParams[i].defaultValue;
        updatedUIParams.push(uiParams[i]);
      } else {
        updatedUIParams.push(uiParams[i]);
      }
    }
    return updatedUIParams;
  };

  // check conditions for show conditional uiParams
  checkCondition = uiParam => {
    for (let i = 0, len = uiParam.conditions.length; i < len; i++) {
      let isConditionSatisfied = false;
      let condition = uiParam.conditions[i];
      for (let j = 0, valLen = condition.values.length; j < valLen; j++) {
        let value = condition.values[j];
        let itemIndex = this.state.uiParams.findIndex(
          x => x.id === condition.id,
        );

        if (this.state.uiParams[itemIndex].defaultValue.toString() === value) {
          isConditionSatisfied = true;
          break;
        }
      }
      if (isConditionSatisfied === false) {
        return false;
      }
    }
    return true;
  };

  // handle labels of ui param which use same key in payload
  setLabel = (conditions, id) => {
    const { t, operationData } = this.props;
    for (let i = 0, len = conditions.length; i < len; i++) {
      let condition = conditions[i];
      let itemIndex = this.state.uiParams.findIndex(
        x => x.id === condition.conditionID,
      );
      if (
        this.state.uiParams[itemIndex].defaultValue.toString() ===
        condition.conditionValue
      ) {
        let label = t(
          `${this.props.deviceType.toLowerCase()}_operation_${operationData.code.toLowerCase()}_${id.replace(
            '-',
            '_',
          )}_conditionLabel_${condition.conditionValue}`,
        );
        return label;
      }
    }
  };

  generateUIParams = uiParams => {
    const { t, operationData } = this.props;
    return uiParams.map((uiParam, index) => {
      if ('conditions' in uiParam && this.checkCondition(uiParam) === false) {
        return;
      }
      if (uiParam.type === 'text') {
        let label = uiParam.hasOwnProperty('conditionLabels')
          ? this.setLabel(uiParam.conditionLabels, uiParam.id)
          : t(
            // eslint-disable-next-line max-len
            `${this.props.deviceType.toLowerCase()}_operation_${operationData.code.toLowerCase()}_${uiParam.id.replace(
              '-',
              '_',
            )}_label`,
          );
        return (
          <Form.Item
            key={index}
            name={uiParam.key ? uiParam.key : null}
            rules={[
              {
                required: !uiParam.optional,
                message: t('label_required_field'),
              },
            ]}
          >
            <Input placeholder={label} />
          </Form.Item>
        );
      } else if (uiParam.type === 'checkbox') {
        return (
          <Form.Item
            key={index}
            name={uiParam.key ? uiParam.key : null}
            valuePropName={uiParam.defaultValue === true ? 'checked' : null}
          >
            <Checkbox onChange={e => this.onChangeCheck(e, index)}>
              {t(
                // eslint-disable-next-line max-len
                `${this.props.deviceType.toLowerCase()}_operation_${operationData.code.toLowerCase()}_${uiParam.id.replace(
                  '-',
                  '_',
                )}_label`,
              )}
            </Checkbox>
          </Form.Item>
        );
      } else if (uiParam.type === 'radioGroup') {
        return (
          <Form.Item
            key={index}
            name={uiParam.key ? uiParam.key : null}
            initialValue={uiParam.defaultValue}
          >
            <Radio.Group onChange={e => this.onChangeRadioOptions(e, index)}>
              {uiParam.values.map((value, i) => (
                <Radio key={i} value={value.key}>
                  {t(
                    // eslint-disable-next-line max-len
                    `${this.props.deviceType.toLowerCase()}_operation_${operationData.code.toLowerCase()}_${uiParam.id.replace(
                      '-',
                      '_',
                    )}_value${i + 1}`,
                  )}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      } else if (uiParam.type === 'select') {
        return (
          <Form.Item
            key={index}
            name={uiParam.key ? uiParam.key : null}
            initialValue={uiParam.value[0]}
          >
            <Select style={{ width: '100%' }}>
              {uiParam.value.map((item, i) => (
                <Option key={item}>
                  {t(
                    // eslint-disable-next-line max-len
                    `${this.props.deviceType.toLowerCase()}_operation_${operationData.code.toLowerCase()}_${uiParam.id.replace(
                      '-',
                      '_',
                    )}_value${i + 1}`,
                  )}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      } else if (uiParam.type === 'info') {
        if (uiParam.display) {
          return (
            <Alert
              style={{ marginBottom: 5 }}
              key={index}
              message={t(
                // eslint-disable-next-line max-len
                `${this.props.deviceType.toLowerCase()}_operation_${operationData.code.toLowerCase()}_${uiParam.id.replace(
                  '-',
                  '_',
                )}_label`,
              )}
              type="info"
              showIcon
            />
          );
        }
      } else if (uiParam.type === 'password') {
        return (
          <Form.Item
            key={index}
            name={uiParam.key ? uiParam.key : uiParam.id}
            initialValue={uiParam.defaultValue}
            rules={[
              {
                required: !uiParam.optional,
                message: t('label_password_required'),
              },
            ]}
          >
            <Input.Password
              label={t(
                // eslint-disable-next-line max-len
                `${this.props.deviceType.toLowerCase()}_operation_${operationData.code.toLowerCase()}_${uiParam.id.replace(
                  '-',
                  '_',
                )}_label`,
              )}
            />
          </Form.Item>
        );
      }
    });
  };

  onChangeCheck = (e, index) => {
    const uiParams = [...this.state.uiParams];
    uiParams[index].defaultValue = e.target.checked;
    this.setState({ uiParams });
  };

  onChangeRadioOptions = (e, index) => {
    const uiParams = [...this.state.uiParams];
    uiParams[index].defaultValue = e.target.value;
    this.setState({ uiParams });
  };

  cancelOperation = () => {
    this.setState({
      isModalActive: false,
    });
  };

  // todo create operations payload here
  applyOperation = () => {
    this.formRef.current
      .validateFields()
      .then(values => {
        let data = {};

        if (values.hasOwnProperty('enable')) {
          data = {
            deviceIDs: this.props.deviceIdentifiers,
            operation: {
              name: this.state.uiParams[1].key,
              enabled: this.state.uiParams[0].defaultValue,
            },
          };
        } else if (values.hasOwnProperty('upload')) {
          const { upload, ...rest } = values;
          data = {
            operation: rest,
            upload: upload,
          };
        } else {
          data = { operation: values };
        }

        this.props.onApplyOperationToDevice(
          this.props.operationData.metadataEntries[0].value.uri,
          data,
        );
        this.cancelOperation();
      })
      .catch(errorInfo => {
        console.log(errorInfo);
      });
  };

  render() {
    const { t } = this.props;
    const { operationData } = this.props;
    let enrollType;
    let status = 'REMOVED';
    if (
      JSON.stringify(this.props.ownerships).includes('COPE') ||
      JSON.stringify(this.props.ownerships).includes('COSU')
    ) {
      enrollType = 'COPE';
    } else {
      enrollType = 'BYOD';
    }
    let isDisabled =
      status === this.props.deviceStatus ||
      (!isAuthorized(this.config.scopes, [
        operationData.metadataEntries[0].value.scope,
      ]) ||
        (enrollType === 'BYOD' &&
          operationData.metadataEntries[0].value.filters));
    return (
      <div>
        <Tooltip
          title={t(
            `${this.props.deviceType.toLowerCase()}_operation_${operationData.code.toLowerCase()}_description`,
          )}
        >
          <HtmlComments
            permission={operationData.metadataEntries[0].value.permission}
          />
          <Button
            type="primary"
            shape="circle"
            // icon={
            //   <EntgraIcon type={operationData.metadataEntries[0].value.icon} />
            // }
            disabled={isDisabled}
            size="large"
            onClick={
              enrollType === 'BYOD' &&
                operationData.metadataEntries[0].value.filters
                ? e =>
                  this.onClickOperation(
                    e,
                    operationData.metadataEntries[0].value.uiParams,
                    false,
                  )
                : e =>
                  this.onClickOperation(
                    e,
                    operationData.metadataEntries[0].value.uiParams,
                    true,
                  )
            }
            style={isDisabled ? { pointerEvents: 'none' } : null}
          />
        </Tooltip>
        <div className={styles.operationDescription}>
          {t(
            `${this.props.deviceType.toLowerCase()}_operation_${operationData.code.toLowerCase()}_name`,
          )}
        </div>
        <Modal
          title={t(
            `${this.props.deviceType.toLowerCase()}_operation_${operationData.code.toLowerCase()}_name`,
          )}
          width="350px"
          open={this.state.isModalActive}
          okText={t('label_send_to_device')}
          onOk={this.applyOperation}
          onCancel={this.cancelOperation}
          centered={true}
          maskClosable={false}
        >
          <div>
            {t(
              `${this.props.deviceType.toLowerCase()}_operation_${operationData.code.toLowerCase()}_description`,
            )}
          </div>
          <Form ref={this.formRef}>
            {this.generateUIParams(this.state.uiParams)}
          </Form>
        </Modal>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(DeviceFeature));
