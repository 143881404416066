/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
import React, { useState, memo, useContext, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import 'reactflow/dist/base.css';
import '.././index.css';
import { Link } from 'react-router-dom';
import ConfigContext from '../../../../../components/ConfigContext';
import { useTranslation } from 'react-i18next';
import { Badge, Space, Spin } from 'antd';
import ProfileDataSection from './ProfileDataSection';
import { useSelector } from 'react-redux';

const DEFAULT_HANDLE_STYLE = {
  width: 15,
  height: 15,
};

// eslint-disable-next-line react/display-name
export default memo(({ data }) => {
  const selectedBranch = useSelector((state) => state.branch.value);
  const { t } = useTranslation();
  const { config } = useContext(ConfigContext);
  const [isConditionTrue, setIsConditionTrue] = useState(false);
  const [url, setUrl] = useState('');
  // Function to format decimal numbers
  const formatDecimalNumber = (number, decimalPlaces = 3) => {
    if (typeof number !== 'number') {
      return number;
    } // Return as it is if not a number

    const decimalPlacesToFixed = decimalPlaces >= 0 ? decimalPlaces : 3;

    const [integerPart, decimalPart] = number
      .toFixed(decimalPlacesToFixed)
      .split('.');
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ',',
    );
    const formattedDecimalPart = decimalPart
      ? decimalPart.padEnd(decimalPlacesToFixed, '0')
      : '0'.repeat(decimalPlacesToFixed);

    return `${formattedIntegerPart}.${formattedDecimalPart}`;
  };

  useEffect(() => {
    let modifiedUrl = `/${config.appName}/load/profile-data?serial=${data.deviceIdentifier}`;
    if (data?.profileData && data.profileData['0.9.2_0']) {
      // Parse date and time strings
      const dateString = data.profileData['0.9.2_0'];
      const timeString = data.profileData['0.9.1_0'];

      const dateParts = dateString.split('-');
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed
      const day = parseInt(dateParts[2], 10);

      let hours = 0;
      let minutes = 0;
      let seconds = 0;

      if (timeString) {
        const timeParts = timeString.split(':');
        hours = parseInt(timeParts[0], 10);
        minutes = parseInt(timeParts[1], 10);
        seconds = parseInt(timeParts[2], 10);
      }

      // Create Date objects
      const timestamp = new Date(
        year,
        month,
        day,
        hours,
        minutes,
        seconds,
      ).getTime();

      // Calculate current timestamp - 15 minutes
      const currentTimestamp = new Date().getTime();
      const fifteenMinutesAgo = currentTimestamp - 15 * 60 * 1000;

      // Add one day to timestamp and convert to UNIX time for endTimestamp
      const sixHoursInMillis = 6 * 60 * 60 * 1000;
      const endTimestamp = timestamp;

      // Set startTimestamp as 7 days less than endTimestamp
      const startTimestamp = endTimestamp - sixHoursInMillis;

      modifiedUrl += `&startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}&branch=${selectedBranch}`;
      setUrl(modifiedUrl);

      // Compare timestamps and update state
      setIsConditionTrue(timestamp > fifteenMinutesAgo);
    } else {
      setIsConditionTrue(false); // No profile data, set condition to false
    }
  }, [data?.profileData]);
  return (
    <>
      {!data.editData && data.profileData && (
        <div className="custom-node-wrapper">
          <Badge.Ribbon
            text={isConditionTrue ? 'On' : 'Off'}
            color={isConditionTrue ? 'green' : 'gold'}
            placement="end"
          >
            <div className="wrapper gradient">
              <div className="inner">
                <Handle
                  type="target"
                  position={Position.Top}
                  isConnectable={true}
                  style={{ ...DEFAULT_HANDLE_STYLE, background: '#0492C2' }}
                />
                <div className="body">
                  <div>
                    <div className="body">
                      {data.icon && <div className="icon">{data.icon}</div>}
                      <Link
                        to={url}
                        target="_blank"
                        className="title"
                        title={t('label_view_profile')}
                        aria-label="View profile"
                      >
                        <span>{data.title}</span>
                      </Link>
                    </div>
                    <ProfileDataSection
                      data={data}
                      formatDecimalNumber={formatDecimalNumber}
                    />
                  </div>
                </div>
                <Handle
                  type="source"
                  position={Position.Bottom}
                  isConnectable={true}
                  style={{ ...DEFAULT_HANDLE_STYLE, background: '#82EEFD' }}
                />
              </div>
            </div>
          </Badge.Ribbon>
        </div>
      )}

      {data.editData && data.profileData && (
        <div className="wrapper gradient">
          <div className="inner">
            <Handle
              type="target"
              position={Position.Top}
              isConnectable={true}
              style={{ ...DEFAULT_HANDLE_STYLE, background: '#0492C2' }}
            />
            <div className="body">
              <div>
                <div className="body">
                  {data.icon && <div className="icon">{data.icon}</div>}

                  <Link to={url} target="_blank">
                    <a
                      className="title"
                      title={t('label_view_profile')}
                      aria-label="View profile"
                    >
                      {data.title}
                    </a>
                  </Link>
                </div>
                <ProfileDataSection
                  data={data}
                  formatDecimalNumber={formatDecimalNumber}
                />
              </div>
            </div>
            <Handle
              type="source"
              position={Position.Bottom}
              isConnectable={true}
              style={{ ...DEFAULT_HANDLE_STYLE, background: '#82EEFD' }}
            />
          </div>
        </div>
      )}

      {!data.profileData && (
        <div className="wrapper gradient">
          <div className="inner">
            <Handle
              type="target"
              position={Position.Top}
              isConnectable={true}
              style={{ ...DEFAULT_HANDLE_STYLE, background: '#0492C2' }}
            />
            <div className="body">
              <div>
                <div className="body">
                  {data.icon && <div className="icon">{data.icon}</div>}
                  <Space>
                    <span className="pseudoDevicetitle">{data.title}</span>
                    {!data.isProfileApiCalled && <Spin size="small" />}
                  </Space>
                </div>
              </div>
            </div>
            <Handle
              type="source"
              position={Position.Bottom}
              isConnectable={true}
              style={{ ...DEFAULT_HANDLE_STYLE, background: '#82EEFD' }}
            />
          </div>
        </div>
      )}
    </>
  );
});
