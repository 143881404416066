/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Table, Tooltip, Button, Col } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';

const ProfileReadingTable = ({
  devices,
  count,
  columns,
  pagination,
  loading,
  handleTableChange,
  getDetailsUI
}) => {
  const { t } = useTranslation();

  return (
    <Table
      // rowKey={(record) => `${record.operationId}-${record.serial}`}
      dataSource={devices}
      columns={columns}
      pagination={{
        ...pagination,
        size: 'small',
        showSizeChanger: true,
        total: count,
        disabled: loading,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
      }}
      onChange={handleTableChange}
      loading={loading}
      bordered
      expandable={{
        expandedRowRender: record => (
          <span style={{ background: '#ffffff' }}>
            <Col span={18} offset={3}>
              {record && <div>{getDetailsUI(record.profileData)}</div>}
            </Col>
          </span>
        ),
        rowExpandable: record => record.name !== 'Not Expandable',
        expandIconColumnIndex: 9,
        expandIcon: ({ expanded, onExpand, record }) => (
          <Tooltip
            placement="top"
            title={expanded ? t('label_hideDetails') : t('label_details')}
          >
            <Button type="link" size="small" onClick={e => onExpand(record, e)}>
              {expanded ? t('label_hideDetails') : t('label_details')}
            </Button>
          </Tooltip>
        )
      }}
    />
  );
};

export default ProfileReadingTable;
