/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
import React from 'react';
import {
  useReactFlow,
  getRectOfNodes,
  getTransformForBounds,
  ControlButton
} from 'reactflow';
import { toPng } from 'html-to-image';
import { ArrowDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getBranchNameByCode } from "../../../../../services/utils/functions";

function getFormattedDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}_${hours}:${minutes}:${seconds}`;
}

//A4 size
const imageWidth = 3508;
const imageHeight = 2480;

function DownloadOrganization() {
  const { t } = useTranslation();
  const { getNodes } = useReactFlow();
  const branchCode = useSelector((state) => state.branch.value);
  const branchName = getBranchNameByCode(branchCode);

  function downloadImage(dataUrl) {
    const a = document.createElement('a');
    const dateTime = getFormattedDateTime();
    a.setAttribute('download', `facility_view_${branchName}_${dateTime}.png`);
    a.setAttribute('href', dataUrl);
    a.click();
  }

  const onClickColor = () => {
    const nodesBounds = getRectOfNodes(getNodes());
    const transform = getTransformForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.5,
      2
    );

    toPng(document.querySelector('.react-flow__viewport'), {
      backgroundColor: '#d2eaf5',
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`
      }
    }).then(downloadImage);
  };

  // eslint-disable-next-line no-unused-vars
  const onClickBlackAndWhite = () => {
    const nodesBounds = getRectOfNodes(getNodes());
    const transform = getTransformForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.5,
      2
    );

    const viewport = document.querySelector('.react-flow__viewport');
    const svgElements = viewport.querySelectorAll('svg');

    // Apply grayscale filter to each SVG element
    svgElements.forEach(svgElement => {
      svgElement.style.filter = 'grayscale(1)';
    });

    // Use toPng with the modified SVG elements
    toPng(viewport, {
      backgroundColor: '#fff',
      width: imageWidth,
      height: imageHeight,
      pixelRatio: 10,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`
      }
    }).then(dataUrl => {
      // Reset the grayscale filter after capturing the image
      svgElements.forEach(svgElement => {
        svgElement.style.filter = 'none';
      });

      downloadImage(dataUrl);
    });
  };

  return (
    <ControlButton
      onClick={() => onClickColor()}
      className="react-flow__controls-downloadflow"
      title={t('label_download_flow')}
      aria-label="download flow"
    >
      <ArrowDownOutlined />
    </ControlButton>
  );
}

export default DownloadOrganization;
