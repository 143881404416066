
import React from 'react';
import { Button, Input, notification, Modal, Descriptions, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import { handleApiError } from '../../../../../services/utils/errorHandler';
import { withConfigContext } from '../../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';

class ChangeState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      updatedOperationStatus: this.props.operation.status,
    };
    this.updatedOperationStatus = this.props.operation.status; // Initialize  here
  }
  deviceIdentifier = this.props.meterSerial;
  operationId = this.props.operation.id;
  operationStatus = this.props.operation.status;
  operationCode = this.props.operation.code;

  // onBlurDeviceNameInput = e => {
  //   this.deviceName = e.target.value;
  // };
  //
  // onPropertyChange = (e, propertyName) => {
  //   const { operation} = this.props;
  //
  //   if (propertyName === 'description') {
  //     this.updatedDescription = e.target.value;
  //   } else {
  //     // const updatedProperties = (device.properties).map((property) => {
  //     //   if (property.name === propertyName) {
  //     //     return { ...property, value: e.target.value };
  //     //   }
  //     //   return property;
  //     // });
  //     // this.updatedProperties = updatedProperties;
  //   }
  //
  // };

  onClickUpdateButton = () => {
    this.onConfirmUpdate();
    this.setState({ visible: false });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  // here handling change of OperationStatus field
  onOperationStatusChange = value => {
    this.setState({ updatedOperationStatus: value });
    this.updatedOperationStatus = value; // Update the property value
  };

  onConfirmUpdate = () => {
    const config = this.props.context;
    const { t } = this.props;

    console.log(this.updatedOperationStatus);

    const payload = {
      operationCode: this.operationCode,
      operationId: this.operationId,
      status: this.updatedOperationStatus,
    };
    const url = window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      `/devices/power-meter/${this.deviceIdentifier}/operation`;

    axios
      .put(url, payload)
      .then(res => {
        if (res.status === 200) {
          console.log(this.props);
          this.props.callback();
          notification.success({
            message: t('label_done'),
            duration: 4,
            description: t('form_operation_update_notification'),
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('form_operation_update_notification_errors'), t);
      });
  };


  render() {
    const { operation } = this.props;
    const { t } = this.props;
    const translationKey = (key) => {
      const label = `label${key}`;
      const translatedValue = t(label);
      const isMissingKey = `label${key}` === t(`label${key}`);
      return isMissingKey ? key : translatedValue;
    }

    return (
      <>
        <Button
          type="link"
          shape="circle"
          icon={<EditOutlined />}
          size={'default'}
          style={{ margin: '2px' }}
          onClick={this.handleVisibleChange}
        >
        </Button>

        <Modal
          open={this.state.visible}
          title={t("form_changeStatus")}
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel" onClick={this.handleCancel}>
              {t("label_cancel")}
            </Button>,
            <Button
              key="update"
              type="primary"
              onClick={this.onClickUpdateButton}
            >
              {t("label_changeState")}
            </Button>,
          ]}
        >
          <div style={{ alignItems: 'right' }}>
            <Descriptions column={1} size="small" bordered>
              <Descriptions.Item label={t("label_deviceIdentifier")}>
                <Input
                  defaultValue={operation ? this.deviceIdentifier : ''}
                  disabled
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("label_operationId")}>
                <Input
                  name={'operationId'}
                  defaultValue={operation ? this.operationId : ''}
                  disabled
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("label_fromStatus")}>
                <Input
                  name={'operationStatus'}
                  defaultValue={operation ? this.operationStatus : ''}
                  disabled
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("label_toStatus")}>
                {/*<Input*/}
                {/*    defaultValue={this.state.updatedOperationStatus}*/}
                {/*    onChange={this.onOperationStatusChange}*/}
                {/*/>*/}
                <Select
                  // defaultValue={this.state.updatedOperationStatus}
                  placeholder="Select"
                  onChange={this.onOperationStatusChange}>
                  <Select.Option value="ERROR">ERROR</Select.Option>
                </Select>
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Modal>
      </>
    );
  }
}

export default withConfigContext(withTranslation()(ChangeState));
