/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import {
  DeleteOutlined,
  ExportOutlined,
  ImportOutlined,
} from '@ant-design/icons';
import { Button, Tooltip, Popconfirm, Divider } from 'antd';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

class BulkActionBar extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      selectedMultiple: false,
      selectedSingle: false,
      isPolicyActive: true,
    };
  }

  // This method checks whether active policies are selected
  onCheckPolicyStatus = () => {
    let tempIsPolicyActive;
    for (let i = 0; i < this.props.selectedRows.length; i++) {
      if (this.props.selectedRows[i].active) {
        tempIsPolicyActive = true;
        break;
      }
      tempIsPolicyActive = false;
    }
    this.setState({ isPolicyActive: tempIsPolicyActive });
  };

  onConfirmRemove = () => {
    if (!this.state.isPolicyActive) {
      this.props.removePolicy();
    }
  };

  onConfirmPublish = () => {
    if (!this.state.isPolicyActive) {
      this.props.publishPolicy();
    }
  };

  onConfirmUnpublish = () => {
    if (this.state.isPolicyActive) {
      this.props.unpublishPolicy();
    }
  };

  render() {
    const isSelected = this.props.selectedRows.length > 0;
    const { t } = this.props;
    return (
      <div>
        <div>
          {isAuthorized(this.config.scopes, ['perm:policies:remove']) && (
            <span>
              <Tooltip
                placement="bottom"
                title={t('label_remove')}
                autoAdjustOverflow={true}
              >
                <Popconfirm
                  placement="topLeft"
                  title={
                    !this.state.isPolicyActive
                      ? t('policyRemove_confirmTxt')
                      : t('policyPublish_invalidTxt')
                  }
                  onConfirm={this.onConfirmRemove}
                  okText={t('label_yes')}
                  cancelText={t('label_no')}
                  disabled={!isSelected}
                >
                  <Button
                    type="link"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    size={'default'}
                    onClick={this.onCheckPolicyStatus}
                    style={{ margin: '2px' }}
                    disabled={!isSelected}
                  >
                    <span>
                      <HtmlComments
                        permission={
                          '/permission/admin/device-mgt/policies/manage'
                        }
                      />
                    </span>
                    {t('label_remove')}
                  </Button>
                </Popconfirm>
              </Tooltip>
            </span>
          )}
          {isAuthorized(this.config.scopes, ['perm:policies:activate']) && (
            <span>
              <Divider type="vertical" />
              <Tooltip placement="bottom" title={t('label_publish')}>
                <Popconfirm
                  placement="topLeft"
                  title={
                    !this.state.isPolicyActive
                      ? t('policyPublish_confirmTxt')
                      : t('policyPublish_invalidTxt')
                  }
                  okText={t('label_yes')}
                  onConfirm={this.onConfirmPublish}
                  cancelText={t('label_no')}
                  disabled={!isSelected}
                >
                  <Button
                    type="link"
                    shape="circle"
                    icon={<ImportOutlined />}
                    onClick={this.onCheckPolicyStatus}
                    size={'default'}
                    style={{
                      margin: '2px',
                    }}
                    disabled={!isSelected}
                  >
                    <span>
                      <HtmlComments
                        permission={
                          '/permission/admin/device-mgt/policies/manage'
                        }
                      />
                    </span>
                    {t('label_publish')}
                  </Button>
                </Popconfirm>
              </Tooltip>
            </span>
          )}
          {isAuthorized(this.config.scopes, ['perm:policies:deactivate']) && (
            <span>
              <Divider type="vertical" />
              <Tooltip placement="bottom" title={t('label_unpublish')}>
                <Popconfirm
                  placement="topLeft"
                  title={
                    this.state.isPolicyActive
                      ? t('policyUnpublish_confirmTxt')
                      : t('policyUnpublish_invalidTxt')
                  }
                  okText={t('label_yes')}
                  onConfirm={this.onConfirmUnpublish}
                  cancelText={t('label_no')}
                  disabled={!isSelected}
                >
                  <Button
                    type="link"
                    shape="circle"
                    icon={<ExportOutlined />}
                    onClick={this.onCheckPolicyStatus}
                    size={'default'}
                    style={{ margin: '2px' }}
                    disabled={!isSelected}
                  >
                    <span>
                      <HtmlComments
                        permission={
                          '/permission/admin/device-mgt/policies/manage'
                        }
                      />
                    </span>
                    {t('label_unpublish')}
                  </Button>
                </Popconfirm>
              </Tooltip>
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(BulkActionBar));
