/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
import { fetchOrganizations, processLoadProfileRequests } from '../api';
import { useState, useEffect, useContext } from 'react';
import useStore from '../store';
import { useTranslation } from 'react-i18next';
import ELK from 'elkjs/lib/elk.bundled.js';
import { RoutesContext } from '../../../index';
import { useReactFlow } from 'reactflow';
import { handleApiError } from '../../../../../services/utils/errorHandler';
import { useSelector } from 'react-redux';
import { notification } from 'antd';

export function getLayoutedElements(nodes, edges, options = {}, elk, t) {
  const isHorizontal = options?.['elk.direction'] === 'RIGHT';
  const graph = {
    id: 'root',
    layoutOptions: options,
    children: nodes.map((node) => ({
      ...node,
      // Adjust the target and source handle positions based on the layout direction.
      targetPosition: isHorizontal ? 'left' : 'top',
      sourcePosition: isHorizontal ? 'right' : 'bottom',
      // Hardcode a width and height for elk to use when layouting.
      width: 170,
      height: node.data.profileData ? 115 : 55,
    })),
    edges: edges,
  };

  return elk
    .layout(graph)
    .then((layoutedGraph) => ({
      nodes: layoutedGraph.children.map((node) => ({
        ...node,
        // React Flow expects a position property on the node instead of `x` and `y` fields.
        position: { x: node.x, y: node.y },
      })),

      edges: layoutedGraph.edges,
    }))
    .catch((error) => {
      handleApiError(error, t('flow_layout_error'), t);
    });
}

export function useFetchOrganizations(
  apiURI,
  powerMeterURI,
  maxDepth,
  maxRoots,
  { reload },
) {
  if (!maxDepth) {
    maxDepth = 10;
  }
  if (!maxRoots) {
    maxRoots = 10;
  }

  const setNodes = useStore((state) => state.setNodes);
  const setEdges = useStore((state) => state.setEdges);
  // eslint-disable-next-line no-unused-vars
  const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  const { nodes, edges, rootCount } = useStore((state) => ({
    nodes: [],
    edges: [],
    rootCount: 0,
  }));
  const { t } = useTranslation();
  const elkOptions = {
    'elk.algorithm': 'layered',
    'elk.layered.spacing.nodeNodeBetweenLayers': '100',
    'elk.spacing.nodeNode': '80',
    'org.eclipse.elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
    'org.eclipse.elk.layered.nodePlacement.bk.edgeStraightening':
      'IMPROVE_STRAIGHTNESS',
    'org.eclipse.elk.graphviz.concentrate': 'true',
    'org.eclipse.elk.core.math.ElkMargin': '5',
  };
  const elk = new ELK();
  const routesContext = useContext(RoutesContext);
  const { fitView } = useReactFlow();
  const selectedBranch = useSelector((state) => state.branch.value);

  useEffect(() => {
    routesContext.setCurrentRoute('allDeviceOrgView');
  }, []);

  useEffect(() => {
    setLoadingOrganizations(true);
    setNodes([]);
    setEdges([]);
    const abortController = new AbortController();
    const axiosConfig = {
      signal: abortController.signal,
    };

    fetchOrganizations(
      apiURI,
      powerMeterURI,
      axiosConfig,
      t,
      nodes,
      edges,
      maxDepth,
      maxRoots,
      rootCount,
      selectedBranch,
    )
      .then((fetchData) => {
        const opts = { 'elk.direction': 'DOWN', ...elkOptions };
        getLayoutedElements(nodes, edges, opts, elk, fetchData)
          .then(({ nodes: layoutedNodes, edges: layoutedEdges }) => {
            setNodes(layoutedNodes);
            setEdges(layoutedEdges);
            // eslint-disable-next-line max-nested-callbacks
            window.requestAnimationFrame(() => {
              fitView();
            });
            return { fetchData };
          })
          .then(() => {
            processLoadProfileRequests(
              powerMeterURI,
              axiosConfig,
              fetchData.deviceIdentifiers,
              fetchData.serials,
              fetchData.allNodes,
              fetchData.allEdges,
              fetchData.count,
              setNodes,
              setEdges,
              t,
            )
              // eslint-disable-next-line max-nested-callbacks
              .then(() => {
                const opts = { 'elk.direction': 'DOWN', ...elkOptions };
                // Call getLayoutedElements again after processLoadProfileRequests
                getLayoutedElements(nodes, edges, opts, elk)
                  .then(({ nodes: updatedNodes, edges: updatedEdges }) => {
                    setNodes(updatedNodes);
                    setEdges(updatedEdges);
                    window.requestAnimationFrame(() => {
                      fitView();
                    });
                  })
                  .catch((error) => {
                    handleApiError(error, t('flow_layout_error'), t);
                  });
              })
              // eslint-disable-next-line max-nested-callbacks
              .catch((error) => {
                notification.info({
                  message: t('label_change'),
                  duration: 3,
                  description: t('label_request_abort'),
                });
              });
          })
          .catch((error) => {
            notification.info({
              message: t('label_change'),
              duration: 3,
              description: t('label_request_abort'),
            });
          });
      })
      .catch((error) => {
        notification.info({
          message: t('label_change'),
          duration: 3,
          description: t('label_request_abort'),
        });
      })
      .finally(() => {
        setLoadingOrganizations(false);
      });

    return () => {
      abortController.abort(); // Abort ongoing fetch requests
    };
  }, [reload]);
  return { loadingOrganizations };
}
