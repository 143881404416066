/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
import React, { useState } from 'react';
import axios from 'axios';
import DeleteConfirmationModal from './DeleteConfirmationModal.js';

export default function ContextMenu({
  id,
  apiURI,
  nodes,
  setNodes,
  edges,
  setEdges,
  top,
  left,
  right,
  bottom,
  onClick,
  onContextMenuClose,
  t,
  switchChecked,
  ...props
}) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [validationError, setValidationError] = useState(null);

  const handleOnDelete = async id => {
    try {
      const updatedNodes = nodes.filter(node => node.id !== id);
      const updatedEdges = edges.filter(
        edge => edge.source !== id && edge.target !== id
      );
      setNodes(updatedNodes);
      setEdges(updatedEdges);
    } catch (error) {
      setValidationError(t('error_deleting_edge'));
    }
  };

  const onNodesDelete = async id => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      try {
        const deleteApiEndpoint = `${apiURI}/associations/${id}`;

        const response = await axios.delete(deleteApiEndpoint);

        if (response.status === 200) {
          resolve(id); // Resolve with the ID of the deleted node
        } else {
          setValidationError(t('error_deleting_edge'));
        }
      } catch (error) {
        setValidationError(t('error_deleting_edge'));
      }
    });
  };
  const onDelete = deletedNodeList => {
    setIsDeleteModalVisible(true);
  };

  const handleDeleteModalConfirm = async values => {
    try {
      setLoading(true);
      const value = await onNodesDelete(id);
      await handleOnDelete(value);
      setIsDeleteModalVisible(false);
      // Call the callback function to close the context menu
      if (onContextMenuClose) {
        onContextMenuClose();
      }
    } catch (error) {
      setValidationError(t('error_deleting_edge'));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteModalCancel = () => {
    setValidationError(null);
    setIsDeleteModalVisible(false);
    // Call the callback function to close the context menu
    if (onContextMenuClose) {
      onContextMenuClose();
    }
  };

  return (
    <div
      style={{ top, left, right, bottom }}
      className="context-menu"
      {...props}
    >
      <p style={{ margin: '0.3em' }}>
        <small>Meter: {id}</small>
      </p>
      <button onClick={onDelete} disabled={!switchChecked}>
        {t('modal_delete')}
      </button>

      <DeleteConfirmationModal
        onConfirm={handleDeleteModalConfirm}
        onCancel={handleDeleteModalCancel}
        visible={isDeleteModalVisible}
        loading={loading}
        t={t}
      />
    </div>
  );
}
