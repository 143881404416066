/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Select, Input, Form, Alert } from 'antd';
import axios from 'axios';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import { splitUserDomain } from '../../../../../../../../services/utils/commonHandler';

const buttonItemLayout = {
  wrapperCol: { span: 24, offset: 19 },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class RoleForm extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      isModalVisible: false,
      roles: [],
      loading: false,
      userData: [],
      userStores: [],
      selectedUserStore: 'PRIMARY',
    };
  }

  componentDidMount() {
    if (isAuthorized(this.config.scopes, ['perm:users:user-details'])) {
      this.loadUsersList(this.state.selectedUserStore);
      this.getUserStores();
    }
    if (this.props.values) {
      let userDomain = splitUserDomain(this.props.values.roleName);
      this.setState({ selectedUserStore: userDomain[1] });
      this.props.values.roleName = userDomain[0];
      this.props.values.userStoreDomain = userDomain[1];
      this.formRef.current.setFieldsValue(this.props.values);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let params = new URL(window.location).searchParams;
    let name = params.get('add-new-role');
    if (JSON.parse(name)) {
      this.formRef.current.resetFields();
    }
  }

  onFinish = values => {
    this.props.callback(values);
  };

  loadUsersList = userStore => {
    const { t } = this.props;
    let apiURL =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/users/list?domain=' +
      userStore;
    axios
      .get(apiURL)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            userData: res.data.data.users,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_users') }),
          t,
        );
      });
  };

  getUserStores = () => {
    const { t } = this.props;
    let apiURL =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/users/user-stores';

    axios
      .get(apiURL)
      .then(res => {
        if (res.status === 200) {
          this.setState({ userStores: res.data.data.userStores });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_users') }),
          t,
        );
      });
  };

  handleUserStoreChange = value => {
    this.setState({ selectedUserStore: value });
    this.loadUsersList(value);
  };

  render() {
    const { userData, selectedUserStore, userStores } = this.state;
    const { action, t } = this.props;
    const userItems = userData.map((user, index) => (
      <Select.Option key={index} value={user.username}>
        {user.username}
      </Select.Option>
    ));
    const userStoreOptions = userStores.map(userStore => (
      <Select.Option key={userStore} value={userStore}>
        {userStore}
      </Select.Option>
    ));
    return (
      <div>
        {!isAuthorized(this.config.scopes, ['perm:users:user-details']) && (
          <div>
            <HtmlComments
              permission={'/permission/admin/device-mgt/users/view'}
            />
            <Alert
              message={t('noPerm_someFields')}
              banner
              style={{ marginBottom: 15 }}
            />
          </div>
        )}
        <Form
          {...formItemLayout}
          name="addRole"
          onFinish={this.onFinish}
          ref={this.formRef}
        >
          <Form.Item
            name="userStoreDomain"
            label={t('label_userStoreDomain')}
            initialValue={selectedUserStore}
          >
            <Select
              disabled={
                !isAuthorized(this.config.scopes, [
                  'perm:users:user-details',
                ]) || action === 'Update'
              }
              onChange={this.handleUserStoreChange}
            >
              {userStoreOptions}
            </Select>
          </Form.Item>
          <Form.Item
            name="roleName"
            label={t('label_roleName')}
            rules={[
              {
                pattern: new RegExp('^(((?!(\\@|\\/|\\s)).){3,})*$'),
                message: t('form_roleNameNotValidMsg'),
              },
              {
                required: true,
                message: t('form_fieldRequiredMsg'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <HtmlComments
            permission={'/permission/admin/device-mgt/users/view'}
          />
          <Form.Item name="users" label={t('label_userList')}>
            <Select
              disabled={
                !isAuthorized(this.config.scopes, ['perm:users:user-details'])
              }
              mode="multiple"
              placeholder={t('form_selectUser')}
            >
              {userItems}
            </Select>
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              loading={this.props.loading}
            >
              {t(`label_${action.toLowerCase()}`)}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(RoleForm));
