/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.css';
import Header from './components/Header';
import { Route } from 'react-router-dom';
import axios from 'axios';
import { handleApiError } from '../../../../services/utils/errorHandler';
import ConfigContext from '../../../../components/ConfigContext';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import Statistics from './scenes/Statistics';
import Rejections from './scenes/Rejections';
import Stoppages from './scenes/Stoppages';
import Plans from './scenes/Plans';
import HourlyProduction from './scenes/HourlyProduction';
import Maintenance from './scenes/Maintenance';
import StatusAndInformation from './scenes/StatusAndInformation';
import { timeRanges } from '../../../../services/utils/commonUtils';
import moment from 'moment';
import useSetRange from '../../../../components/UseSetRange';
import { iframeHandler } from '../../../../services/utils/iframeHandler';
import PowerConsumption from './scenes/PowerConsumption';

export const DeviceDetailsContext = React.createContext();
const DeviceDetailsProvider = DeviceDetailsContext.Provider;

const AtlasSingleDevice = props => {
  const { deviceIdentifier, deviceType } = props.match.params;
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation();
  const [currentRoute, setCurrentRoute] = useState('statistics');
  const [device, setDevice] = useState(null);
  const [shiftType, setShiftType] = useState('all');
  const currentDate = new Date();
  currentDate.setHours(8, 0, 0, 0);
  const [from, setFrom] = useState(currentDate.getTime());
  const [timeRangeOptions, setTimeRangeOptions] = useState(timeRanges(t));
  const defaultTimeOptionsLen = timeRanges(t).length;
  const [range, setRange] = useState(timeRangeOptions[7].value);

  const searchParams = new URLSearchParams(window.location.search);
  const fromUrlParam = searchParams.has('from') ? searchParams.get('from') : '';
  const toUrlParam = searchParams.has('to') ? searchParams.get('to') : '';
  const checkUrlDateRange = `from=${fromUrlParam}&to=${toUrlParam}`;

  const routes = [
    {
      path: `/${config.appName}/devices/:deviceType/:deviceIdentifier/info`,
      component: StatusAndInformation,
      exact: true,
    },
    {
      path: `/${config.appName}/devices/:deviceType/:deviceIdentifier/maintenance`,
      component: Maintenance,
      exact: true,
    },
    {
      path: `/${config.appName}/devices/:deviceType/:deviceIdentifier/statistics`,
      component: props => (
        <Statistics
          range={range}
          {...props}
        />
      ),
      exact: true,
    },
    {
      path: `/${config.appName}/devices/:deviceType/:deviceIdentifier/power-consumption`,
      component: props => (
        <PowerConsumption
          range={range}
          {...props}
        />
      ),
      exact: true,
    },
    {
      path: `/${config.appName}/devices/:deviceType/:deviceIdentifier/rejections`,
      component: props => (
        <Rejections
          range={range}
          {...props}
        />
      ),
      exact: true,
    },
    {
      path: `/${config.appName}/devices/:deviceType/:deviceIdentifier/hourly-production`,
      component: props => (
        <HourlyProduction
          range={range}
          {...props}
        />
      ),
      exact: true,
    },
    {
      path: `/${config.appName}/devices/:deviceType/:deviceIdentifier/stoppages`,
      component: props => (
        <Stoppages
          range={range}
          {...props}
        />
      ),
      exact: true,
    },
    {
      path: `/${config.appName}/devices/:deviceType/:deviceIdentifier/plans`,
      component: props => (
        <Plans
          range={range}
          {...props}
        />
      ),
      exact: true,
    },
  ];

  const deviceDetails = {
    deviceIdentifier,
    deviceType,
    device,
    currentRoute,
    setCurrentRoute,
  };

  useEffect(() => {
    fetchDevice();

    if (fromUrlParam !== '' && toUrlParam !== '') {
      if (!isNaN(fromUrlParam * 1) && !isNaN(toUrlParam * 1)) {
        const fromTimestamp = fromUrlParam;
        const toTimestamp = toUrlParam;

        const newTimeRange = {
          id: defaultTimeOptionsLen + 1,
          label: `${moment(fromTimestamp).format('DD/MM/YY HH:mm')}-${moment(toTimestamp).format('DD/MM/YY HH:mm')}`,
          value: `from=${fromTimestamp}&to=${toTimestamp}`,
        };
        setTimeRangeOptions(prevOptions => [...prevOptions, newTimeRange]);
      }
    }
  }, []);


  useEffect(() => {
    if (timeRangeOptions.length > defaultTimeOptionsLen) {
      setRange(timeRangeOptions[defaultTimeOptionsLen].value);
    }
  }, [timeRangeOptions]);

  useEffect(() => {
    let timeOption = defaultTimeOptionsLen;
    if (shiftType !== 'all' && (timeRangeOptions.length > timeOption)) {
      setTimeRangeOptions([...timeRanges(t)]);
    } else {
      const timeRangeIndex = timeRanges(t).findIndex(range => range.value === checkUrlDateRange);
      timeOption = timeRangeIndex !== -1 ? timeRangeIndex : timeOption;
    }

    useSetRange(shiftType, from, setFrom, setRange, timeRangeOptions, timeOption);
  }, [from, shiftType]);

  useEffect(() => {
    const eventHandler = (event) => iframeHandler(event, timeRangeOptions, defaultTimeOptionsLen, setTimeRangeOptions);
    window.addEventListener('message', eventHandler);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, [timeRangeOptions, defaultTimeOptionsLen, setTimeRangeOptions]);

  const fetchDevice = () => {
    axios
      .get(
        window.location.origin +
        config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        `/devices/${deviceType}/${deviceIdentifier}`,
      )
      .then(result => {
        if (result.status === 200) {
          setDevice(result.data.data);
        } else {
          throw new Error();
        }
      })
      .catch(error => {
        handleApiError(error, t('api_retrieveDeviceDetailsError'), t);
      });
  };

  // Display a loading animation if the device is null
  if (device == null) {
    return (
      <Spin className={styles.spinner} size="large" tip={t('label_loading')} />
    );
  }

  return (
    <div>
      <DeviceDetailsProvider value={deviceDetails}>
        <Header
          shiftType={shiftType}
          setShiftType={setShiftType}
          range={range}
          setRange={setRange}
          timeRanges={timeRangeOptions}
          from={from}
          setFrom={setFrom}
        />
        <div className={styles.content}>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
        </div>
      </DeviceDetailsProvider>
    </div>
  );
};

export default AtlasSingleDevice;
