/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext } from 'react';
import { Col, DatePicker, Menu, Row, Select, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import styles from './styles.module.css';
import { DeviceDetailsContext } from '../../index';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ConfigContext from '../../../../../../components/ConfigContext';
import { useTranslation } from 'react-i18next';
import 'moment-timezone';
import BreadcrumbComponent from '../../../../../../components/BreadCrumb';

const { Text } = Typography;

const routes = (deviceName, appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      path: `/${appName}/devices`,
      breadcrumbName: t('home_menu_machines'),
    },
    {
      breadcrumbName: deviceName.charAt(0).toUpperCase() + deviceName.slice(1),
    },
  ];
};

const getItem = (label, key, icon, children, type) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

const { Option } = Select;

const Header = props => {
  const {
    setShiftType,
    shiftType,
    setFrom,
    from,
    setRange,
    range,
    timeRanges,
  } = props;
  const deviceDetails = useContext(DeviceDetailsContext);
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation();
  let history = useHistory();

  const items = [
    getItem(t('label_statistics'), 'statistics'),
    getItem(t('label_hourly_production'), 'hourly-production'),
    getItem(t('label_power_consumption'), 'power-consumption'),
    getItem(t('label_rejections'), 'rejections'),
    getItem(t('label_stoppages'), 'stoppages'),
    // getItem(t('label_plans'), 'plans'),
    getItem(t('label_statusNinformation'), 'info'),
    getItem(t('label_maintenance'), 'maintenance'),
  ];

  const menuClick = item => {
    let path =
      `/${config.appName}/devices/${deviceDetails.deviceType}/${deviceDetails.deviceIdentifier}` +
      '/' +
      item.key;
    history.push(path);
  };

  const dateOfLastUpdate = deviceDetails.device.enrolmentInfo.dateOfLastUpdate;

  let statusCommStatus = "";
  let statusLastActiveAt = "";
  const properties = deviceDetails.device.properties;
  for (const property of properties) {
    if (property.name === "_status_commStatus") {
      statusCommStatus = property.value;
    } else if (property.name === "_status_lastActiveAt") {
      const date = new Date(parseInt(property.value));
      statusLastActiveAt = date.toLocaleString();
    }

    if (statusCommStatus && statusLastActiveAt) {
      break;
    }
  }

  const breadcrumbData = `${deviceDetails.device.name} - ${statusCommStatus} (${statusLastActiveAt})`;

  const handleFromDateChange = (date) => {
    const time =
      shiftType === "dayShift"
        ? date.startOf("day").set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
        : shiftType === "nightShift"
        ? date.set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
        : null;

    setFrom(time ? time.valueOf() : null);
  };

  return (
    <PageHeader className={styles.pageHeader} ghost={false}>
      <div className={styles.positioning}>
        <Row justify="space-between" align="middle" gutter={8}>
          <Col>
            <BreadcrumbComponent
              breadcrumbList={routes(breadcrumbData, config.appName, t)}
            />
          </Col>
          <Col>
            <Row align="middle" gutter={[8, 8]}>
              <Col flex="auto">
                <Select
                  size="small"
                  className="shift-types-selector"
                  style={{ width: 175 }}
                  onChange={setShiftType}
                  value={shiftType}
                >
                  <Option key="dayShift" value="dayShift">
                    {t('label_day_shift')}
                  </Option>
                  <Option key="nightShift" value="nightShift">
                    {t('label_night_shift')}
                  </Option>
                  <Option key="all" value="all">
                    {t('label_all_shift')}
                  </Option>
                </Select>
              </Col>
              {shiftType !== 'all' ? (
                <Col>
                  <DatePicker
                    size="small"
                    className={`date-range-selector ${styles.commonStyle}`}
                    value={moment(from)}
                    onChange={handleFromDateChange}
                    disabledDate={(current) => current && current > moment().endOf('day')}
                  />
                </Col>
              ) : (
                <Col>
                  <Select
                    size="small"
                    className={`date-range-selector ${styles.commonStyle}`}
                    onChange={setRange}
                    value={range}
                  >
                    {timeRanges.map(({ id, label, value }) => (
                      <Option key={id} value={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                </Col>
              )}
            </Row>
          </Col>
          <Col>
            <Menu
              mode="horizontal"
              selectedKeys={[deviceDetails.currentRoute]}
              className={styles.menu}
              items={items}
              onClick={menuClick}
            />
          </Col>
        </Row>
      </div>
    </PageHeader>
  );
};
export default Header;
