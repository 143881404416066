/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
import React, {
  useState,
  useContext,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { Layout, Col, Result, Row, Spin, Drawer, Switch } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  ZoomInOutlined,
  ZoomOutOutlined,
  PlusOutlined,
  UnorderedListOutlined,
  ExclamationCircleOutlined,
  ReloadOutlined,
  EditOutlined,
  ReadOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import ConfigContext from '../../../../components/ConfigContext';
import axios from 'axios';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { useTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import ReactFlow, {
  Controls,
  ControlButton,
  Background,
  Panel,
  ReactFlowProvider,
  useReactFlow,
} from 'reactflow';
import useStore from './store';
import 'reactflow/dist/base.css';
import './index.css';
import CustomNode from './util/CustomNode.js';
import BezierEdge from './util/BezierEdge.js';
import SmoothStepEdge from './util/SmoothStepEdge.js';
import helpContent from './util/HelpDoc.js';
import ELK from 'elkjs/lib/elk.bundled.js';
import DownloadOrganization from './util/DownloadOrg.js';
import NodeForm from './util/NodeForm.js';
import PowerMeterIcon from './util/PowerMeterIcon.js';
import ContextMenu from './util/ContextMenu.js';
import EdgeCreationModal from './util/EdgeCreationModal.js';
import EdgeUpdateModal from './util/EdgeUpdateModal.js';
import {
  getLayoutedElements,
  useFetchOrganizations,
} from './util/LayoutUtil.js';
import DefaultViewEditor from './util/DefaultViewEditor.js';
import { useSelector } from 'react-redux';

const { Content } = Layout;
const snapGrid = [15, 15];
const defaultViewport = { x: 0, y: 0, zoom: 0.4 };
const nodeTypes = {
  turbo: CustomNode,
};
const defaultEdgeOptions = {
  type: 'turbo',
  markerEnd: 'edge-circle',
};

const elk = new ELK();
const elkOptions = {
  'elk.algorithm': 'layered',
  'elk.layered.spacing.nodeNodeBetweenLayers': '100',
  'elk.spacing.nodeNode': '80',
  'org.eclipse.elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
  'org.eclipse.elk.layered.nodePlacement.bk.edgeStraightening':
    'IMPROVE_STRAIGHTNESS',
  'org.eclipse.elk.graphviz.concentrate': 'true',
  'org.eclipse.elk.core.math.ElkMargin': '50',
};

const DeviceOrganization = () => {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation();
  const selectedBranch = useSelector((state) => state.branch.value) || '01';
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdgeModalVisible, setIsEdgeModalVisible] = useState(false);
  const [isEdgeUpdateModalVisible, setIsEdgeUpdateModalVisible] =
    useState(false);
  const [menu, setMenu] = useState(null);
  const [source, setSource] = useState('');
  const [target, setTarget] = useState('');
  const [oldEdge, setOldEdge] = useState('');
  const [newConnection, setNewConnection] = useState('');
  const [isNodeExpanded, setIsNodeExpanded] = useState(true);
  const ref = useRef(null);
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    // rootCount
  } = useStore();
  const setNodes = useStore((state) => state.setNodes);
  const setEdges = useStore((state) => state.setEdges);
  const { zoomIn, zoomOut, fitView } = useReactFlow();
  const [validationError, setValidationError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [defaultViewEditDrawerVisible, setDefaultViewEditDrawerVisible] =
    useState(false);
  const [selectedEdgeType, setSelectedEdgeType] = useState('smooth');
  const [maxDepth, setMaxDepth] = useState(10);
  const [maxRoots, setMaxRoots] = useState(10);
  const [reload, setReload] = useState(0);
  const [lastReloadTime, setLastReloadTime] = useState(Date.now());
  // const [rootNodeCount, setRootNodeCount] = useState(0);
  const defaultMaxDepth = 10; // default value for maxDepth
  const defaultMaxRoots = 10; // default value for maxRoots
  const previousBranch = useRef(null);

  const resetValues = () => {
    setMaxDepth(defaultMaxDepth);
    setMaxRoots(defaultMaxRoots);
  };
  const [switchChecked, setSwitchChecked] = useState(false);

  const reloadIfConditionsMet = () => {
    if (!switchChecked) {
      const currentTime = Date.now();
      if (currentTime - lastReloadTime >= 300000) {
        setLastReloadTime(currentTime);
        setReload((prevReload) => prevReload + 1);
      }
    }
  };

  useEffect(() => {
    reloadIfConditionsMet();
  }, [switchChecked]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!switchChecked) {
        const currentTime = Date.now();
        setLastReloadTime(currentTime);
        setReload((prevReload) => prevReload + 1);
      }
    }, 900000); // 15 minutes

    return () => clearInterval(interval);
  }, [switchChecked, reload]);

  const manualReload = () => {
    const currentTime = Date.now();
    setLastReloadTime(currentTime);
    setReload((prevReload) => prevReload + 1);
  };

  useEffect(() => {
    if (
      previousBranch.current !== null &&
      selectedBranch !== previousBranch.current
    ) {
      manualReload();
    }
    previousBranch.current = selectedBranch;
  }, [selectedBranch]);

  let turboEdgeComponent;
  switch (selectedEdgeType) {
    case 'bezier':
      turboEdgeComponent = BezierEdge;
      break;
    default:
      turboEdgeComponent = SmoothStepEdge;
  }

  const edgeTypes = {
    turbo: turboEdgeComponent,
  };
  const apiURI =
    window.location.origin +
    '/entgra-ui-request-handler/invoke' +
    '/device-org/v1.0';

  const powerMeterURI =
    window.location.origin +
    '/entgra-ui-request-handler/invoke' +
    '/device-mgt/power-meter-dlms/v1.0';

  const handleEdgeTypeChange = (event) => {
    setSelectedEdgeType(event.target.value);
  };

  const { loadingOrganizations } = useFetchOrganizations(
    apiURI,
    powerMeterURI,
    maxDepth,
    maxRoots,
    { reload },
  );

  // todo restrict add device button when max root count is reached
  // const maxRootCountReach = rootNodeCount => rootNodeCount >= 100;

  // const maxRootCountReach = () => {
  //   console.log(rootNodeCount);
  //   console.log('within max');
  //   if (rootNodeCount >= 100) {
  //     return true;
  //   }
  //   return false;
  // };

  // useEffect(() => {
  //   console.log('within');
  //   console.log(rootNodeCount);
  //   console.log(rootCount);
  //   setRootNodeCount(rootCount);
  // }, [reload]);

  // useEffect(() => {
  //   console.log();
  //   maxRootCountReach;
  // }, [rootNodeCount]);

  const onLayout = useCallback(
    ({ direction }) => {
      const opts = { 'elk.direction': direction, ...elkOptions };
      const ns = nodes;
      const es = edges;

      getLayoutedElements(ns, es, opts, elk, t).then(
        ({ nodes: layoutedNodes, edges: layoutedEdges }) => {
          setNodes(layoutedNodes);
          setEdges(layoutedEdges);

          window.requestAnimationFrame(() => fitView());
        },
      );
    },
    [nodes, edges, t, setNodes, setEdges, fitView],
  );

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const showDefaultViewEditDrawer = () => {
    setDefaultViewEditDrawerVisible(true);
  };

  const onCloseDefaultViewEditDrawer = () => {
    setDefaultViewEditDrawerVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setValidationError(null);
    setIsModalVisible(false);
  };
  const handleCreate = async (values) => {
    try {
      setLoading(true);

      const deviceApiURI =
        window.location.origin +
        '/entgra-ui-request-handler/invoke' +
        //        `/device-mgt/v1.0/devices?name=${values.nodeName}`;
        // config.serverConfig.invoker.uri +
        `/device-mgt/power-meter-dlms/v1.0/devices?name=${values.nodeName}&branch=${selectedBranch}`;

      // eslint-disable-next-line no-unused-vars
      const response = await axios.get(deviceApiURI).then(async (res) => {
        if (res.status === 200) {
          const devices = [];
          if (res.data.data.devices.length !== 0) {
            const filteredDevices = res.data.data.devices.filter(
              (reading) => values.nodeName === reading.name,
            );
            if (filteredDevices.length !== 0) {
              devices.push(filteredDevices[0]);
            } else {
              const nonMeter = await axios.get(
                window.location.origin +
                  '/entgra-ui-request-handler/invoke' +
                  `/device-mgt/v1.0/devices?name=${values.nodeName}`,
              );
              if (nonMeter.data.data.devices.length !== 0) {
                const nonMeterDevice = nonMeter.data.data.devices.filter(
                  (reading) => values.nodeName === reading.name,
                );
                devices.push(nonMeterDevice[0]);
              }
            }
          }
          const device = devices[0];

          const orgURI = apiURI + '/';
          const requestBody = {
            deviceId: device.id,
            parentDeviceId: null,
            deviceOrganizationMeta: values.orgDescription,
          };
          // eslint-disable-next-line no-unused-vars
          const organization = await axios
            .post(orgURI, requestBody)
            .then(async (res) => {
              if (res.status === 201 && res.data.data.isSuccess === true) {
                const orgDetails = await axios.get(
                  apiURI + `/organization/${device.id}/${null}`,
                );
                const nodeID = orgDetails.data.data.deviceId;
                const profileReading = await axios.get(
                  powerMeterURI +
                    `/admin/devices/last-load-profiles?serial=${device.deviceIdentifier}&onlyLast=true&limit=1&obis=LP`,
                );
                if (!nodes.find((node) => node.id === `${nodeID}`)) {
                  const newNode = {
                    id: `${nodeID}`,
                    sourcePosition: 'bottom',
                    targetPosition: 'top',
                    data: {
                      icon: <PowerMeterIcon />,
                      title: device.name,
                      description: '',
                      deviceIdentifier: device.deviceIdentifier,
                      children: [],
                      showProfileData: true,
                      editData: true,
                      isProfileApiCalled: true,
                      profileData:
                        profileReading.data.data.profileReadings[0]
                          ?.profileData,
                    },
                    position: { x: 0, y: 0 },
                    type: 'turbo',
                  };
                  nodes.push(newNode);
                  onLayout({ direction: 'DOWN' });
                  setIsModalVisible(false);
                } else {
                  setValidationError(t('validation_device_exist'));
                }
              } else {
                setValidationError(t('validation_device_exist'));
              }
            });
        }
      });
    } catch (error) {
      setValidationError(t('validation_device_name_msg'));
    } finally {
      setLoading(false);
    }
  };

  const onNodeContextMenu = useCallback(
    (event, node) => {
      // Prevent native context menu from showing
      event.preventDefault();

      // Use event.clientX and event.clientY to get the mouse position
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      // Get the React Flow container's bounding box
      const flowContainer = ref.current.getBoundingClientRect();

      // Adjust the position relative to the React Flow container
      const relativeX = mouseX - flowContainer.left;
      const relativeY = mouseY - flowContainer.top;

      setMenu({
        id: node.id,
        top: relativeY,
        left: relativeX,
      });
    },
    [setMenu],
  );
  // Close the context menu if it's open whenever the window is clicked.
  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

  const routes = (appName, t) => [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_deviceOrgView'),
    },
  ];

  const proOptions = { hideAttribution: true };

  const onConnectWrapper = async (description) => {
    try {
      await onConnect(source, target, apiURI, description, t);
      setIsEdgeModalVisible(false);
      setSource('');
      setTarget('');
    } catch (error) {
      setValidationError(t('error_creating_org'));
    } finally {
      setLoading(false);
    }
  };

  const handleOnConnect = (connection) => {
    setIsEdgeModalVisible(true);
    const { source, target } = connection;
    setSource(source);
    setTarget(target);
  };

  const handleModalConfirm = (description) => {
    setLoading(true);
    onConnectWrapper(description);
  };

  const handleModalCancel = () => {
    setIsEdgeModalVisible(false);
    setSource('');
    setTarget('');
    setValidationError(null);
  };

  const onEdgeUpdate = (oldEdge, newConnection) => {
    setOldEdge(oldEdge);
    setNewConnection(newConnection);
    setIsEdgeUpdateModalVisible(true);
  };

  const handleEdgeUpdateConfirm = async () => {
    const { id } = oldEdge;

    try {
      setLoading(true);
      // Delete the old edge
      // eslint-disable-next-line no-unused-vars
      const deleteResponse = await axios
        .delete(apiURI + `/${id}`)
        .then(async (res) => {
          // If deletion is successful, call handleOnConnect to create new edge
          if (res.status === 200 && res.data.data.isSuccess === true) {
            const updatedEdges = edges.filter((edge) => edge.id !== id);
            setEdges(updatedEdges);
            handleOnConnect(newConnection);
          } else {
            setValidationError(t('error_deleting_edge'));
          }
        });
    } catch (error) {
      setValidationError(t('error_creating_org'));
    } finally {
      setLoading(false);
    }

    setIsEdgeUpdateModalVisible(false);
    setOldEdge('');
    setNewConnection('');
  };

  const handleEdgeUpdateCancel = () => {
    setIsEdgeUpdateModalVisible(false);
    setOldEdge('');
    setNewConnection('');
    setValidationError(null);
  };

  const detailViewNodes = () => {
    const updatedNodes = nodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          showProfileData: true,
        },
      };
    });
    setNodes(updatedNodes);
    setIsNodeExpanded(true);
  };

  const summaryViewNodes = () => {
    const updatedNodes = nodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          showProfileData: false,
        },
      };
    });
    setNodes(updatedNodes);
    setIsNodeExpanded(false);
  };

  const setNodeMode = (checked) => {
    if (checked) {
      const updatedNodes = nodes.map((node) => {
        return {
          ...node,
          data: {
            ...node.data,
            editData: true,
          },
        };
      });
      setNodes(updatedNodes);
      setSwitchChecked(true);
    } else {
      const updatedNodes = nodes.map((node) => {
        return {
          ...node,
          data: {
            ...node.data,
            editData: false,
          },
        };
      });
      setNodes(updatedNodes);
      setSwitchChecked(false);
    }
  };

  const onZoomInHandler = () => {
    zoomIn();
  };

  const onZoomOutHandler = () => {
    zoomOut();
  };

  const updateMaxDepth = (value) => {
    const inputValue = parseInt(value, 10);
    const maxValue = 99;
    const minValue = 1;
    if (inputValue > maxValue) {
      setMaxDepth(maxValue);
      return;
    }
    if (inputValue < minValue) {
      setMaxDepth(minValue);
      return;
    }
    setMaxDepth(inputValue);
  };

  const updateMaxRoots = (value) => {
    const inputValue = parseInt(value, 10);
    const maxValue = 99;
    const minValue = 1;
    if (inputValue > maxValue) {
      setMaxRoots(maxValue);
      return;
    }
    if (inputValue < minValue) {
      setMaxRoots(minValue);
      return;
    }
    setMaxRoots(inputValue);
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      setReload((prevReload) => prevReload + 1);
    }
  };
  return (
    <div>
      <Authorized
        scope={['dm:device-org:view']}
        yes={
          <div>
            <PageHeader
              className={styles.pageHeader}
              ghost={false}
              breadcrumb={
                <BreadcrumbComponent
                  breadcrumbList={routes(config.appName, t)}
                />
              }
            ></PageHeader>
            <Row></Row>
            <Row className={styles.rowContent}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Layout style={{ height: '80vh' }}>
                  <Content style={{ padding: '1px', position: 'relative' }}>
                    {loading && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    )}
                    <ReactFlow
                      ref={ref}
                      nodes={nodes}
                      edges={edges}
                      onNodesChange={onNodesChange}
                      onEdgesChange={onEdgesChange}
                      onConnect={handleOnConnect}
                      snapToGrid={true}
                      snapGrid={snapGrid}
                      nodeTypes={nodeTypes}
                      edgeTypes={edgeTypes}
                      defaultViewport={defaultViewport}
                      fitView={true}
                      proOptions={proOptions}
                      defaultEdgeOptions={defaultEdgeOptions}
                      onNodeContextMenu={onNodeContextMenu}
                      onPaneClick={onPaneClick}
                      deleteKeyCode={null}
                      onEdgeUpdate={onEdgeUpdate}
                    >
                      <Background gap={25} />
                      <Authorized
                        scope={['dm:device-org:delete']}
                        yes={
                          <>
                            {menu && switchChecked && (
                              <ContextMenu
                                onClick={onPaneClick}
                                apiURI={apiURI}
                                nodes={nodes}
                                setNodes={setNodes}
                                edges={edges}
                                setEdges={setEdges}
                                onContextMenuClose={() => setMenu(null)}
                                {...menu}
                                t={t}
                                switchChecked={switchChecked}
                              />
                            )}
                          </>
                        }
                      />

                      <Panel className="viewport-panel" position="top-right">
                        <Authorized
                          scope={['dm:device-org:add']}
                          yes={
                            <>
                              <Switch
                                className="viewport-panel-switch"
                                onChange={(checked) => setNodeMode(checked)}
                                style={{
                                  backgroundColor: switchChecked
                                    ? '#bae0ff'
                                    : '#ffffff',
                                }}
                                checkedChildren={
                                  <span
                                    title={t('label_edit_mode')}
                                    aria-label="Edit Mode"
                                  >
                                    <EditOutlined />
                                  </span>
                                }
                                unCheckedChildren={
                                  <span
                                    title={t('label_view_mode')}
                                    aria-label="Read Mode"
                                  >
                                    <ReadOutlined />
                                  </span>
                                }
                                defaultChecked={false}
                              ></Switch>
                            </>
                          }
                        />

                        <button
                          onClick={manualReload}
                          className="viewport-panel-button"
                          title={t('label_refresh')}
                          aria-label="refresh"
                          disabled={loadingOrganizations}
                        >
                          <ReloadOutlined />
                        </button>
                      </Panel>

                      <Controls showZoom={false} position="top-left">
                        <ControlButton
                          onClick={onZoomInHandler}
                          className="react-flow__controls-zoomin"
                          title={t('label_zoom_in')}
                          aria-label="zoom in"
                        >
                          <ZoomInOutlined />
                        </ControlButton>
                        <ControlButton
                          onClick={onZoomOutHandler}
                          className="react-flow__controls-zoomout"
                          title={t('label_zoom_out')}
                          aria-label="zoom out"
                        >
                          <ZoomOutOutlined />
                        </ControlButton>
                        <Authorized
                          scope={['dm:device-org:delete']}
                          yes={
                            <>
                              {switchChecked && (
                                <ControlButton
                                  onClick={() => showModal()}
                                  className="react-flow__controls-addmeter"
                                  title={t('label_add_device')}
                                  aria-label="add device"
                                  disabled={!switchChecked}
                                >
                                  <PlusOutlined />
                                </ControlButton>
                              )}
                            </>
                          }
                        />
                        <DownloadOrganization />
                        {isNodeExpanded === false && (
                          <ControlButton
                            onClick={() => detailViewNodes()}
                            className="react-flow__controls-detailview"
                            title={t('label_detail_view')}
                            aria-label="detail view"
                          >
                            <UnorderedListOutlined />
                          </ControlButton>
                        )}

                        {isNodeExpanded === true && (
                          <ControlButton
                            onClick={() => summaryViewNodes()}
                            className="react-flow__controls-summaryview"
                            title={t('label_summary_view')}
                            aria-label="summary view"
                          >
                            <UnorderedListOutlined />
                          </ControlButton>
                        )}

                        <ControlButton
                          onClick={() => showDrawer()}
                          className="react-flow__controls-showHelp"
                          title={t('label_help_doc')}
                          aria-label="Help Doc"
                        >
                          <ExclamationCircleOutlined />
                        </ControlButton>
                        <ControlButton
                          onClick={() => showDefaultViewEditDrawer()}
                          className="react-flow__controls-showEditor"
                          title={t('label_default_view_editor')}
                          aria-label="View Editor"
                        >
                          <SettingOutlined />
                        </ControlButton>
                      </Controls>

                      <Drawer
                        title={t('label_help_doc_title')}
                        placement="right"
                        closable={false}
                        onClose={onClose}
                        open={drawerVisible}
                      >
                        {helpContent}
                      </Drawer>
                      <Drawer
                        title={t('label_default_view_editor')}
                        placement="right"
                        closable={false}
                        onClose={onCloseDefaultViewEditDrawer}
                        open={defaultViewEditDrawerVisible}
                      >
                        <DefaultViewEditor
                          t={t}
                          maxRoots={maxRoots}
                          maxDepth={maxDepth}
                          updateMaxRoots={updateMaxRoots}
                          updateMaxDepth={updateMaxDepth}
                          resetValues={resetValues}
                          loadingOrganizations={loadingOrganizations}
                          selectedEdgeType={selectedEdgeType}
                          handleEdgeTypeChange={handleEdgeTypeChange}
                          handleKeypress={handleKeypress}
                          onLayout={onLayout}
                        />
                      </Drawer>

                      <NodeForm
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        onCreate={handleCreate}
                        validationError={validationError}
                        loading={loading}
                        t={t}
                      />
                      <EdgeCreationModal
                        onConfirm={handleModalConfirm}
                        onCancel={handleModalCancel}
                        visible={isEdgeModalVisible}
                        loading={loading}
                        t={t}
                      />

                      <EdgeUpdateModal
                        onConfirm={handleEdgeUpdateConfirm}
                        onCancel={handleEdgeUpdateCancel}
                        visible={isEdgeUpdateModalVisible}
                        loading={loading}
                        t={t}
                      />
                    </ReactFlow>
                  </Content>
                </Layout>
              </Col>
            </Row>
          </div>
        }
        no={
          <Result
            status="403"
            title={t('noPerm_accessPageTitle')}
            subTitle={t('noPerm_contactSysAdmin')}
          />
        }
      />
    </div>
  );
};

const DeviceOrganizationView = () => {
  return (
    <ReactFlowProvider>
      <DeviceOrganization />
    </ReactFlowProvider>
  );
};

export default DeviceOrganizationView;
