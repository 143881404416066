/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Col, Row, Switch, Tabs, Typography } from 'antd';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import { checkCondition } from '../../services/payloadHandler';
import Policy from '../Policy';
import styles from './styles.module.css';
const { Title } = Typography;
const { TabPane } = Tabs;
import { CheckCircleFilled } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

class ConfigureProfile extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      policyUIPayload: null,
      activePolicyList: [],
    };
  }

  // set the activePolicyList and policyUIPayload
  setPolicies() {
    const policyUIPayload = this.state.policyUIPayload
      ? [...this.state.policyUIPayload]
      : [...this.props.policyUIConfigurationsList];
    const activePolicyList = [];
    policyUIPayload.forEach(policy => {
      if (policy.isActivePolicy) {
        activePolicyList.push(policy.name);
      }
    });
    this.setState({
      policyUIPayload: policyUIPayload,
      activePolicyList: activePolicyList,
    });
  }

  componentDidMount() {
    this.setPolicies();
  }

  setUpdatedPolicy = (index, policy) => {
    let { policyUIPayload } = this.state;
    policyUIPayload[index] = policy;

    this.setState({ policyUIPayload: policyUIPayload });
  };

  // handle Switch on off button
  handleMainPanel = (e, policy) => {
    policy.isActivePolicy = !!e;
    this.setPolicies();
  };

  isViolated = false;

  setDevicePayloadContent = (
    uiPayloadFeatureContent,
    devicePayloadFeatureContent,
  ) => {
    const items = uiPayloadFeatureContent.items;
    const { t } = this.props;
    for (const item of items) {
      if (
        !item.hasOwnProperty('conditions') ||
        checkCondition(item, uiPayloadFeatureContent) === true
      ) {
        if ('checkbox' in item) {
          devicePayloadFeatureContent[item.key] =
            item.value.toString() === 'true';
        } else if (
          'nullableValue' in item &&
          item.value === item.nullableValue
        ) {
          devicePayloadFeatureContent[item.key] = null;
        } else if (!('nullableValue' in item) && item.isRequired) {
          if (
            (item.value === '' ||
              (Array.isArray(item.value) && item.value.length === 0)) &&
            !item.isHidden
          ) {
            this.isViolated = true;
            if (!item.error) {
              item.error = {};
            }
            item.error.required = t('form_fieldRequiredMsg');
          } else {
            if (item.error) {
              delete item.error;
            }
            devicePayloadFeatureContent[item.key] = item.value;
          }
        } else if (
          item.isNoneEmptyTable === true &&
          (item.value === '' ||
            (Array.isArray(item.value) && item.value.length === 0))
        ) {
          this.isViolated = true;
          if (!item.error) {
            item.error = {};
          }
          item.error.required = t('form_nonEmptyTableMsg');
        } else if (item.hasOwnProperty('error')) {
          this.isViolated = true;
        } else {
          devicePayloadFeatureContent[item.key] = item.value;
        }
      }
      if ('inputList' in item) {
        if (item.value === '') {
          devicePayloadFeatureContent[item.key] = [];
        }
      }
    }
    if (uiPayloadFeatureContent.hasOwnProperty('subContents')) {
      for (const subContent of uiPayloadFeatureContent.subContents) {
        if (checkCondition(subContent, uiPayloadFeatureContent)) {
          let subContentPayload = {};
          this.setDevicePayloadContent(subContent, subContentPayload);
          devicePayloadFeatureContent[subContent.key] = subContentPayload;
        }
      }
    }
  };

  onHandleContinue = e => {
    let activePolicyFeatures = {};
    let profileFeaturesList = [];
    let { policyUIPayload } = this.state;
    policyUIPayload.forEach(policy => {
      if (policy.isActivePolicy) {
        policy.features.map(feature => {
          activePolicyFeatures[feature.featureCode] = {};
          activePolicyFeatures[feature.featureCode].content = feature.content;
          if (feature.correctiveActions) {
            activePolicyFeatures[feature.featureCode].correctiveActions =
              feature.correctiveActions;
          }
        });
      }
    });

    for (const featureKey in activePolicyFeatures) {
      if (activePolicyFeatures.hasOwnProperty(featureKey)) {
        let devicePayloadFeatureContent = {};

        this.setDevicePayloadContent(
          activePolicyFeatures[featureKey].content,
          devicePayloadFeatureContent,
        );

        let profileFeature = {
          featureCode: featureKey,
          deviceType: this.props.deviceType,
          content: devicePayloadFeatureContent,
        };
        if (activePolicyFeatures[featureKey].correctiveActions) {
          profileFeature.correctiveActions =
            activePolicyFeatures[featureKey].correctiveActions;
        }
        profileFeaturesList.push(profileFeature);
      }
    }
    this.props.setPolicyPayloadData({
      configureProfileData: profileFeaturesList,
    });
    this.setState({ policyUIPayload });
    if (!this.isViolated && profileFeaturesList.length > 0) {
      this.props.setNextStep();
    }
    this.isViolated = false;
  };

  render() {
    const { policyUIPayload, activePolicyList } = this.state;
    const { t } = this.props;
    return (
      <div>
        {policyUIPayload != null && (
          <Tabs tabPosition={'left'} size={'large'}>
            {policyUIPayload.map((policy, index) => {
              return (
                <TabPane
                  tab={
                    <span>
                      <div style={{ textAlign: 'left' }}>
                        {t(policy.name)}{' '}
                        <CheckCircleFilled
                          style={{
                            color: '#6ab04c',
                            display: activePolicyList.includes(policy.name)
                              ? 'inline'
                              : 'none',
                          }}
                        />
                      </div>
                    </span>
                  }
                  key={`policy-${index}`}
                >
                  <div>
                    <div>
                      <Row>
                        <Col offset={0} span={14}>
                          <Title level={4}> {t(policy.name)} </Title>
                        </Col>
                        <Col offset={5} span={1}>
                          <Switch
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            onChange={e => this.handleMainPanel(e, policy)}
                            defaultChecked={policy.isActivePolicy}
                            disabled={this.props.disabled}
                          />
                        </Col>
                      </Row>
                      <Row>{t(policy.description)}</Row>
                    </div>
                    {policy.isActivePolicy && (
                      <Policy
                        deviceType={this.props.deviceType}
                        policy={policy}
                        callback={policyData =>
                          this.setUpdatedPolicy(index, policyData)
                        }
                        disabled={this.props.disabled}
                      />
                    )}
                  </div>
                  {!this.props.disabled && (
                    <Row>
                      <Col lg={{ span: 10, offset: 18 }}>
                        <div className={styles.buttons}>
                          <Button
                            style={{ marginRight: 8 }}
                            onClick={this.props.setPrevStep}
                          >
                            {t('label_back')}
                          </Button>
                          <Button
                            type="primary"
                            onClick={this.onHandleContinue}
                            disabled={activePolicyList.length === 0}
                          >
                            {t('label_continue')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )}
                </TabPane>
              );
            })}
          </Tabs>
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(ConfigureProfile));
