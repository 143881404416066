/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */


import React from 'react';
import axios from 'axios';
import {Space, Table} from 'antd';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {withConfigContext} from '../../../../../components/ConfigContext';
import {handleApiError} from '../../../../../services/utils/errorHandler';
import styles from './styles.module.css';
import {withTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import ChangeState from "../ChangeState";

class MeterOperationTable extends React.Component {
    constructor(props) {
        super(props);
        this.config = this.props.context;
        TimeAgo.addLocale(en);
        this.state = {
            data: {
                operations: [],
                count: null,
            },
            pagination: {},
            loading: false,
            selectedRows: [],
            searchFields: [],
            loadSearchBar: false,
        };

        const {t} = this.props;
        let toLink = null;
        this.columns = [
            {
                title: t('label_opId'),
                dataIndex: 'id',
                key: 'id',
                render: (data, row) => {
                    const startTimestamp = new Date(row.createdTimeStamp).getTime();
                    const endTimestamp = new Date(row.receivedTimeStamp).getTime();

                    // if (enableClickableLinks) {//put permission access if needed
                    toLink = `/${this.config.appName}/read/operations/activities?serial=${
                        this.props.meterSerial
                    }&operationId=${row.id}&status=${row.status}&startTimestamp=${
                        startTimestamp - 2
                    }&endTimestamp=${
                        endTimestamp - startTimestamp < 60000
                            ? endTimestamp + 60000
                            : endTimestamp - 2
                    }`;
                    // }
                    return (
                        <Link to={toLink} target="_blank">{row.id}</Link>
                    );
                },
            },
            {
                title: t('label_opCode'),
                dataIndex: 'code',
                key: 'code',
                render: (data, row) => {
                    return (
                        <Link to={toLink} target="_blank">{row.code}</Link>
                    );
                }
            },
            {
                title: t('label_created'),
                dataIndex: 'createdTimeStamp',
                key: 'createdTimeStamp',
                render: (data, row) => {
                    const createdDate = new Date(row.createdTimeStamp).toLocaleDateString();
                    const createdTime = new Date(row.createdTimeStamp).toLocaleTimeString();
                    return (
                        <Link to={toLink} target="_blank">{createdDate}&nbsp;{createdTime}</Link>
                    );
                }
            },
            {
                title: t('label_updated'),
                dataIndex: 'receivedTimeStamp',
                key: 'receivedTimeStamp',
                render: (data, row) => {
                    const receivedDate = new Date(row.receivedTimeStamp).toLocaleDateString();
                    const receivedTime = new Date(row.receivedTimeStamp).toLocaleTimeString();
                    return (
                        <Link to={toLink} target="_blank">{receivedDate}{receivedTime}</Link>
                    );
                }
            },
            {
                title: t('label_initiatedBy'),
                dataIndex: 'initiatedBy',
                key: 'initiatedBy',
                render: (data, row) => {
                    return (
                        <Link to={toLink} target="_blank">{row.initiatedBy}</Link>
                    );
                }
            },
            {
                title: t('label_status'),
                dataIndex: 'status',
                key: 'status',
                render: (data, row) => {
                    return (
                        <Link to={toLink} target="_blank">{row.status}</Link>
                    );
                }
            },
            {
                title: t('label_action'),
                dataIndex: 'activityId',
                key: 'actions',
                align: 'right',
                width: 25,
                // do not remove unused data variable
                render: (data, row) => {
                    if (row.status == 'PENDING' || row.status =='NOTNOW') {
                        return (
                            <Space>
                                <ChangeState
                                    view={'meterOperationTable'}
                                    operation={row}
                                    meterSerial={this.props.meterSerial}
                                    callback={this.reloadOperationTable}
                                    disabled={false}
                                />
                            </Space>
                        );
                    } else {
                        return 'N/A';
                    }
                },
            },

        ];
    }

    componentDidMount() {
        this.fetchMeters();
        // this.fetchMeters(false);
    }

    // Rerender component when parameters change
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)
        ) {
            const pagination = {...this.state.pagination};
            pagination.page = 1;
            pagination.current = 1;
            this.setState({pagination});
            this.fetchMeters(true, {}, this.props.filters);
        }
    }

    fetchMeters = (loadSearchBar, params = {}, filters = {}) => {
        this.setState({loading: true});
        const {t} = this.props;
        const meterSerial = this.props.meterSerial;
        // get current page
        const currentPage = params.hasOwnProperty('page') ? params.page : 1;

        let limit = 5;
        if (params.results) {
            limit = params.results;
        }

        const extraParams = {
            offset: 5 * (currentPage - 1),
            limit: limit,
            ...this.props.filters,
        };

        const encodedExtraParams = Object.keys(extraParams)
            .map(key => key + '=' + extraParams[key])
            .join('&');

        let apiUrl =
            window.location.origin +
            this.config.serverConfig.invoker.uri +
            `/device-mgt/v1.0/devices/power-meter/${meterSerial}/operations?` +
            encodedExtraParams;

        axios
            .get(apiUrl)
            .then(res => {
                if (res.status === 200) {
                    const pagination = {...this.state.pagination};
                    const operationsData = res.data.data.operations;
                    this.setState({
                        loading: false,
                        data: {
                            operations: operationsData,
                            count: res.data.data.count,
                        },
                        pagination,
                        loadSearchBar: true,
                    });
                }
            })
            .catch(error => {
                handleApiError(error, t('label_error_load_meters'), t);
                this.setState({loading: false});
            });
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.fetchMeters(true,{
            page: pagination.current,
            results: pagination.pageSize,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    reloadOperationTable = () => {
        this.fetchMeters(true);
    };

    render() {
        const {data, pagination, loading} = this.state;
        const {t} = this.props;
        const paginationOptions = {
            pageSize: 5,
            showSizeChanger: true,
            pageSizeOptions: ['5'],
        };
        return (
            <div className={styles.tableContainer}>
                <div style={{backgroundColor: '#ffffff', borderRadius: 5}}>
                    <Table
                        columns={this.columns}
                        dataSource={data.operations}
                        rowKey="operation"
                        className={styles.customTable}
                        size="small"
                        pagination={{
                            ...pagination,
                            size: 'small',
                            total: data.count,
                            disabled: loading,
                            showTotal: (total, range) =>
                                `${range[0]}-${range[1]} ${t('table_pagination', {
                                    total: total,
                                    data: t('home_menu_operations'),
                                })}`,
                            ...paginationOptions,
                        }}
                        loading={loading}
                        onChange={this.handleTableChange}
                        bordered
                    />
                </div>

            </div>
        );
    }
}

export default withConfigContext(withTranslation()(MeterOperationTable));
