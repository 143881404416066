/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Descriptions, Tabs } from 'antd';
import { BarsOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

export const DeviceDetails = props => {
  const { deviceInfo, enrolmentInfo } = props;
  const { t } = useTranslation();

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Adjust the formatting options as needed
  };

  const translationKey = (key) => {
    key = key.startsWith('_') ? key.slice(1) : key;
    const label = `label_${key}`;
    const translatedValue = t(label);
    const isMissingKey = `label_${key}` === t(`label_${key}`);
    return isMissingKey ? key : translatedValue;
  } 

  return (
    <div className={styles.content}>
      <Tabs defaultActiveKey="properties">
          <TabPane
            tab={
              <span>
                <BarsOutlined />
                {t('label_properties')}
              </span>
            }
            key="properties"
          >
            <Descriptions
              column={1}
              size="small"
              bordered={true}
              className={styles.formattedItems}
            >
              {Object.keys(deviceInfo).map(key => {
                let data = deviceInfo[key];

                return (
                  <Descriptions.Item label={translationKey(key)} key={key}>
                    {typeof deviceInfo[key] === 'object'
                      ? JSON.stringify(deviceInfo[key])
                      : data}
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          </TabPane>
          <TabPane
            tab={
              <span>
                <BarsOutlined />
                {t('label_enrolmentInfo')}
              </span>
            }
            key="formatted"
          >
            <Descriptions
              column={1}
              size="small"
              bordered={true}
              className={styles.formattedItems}
            >
              {Object.keys(enrolmentInfo).map(key => {
                let data = enrolmentInfo[key];
                switch (key) {
                  case 'dateOfEnrolment':
                  case 'dateOfLastUpdate':
                    data = formatDate(data);
                    break;
                }
                return (
                  (!['owner', 'ownership', 'isTransferred'].includes(key)) ? (
                    <Descriptions.Item label={translationKey(key)} key={key}>
                      {typeof deviceInfo[key] === 'object'
                        ? JSON.stringify(deviceInfo[key])
                        : data}
                    </Descriptions.Item>
                  ) : ('')
                );
              })}
            </Descriptions>
          </TabPane>
        </Tabs>
    </div>
  );
};

export default DeviceDetails;
