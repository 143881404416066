import {RoutesContext} from '../../index';
import React, {useState, useEffect, useContext} from 'react';
import {Col, DatePicker, Result, Row, Button, Divider, Collapse, Input} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {withConfigContext} from '../../../../components/ConfigContext';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import {withTranslation} from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import axios from 'axios';
import { subDays } from 'date-fns';
import {ExportOutlined} from '@ant-design/icons';
import MeterOperationTable from './MeterOperationTable';
import MeterInfo from './MeterInfo';
import IframeWrapper from '../../components/IframeWrapper';
import DateRangeDropdown from "../../components/common/DateRangeDropdown";
import { getUnixTime } from "../../../../services/utils/functions";
import configs from "../../../../../public/conf/config.json";

const MeterView = (props) => {
    const config = props.context;
    const {t} = props;
    const routesContext = useContext(RoutesContext);
    const {Panel} = Collapse;
    const {RangePicker} = DatePicker;
    const rightNow = new Date();
    const [range, setRange] = useState({
        from: getUnixTime(subDays(rightNow, 2)),
        to: getUnixTime(rightNow),
    });
    const [meterData, setMeterData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isViewButtonDisabled, setIsViewButtonDisabled] = useState(true);
    const [meterSerial, setSerialValue] = useState('');
    const [outsideSerial, setOutsideSerial] = useState(false);
    const [outsideDates, setOutsideDates] = useState(false);
    const [showMeterDetails, setShowMeterDetails] = useState(false);
    const [value, setValue] = useState('');
    const [dashboardUrl1, setDashboardUrl1] = useState('');
    const [dashboardUrl2, setDashboardUrl2] = useState('');

    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

    const handleChange = (val) => {
        if (rx_live.test(val.target.value)) {
            setValue(val.target.value);
        }
    };

    const handleKeypress = (e) => {
        if (e.keyCode === 13) {
            if (value !== '') {
                setSerialValue(value);
            }
        }
    };

    const queryParams = `&var-serial=${meterSerial}&from=${range.from}&to=${range.to}`;

    useEffect(() => {
        // Use the context to set the current route
        routesContext.setCurrentRoute('allMeterView');
        setDashboardUrl1(`${configs.grafanaConfig.meterLocation}${queryParams}`);
        setDashboardUrl2(`${configs.grafanaConfig.RSSIHistory}${queryParams}`);
    }, [meterSerial, range.from, range.to]);

    const navigateViewMeterData = () => {
        let path = '../meter/data?serial=' + meterSerial;
        window.open(path, '_blank', 'noopener,noreferrer');
    };

    useEffect(() => {

        const str = window.location.search;
        if (str) {
            const urlParams = new URLSearchParams(window.location.search);
            const paramSerial = urlParams.get('serial');
            const paramFrom = urlParams.get('from');
            const paramTo = urlParams.get('to');
            if (paramSerial !== null) {
                setSerialValue(paramSerial);
                setOutsideSerial(true);
                handleSerialSearch();
            }
            if (paramFrom !== null && paramTo !== null) {
                setRange({ from: paramFrom, to: paramTo });
                setOutsideDates(true);
            }
        }
        // eslint-disable-next-line
    }, [t]);

    useEffect(() => {
        if (meterSerial) {
            setIsLoading(true);
            handleSerialSearch();
        }
        // eslint-disable-next-line
    }, [meterSerial]);

    useEffect(() => {
        // After the Axios call is completed, set isViewButtonDisabled to false
        setIsViewButtonDisabled(false);
    }, [isLoading]);

    const handleSerialSearch = () => {
        if (meterSerial) {
            axios({
                method: 'GET',
                url: window.location.origin + config.serverConfig.invoker.uri + `/device-mgt/power-meter-dlms/v1.0/devices/device/${meterSerial}`,
            })
                .then((response) => {
                    const responseData = response.data.data;
                    const meterDetails = {
                        serial: responseData.deviceIdentifier,
                        ipAddress: responseData.properties?.find((prop) => prop.name === 'IP_ADDRESS')?.value || '-',
                        meterType: responseData.properties?.find((prop) => prop.name === 'SUB_TYPE_ID')?.value,
                        status: responseData.enrolmentInfo.status,
                        category: responseData.properties?.find((prop) => prop.name === 'CATEGORY')?.value || '-',
                        branch: responseData.properties?.find((prop) => prop.name === 'BRANCH')?.value || '-',
                        depot: responseData.properties?.find((prop) => prop.name === 'DEPOT')?.value || '-',
                        substation: responseData.properties?.find((prop) => prop.name === 'SUBSTATION_ID')?.value || '-',
                        customerRef: responseData.properties?.find((prop) => prop.name === 'CUSTOMER_REF')?.value || '-',
                    };
                    setMeterData(meterDetails);
                    setIsLoading(false);
                    // Show the MeterDetails component after successful search
                    setShowMeterDetails(true);
                })
                .catch((error) => {
                    // Handle error here
                });
        }
    }

    const routes = (appName) => [
        {
            path: `/${appName}`,
            breadcrumbName: t('home_menu_home'),
        },
        {
            breadcrumbName: t('home_menu_meter'),
        },
        {
            breadcrumbName: t('home_menu_meterView'),
        },
    ];

    return (
        <div>
            <Authorized
                scope={['perm:rmr:admin:meter-inventory']}
                yes={
                    <div>
                        <PageHeader className={styles.pageHeader} ghost={false}>
                            <div className={styles.positioning}>
                                <Row justify="space-between" align="middle" gutter={8}>
                                    <Col>
                                        <BreadcrumbComponent breadcrumbList={routes(config.appName)}/>
                                    </Col>
                                    <Col>
                                        <Row align="middle" gutter={[8, 8]}>
                                            {!outsideSerial &&
                                                <Input
                                                    type="text"
                                                    style={{width: 200, marginLeft: 25, marginRight: 25}}
                                                    placeholder="Meter Serial"
                                                    onChange={handleChange}
                                                    onKeyDown={handleKeypress}
                                                    maxLength="12"
                                                    value={value}
                                                    pattern="[+-]?\d+(?:[.,]\d+)?"
                                                />
                                            }
                                            <Col>
                                                {!outsideDates && (
                                                    <DateRangeDropdown
                                                        alignDropdown="right"
                                                        getRange={(data) => setRange(data)}
                                                        closeOnBackground
                                                    />
                                                )}
                                            </Col>
                                            <Col>
                                                <Button
                                                    onClick={navigateViewMeterData}
                                                    disabled={isLoading || isViewButtonDisabled}
                                                    style={{marginRight: 10}}
                                                    type="primary"
                                                    icon={<ExportOutlined/>}>
                                                    {t('label_visualizeMeterData')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </PageHeader>
                        {meterSerial !== '' && !isLoading && (
                            <>
                                <div className="row">
                                    <div className="col-md-12">
                                        {showMeterDetails && <MeterInfo meterInfo={meterData} />}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <Row gutter={[16, 16]}>
                                        <Col className={styles.sidePadding} style={{ width: "30%", marginLeft:"25px"}}>
                                            <IframeWrapper iframeSrc={dashboardUrl1} height="720px"/>
                                        </Col>
                                        <Col style={{ width: "50%" }}>
                                            <Row style={{width:"100%"}}>
                                                <IframeWrapper iframeSrc={dashboardUrl2} height="320px" width="620px"/>
                                            </Row>
                                            <Row>
                                                <MeterOperationTable meterSerial={meterSerial} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>

                            </>
                        )}


                    </div>
                }
                no={
                    <Result
                        status="403"
                        title={t('noPerm_accessPageTitle')}
                        subTitle={t('noPerm_contactSysAdmin')}
                    />
                }
            />
        </div>
    );
};

export default withConfigContext(withTranslation()(MeterView));
