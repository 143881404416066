/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Tooltip,
  Upload,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { QuestionCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 10 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
  },
};

const buttonFormItem = {
  wrapperCol: {
    md: { span: 20 },
    lg: { span: 22 },
    xl: { span: 22 },
  },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

class AgentConfig extends React.Component {
  formRef = React.createRef();
  appxCert = '';

  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      isEnable: false,
      appxFileList: [],
    };
  }

  componentDidMount() {
    if (this.props.windowsPlatformConfigData.length > 0) {
      let platformConfigData = {};
      this.props.windowsPlatformConfigData.forEach(config => {
        platformConfigData[config.name] = config.value;
      });
      this.formRef.current.setFieldsValue(platformConfigData);
    }
  }

  onChangeEvent = e => {
    this.setState({
      isEnable: e.target.checked,
    });
  };

  onFinish = values => {
    values.appxCertificate = this.appxCert;
    this.props.callback(values);
  };

  readFile = file => {
    const { t } = this.props;
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(t('fileReading_errorTxt'));
      };
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsText(file);
    });
  };

  validateFile = async (rule, value) => {
    const { t } = this.props;
    if (value && value.file && value.file.status !== 'removed') {
      await this.readFile(value.file)
        .then(fileContent => {
          this.appxCert = fileContent;
          if (fileContent.includes('-----BEGIN CERTIFICATE-----')) {
            return Promise.resolve();
          }
          throw new Error();
        })
        .catch(err => {
          console.log(err);
          this.setDefaultValues();
          return Promise.reject(t('form_appxCertFileInValidMsg'));
        });
    } else {
      return Promise.resolve();
    }
  };

  handleAppxFileChange = info => {
    let fileList = [...info.fileList];

    // Limit the number of uploaded files
    fileList = fileList.slice(-1);
    this.setState({ appxFileList: fileList });
    if (fileList.length === 0) {
      this.appxCert = '';
    }
  };

  render() {
    const { isEnable, appxFileList } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div>
          <Divider>
            <PageHeader
              className="site-page-header-responsive"
              subTitle={t('label_windowsAgentConfiguration')}
            />
          </Divider>
        </div>
        <div>
          <Form
            {...formItemLayout}
            labelAlign="right"
            layout="horizontal"
            name={'windowsAgentConfigurationForm'}
            onFinish={this.onFinish}
            ref={this.formRef}
          >
            <Form.Item
              {...tailLayout}
              name="agentInstallEnable"
              valuePropName="checked"
            >
              <Checkbox onChange={this.onChangeEvent}>
                <span>
                  {t('label_EnableAgentInstallation', {
                    label: '',
                  })}
                  &nbsp;
                  <Tooltip
                    title={t('label_EnableAgentInstallation', {
                      label: `${t('label_windows')} `,
                    })}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              </Checkbox>
            </Form.Item>
            <div style={{ display: isEnable ? 'unset' : 'none' }}>
              <Form.Item
                label={
                  <span>
                    {t('label_appxHostedLocation')}&nbsp;
                    <Tooltip title={t('label_appxHostedLocation')}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="appxHostedLocation"
                rules={[
                  {
                    required: true,
                    message: t('form_generalRequiredMsg', {
                      label: t('label_appxHostedLocation'),
                    }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    {t('label_appxPackageFamilyName')}&nbsp;
                    <Tooltip title={t('label_appxPackageFamilyName')}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="packageFamilyName"
                rules={[
                  {
                    required: true,
                    message: t('form_generalRequiredMsg', {
                      label: t('label_appxPackageFamilyName'),
                    }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    {t('label_appxCertificate')}&nbsp;
                    <Tooltip title={t('form_uploadAppxCertificate')}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                rules={[
                  {
                    validator: this.validateFile,
                  },
                ]}
                name="appxCertificate"
              >
                <Upload.Dragger
                  beforeUpload={() => false}
                  onChange={this.handleAppxFileChange}
                  fileList={appxFileList}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    {t('uploadAppxCertificate_infoTxt')}
                  </p>
                </Upload.Dragger>
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    {t('label_appxCertificateHash')}&nbsp;
                    <Tooltip title={t('label_appxCertificateHash')}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="appxCertificateHash"
                rules={[
                  {
                    required: true,
                    message: t('form_generalRequiredMsg', {
                      label: t('label_appxCertificateHash'),
                    }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    {t('label_MSIHostedLocation')}&nbsp;
                    <Tooltip title={t('label_MSIHostedLocation')}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="msiHostedLocation"
                rules={[
                  {
                    required: true,
                    message: t('form_generalRequiredMsg', {
                      label: t('label_MSIHostedLocation'),
                    }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    {t('label_MSIProductID')}&nbsp;
                    <Tooltip title={t('label_MSIProductID')}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="msiProductId"
                rules={[
                  {
                    required: true,
                    message: t('form_generalRequiredMsg', {
                      label: t('label_MSIProductID'),
                    }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    {t('label_MSIFileHash')}&nbsp;
                    <Tooltip title={t('label_MSIFileHash')}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="msiFileHash"
                rules={[
                  {
                    required: true,
                    message: t('form_generalRequiredMsg', {
                      label: t('label_MSIFileHash'),
                    }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <Form.Item {...buttonFormItem}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: 'right' }}
              >
                {t('label_save')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AgentConfig);
