/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import styles from './styles.module.css';
import {
  getBranchName,
  getSubTypeName
} from '../../../../services/utils/functions';

const ProfileDataInfo = props => {
  return (
    <div className={styles.cardBody}>
      <div className={styles.row}>
        <div className={styles.column}>
          <span>
            <b>Meter</b>
          </span>
          <h6>{props.meterInfo.name}</h6>
        </div>
        <div className={styles.column}>
          <span>
            <b>Serial</b>
          </span>
          <h6>{props.meterInfo.serial}</h6>
        </div>
        <div className={styles.column}>
          <span>
            <b>IP Address</b>
          </span>
          <h6>{props.meterInfo.ipAddress}</h6>
        </div>
        <div className={styles.column}>
          <span>
            <b>Meter Type</b>
          </span>
          <h6>{getSubTypeName(props.meterInfo.meterType)}</h6>
        </div>
        <div className={styles.column}>
          <span>
            <b>Status</b>
          </span>
          <h6>{props.meterInfo.status}</h6>
        </div>
        <div className={styles.column}>
          <span>
            <b>Facility</b>
          </span>
          <h6>{getBranchName(props.meterInfo.branch)}</h6>
        </div>
      </div>
    </div>
  );
};

export default ProfileDataInfo;
