/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Col,
  DatePicker,
  Result,
  Row,
  Select,
  Spin,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { withConfigContext } from '../../../../components/ConfigContext';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import { grafanaUrlAccessible } from '../../../../services/utils/urlUtils';
import IframeWrapper from '../../components/IframeWrapper';
import { timeRanges } from '../../../../services/utils/commonUtils';
import moment from 'moment';

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_output'),
    },
    {
      breadcrumbName: t('home_menu_penAssemly'),
    },
  ];
};


class OutputPenAssembly extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    const { t } = this.props;
    const currentDate = new Date();
    currentDate.setHours(8, 0, 0, 0);
    this.timeRangeOptions = timeRanges(t);
    this.state = {
      isDashboardAccessible: false,
      isLoading: true,
      shiftType: 'all',
      from: currentDate.getTime(),
      range: this.timeRangeOptions[7].value,
    };
  }

  checkDashboardAccess = async (dashboardUrl) => {
    const accessible = await grafanaUrlAccessible(`${this.config.grafanaConfig.reportBase}/${dashboardUrl}`);
    this.setState({ isDashboardAccessible: accessible, isLoading: false });
  };

  componentDidMount() {
    this.context.setCurrentRoute('allOutputPenAssembly');
    this.checkDashboardAccess(`${this.config.grafanaConfig.output}&${this.state.range}`);
  }

  handleShiftTypeChange = (value) => {
    const { from, shiftType } = this.state;

    let updatedFrom = from;
    let updatedRange = this.state.range;

    if (value === 'all') {
      updatedRange = this.timeRangeOptions[7].value;
    } else {
      let addHours = 1;
      if (value === 'dayShift') {
        addHours = 11;
        const fromDate = new Date(from);
        if (fromDate.getHours() !== 8) {
          fromDate.setHours(8, 0, 0, 0);
          updatedFrom = fromDate.getTime();
        }
      } else if (value === 'nightShift') {
        addHours = 13;
        const fromDate = new Date(from);
        if (fromDate.getHours() !== 19) {
          fromDate.setHours(19, 0, 0, 0);
          updatedFrom = fromDate.getTime();
        }
      }
      updatedRange = `from=${updatedFrom}&to=${updatedFrom + (addHours * 60 * 60 * 1000)}`;
    }

    this.setState({ shiftType: value, from: updatedFrom, range: updatedRange });
  };


  handleFromDateChange = (date) => {
    const { shiftType } = this.state;
    const time =
      shiftType === 'dayShift'
        ? date.startOf('day').set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
        : shiftType === 'nightShift'
        ? date.set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
        : null;

    let range = this.state.range;
    if (shiftType === 'all') {
      range = date;
    } else {
      range = `from=${time.valueOf()}&to=${time.valueOf() + (11 * 60 * 60 * 1000)}`;
    }

    this.setState({ from: time ? time.valueOf() : null, range });
  };

  render() {
    const config = this.props.context;
    const { Option } = Select;
    const { t } = this.props;
    const { shiftType, from, range, isDashboardAccessible, isLoading } = this.state;
    const dashboardUrl = `${this.config.grafanaConfig.output}&${range}`;
    const timeRangeOptions = this.timeRangeOptions;

    return (
      <div>
        <Authorized
          scope={['perm:devices:view']}
          yes={
            <div>
              <PageHeader className={styles.pageHeader} ghost={false}>
                <div className={styles.positioning}>
                  <Row justify="space-between" align="middle" gutter={8}>
                    <Col>
                      <BreadcrumbComponent breadcrumbList={routes(config.appName, t)} />
                    </Col>
                    <Col>
                      <Row align="middle" gutter={[8, 8]}>
                        <Col flex="auto">
                          <Select
                            size="small"
                            className="shift-types-selector"
                            style={{ width: 175 }}
                            onChange={this.handleShiftTypeChange}
                            value={shiftType}
                          >
                            <Option key="dayShift" value="dayShift">
                              {t('label_day_shift')}
                            </Option>
                            <Option key="nightShift" value="nightShift">
                              {t('label_night_shift')}
                            </Option>
                            <Option key="all" value="all">
                              {t('label_all_shift')}
                            </Option>
                          </Select>
                        </Col>
                        {shiftType !== 'all' ? (
                          <Col>
                            <DatePicker
                              size="small"
                              className={`date-range-selector ${styles.commonStyle}`}
                              value={moment(from)}
                              onChange={this.handleFromDateChange}
                              disabledDate={(current) => current && current > moment().endOf('day')}
                            />
                          </Col>
                        ) : (
                          <Col>
                            <Select
                              size="small"
                              className={`date-range-selector ${styles.commonStyle}`}
                              onChange={(value) => this.setState({ range: value })}
                              value={range}
                            >
                              {timeRangeOptions.map(({ id, label, value }) => (
                                <Option key={id} value={value}>
                                  {label}
                                </Option>
                              ))}
                            </Select>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </PageHeader>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div>
                    {isLoading ? (
                      <div style={{ textAlign: 'center', marginTop:35 }}>
                        <Spin size="large" />
                      </div>
                    ) : isDashboardAccessible ? (
                      <IframeWrapper iframeSrc={dashboardUrl} height={'650px'} />
                    ) : (
                      <Result status="404" subTitle={t('label_dashboard_error')} />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

OutputPenAssembly.contextType = RoutesContext;
export default withConfigContext(withTranslation()(OutputPenAssembly));
