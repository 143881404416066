/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import axios from 'axios';
import { Button, Result, Space, Table, Tag, Tooltip } from 'antd';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import styles from './styles.module.css';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import EditDEPEnrollment from './components/EditDEPEnrollment';
import AssignDEPProfile from './components/AssignDEPProfile';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

class DEPDevicesTable extends React.Component {
  constructor(props) {
    super(props);
    TimeAgo.addLocale(en);
    this.config = this.props.context;
    this.state = {
      data: [],
      pagination: {},
      loading: false,
      selectedRows: [],
      paramsObj: {},
      syncing: false,
    };
    const { t } = this.props;
    this.columns = [
      {
        title: t('label_serial'),
        dataIndex: 'serial',
        width: 100,
      },
      {
        title: t('label_model'),
        dataIndex: 'model',
        // todo add filtering options
      },
      {
        title: t('label_username'),
        dataIndex: 'username',
        // todo add filtering options
      },
      {
        title: t('label_profile'),
        dataIndex: 'profile',
        // todo add filtering options
      },
      {
        title: t('label_status'),
        dataIndex: 'status',
        key: 'status',

        // eslint-disable-next-line react/display-name
        render: status => {
          let color = '#f9ca24';
          switch (status) {
            case 'pushed':
              color = '#6ab04c';
              break;
            case 'empty':
              color = '#ff7979';
              break;
          }
          return <Tag color={color}>{status}</Tag>;
        },
        // todo add filtering options
      },
      {
        title: t('label_logInRequired'),
        dataIndex: 'needBasicAuth',
        key: 'needBasicAuth',
        width: 50,
        // eslint-disable-next-line react/display-name
        render: needBasicAuth => {
          if (needBasicAuth) {
            return <CheckCircleTwoTone twoToneColor={'#6ab04c'} />;
          }
          return <CloseCircleTwoTone twoToneColor={'#ff7979'} />;
        },
        // todo add filtering options
      },
      {
        title: t('label_agentRequired'),
        dataIndex: 'agentRequired',
        key: 'agentRequired',
        width: 50,
        // eslint-disable-next-line react/display-name
        render: agentRequired => {
          if (agentRequired) {
            return <CheckCircleTwoTone color={'#6ab04c'} />;
          }
          return <CloseCircleTwoTone color={'#ff7979'} />;
        },
        // todo add filtering options
      },
    ];
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({
        selectedRows: selectedRows,
      });
    },
  };

  componentDidMount() {
    if (this.props.apiUrl) {
      this.setPermittedActions();
      this.fetch();
    }
  }

  setPermittedActions = () => {
    const { t } = this.props;
    if (isAuthorized(this.config.scopes, ['perm:ios:dep-add'])) {
      this.columns.push({
        title: t('label_actions'),
        dataIndex: 'serial',
        key: 'actions',
        // eslint-disable-next-line react/display-name
        render: serial => (
          <Space>
            <div>
              <Tooltip placement="top" title={t('label_editEnrollment')}>
                <HtmlComments
                  permission={'/permission/admin/device-mgt/devices/dep/add'}
                />
                <a>
                  <EditDEPEnrollment serial={serial} />
                </a>
              </Tooltip>
            </div>
            <div style={{ marginLeft: 10 }}>
              <Tooltip placement="top" title={t('label_assignDEPProfile')}>
                <HtmlComments
                  permission={'/permission/admin/device-mgt/devices/dep/add'}
                />
                <a>
                  <AssignDEPProfile serial={serial} />
                </a>
              </Tooltip>
            </div>
          </Space>
        ),
        // todo add filtering options
      });
    }
  };

  // Rerender component when parameters change
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.apiUrl !== this.props.apiUrl) {
      this.fetch();
    }
  }

  // fetch data from api
  fetch = (params = {}) => {
    const { t } = this.props;
    this.setState({ loading: true });
    // get current page
    const currentPage = params.hasOwnProperty('page') ? params.page : 1;

    const extraParams = {
      offset: 10 * (currentPage - 1), // calculate the offset
      limit: 10,
    };

    const encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    // send request to the invokerss
    axios
      .get(this.props.apiUrl + encodedExtraParams)
      .then(res => {
        if (res.status === 200) {
          const pagination = { ...this.state.pagination };
          this.setState({
            loading: false,
            data: res.data.data,
            pagination,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_depDevices') }),
          t,
        );

        this.setState({ loading: false });
      });
  };

  syncDevices = () => {
    this.setState({ syncing: true });
    const { t } = this.props;
    const config = this.props.context;

    const apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/ios/v1.0/dep/devices/sync?limit=100';
    // send request to the invokers
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          this.setState({ syncing: false });
          this.fetch();
        }
      })
      .catch(error => {
        handleApiError(error, t('api_syncDEPDevicesError'), t);

        this.setState({ syncing: false });
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  render() {
    const { data, pagination, loading, syncing } = this.state;
    const { t } = this.props;
    return (
      <div className={styles.tableContainer}>
        {data.count > 0 && (
          <div>
            <Button
              loading={syncing}
              type="primary"
              style={{ marginBottom: 10 }}
              onClick={this.syncDevices}
            >
              {!syncing ? t('label_syncDevices') : t('label_syncing')}
            </Button>
            <Table
              columns={this.columns}
              rowKey={record => record.id}
              dataSource={data.devices}
              pagination={{
                ...pagination,
                size: 'small',
                total: data.count,
                disabled: loading,
                showTotal: (total, range) =>
                  t('table_pagination', {
                    range1: range[0],
                    range2: range[1],
                    total: total,
                    data: t('home_menu_devices'),
                  }),
              }}
              loading={loading}
              onChange={this.handleTableChange}
              rowSelection={this.rowSelection}
            />
          </div>
        )}
        {data.count === 0 && (
          // todo Change Result icon #issue592
          <Result
            status="404"
            title={t('noDEPDevices_Txt')}
            subTitle={t('getDEPDevice_Txt')}
            extra={
              <Button
                loading={syncing}
                type="primary"
                onClick={this.syncDevices}
              >
                {!syncing ? t('label_syncDevices') : t('label_syncing')}
              </Button>
            }
          />
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(DEPDevicesTable));
