/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import {
  Col,
  DatePicker,
  Result,
  Row,
  Select,
  Button,
  Space
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { withConfigContext } from '../../../../components/ConfigContext';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import { timeRanges } from '../../../../services/utils/commonUtils';
import InvoiceHistoryTable from '../../components/InvoiceHistoryTable';
import moment from 'moment';
import DownloadOutlined from '@ant-design/icons/es/icons/DownloadOutlined';

const { RangePicker } = DatePicker;
let start = '';
let end = '';
const url = "https://mgt.sg.entgra.net/gdashboard/d-solo/c6a72c8e-4d28-4b49-b746-5e2d7a106d5f/consumption?orgId=1";

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_invoice'),
    },
    {
      breadcrumbName: t('home_menu_invoiceHistory'),
    },
  ];
};

const json = [
  {
    MONTH_ID: 1,
    MONTH_NAME: moment().subtract(11, 'months').format('YYYY') + " - " +
      moment().subtract(11, 'months').format('MMMM'),
    INVOICE_VALUE: 'Rs. 1000.00',
    INVOICE_DATE: moment().subtract(11, 'months').endOf('month').format('YYYY-MM-DD'),
    PAYMENTS: 'Rs. 2,700.00',
    BALANCE_CF: 'Rs. 0.00'
  },
  {
    MONTH_ID: 2,
    MONTH_NAME: moment().subtract(10, 'months').format('YYYY') + " - " +
      moment().subtract(10, 'months').format('MMMM'),
    INVOICE_VALUE: 'Rs. 2,568.55',
    INVOICE_DATE: moment().subtract(10, 'months').endOf('month').format('YYYY-MM-DD'),
    PAYMENTS: 'Rs. 3,100.00',
    BALANCE_CF: 'Rs. 0.00'
  },
  {
    MONTH_ID: 3,
    MONTH_NAME: moment().subtract(9, 'months').format('YYYY') + " - " +
      moment().subtract(9, 'months').format('MMMM'),
    INVOICE_VALUE: 'Rs. 3,248.00',
    INVOICE_DATE: moment().subtract(9, 'months').endOf('month').format('YYYY-MM-DD'),
    PAYMENTS: 'Rs. 3,250.00',
    BALANCE_CF: 'Rs. -2.00'
  },
  {
    MONTH_ID: 4,
    MONTH_NAME: moment().subtract(8, 'months').format('YYYY') + " - " +
      moment().subtract(8, 'months').format('MMMM'),
    INVOICE_VALUE: 'Rs. 3,400.00',
    INVOICE_DATE: moment().subtract(8, 'months').endOf('month').format('YYYY-MM-DD'),
    PAYMENTS: 'Rs. 3,400.00',
    BALANCE_CF: 'Rs. -2.00'
  },
  {
    MONTH_ID: 5,
    MONTH_NAME: moment().subtract(7, 'months').format('YYYY') + " - " +
      moment().subtract(7, 'months').format('MMMM'),
    INVOICE_VALUE: 'Rs. 2,948.00',
    INVOICE_DATE: moment().subtract(7, 'months').endOf('month').format('YYYY-MM-DD'),
    PAYMENTS: 'Rs. 2,950.00',
    BALANCE_CF: 'Rs. -4.00'
  },
  {
    MONTH_ID: 6,
    MONTH_NAME: moment().subtract(6, 'months').format('YYYY') + " - " +
      moment().subtract(6, 'months').format('MMMM'),
    INVOICE_VALUE: 'Rs. 2,848.00',
    INVOICE_DATE: moment().subtract(6, 'months').endOf('month').format('YYYY-MM-DD'),
    PAYMENTS: 'Rs. 2,850.00',
    BALANCE_CF: 'Rs. -6.00'
  },
  {
    MONTH_ID: 7,
    MONTH_NAME: moment().subtract(5, 'months').format('YYYY') + " - " +
      moment().subtract(5, 'months').format('MMMM'),
    INVOICE_VALUE: 'Rs. 4,094.00',
    INVOICE_DATE: moment().subtract(5, 'months').endOf('month').format('YYYY-MM-DD'),
    PAYMENTS: 'Rs. 4,100.00',
    BALANCE_CF: 'Rs. -10.00'
  },
  {
    MONTH_ID: 8,
    MONTH_NAME: moment().subtract(4, 'months').format('YYYY') + " - " +
      moment().subtract(4, 'months').format('MMMM'),
    INVOICE_VALUE: 'Rs. 5,671.00',
    INVOICE_DATE: moment().subtract(4, 'months').endOf('month').format('YYYY-MM-DD'),
    PAYMENTS: 'Rs. 5,671.00',
    BALANCE_CF: 'Rs. -10.00'
  },
  {
    MONTH_ID: 9,
    MONTH_NAME: moment().subtract(3, 'months').format('YYYY') + " - " +
      moment().subtract(3, 'months').format('MMMM'),
    INVOICE_VALUE: 'Rs. 5,830.00',
    INVOICE_DATE: moment().subtract(3, 'months').endOf('month').format('YYYY-MM-DD'),
    PAYMENTS: 'Rs. 5,820.00',
    BALANCE_CF: 'Rs. 0.00'
  },
  {
    MONTH_ID: 10,
    MONTH_NAME: moment().subtract(2, 'months').format('YYYY') + " - " +
      moment().subtract(2, 'months').format('MMMM'),
    INVOICE_VALUE: '14,222.50',
    INVOICE_DATE: moment().subtract(2, 'months').endOf('month').format('YYYY-MM-DD'),
    PAYMENTS: 'Rs. 0.00',
    BALANCE_CF: 'Rs. 0.00'
  },
  {
    MONTH_ID: 11,
    MONTH_NAME: moment().subtract(1, 'months').format('YYYY') + " - " +
      moment().subtract(1, 'months').format('MMMM'),
    INVOICE_VALUE: 'Rs. 12,776.41',
    INVOICE_DATE: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
    PAYMENTS: '18,222.55',
    BALANCE_CF: 'Rs. 0.00'
  },
  {
    MONTH_ID: 12,
    MONTH_NAME: moment().format('YYYY') + " - " +
      moment().format('MMMM'),
    INVOICE_VALUE: '-',
    INVOICE_DATE: '-',
    PAYMENTS: '-',
    BALANCE_CF: '-'
  }
];


class InvoiceHistory extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    const { t } = this.props;
    const currentDate = new Date();
    currentDate.setHours(8, 0, 0, 0);
    this.timeRangeOptions = timeRanges(t);

    end = moment(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ),
    );
    start = moment().startOf('month').add(1, 'month');
    start = start.add(-1, 'year');

    this.state = {
      start: start,
      end: end,
      isDashboardAccessible: false,
      isLoading: true,
      shiftType: 'all',
      from: currentDate.getTime(),
      range: `&from=${start.unix() * 1000}&to=${end.unix() * 1000}`,
    };

  }

  onChangeDateRange = (dates, dateString) => {

    const fromTime = Date.parse(dateString[0]);
    const toTime = Date.parse(dateString[1]);
    this.setState({
      range: `&from=${fromTime}&to=${toTime}`,
      start: dateString[0],
      end: dateString[1]
    },
      () => {

      });
  };


  onClickDownloadReport = () => {
    const pdf = new jsPDF("p", "pt", "a4");
    const columns = [
      "Month",
      "Invoice Value",
      "Invoice Date",
      "Payments",
      "Balance C/F"
    ];
    var rows = [];

    for (let i = 0; i < json.length; i++) {
      var temp = [
        json[i].MONTH_NAME,
        json[i].INVOICE_VALUE,
        json[i].INVOICE_DATE,
        json[i].PAYMENTS,
        json[i].BALANCE_CF
      ];
      rows.push(temp);
    }

    pdf.text(235, 40, "Invoice History");
    pdf.autoTable(columns, rows, {
      startY: 65,
      theme: "grid",
      styles: {
        font: "times",
        halign: "right",
        cellPadding: 3.5,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0]
      },
      headStyles: {
        textColor: [0, 0, 0],
        fontStyle: "normal",
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [166, 204, 247],
        halign: 'center'
      },
      alternateRowStyles: {
        fillColor: [212, 212, 212],
        textColor: [0, 0, 0],
        lineWidth: 0.5,
        lineColor: [0, 0, 0]
      },
      rowStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0]
      },
      columnStyles: {
        0: { halign: 'left' }
      },
      tableLineColor: [0, 0, 0]
    });

    pdf.save("Invoice_History");
  };

  checkDashboardAccess = async (dashboardUrl) => {
    const accessible = true;

    this.setState({ isDashboardAccessible: accessible, isLoading: false });
  };

  componentDidMount() {
    this.context.setCurrentRoute('allInvoiceHistory');
    this.checkDashboardAccess(`${url}&${this.state.range}&panelId=2`);
  }


  render() {
    const config = this.props.context;
    const { Option } = Select;
    const { t } = this.props;
    const { shiftType, from, range, isDashboardAccessible, isLoading } = this.state;
    const dashboardUrl = `${url}&${this.state.range}&panelId=2`;

    //Todo: Back end need to be implemented for range to be used
    const timeRangeOptions = this.timeRangeOptions;

    const handleChange = (value) => {

    };

    return (
      <div>
        <Authorized
          scope={['dm:devices:ops:view']}
          yes={
            <div>
              <PageHeader className={styles.pageHeader} ghost={false}>
                <div className={styles.positioning}>
                  <Row justify="space-between" align="middle" gutter={8}>
                    <Col>
                      <BreadcrumbComponent breadcrumbList={routes(config.appName, t)} />
                    </Col>
                    <Col>
                      <Row align="middle" gutter={[8, 8]}>
                        <Col className={styles.col}>
                          <RangePicker
                            defaultValue={[this.state.start, this.state.end]}
                            showTime
                            onChange={this.onChangeDateRange}
                          />
                        </Col>
                        <Col>
                          <Space wrap>
                            <Select
                              defaultValue='Meter 01'
                              style={{
                                width: 120,
                              }}
                              onChange={handleChange}
                              options={[
                                {
                                  value: 'Meter 01',
                                  label: 'Meter 01',
                                },
                                {
                                  value: 'Meter 02',
                                  label: 'Meter 02',
                                },
                                {
                                  value: 'Meter 03',
                                  label: 'Meter 03',
                                }
                              ]}
                            />
                          </Space>
                        </Col>
                        <Col>
                          <Button
                            style={{ marginRight: 10 }}
                            type="primary"
                            icon={<DownloadOutlined />}
                            onClick={this.onClickDownloadReport}
                          >
                            {t('label_downloadReport')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </PageHeader>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div className={styles.deviceTable}>
                    <InvoiceHistoryTable
                      invoiceHistory={json} />
                  </div>
                </Col>
              </Row>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

InvoiceHistory.contextType = RoutesContext;
export default withConfigContext(withTranslation()(InvoiceHistory));
