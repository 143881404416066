/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Divider, Form, Input, Tooltip } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 10 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
  },
};

const buttonFormItem = {
  wrapperCol: {
    md: { span: 20 },
    lg: { span: 22 },
    xl: { span: 22 },
  },
};

class DEPConfig extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let policyUIPayload = [...this.props.platformConfigData];
    let initialValues = {};

    for (let policyConfig of policyUIPayload) {
      switch (policyConfig.name) {
        case 'agentPackageName':
          initialValues.agentPackageName = policyConfig.value;
          break;
        case 'consumerKey':
          initialValues.consumerKey = policyConfig.value;
          break;
        case 'consumerSecret':
          initialValues.consumerSecret = policyConfig.value;
          break;
        case 'accessToken':
          initialValues.accessToken = policyConfig.value;
          break;
        case 'accessSecret':
          initialValues.accessSecret = policyConfig.value;
          break;
        case 'accessTokenExpiry':
          initialValues.accessTokenExpiry = policyConfig.value;
          break;
      }
    }
    this.formRef.current.setFieldsValue(initialValues);
  }

  onFinish = values => {
    this.props.callback(values);
  };

  onFinishFailed = () => {
    console.log('Finish Failed .......');
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <Form
            {...formItemLayout}
            name="DEPForm"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            scrollToFirstError
            ref={this.formRef}
          >
            <div>
              <Divider>
                <PageHeader
                  className="site-page-header-responsive"
                  subTitle={`${t('label_deviceEnrollmentProgram')} (${t(
                    'label_DEP',
                  )})`}
                />
              </Divider>
            </div>
            <Form.Item
              label={
                <span>
                  {t('label_agentAppId')}&nbsp;
                  <Tooltip title={t('agentAppID_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="agentPackageName"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_consumerKey')}&nbsp;
                  <Tooltip title={t('consumerKey_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="consumerKey"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_consumerKey'),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_consumerSecret')}&nbsp;
                  <Tooltip title={t('consumerSecret_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="consumerSecret"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_consumerSecret'),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_accessToken')}&nbsp;
                  <Tooltip title={t('accessToken_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="accessToken"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_accessToken'),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_accessSecret')}&nbsp;
                  <Tooltip title={t('accessSecret_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="accessSecret"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_accessSecretValue'),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_accessTokenExpiry')}&nbsp;
                  <Tooltip title={t('accessTokenExpiry_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="accessTokenExpiry"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_accessTokenExpiry'),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item {...buttonFormItem}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: 'right' }}
              >
                {t('label_save')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(DEPConfig);
