/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Divider,
  Modal,
  notification,
  Popconfirm,
  Tooltip,
  Typography,
} from 'antd';
import axios from 'axios';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import UserForm from '../UserForm';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import PasswordResettingForm from '../PasswordResstingForm';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import { splitUserDomain } from '../../../../../../../../services/utils/commonHandler';

const { Text } = Typography;

class UserActions extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      isEditModalVisible: false,
    };
  }

  componentDidMount() {
    this.fetchUserRoles(this.props.data.username);
  }

  editFormValues = {};

  openEditModal = () => {
    this.setState({
      isEditModalVisible: true,
    });
    let userDomain = splitUserDomain(this.props.data.username);
    this.editFormValues = {
      firstname: this.props.data.firstname,
      lastname: this.props.data.lastname,
      username: this.props.data.username,
      emailAddress: this.props.data.emailAddress,
      userRoles: this.rolesData,
      userStoreDomain: userDomain[1],
    };
  };

  onCancelHandler = () => {
    this.setState({
      isEditModalVisible: false,
    });
  };

  onConfirmDeleteUser = () => {
    const { t } = this.props;
    let userDomain = splitUserDomain(this.props.data.username);
    axios
      .delete(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/users?username=' +
        userDomain[0] +
        '&domain=' +
        userDomain[1],
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.props.fetchUsers();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_userDeleteMsg'),
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_deleteUserError'), t);
      });
  };

  rolesData = [];

  fetchUserRoles = username => {
    const { t } = this.props;
    let userDomain = splitUserDomain(username);

    let apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/users/roles?username=' +
      userDomain[0] +
      '&domain=' +
      userDomain[1];

    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          this.rolesData = res.data.data.roles;
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_roles') }),
          t,
        );
      });
  };

  fetchUpdatedUser = values => {
    this.onUpdateUser(values);
  };

  onUpdateUser = value => {
    const { t } = this.props;
    let userDomain = splitUserDomain(this.props.data.username);
    const userData = {
      firstname: value.firstname,
      lastname: value.lastname,
      emailAddress: value.emailAddress,
      roles: value.userRoles,
    };
    axios
      .put(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/users?username=' +
        userDomain[0] +
        '&domain=' +
        userDomain[1],
        userData,
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.props.fetchUsers();
          this.setState({
            isEditModalVisible: false,
          });
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_userUpdateMsg'),
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_updateError', { label: t('label_user') }),
          t,
        );
      });
  };

  render() {
    const { t } = this.props;
    const isAdminUser = this.props.data.username === 'admin';
    const isAPIMReservedUser =
      this.props.data.username === 'apim_reserved_user';
    return (
      <div>
        <div
          style={{
            display: isAdminUser || isAPIMReservedUser ? 'none' : 'inline',
          }}
        >
          <HtmlComments
            permission={'/permission/admin/device-mgt/users/manage'}
          />
          {isAuthorized(this.config.scopes, ['perm:users:update']) && (
            <span>
              <Tooltip placement="top" title={t('label_editUser')}>
                <a>
                  <EditOutlined onClick={this.openEditModal} />
                </a>
              </Tooltip>
            </span>
          )}
          {isAuthorized(this.config.scopes, ['perm:users:delete']) && (
            <span>
              <Divider type="vertical" />
              <Tooltip placement="bottom" title={t('label_removeUser')}>
                <Popconfirm
                  placement="top"
                  title={t('label_areYouSure')}
                  onConfirm={this.onConfirmDeleteUser}
                  okText={t('label_ok')}
                  cancelText={t('label_cancel')}
                >
                  <a>
                    <Text type="danger">
                      <DeleteOutlined />
                    </Text>
                  </a>
                </Popconfirm>
              </Tooltip>
            </span>
          )}
        </div>
        <div>
          <Modal
            open={this.state.isEditModalVisible}
            onCancel={this.onCancelHandler}
            footer={null}
            maskClosable={false}
          >
            <HtmlComments
              permission={'/permission/admin/device-mgt/roles/view'}
            />
            <UserForm
              action={'Update'}
              values={this.editFormValues}
              modalVisibility={this.state.isEditModalVisible}
              callback={values => this.fetchUpdatedUser(values)}
            />
            <PasswordResettingForm username={this.props.data.username} />
          </Modal>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(UserActions));
