/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Divider, Radio, Row, Tooltip, Typography } from 'antd';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import CustomAlerts from '../CustomAlerts';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.css';

const { Title, Text } = Typography;

class CustomRadioGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = e => {
    const item = { ...this.props.itemData };
    item.value = e.target.value;
    this.props.callback(item);
  };

  render() {
    const { itemData, t } = this.props;
    const { value } = itemData;
    const radio = itemData.radioGroup.options.map((item, index) => (
      <Radio key={index} value={item.definedValue}>
        {t(`${item.displayingValue}`)}
      </Radio>
    ));
    return (
      <div>
        {itemData.divider && (
          <Divider plain>
            <Text type="secondary">{t(itemData.divider)}</Text>
          </Divider>
        )}
        {itemData.subTitle && <Title level={4}> {t(itemData.subTitle)} </Title>}
        <CustomAlerts notifications={itemData.notifications} />
        <div className={styles.radioGroupContainer}>
          <Row>
            <span>
              {t(`${itemData.label}`)}&nbsp;
              {itemData.tooltip && (
                <Tooltip
                  title={
                    <div>
                      <div>{t(`${itemData.tooltip}`)}</div>
                      <div style={{ textAlign: 'right' }}>
                        {itemData.docLink && (
                          <a
                            onClick={() => {
                              window.open(itemData.docLink, '_blank');
                            }}
                          >
                            {' '}
                            {t('label_SeeMoreDetails')}
                          </a>
                        )}
                      </div>
                    </div>
                  }
                  placement="right"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </span>
          </Row>
          <Row>
            <Radio.Group
              disabled={this.props.disabled}
              onChange={this.onChange}
              defaultValue={value}
            >
              {radio}
            </Radio.Group>
          </Row>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(CustomRadioGroup));
