/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import axios from 'axios';
import {
  Alert,
  Button,
  Collapse,
  List,
  message,
  Modal,
  notification,
  Table,
} from 'antd';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import AddUser from './components/AddUser';
import UserActions from './components/UserActions';
import ExternalDevicesModal from './components/ExternalDevicesModal';
import DeviceTable from '../../../../components/DevicesTable';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import styles from './styles.module.css';
import Filter from '../../../../components/Filter';
import { SearchOutlined } from '@ant-design/icons';
import UserBulkActionBar from '../UsersTable/components/UserBulkActionBar';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import { splitUserDomain } from '../../../../../../services/utils/commonHandler';

const ButtonGroup = Button.Group;
const { Panel } = Collapse;

const emptyDevicePage = t => {
  return {
    title: t('deviceNotEnroll_titleTxt'),
    subTitle: t('deviceNotEnroll_subtitleTxt'),
    buttonName: t('deviceNotEnroll_buttonTxt'),
    url: '/devices/enroll',
  };
};

let apiUrl;

class UsersTable extends React.Component {
  constructor(props) {
    super(props);
    TimeAgo.addLocale(en);
    const { t } = this.props;
    this.config = this.props.context;
    this.state = {
      data: [],
      pagination: {},
      loading: false,
      rolesModalVisible: false,
      devicesModalVisible: false,
      rolesData: [],
      user: '',
      filters: {},
      loadSearchBar: true,
      selectedRows: [],
      filterFields: [
        {
          name: 'name',
          type: 'input',
          placeholder: t('label_deviceName'),
          label: t('label_name'),
        },
        {
          name: 'status',
          placeholder: t('label_status'),
          type: 'select',
          values: ['All'],
          label: t('label_status'),
        },
        {
          name: 'serialNumber',
          type: 'input',
          placeholder: t('label_serialNumber'),
          label: t('label_serialNumber'),
        },
        {
          name: 'type',
          placeholder: t('label_deviceType'),
          type: 'select',
          values: ['All'],
          label: t('label_type'),
        },
        {
          name: 'ownership',
          placeholder: t('label_ownership'),
          type: 'select',
          values: ['All'],
          label: t('label_ownership'),
        },
      ],
    };

    this.columns = [
      {
        title: t('label_username'),
        dataIndex: 'username',
        key: 'username',
      },
      {
        title: t('label_firstName'),
        dataIndex: 'firstname',
        key: 'firstname',
        width: 200,
      },
      {
        title: t('label_lastName'),
        dataIndex: 'lastname',
        key: 'lastname',
      },
      {
        title: t('label_email'),
        dataIndex: 'emailAddress',
        key: 'emailAddress',
      },
      {
        dataIndex: 'username',
        key: 'roles',
        render: username => (
          <ButtonGroup>
            <Button
              type="link"
              size="small"
              onClick={() => this.fetchRoles(username)}
            >
              {t('home_menu_roles')}
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => this.openDeviceListModal(username)}
            >
              <HtmlComments
                permission={
                  '/permission/admin/device-mgt/devices/owning-device/view'
                }
              />
              {t('home_menu_devices')}
            </Button>
            <ExternalDevicesModal user={username} />
          </ButtonGroup>
        ),
      },
    ];
  }

  username = '';
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({
        selectedRows: selectedRows,
      });
    },
  };

  componentDidMount() {
    this.setPermittedActions();
    this.fetchUsers();
  }

  setPermittedActions = () => {
    const { t } = this.props;
    if (
      isAuthorized(this.config.scopes, [
        ['perm:users:update', 'perm:users:delete'],
      ])
    ) {
      this.columns.splice(4, 0, {
        title: t('label_actions'),
        dataIndex: 'id',
        key: 'action',
        render: (id, row) => (
          <div>
            <HtmlComments
              permission={'/permission/admin/device-mgt/users/manage'}
            />
            <UserActions data={row} fetchUsers={this.fetchUsers} />
          </div>
        ),
      });
    }
  };

  // Rerender component when parameters change
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)
    ) {
      const pagination = { ...this.state.pagination };
      pagination.page = 1;
      pagination.current = 1;
      this.setState({ pagination });
      this.fetchUsers(true, {}, this.props.filters);
    }
  }

  // fetch data from api
  fetchUsers = (params = {}, filters = {}) => {
    const config = this.props.context;
    this.setState({ loading: true });
    const { t } = this.props;
    // get current page
    const currentPage = params.hasOwnProperty('page') ? params.page : 1;

    let limit = 10;
    if (params.results) {
      limit = params.results;
    }

    const extraParams = {
      offset: 10 * (currentPage - 1), // calculate the offset
      limit: limit,
      ...this.props.filters,
    };

    const encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/users/search?' +
      encodedExtraParams;

    // send request to the invoker
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const pagination = { ...this.state.pagination };
          this.setState({
            loading: false,
            data: res.data.data,
            pagination,
          });
        }
      })
      .catch(error => {
        if (error.hasOwnProperty('response') && error.response.status === 401) {
          // todo display a popop with error
          message.error(t('api_notLoggedInError'));
          window.location.href =
            window.location.origin + `/${config.appName}/login`;
        } else {
          notification.error({
            message: t('api_errorMsg'),
            duration: 0,
            description: t('api_loadError', { label: t('home_menu_users') }),
          });
        }

        this.setState({ loading: false });
      });
  };

  inviteUsers = enrollmentTypes => {
    const { t } = this.props;
    const usernameList = this.state.selectedRows.map(obj => obj.username);
    const payload = {
      usernames: usernameList,
      deviceEnrollmentTypes: enrollmentTypes,
    };
    // send request to the invoker
    axios
      .post(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/users/send-invitation',
        JSON.stringify(payload),
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          notification.success({
            message: 'Done',
            duration: 4,
            description: t('api_invitationSentMsg'),
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_inviteUserError'), t);
        this.setState({ loading: false });
      });
  };

  fetchRoles = username => {
    const config = this.props.context;
    const { t } = this.props;
    this.setState({
      rolesModalVisible: true,
      user: username,
    });
    let userDomain = splitUserDomain(username);

    apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/users/roles?username=' +
      userDomain[0] +
      '&domain=' +
      userDomain[1];

    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            rolesData: res.data.data.roles,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_users') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetchUsers({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  handleOk = e => {
    this.setState({
      rolesModalVisible: false,
      devicesModalVisible: false,
    });
  };

  handleCancel = e => {
    this.setState({
      rolesModalVisible: false,
      devicesModalVisible: false,
    });
  };

  openDeviceListModal = username => {
    this.username = username;
    this.setState({
      devicesModalVisible: true,
    });
    if (isAuthorized(this.config.scopes, ['perm:devices:view'])) {
      this.getDeviceFilters();
    }
  };

  getDeviceFilters = () => {
    const config = this.props.context;
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
        config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/devices/filters',
      )
      .then(res => {
        if (res.status === 200) {
          const deviceFilters = res.data.data;
          const searchFields = [...this.state.filterFields];
          searchFields.map(searchField => {
            if (searchField.name === 'status') {
              searchField.values.push(...deviceFilters.statuses);
            }
            if (searchField.name === 'type') {
              searchField.values.push(...deviceFilters.deviceTypes);
            }
            if (searchField.name === 'ownership') {
              searchField.values.push(...deviceFilters.ownerships);
            }
          });
          this.setState({ filterFields: searchFields });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_deviceFilterRetrieveError'), t);
      });
  };

  fetchFilterData = (params, values) => {
    this.setState({ filters: values });
  };

  fetchSearchBarStatus = status => {
    this.setState({ loadSearchBar: status });
  };

  render() {
    const {
      data,
      pagination,
      loading,
      loadSearchBar,
      selectedRows,
    } = this.state;
    const config = this.props.context;
    const { t } = this.props;
    const apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      `/devices?user=${this.username}&`;

    return (
      <div className={styles.tableContainer}>
        {!isAuthorized(this.config.scopes, [
          'perm:users:update',
          'perm:users:delete',
        ]) && (
            <div>
              <HtmlComments
                permission={'/permission/admin/device-mgt/users/manage'}
              />
              <Alert
                message={t('noPerm_manageUsers')}
                banner
                style={{ marginBottom: 15 }}
              />
            </div>
          )}
        <div>
          <AddUser fetchUsers={this.fetchUsers} />
        </div>
        <div style={{ backgroundColor: '#ffffff', borderRadius: 5 }}>
          <UserBulkActionBar
            selectedRows={selectedRows}
            inviteUsers={this.inviteUsers}
          />
          <Table
            columns={this.columns}
            rowKey={record => record.username}
            dataSource={data.users}
            pagination={{
              ...pagination,
              size: 'small',
              total: data.count,
              disabled: loading,
              showTotal: (total, range) =>
                t('table_pagination', {
                  range1: range[0],
                  range2: range[1],
                  total: total,
                  data: t('home_menu_users'),
                }),
            }}
            loading={loading}
            onChange={this.handleTableChange}
            rowSelection={this.rowSelection}
          />
        </div>
        <div>
          <Modal
            title={t('title_roles')}
            width="900px"
            open={this.state.rolesModalVisible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            maskClosable={false}
          >
            <div>
              <List
                size="small"
                bordered
                dataSource={this.state.rolesData}
                renderItem={item => <List.Item>{item}</List.Item>}
              />
            </div>
          </Modal>
        </div>

        <div>
          <Modal
            title={t('title_userDevice', { user: this.username })}
            width="80%"
            open={this.state.devicesModalVisible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            maskClosable={false}
          >
            <div>
              {!isAuthorized(this.config.scopes, ['perm:devices:view']) && (
                <div>
                  <HtmlComments
                    permission={
                      '/permission/admin/device-mgt/devices/owning-device/view'
                    }
                  />
                  <Alert
                    message={t('noPerm_filters')}
                    banner
                    style={{ marginBottom: 15 }}
                  />
                </div>
              )}
              {loadSearchBar &&
                isAuthorized(this.config.scopes, ['perm:devices:view']) && (
                  <div style={{ marginBottom: 10 }}>
                    <HtmlComments
                      permission={
                        '/permission/admin/device-mgt/devices/owning-device/view'
                      }
                    />
                    <Collapse
                      bordered={false}
                      expandIcon={() => <SearchOutlined />}
                      className={styles.searchCollapse}
                    >
                      <Panel
                        header={t('form_searchDevices')}
                        key="1"
                        style={{ borderColor: '#fff' }}
                      >
                        <Filter
                          filters={this.state.filterFields}
                          callback={(params, values) =>
                            this.fetchFilterData({}, values)
                          }
                        />
                      </Panel>
                    </Collapse>
                  </div>
                )}
              <DeviceTable
                apiUrl={apiUrl}
                filters={this.state.filters}
                showAdvancedSearch={false}
                username={this.username}
                searchBarCallback={status => this.fetchSearchBarStatus(status)}
                emptyDevicePage={emptyDevicePage(t)}
              />
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(UsersTable));
