/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Checkbox, Form, Input, Select, Alert } from 'antd';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const buttonItemLayout = {
  wrapperCol: { span: 24, offset: 19 },
};

const checkboxFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const skipSetupItems = {
  AppleID: 'Apple ID',
  Biometric: 'Biometric',
  Diagnostics: 'Diagnostics',
  DisplayTone: 'Display Tone',
  Location: 'Location',
  Passcode: 'Passcode',
  Payment: 'Payment',
  Privacy: 'Privacy',
  Restore: 'Restore',
  Siri: 'SIRI',
  TOS: 'Terms of Service',
  Zoom: 'Zoom',
  Android: 'Move from Android',
  WatchMigration: 'Watch Migration',
  HomeButtonSensitivity: 'Home Button Sensitivity',
};

const setupItemKeys = Object.keys(skipSetupItems);

class DEPProfileForm extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      isSupervised: false,
      warningMessage: t('MDMRemovable_Txt'),
    };
    this.skipSetupOptions = [];
    for (let setupKey of Object.keys(skipSetupItems)) {
      this.skipSetupOptions.push(
        <Option key={setupKey}>{t(`label_SetupItems_${setupKey}`)}</Option>,
      );
    }
  }

  componentDidMount() {
    if (this.props.values) {
      this.formRef.current.setFieldsValue(this.props.values);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let params = new URL(window.location).searchParams;
    let name = params.get('add-dep-profile');
    if (JSON.parse(name) && prevState === this.state) {
      this.formRef.current.resetFields();
    }
  }

  onFinish = values => {
    if (!this.state.isSupervised) {
      // eslint-disable-next-line camelcase
      values.is_mdm_removable = true;
    }
    this.props.callback(values);
  };

  handleSelectAll = value => {
    if (value && value.length && value.includes('all')) {
      if (value.length === setupItemKeys.length + 1) {
        return [];
      }
      return [...setupItemKeys];
    }
    return value;
  };

  onIsSupervisedChanged = e => {
    const { t } = this.props;
    if (e.target.checked) {
      this.setState({
        warningMessage: t('MDMRemovable_infoTxt'),
        isSupervised: e.target.checked,
      });
    } else {
      this.setState({
        warningMessage: t('MDMRemovable_Txt'),
        isSupervised: e.target.checked,
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Form
        {...formItemLayout}
        name="DEPProfileForm"
        onFinish={this.onFinish}
        ref={this.formRef}
      >
        <Form.Item
          label={t('label_enterProfileName')}
          name="profile_name"
          rules={[
            {
              required: true,
              message: t('form_profileNameRequiredMsg'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('label_enterProfileURL')}
          name="url"
          rules={[
            {
              required: true,
              message: t('form_profileURLRequiredMsg'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('label_department')} name="department">
          <Input />
        </Form.Item>
        <Form.Item
          label={t('label_supportPhoneNumber')}
          name="support_phone_number"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('label_supportEmailAddress')}
          name="support_email_address"
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('label_anchorCertificates')} name="anchor_certs">
          <Input />
        </Form.Item>
        <Form.Item
          label={t('label_skipSetupItems')}
          name="skip_setup_items"
          getValueFromEvent={this.handleSelectAll}
        >
          <Select
            mode="multiple"
            placeholder={t('form_selectSetupItemType')}
            maxTagCount={5}
            onChange={this.handleSelectAll}
          >
            <Option key="all" value="all">
              ---{t('title_selectAll')}---
            </Option>
            {this.skipSetupOptions}
          </Select>
        </Form.Item>
        <Form.Item
          name="is_supervised"
          {...checkboxFormItemLayout}
          initialValue={this.state.isSupervised}
          onChange={this.onIsSupervisedChanged}
          valuePropName="checked"
        >
          <Checkbox>{t('label_supervisionRequired')}</Checkbox>
        </Form.Item>
        <Form.Item
          initialValue={true}
          name="is_mdm_removable"
          {...checkboxFormItemLayout}
          valuePropName="checked"
        >
          {this.state.isSupervised ? (
            <Checkbox>
              {t('label_MDMRemovable')}{' '}
              {<Alert message={t('MDMRemovable_infoTxt')} type={'info'} />}
            </Checkbox>
          ) : (
            <Alert message={t('MDMMandatory_Txt')} type={'warning'} />
          )}
        </Form.Item>
        <Form.Item
          name="is_mandatory"
          {...checkboxFormItemLayout}
          initialValue={false}
          valuePropName="checked"
        >
          <Checkbox>{t('label_mandatory')}</Checkbox>
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
          <Button type="primary" htmlType="submit">
            {t('label_save')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default withTranslation()(DEPProfileForm);
