/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Collapse, notification } from 'antd';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import DeviceTable from '../../../../../../components/DevicesTable';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import Filter from '../../../../../../components/Filter';
import { SearchOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import { withTranslation } from 'react-i18next';

const { Panel } = Collapse;

const emptyDevicePage = t => {
  return {
    title: t('noAssignDevicesToGroup_Txt'),
    subTitle: t('assignEnrolledDevicesToGroup_Txt'),
    buttonName: t('label_AssignDevicesToGroup'),
    url: '/devices',
  };
};

let apiUrl;

let setSearchFields = t => {
  return [
    {
      name: 'name',
      type: 'input',
      placeholder: t('label_deviceName'),
      label: t('label_name'),
    },
    {
      name: 'user',
      type: 'input',
      placeholder: t('label_owner'),
      label: t('home_menu_user'),
    },
    {
      name: 'serialNumber',
      type: 'input',
      placeholder: t('label_serialNumber'),
      label: t('label_serialNumber'),
    },
    {
      name: 'status',
      placeholder: t('label_status'),
      type: 'select',
      values: ['All'],
      label: t('label_status'),
    },
    {
      name: 'type',
      placeholder: t('label_deviceType'),
      type: 'select',
      values: ['All'],
      label: t('label_type'),
    },
    {
      name: 'ownership',
      placeholder: t('label_ownership'),
      type: 'select',
      values: ['All'],
      label: t('label_ownership'),
    },
  ];
};

class AssignDevices extends React.Component {
  constructor(props) {
    super(props);
    TimeAgo.addLocale(en);
    const config = this.props.context;
    this.state = {
      data: [],
      pagination: {},
      loading: false,
      visible: false,
      apiUrl:
        window.location.origin +
        config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/devices?',
      filterFields: [],
      filters: {},
      loadSearchBar: true,
      deviceList: [],
    };
  }

  componentDidMount() {
    this.getDeviceFilters();
    this.fetchGroupDevices();
  }

  // fetch data from api
  fetchGroupDevices = (params = {}, filters = {}) => {
    const config = this.props.context;

    // get current page
    apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/devices?';

    this.setState({ apiUrl: apiUrl });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetchGroupDevices({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  getDeviceFilters = () => {
    const config = this.props.context;
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
        config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/devices/filters',
      )
      .then(res => {
        if (res.status === 200) {
          const deviceFilters = res.data.data;
          let searchFields = setSearchFields(t);
          searchFields.map(searchField => {
            if (searchField.name === 'status') {
              searchField.values.push(...deviceFilters.statuses);
            }
            if (searchField.name === 'type') {
              searchField.values.push(...deviceFilters.deviceTypes);
            }
            if (searchField.name === 'ownership') {
              searchField.values.push(...deviceFilters.ownerships);
            }
          });
          this.setState({ filterFields: searchFields });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_deviceFilterRetrieveError'), t);
      });
  };

  fetchFilterData = (params, values) => {
    this.setState({ filters: values });
  };

  fetchSearchBarStatus = status => {
    this.setState({ loadSearchBar: status });
  };

  deviceListCallback = deviceList => {
    this.setState({ deviceList: deviceList });
  };

  onAssignDevices = () => {
    const { t } = this.props;
    if (this.state.deviceList.length > 0) {
      this.addDevicesToGroup([this.props.groupData.id], this.state.deviceList);
    } else {
      notification.error({
        message: t('noDeviceSelected_Txt'),
        duration: 4,
        description: t('deviceAssign_infoTxt'),
      });
    }
  };

  addDevicesToGroup = (groupId, deviceList) => {
    const config = this.props.context;
    const { t } = this.props;
    this.setState({ loading: true });
    let apiUrl;
    let deviceData;
    if (!groupId[0]) {
      apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/groups/id/' +
        groupId +
        '/devices/add';
      deviceData = deviceList.map(obj => ({
        id: obj.deviceIdentifier,
        type: obj.type,
      }));
    } else {
      apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/groups/id/' +
        groupId[0] +
        '/devices/add';
      deviceData = deviceList.map(obj => ({
        id: obj.deviceIdentifier,
        type: obj.type,
      }));
    }
    // send request to the invoker
    axios
      .post(apiUrl, deviceData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({ loading: false, visible: false });
          this.props.refreshDeviceCallback(true);
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_addDeviceGroupMsg'),
          });
        }
      })
      .catch(error => {
        this.setState({ loading: false });
        handleApiError(error, t('api_addDeviceGroupError'), t);
      });
  };

  render() {
    const { apiUrl, loadSearchBar, loading } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div style={{ alignItems: 'center' }}>
          {loadSearchBar && (
            <div style={{ marginBottom: 10 }}>
              <Collapse
                bordered={false}
                expandIcon={() => <SearchOutlined />}
                className={styles.searchCollapse}
              >
                <Panel
                  header={t('form_searchDevices')}
                  key="1"
                  style={{ borderColor: '#fff' }}
                >
                  <Filter
                    filters={this.state.filterFields}
                    callback={(params, values) =>
                      this.fetchFilterData({}, values)
                    }
                  />
                </Panel>
              </Collapse>
            </div>
          )}
          <DeviceTable
            filters={this.state.filters}
            showAdvancedSearch={false}
            apiUrl={apiUrl}
            searchBarCallback={status => this.fetchSearchBarStatus(status)}
            emptyDevicePage={emptyDevicePage}
            deviceListCallback={this.deviceListCallback}
          />
          <div style={{ float: 'right', marginTop: 10 }}>
            <Button
              type={'primary'}
              onClick={this.onAssignDevices}
              loading={loading}
            >
              {t('label_assignDevices')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(AssignDevices));
