/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import axios from 'axios';
import { Alert, Button, Table, Tooltip } from 'antd';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import GroupActions from './components/GroupActions';
import AddGroup from './components/AddGroup';
import GroupDevicesModal from './components/GroupDevicesModal';
import styles from './styles.module.css';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import { Link } from 'react-router-dom';
import { EnvironmentOutlined, SettingOutlined } from '@ant-design/icons';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import DeviceOperationsDrawer from './components/DeviceOperationsDrawer';

let apiUrl;

class GroupsTable extends React.Component {
  ownerships;

  constructor(props) {
    super(props);
    TimeAgo.addLocale(en);
    this.config = this.props.context;
    this.state = {
      data: [],
      pagination: {},
      loading: false,
      selectedRows: [],
      refreshTable: false,
      openAssignDevicesModal: false,
      isOperationsVisible: false,
      groupIDsList: [],
      isRowsSelected: false,
    };
    const { t } = this.props;
    this.columns = [
      {
        title: t('label_groupName'),
        dataIndex: 'name',
        width: 100,
      },
      {
        title: t('label_owner'),
        dataIndex: 'owner',
        key: 'owner',
        // render: enrolmentInfo => enrolmentInfo.owner
        // todo add filtering options
      },
      {
        title: t('label_description'),
        dataIndex: 'description',
        key: 'description',
        render: (id, row) => {
          if (row.owner === 'wso2.system.user') {
            return t('default_system_group_description', { group: row.name });
          }
          return row.description;
        },
        //  render: enrolmentInfo => enrolmentInfo.ownership
        // todo add filtering options
      },
    ];
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let groupList = [];
      selectedRows.map(obj => {
        groupList.push(obj.id);
      });
      this.setState({
        selectedRows: selectedRows,
        groupIDsList: groupList,
      });
      if (selectedRows.length > 0) {
        this.setState({
          isRowsSelected: true,
        });
      } else {
        this.setState({
          isRowsSelected: false,
        });
      }
    },
  };

  componentDidMount() {
    this.setPermittedActions();
    this.fetchGroups();
  }

  refreshDeviceCallback = status => {
    this.setState({ refreshTable: status });
  };

  setPermittedActions = () => {
    const { t } = this.props;
    if (
      isAuthorized(this.config.scopes, [
        ['perm:groups:update', 'perm:groups:share', 'perm:groups:remove'],
      ])
    ) {
      this.columns.push({
        title: t('label_actions'),
        dataIndex: 'id',
        key: 'action',
        width: 300,
        render: (id, row) => (
          <span>
            <HtmlComments
              permission={
                '/permission/admin/device-mgt/groups/update OR\n' +
                '/permission/admin/device-mgt/groups/share OR\n' +
                '/permission/admin/device-mgt/groups/remove'
              }
            />
            <GroupActions
              data={row}
              fetchGroups={this.fetchGroups}
              selectedRows={this.state.selectedRows}
            />
          </span>
        ),
      });
    }
    if (isAuthorized(this.config.scopes, ['perm:devices:view'])) {
      this.columns.push(
        {
          dataIndex: 'id',
          key: 'details',
          render: (id, row) => (
            <div>
              <HtmlComments
                permission={
                  '/permission/admin/device-mgt/devices/owning-device/view'
                }
              />
              <GroupDevicesModal
                groupData={row}
                refreshTable={this.state.refreshTable}
                refreshDeviceCallback={status =>
                  this.refreshDeviceCallback(status)
                }
              />
            </div>
          ),
        },
        {
          title: '',
          dataIndex: 'id',
          key: 'actions',
          render: (data, row) => {
            const { id } = row;
            return (
              <div>
                <HtmlComments
                  permission={
                    '/permission/admin/device-mgt/devices/owning-device/view'
                  }
                />
                <Link to={`/${this.config.appName}/geo/history/${id}`}>
                  <EnvironmentOutlined /> {t('label_devicesHistory')}
                </Link>
              </div>
            );
          },
        },
      );
    }
  };

  // Rerender component when parameters change
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)
    ) {
      const pagination = { ...this.state.pagination };
      pagination.page = 1;
      pagination.current = 1;
      this.setState({ pagination });
      this.fetchGroups(true, {}, this.props.filters);
    }
  }

  // fetch data from api
  fetchGroups = (params = {}, filters = {}) => {
    const config = this.props.context;
    const { t } = this.props;
    this.setState({ loading: true });

    // get current page
    const currentPage = params.hasOwnProperty('page') ? params.page : 1;

    let limit = 10;
    if (params.results) {
      limit = params.results;
    }

    const extraParams = {
      offset: 10 * (currentPage - 1), // calculate the offset
      limit: limit,
      ...this.props.filters,
    };

    const encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    if (isAuthorized(config.scopes, ['perm:admin-groups:view'])) {
      apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/admin/groups?' +
        encodedExtraParams;
    } else if (isAuthorized(config.scopes, ['perm:groups:groups'])) {
      apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/groups?' +
        encodedExtraParams;
    }

    // send request to the invokerss
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const pagination = { ...this.state.pagination };
          this.setState({
            loading: false,
            data: res.data.data,
            pagination,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_deviceGroups') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetchGroups({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  onAddOperations = () => {
    this.ownerships = ['COPE', 'BYOD'];
    this.setState({
      isOperationsVisible: true,
    });
  };

  onOperationsClose = () => {
    this.setState({
      isOperationsVisible: false,
    });
  };

  render() {
    const { data, pagination, loading } = this.state;
    const { t } = this.props;

    return (
      <div className={styles.tableContainer}>
        {!isAuthorized(this.config.scopes, [
          'perm:groups:update',
          'perm:groups:share',
          'perm:groups:remove',
        ]) && (
            <div>
              <HtmlComments
                permission={
                  '/permission/admin/device-mgt/groups/update OR\n' +
                  '/permission/admin/device-mgt/groups/share OR\n' +
                  '/permission/admin/device-mgt/groups/remove'
                }
              />
              <Alert
                message={t('noPerm_manageGroups')}
                banner
                style={{ marginBottom: 15 }}
              />
            </div>
          )}
        <div style={{ background: '#f0f2f5' }}>
          <HtmlComments
            permission={
              '/permission/admin/device-mgt/admin/groups/add\n' +
              '/permission/admin/device-mgt/groups/add'
            }
          />
          <AddGroup
            fetchGroups={this.fetchGroups}
            style={{ marginBottom: '10px' }}
          />
        </div>
        <div style={{ backgroundColor: '#ffffff', borderRadius: 5 }}>
          <Tooltip
            placement="bottom"
            title={
              !this.state.isRowsSelected
                ? t('label_select_groups-toAdd_operations')
                : t('label_add _operation')
            }
          >
            <Button
              type="link"
              shape="circle"
              icon={<SettingOutlined />}
              size={'default'}
              onClick={this.onAddOperations}
              disabled={!this.state.isRowsSelected}
            />
          </Tooltip>
          <Table
            columns={this.columns}
            rowKey={record => record.id}
            dataSource={data.deviceGroups}
            pagination={{
              ...pagination,
              size: 'small',
              total: data.count,
              disabled: loading,
              showTotal: (total, range) =>
                t('table_pagination', {
                  range1: range[0],
                  range2: range[1],
                  total: total,
                  data: t('home_menu_groups'),
                }),
            }}
            loading={loading}
            onChange={this.handleTableChange}
            rowSelection={this.rowSelection}
          />
        </div>
        {this.state.isOperationsVisible && (
          <DeviceOperationsDrawer
            groupIDList={this.state.groupIDsList}
            onOperationsClose={this.onOperationsClose}
          />
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(GroupsTable));
