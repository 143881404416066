/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Divider, Row, Tooltip, Typography } from 'antd';
import CustomAlerts from '../CustomAlerts';
import { Form } from '@ant-design/compatible';
import { withTranslation } from 'react-i18next';
const { Title, Text } = Typography;

class CustomCheckbox extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = e => {
    const item = { ...this.props.itemData };
    item.value = e.target.checked;
    this.props.callback(item);
  };

  render() {
    const { itemData, defaultValue, t } = this.props;
    const { value } = itemData;
    let defaultCheckedValue = defaultValue ? defaultValue : value;
    return (
      <div>
        <div>
          {itemData.divider && (
            <Divider plain>
              <Text type="secondary">{t(itemData.divider)}</Text>
            </Divider>
          )}
          {itemData.subTitle && (
            <Title level={4}> {t(itemData.subTitle)} </Title>
          )}
          <CustomAlerts notifications={itemData.notifications} />
          <div style={{ margin: 5 }}>
            <Row>
              <Form.Item>
                <Checkbox
                  checked={defaultCheckedValue.toString() === 'true'}
                  disabled={this.props.disabled}
                  onChange={this.onChange}
                >
                  <span>
                    {t(`${itemData.label}`)} &nbsp;
                    {itemData.tooltip && (
                      <Tooltip
                        title={
                          <div>
                            <div>{t(`${itemData.tooltip}`)}</div>
                            <div style={{ textAlign: 'right' }}>
                              {itemData.docLink && (
                                <a
                                  onClick={() => {
                                    window.open(itemData.docLink, '_blank');
                                  }}
                                >
                                  {' '}
                                  {t('label_SeeMoreDetails')}
                                </a>
                              )}
                            </div>
                          </div>
                        }
                        placement="right"
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    )}
                  </span>
                </Checkbox>
              </Form.Item>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(withTranslation()(CustomCheckbox));
