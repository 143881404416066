/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
/* eslint-disable react/no-unescaped-entities */
// HelpDocumentation.js

import React from 'react';

// Help documentation content
const helpContent = (
  <div>
    <h3>Flow diagram editor for organizing devices</h3>

    <h4>Viewport Display:</h4>
    <p>The device organization flow is shown in the viewport area.</p>

    <h4>Left Side Panel:</h4>
    <ol>
      <li>
        <strong>Fit View:</strong> Adjust the viewport to fit the entire flow
        diagram.
      </li>
      <li>
        <strong>Toggle Interactivity:</strong> Toggle interactivity mode for the
        flow diagram / lock or unlock the diagram.
      </li>
      <li>
        <strong>Zoom In:</strong> Zoom in to get a closer view of the diagram /
        mouse scroll up.
      </li>
      <li>
        <strong>Zoom Out:</strong> Zoom out to see more of the diagram at once /
        mouse scroll down.
      </li>
      <li>
        <strong>Add Device:</strong> This button is not shown if user does not
        have add/delete permissions. Shown only in edit mode. Add a new device
        to the diagram.
      </li>
      <li>
        <strong>Download Flow:</strong> Download the flow diagram.
      </li>
      <li>
        <strong>Detail / Summary View:</strong> Switch between detail and
        summary views of the diagram.
      </li>
    </ol>

    <h4>Right Side Panel:</h4>
    <ol>
      <li>
        <strong>Read / Edit Mode:</strong> This button is not shown if user does
        not have add permissions. Only default read mode is enabled if user does
        not have add/delete permissions. Auto refresh happens in read mode. Auto
        refresh stops in edit mode. Adding new devices, deleting devices and
        connecting devices can only be done in the edit mode.
      </li>
      <li>
        <strong>Manual Refresh:</strong> Refresh the flow according to search
        criterias.
      </li>
    </ol>
    <h4>Adding a Device:</h4>
    <p>
      This menu is not shown if user does not have add permissions. From the
      "Add Device" option, a new node/device can be added to the viewport.
    </p>

    <h4>Context Menu:</h4>
    <p>
      This menu is not shown if user does not have delete permissions.Only
      available in the the edit mode. A context menu with a "Delete" option pops
      up when right-clicked on the node/device body.
    </p>

    <h4>Edge Creation:</h4>
    <p>
      After creating at least two nodes/devices, an edge can be created by
      dragging from the source node handle to the target node handle and
      dropping it.
    </p>

    <h4>Edge Update:</h4>
    <p>
      An already created edge can be detached and dragged and dropped onto
      another node.
    </p>

    <h4>Confirmation Modals:</h4>
    <p>
      Actions such as adding a device, deleting, edge creation, and edge update
      trigger confirmation modals to get user confirmation.
    </p>

    <h4>Device Name Navigation:</h4>
    <p>
      For devices which are meters, device names act as a navigation link to
      relevant profile readings.
    </p>
  </div>
);

export default helpContent;
