/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Result } from 'antd';
import { withTranslation } from 'react-i18next';
import BillingTable from './components/BillingTable';
import AddBill from './components/AddBill';
import axios from 'axios';
import HtmlComments from '../../../../../../../../../../components/ReactHtmlComments/HtmlComments';
import Authorized from '../../../../../../../../../../components/Authorized';
import { handleApiError } from '../../../../../../../../../../services/utils/errorHandler';
import { withConfigContext } from '../../../../../../../../../../components/ConfigContext';

class Billing extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      addBillVisibility: false,
      metaData: [],
      loading: false,
    };
    this.handleAdd = this.handleAdd.bind(this);
    this.loadMetaData = this.loadMetaData.bind(this);
    this.closeAddBillModal = this.closeAddBillModal.bind(this);
  }

  componentDidMount() {
    this.loadMetaData();
  }

  loadMetaData() {
    this.setState({ loading: true });
    this.closeAddBillModal();
    const { t } = this.props;
    let metaKey = 'PER_DEVICE_COST';
    axios
      .get(
        window.location.origin +
          this.props.config.serverConfig.invoker.uri +
          '/device-mgt/v1.0/metadata/' +
          metaKey,
      )
      .then(res => {
        if (res.status === 200) {
          if (res.data.data && res.data.data.metaValue) {
            let data = JSON.parse(res.data.data.metaValue);
            this.setState({
              metaData: data,
            });
          }
          this.setState({
            loading: false,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_Billing') }),
          t,
        );
        this.setState({ loading: false });
      });
  }

  closeAddBillModal() {
    this.setState({ addBillVisibility: false });
  }

  handleAdd() {
    this.setState({ addBillVisibility: true });
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <HtmlComments
          permission={'/permission/admin/device-mgt/metadata/view'}
        />
        <Authorized
          scope={[['perm:admin:metadata:view']]}
          yes={
            this.config.userDomain === 'carbon.super' ? (
              <div>
                <div>
                  {this.state.metaData[0] !== null && (
                    <>
                      <HtmlComments
                        permission={
                          '/permission/admin/device-mgt/metadata/view'
                        }
                      />
                      <Button
                        onClick={this.handleAdd}
                        type="primary"
                        style={{
                          marginBottom: 40,
                          marginTop: 25,
                          float: 'right',
                        }}
                      >
                        Add a New Billing
                      </Button>
                    </>
                  )}
                  <>
                    <HtmlComments
                      permission={'/permission/admin/device-mgt/metadata/view'}
                    />
                    <BillingTable
                      config={this.props.config}
                      callback={() => this.loadMetaData()}
                      metaData={this.state.metaData}
                      loading={this.state.loading}
                      handleAdd={this.handleAdd}
                    />
                  </>
                </div>
                {this.state.addBillVisibility && (
                  <>
                    <HtmlComments
                      permission={'/permission/admin/device-mgt/metadata/view'}
                    />
                    <AddBill
                      config={this.props.config}
                      metaData={this.state.metaData}
                      closeAddBillModal={() => this.closeAddBillModal()}
                      callback={() => this.loadMetaData()}
                    />
                  </>
                )}
              </div>
            ) : (
              <Result
                status="403"
                title={t('noPerm_accessPageTitle')}
                subTitle={t('noPerm_contactSysAdmin')}
              />
            )
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(Billing));
