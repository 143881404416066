/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
import React from 'react';
import { Modal, Form, Input, Alert, Spin, Button } from 'antd';
import { handleApiError } from '../../../../../services/utils/errorHandler';

const NodeForm = ({
  visible,
  onCancel,
  onCreate,
  validationError,
  loading,
  t
}) => {
  const [form] = Form.useForm();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      form.resetFields();
      onCreate(values);
    } catch (error) {
      handleApiError(error, t('validation_error'), t);
    }
  };

  return (
    <Modal
      open={visible}
      title={t('modal_add_device_title')}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={loading}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t('modal_cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleOk}
          loading={loading}
        >
          {t('modal_create')}
        </Button>
      ]}
    >
      <Form form={form} name="nodeForm">
        <Form.Item
          name="nodeName"
          label="Device Name"
          rules={[
            {
              required: true,
              message: t('modal_add_device_name_msg')
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="orgDescription"
          label="Organization Description"
          rules={[
            {
              required: true,
              message: t('modal_input_add_org')
            }
          ]}
        >
          <Input />
        </Form.Item>
        {validationError && (
          <Alert message={validationError} type="error" showIcon />
        )}
      </Form>

      {loading && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin />
        </div>
      )}
    </Modal>
  );
};

export default NodeForm;
