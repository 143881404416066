/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import axios from 'axios';
import { Button, notification, Result, Row, Table, Typography } from 'antd';
import TimeAgo from 'javascript-time-ago';

// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import PolicyAction from '../PolicyAction';
import PolicyAssignees from '../PolicyAssignees';
import PolicyBulkActionBar from '../PolicyBulkActionBar';
import PolicyOperations from '../PolicyOperations';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { Paragraph } = Typography;

let apiUrl;

class PoliciesTable extends React.Component {
  constructor(props) {
    super(props);
    TimeAgo.addLocale(en);
    this.config = this.props.context;
    this.state = {
      data: [],
      pagination: {},
      loading: false,
      selectedRows: [],
    };
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({
        selectedRows: selectedRows,
      });
    },
  };

  // This method is used to update the selected row state
  // after publishing and unpublishing a policy
  updateRowSelection = () => {
    const { data, selectedRows } = this.state;
    let updatedRows = [];

    selectedRows.map(row => {
      let policy = data.policies.find(value => value.id === row.id);
      updatedRows.push(policy);
    });
    if (typeof updatedRows[0] !== 'undefined') {
      this.setState({ selectedRows: updatedRows });
    }
  };

  componentDidMount() {
    this.fetchPolicies();
  }

  // fetch data from api
  fetchPolicies = (params = {}) => {
    const config = this.props.context;
    const { t } = this.props;
    this.setState({ loading: true });

    // get current page
    const currentPage = params.hasOwnProperty('page') ? params.page : 1;

    const extraParams = {
      offset: 10 * (currentPage - 1), // calculate the offset
      limit: 10,
    };

    const encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/policies/list?' +
      encodedExtraParams;

    // send request to the invokerss
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const pagination = { ...this.state.pagination };
          this.setState({
            loading: false,
            data: res.data.data,
            pagination,
          });
          this.updateRowSelection();
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_policies') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetchPolicies({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  unpublishPolicy = () => {
    const { t } = this.props;
    const policyIDs = this.state.selectedRows.map(obj => obj.id);
    // send request to the invoker
    axios
      .post(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/policies/deactivate-policy',
        policyIDs,
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.fetchPolicies();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_policyUnpublishedMsg'),
          });
          this.setState({
            pagination: { ...this.state.pagination, current: 1 },
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_policyUnpublishedError'), t);
      });
  };

  publishPolicy = () => {
    const { t } = this.props;
    const policyIDs = this.state.selectedRows.map(obj => obj.id);
    // send request to the invoker
    axios
      .post(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/policies/activate-policy',
        policyIDs,
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.fetchPolicies();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_policyPublishedMsg'),
          });
          this.setState({
            pagination: { ...this.state.pagination, current: 1 },
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_policyPublishedError'), t);
      });
  };

  removePolicy = () => {
    const { t } = this.props;
    const policyIDs = this.state.selectedRows.map(obj => obj.id);
    // send request to the invoker
    axios
      .post(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/policies/remove-policy',
        policyIDs,
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.fetchPolicies();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_policyRemovedMsg'),
          });
          this.setState({
            pagination: { ...this.state.pagination, current: 1 },
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_policyRemovedError'), t);
      });
  };

  applyChanges = () => {
    const { t } = this.props;
    // send request to the invoker
    axios
      .put(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/policies/apply-changes',
        'null',
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.fetchPolicies();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_changeAppliedMsg'),
          });
          this.setState({
            pagination: { ...this.state.pagination, current: 1 },
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_applyChangesToDeviceError'), t);
      });
  };

  render() {
    const { t } = this.props;
    const { data, pagination, loading, selectedRows } = this.state;
    const isPoliciesExist = data.policies ? data.policies.length > 0 : false;
    const columns = [
      {
        title: t('label_policyName'),
        dataIndex: 'policyName',
        width: '20%',
      },
      {
        title: t('label_description'),
        dataIndex: 'description',
        key: 'description',
        width: '30%',
        // todo add filtering options
        render: (data, row) => {
          return (
            <Paragraph
              style={{ flex: 'auto', marginTop: '5%' }}
              ellipsis={{
                rows: 1,
                symbol: 'more',
                onEllipsis: ellipsis => { },
              }}
            >
              {row.description}
            </Paragraph>
          );
        },
      },
      {
        width: '10%',
        render: (id, row) => (
          <span>
            <PolicyAssignees selectedPolicyData={row} />
          </span>
        ),
      },
      {
        title: t('label_status'),
        dataIndex: 'active',
        key: 'active',
        width: '10%',
        // eslint-disable-next-line react/display-name
        render: (data, row) => {
          const { active, updated } = row;
          let status = t('label_inactive');
          let color = '#91681a';
          let backGroundColor = '#f9dd9e';
          if (active) {
            backGroundColor = '#c4e0c6';
            color = '#14403e';
            status = t('label_active');
          }
          return (
            <Row>
              <span
                style={{
                  display: 'inline-block',
                  width: 120,
                  textAlign: 'center',
                  color: color,
                  background: backGroundColor,
                  padding: '3px 10px 3px 10px',
                  borderRadius: 7,
                }}
              >
                {status.toString()}
              </span>
              {updated ? (
                <span
                  style={{
                    marginTop: 5,
                    display: 'inline-block',
                    width: 120,
                    textAlign: 'center',
                    color: '#4e3b87',
                    background: '#b5b8dd',
                    padding: '3px 10px 3px 10px',
                    borderRadius: 7,
                  }}
                >
                  {t('label_updated')}
                </span>
              ) : null}
            </Row>
          );
        },
        // todo add filtering options
      },
      {
        title: t('label_compliance'),
        dataIndex: 'compliance',
        key: 'compliance',
        width: '10%',
        render: (id, row) => {
          return t(`label_${row.compliance.toLowerCase()}`);
        },
        //  render: enrolmentInfo => enrolmentInfo.ownership
        // todo add filtering options
      },
      {
        title: t('label_policyType'),
        dataIndex: 'policyType',
        key: 'policyType',
        width: '10%',
        render: (id, row) => {
          return t(`label_${row.policyType.toLowerCase()}_uppercase`);
        },
        //  render: enrolmentInfo => enrolmentInfo.ownership
        // todo add filtering options
      },
      {
        title: t('label_actions'),
        dataIndex: 'id',
        key: 'action',
        width: '10%',
        render: (id, row) => (
          <span>
            <PolicyAction selectedPolicyData={row} />
          </span>
        ),
      },
    ];
    return (
      <div>
        {data.count > 0 && (
          <div>
            <div>
              <HtmlComments
                permission={'/permission/admin/device-mgt/policies/manage'}
              />
              {isAuthorized(this.config.scopes, ['perm:policies:changes']) && (
                <PolicyOperations
                  selectedRows={selectedRows}
                  applyChanges={this.applyChanges}
                  isPoliciesExist={isPoliciesExist}
                />
              )}
            </div>
            <div className={styles.tableContainer}>
              {isAuthorized(this.config.scopes, [
                [
                  'perm:policies:deactivate',
                  'perm:policies:activate',
                  'perm:policies:remove',
                  'perm:policies:changes',
                ],
              ]) && (
                  <div>
                    <HtmlComments
                      permission={'/permission/admin/device-mgt/policies/manage'}
                    />
                    <PolicyBulkActionBar
                      selectedRows={selectedRows}
                      unpublishPolicy={this.unpublishPolicy}
                      publishPolicy={this.publishPolicy}
                      removePolicy={this.removePolicy}
                      applyChanges={this.applyChanges}
                    />
                  </div>
                )}
              <Table
                columns={columns}
                className={styles.policyTable}
                rowKey={record => record.id}
                dataSource={data.policies}
                pagination={{
                  ...pagination,
                  size: 'small',
                  // position: "top",
                  total: data.count,
                  disabled: loading,
                  showTotal: (total, range) =>
                    t('table_pagination', {
                      range1: range[0],
                      range2: range[1],
                      total: total,
                      data: t('home_menu_policies'),
                    }),
                  // showQuickJumper: true
                }}
                loading={loading}
                onChange={this.handleTableChange}
                rowSelection={this.rowSelection}
              />
            </div>
          </div>
        )}
        {data.count === 0 && (
          // todo Change Result icon #issue592
          <div>
            <HtmlComments
              permission={'/permission/admin/device-mgt/policies/manage'}
            />
            <Result
              status="404"
              title={t('noPolicy_Txt')}
              subTitle={
                isAuthorized(this.config.scopes, ['perm:policies:manage'])
                  ? t('addNewPolicy_Txt')
                  : null
              }
              extra={
                <div>
                  <HtmlComments
                    permission={'/permission/admin/device-mgt/policies/manage'}
                  />
                  {isAuthorized(this.config.scopes, [
                    'perm:policies:manage',
                  ]) && (
                      <Link to={`/${this.config.appName}/policy/add`}>
                        <Button type="primary">{t('label_addNewPolicy')}</Button>
                      </Link>
                    )}
                </div>
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(PoliciesTable));
