/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Select, Card, Row, Col, Form } from 'antd';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

class CorrectiveAction extends React.Component {
  onChange = value => {
    const feature = { ...this.props.feature };
    if (value === 'NONE') {
      feature.correctiveActions = [];
    } else {
      feature.correctiveActions = [JSON.parse(value)];
    }

    this.props.callback(feature);
  };
  render() {
    const { feature, t } = this.props;
    let correctiveActions;
    if (feature.correctiveActions && feature.correctiveActions.length > 0) {
      correctiveActions = feature.correctiveActions.map(correctiveAction => {
        delete correctiveAction.isReactive;
        return JSON.stringify(correctiveAction);
      });
    } else {
      correctiveActions = 'NONE';
    }
    return (
      <div>
        <Card size="small" style={{ marginBottom: '5px' }}>
          <Row>
            <Col span={8}>{feature.featureCode}</Col>
            <Col span={16}>
              <Form.Item name={feature.featureCode}>
                <Select
                  style={{ width: '100%' }}
                  onChange={this.onChange}
                  key={feature.featureCode}
                  defaultValue={correctiveActions}
                  disabled={this.props.disabled}
                >
                  <Option value="NONE">{t('label_none')}</Option>
                  {this.props.correctivePoliciesList}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

export default withTranslation()(CorrectiveAction);
