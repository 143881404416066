/* eslint-disable no-nested-ternary */
/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Col, Result, Row, Divider, Select, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { withConfigContext } from '../../../../components/ConfigContext';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import { subHours } from 'date-fns';
import IframeWrapper from '../../components/IframeWrapper';
import { getUnixTime } from '../../../../services/utils/functions';
import configs from '../../../../../public/conf/config.json';
import DateRangeDropdown from '../../components/common/DateRangeDropdown';
import HtmlComments from '../../../../components/ReactHtmlComments/HtmlComments';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

const HourlyAnalyticReports = (props) => {
  const config = props.context;
  const deviceOptions = useSelector(
    (state) => state.deviceOption.deviceOptions,
  );
  const defaultDevice = useSelector(
    (state) => state.deviceOption.defaultDevice,
  );
  const selectedBranch = useSelector((state) => state.branch.value);
  const { t } = props;
  const routesContext = useContext(RoutesContext);
  const [meterSerials, setMeterSerials] = useState(
    `var-serial=${defaultDevice}`,
  );
  const [profileParams, setProfileParams] = useState(
    'var-column=LP_1_8_0_diff&var-column=LP_9_7_0_max',
  );
  const previousDefaultDevice = useRef(null);
  const rightNow = new Date();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState([
    defaultDevice.toString(),
  ]);
  //commenting out few parameters until meter hardware is updated
  const [profileParamOptions, setProfileParamOptions] = useState([
    { value: 'All', label: 'All' },
    { value: 'LP_1_7_0_avg', label: 'Import kW Avg (kW)' },
    { value: 'LP_1_8_0_diff', label: 'Import kWh Change (kWh)' },
    // { value: 'LP_3_5_0_avg', label: 'Import kvar Avg (kVAR)' },
    // { value: 'LP_3_8_0_diff', label: 'Import kvarh Change (kVARh)' },
    { value: 'LP_9_7_0_avg', label: 'Import kVA Avg (kVA)' },
    { value: 'LP_9_7_0_max', label: 'Peak Demand (kVA)' },
    { value: 'LP_31_7_0_avg', label: 'Phase A Current Avg (A)' },
    { value: 'LP_51_7_0_avg', label: 'Phase B Current Avg (A)' },
    { value: 'LP_71_7_0_avg', label: 'Phase C Current Avg (A)' },
    { value: 'LP_32_7_0_avg', label: 'Phase A Voltage Avg (V)' },
    { value: 'LP_52_7_0_avg', label: 'Phase B Voltage Avg (V)' },
    { value: 'LP_72_7_0_avg', label: 'Phase C Voltage Avg (V)' },
    { value: 'LP_13_7_0_avg', label: 'Phase ABC Power factor Avg' },
    // { value: 'LP_33_7_0_avg', label: 'Phase A Power factor Avg' },
    // { value: 'LP_43_7_0_avg', label: 'Phase B Power factor Avg' },
    // { value: 'LP_63_7_0_avg', label: 'Phase C Power factor Avg' },
    // { value: 'LP_3_5_0_max', label: 'Peak Demand (kVAR)' },
    // { value: 'F_avg', label: 'Frequency Avg (Hz)' },
  ]);
  const [selectedProfileParamOption, setSelectedProfileParamOption] = useState([
    'LP_1_8_0_diff',
    'LP_9_7_0_max',
  ]);
  const [dashboardUrl, setDashboardUrl] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const startTimestamp = urlParams.get('startTimestamp');
  const endTimestamp = urlParams.get('endTimestamp');
  const initialState =
    startTimestamp && endTimestamp
      ? {
          from: parseInt(startTimestamp, 10),
          to: parseInt(endTimestamp, 10),
        }
      : {
          from: getUnixTime(subHours(rightNow, 6)),
          to: getUnixTime(rightNow),
        };

  const [range, setRange] = useState(initialState);

  useEffect(() => {
    setMeterSerials(`var-serial=${defaultDevice}`);
    setSelectedOption([defaultDevice.toString()]);
  }, [selectedBranch]);

  useEffect(() => {
    routesContext.setCurrentRoute('allHourlyDataAnalytics');
    setIsLoading(true);

    // This function is used to update the URL
    const updateDashboardUrl = () => {
      setDashboardUrl(
        // eslint-disable-next-line max-len
        `${configs.grafanaConfig.hourlyAnalyticsReportsVisualization}&from=${range.from}&to=${range.to}&${meterSerials}&${profileParams}&var-branch=${selectedBranch}`,
      );
    };

    // Check if the default device has changed
    if (!isEqual(previousDefaultDevice.current, defaultDevice)) {
      // Update the state with the new default device
      setSelectedOption([defaultDevice.toString()]);
      previousDefaultDevice.current = defaultDevice;

      // Update meterSerials with the new default device
      setMeterSerials(`var-serial=${defaultDevice}`);

      // Update the URL with the new default device
      updateDashboardUrl();
    } else {
      // Update the URL with the current meterSerials
      updateDashboardUrl();
    }
  }, [
    defaultDevice,
    meterSerials,
    profileParams,
    range.from,
    range.to,
    selectedBranch,
  ]);

  const handleSerialChange = (serials) => {
    if (serials.length !== 0) {
      const containsAll = serials.some((serial) => serial === 'All');
      if (containsAll) {
        setSelectedOption(['All']);
      } else {
        setSelectedOption(serials);
      }
    } else {
      setSelectedOption(serials);
    }

    if (serials.length !== 0) {
      const containsAll = serials.some((serial) => serial === 'All');
      let serialString = '';
      if (containsAll) {
        serialString = 'var-serial=All';
        setMeterSerials(serialString);
      } else {
        serials.forEach((serial, index) => {
          serialString += `var-serial=${serial}`;
          if (index !== serials.length - 1) {
            serialString += '&';
          }
        });
        setMeterSerials(serialString);
      }
    }
  };

  const handleProfileParamChange = (params) => {
    if (params.length !== 0) {
      const containsAll = params.some((param) => param === 'All');
      if (containsAll) {
        setSelectedProfileParamOption(['All']);
      } else {
        setSelectedProfileParamOption(params);
      }
    } else {
      setSelectedProfileParamOption(params);
    }

    if (params.length !== 0) {
      const containsAll = params.some((param) => param === 'All');
      let paramString = '';
      if (containsAll) {
        paramString = 'var-column=All';
        setProfileParams(paramString);
      } else {
        params.forEach((param, index) => {
          paramString += `var-column=${param}`;
          if (index !== params.length - 1) {
            paramString += '&';
          }
        });
        setProfileParams(paramString);
      }
    }
  };

  const routes = (appName) => [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_hourlyDataAnalytics'),
    },
  ];

  return (
    <div>
      <HtmlComments
        permission={
          '/permission/admin/device-mgt/rmr/device/admin/view-meter-reading'
        }
      />
      <Authorized
        scope={['perm:rmr:admin:view-meter-reading']}
        yes={
          <div>
            <PageHeader className={styles.pageHeader} ghost={false}>
              <div className={styles.positioning}>
                <Row justify="space-between" align="middle" gutter={8}>
                  <Col>
                    <BreadcrumbComponent
                      breadcrumbList={routes(config.appName)}
                    />
                  </Col>
                  <Col>
                    <Row align="middle" gutter={[8, 8]}>
                      <Col>
                        <Space>
                          <Select
                            maxTagCount={2}
                            showSearch
                            filterOption={(input, option) =>
                              option.props.label
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.props.value
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{
                              width: '100%',
                              minWidth: '200px',
                            }}
                            mode="multiple"
                            placeholder={t('please_select')}
                            defaultValue={['000000000013']}
                            value={selectedOption}
                            onChange={handleSerialChange}
                            options={deviceOptions.filter((option) =>
                              selectedOption.includes('All')
                                ? option.value === 'All'
                                : true,
                            )}
                          />
                        </Space>
                      </Col>
                      <Col>
                        <Space>
                          <Select
                            showSearch
                            maxTagCount={1}
                            filterOption={(input, option) =>
                              option.props.label
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.props.value
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{
                              width: '100%',
                              minWidth: '200px',
                            }}
                            mode="multiple"
                            placeholder={t('please_select')}
                            defaultValue={['LP_1_8_0_diff', 'LP_9_7_0_max']}
                            value={selectedProfileParamOption}
                            onChange={handleProfileParamChange}
                            options={profileParamOptions.filter((option) =>
                              selectedProfileParamOption.includes('All')
                                ? option.value === 'All'
                                : true,
                            )}
                          />
                        </Space>
                      </Col>
                      <Col>
                        <Space>
                          {startTimestamp && endTimestamp ? (
                            <DateRangeDropdown
                              startTimestamp={range.from}
                              endTimestamp={range.to}
                              getRange={(data) => setRange(data)}
                              closeOnBackground
                              disabled={true}
                              range={3}
                            />
                          ) : (
                            <DateRangeDropdown
                              alignDropdown="right"
                              getRange={(data) => setRange(data)}
                              closeOnBackground
                              range={3}
                            />
                          )}
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </PageHeader>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {dashboardUrl !== '' &&
                  meterSerials !== '' &&
                  range.from &&
                  range.to && (
                    <div className="row">
                      <div className="col-md-12">
                        <IframeWrapper
                          iframeSrc={dashboardUrl}
                          height={'700px'}
                        />
                      </div>
                    </div>
                  )}
              </Col>
            </Row>
            <Divider />
          </div>
        }
        no={
          <Result
            status="403"
            title={t('noPerm_accessPageTitle')}
            subTitle={t('noPerm_contactSysAdmin')}
          />
        }
      />
    </div>
  );
};

export default withConfigContext(withTranslation()(HourlyAnalyticReports));
