/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { CSVLink } from 'react-csv';
import { RoutesContext } from '../../index';
import { withConfigContext } from '../../../../components/ConfigContext';
import styles from './styles.module.css';
import {
  Typography,
  Col,
  DatePicker,
  Result,
  Row,
  Button,
  Select,
  Empty,
  Spin,
  Modal,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment';
import HtmlComments from '../../../../components/ReactHtmlComments/HtmlComments';
import Authorized from '../../../../components/Authorized';
import { DownloadOutlined, NodeIndexOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { handleApiError } from '../../../../services/utils/errorHandler';
import { getUiConfig } from '../../../../services/utils/uiConfigHandler';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import BillingCard from './components/BillngCard';
import singleDeviceStyles from '../SingleDevice/styles.module.css';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('label_Usage'),
    },
  ];
};

class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;

    let firstDay;
    let lastDay;
    let start = localStorage.getItem('start');
    let end = localStorage.getItem('end');
    if (start !== null && end !== null) {
      firstDay = moment(start);
      lastDay = moment(end);
    } else {
      let date = new Date();
      firstDay = moment(new Date(date.getFullYear(), date.getMonth(), 1));
      lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    }

    this.state = {
      start: firstDay,
      end: lastDay,
      exportData: false,
      billingData: [],
      exportDataList: [],
      tenantList: [],
      tenantDomain: this.config.userDomain,
      loading: false,
      showCsvLink: false,
      isViewDocuments: false,
      isHideBillGenerationInSuperTenant: false,
      isHideBillGenerationInSubTenant: false,
      isHideTotalCalculationInSuperTenant: false,
      isHideTotalCalculationInSubTenant: false,
      isHideDomainSelectionInSuperTenant: false,
      isHideDomainSelectionInSubTenant: false,
    };
    this.documents = this.props.context.documents;
    this.csvLinkEl = React.createRef();
    this.viewUsage = this.viewUsage.bind(this);
    this.exportData = this.exportData.bind(this);
    this.onChangeDateRange = this.onChangeDateRange.bind(this);
    this.getTenants = this.getTenants.bind(this);
    this.handleDomainChange = this.handleDomainChange.bind(this);
    this.getConfig = this.getConfig.bind(this);
    this.viewDocuments = this.viewDocuments.bind(this);
    this.closeDocumentsModal = this.closeDocumentsModal.bind(this);
  }

  componentDidMount() {
    this.context.setCurrentRoute('billing');
    this.getData();
    this.getConfig();
  }

  getTenants() {
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt-config/v1.0/tenants',
      )
      .then(res => {
        if (res.status === 200) {
          this.setState({
            tenantList: res.data.data,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_tenants') }),
          t,
        );
      });
  }

  viewUsage() {
    this.getData();
  }

  exportData() {
    this.setState({ exportData: true });
    this.getData();
  }

  getData() {
    const { t } = this.props;
    if (this.config.userDomain === 'carbon.super') {
      this.getTenants();
    }
    this.setState({ loading: true });

    if (moment.isMoment(this.state.start)) {
      this.setState({
        start: moment(this.state.start).format('YYYY-MM-DD HH:mm:ss'),
      });
    }
    if (moment.isMoment(this.state.end)) {
      this.setState({
        end: moment(this.state.end).format('YYYY-MM-DD HH:mm:ss'),
      });
    }

    let extraParams;

    if (moment.isMoment(this.state.end) && moment.isMoment(this.state.start)) {
      extraParams = {
        tenantDomain: this.state.tenantDomain,
        startDate: moment(this.state.start).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(this.state.end).format('YYYY-MM-DD HH:mm:ss'),
      };
    } else {
      extraParams = {
        tenantDomain: this.state.tenantDomain,
        startDate: this.state.start,
        endDate: this.state.end,
      };
    }

    const encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    axios
      .get(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/v1.0' +
        '/admin/devices/billing?' +
        encodedExtraParams,
      )
      .then(res => {
        if (res.status === 200) {
          const exportData = res.data.data;
          let data = [];

          if (this.state.exportData) {
            this.setState({ exportData: false });
            if (exportData.length !== 0) {
              for (
                var i = 0;
                i <
                exportData.devices[exportData.devices.length - 1].device.length;
                i++
              ) {
                if (i === 0) {
                  data[i] = {
                    deviceIdentifier:
                      exportData.devices[exportData.devices.length - 1].device[
                        i
                      ].deviceIdentifier,
                    description:
                      exportData.devices[exportData.devices.length - 1].device[
                        i
                      ].name,
                    name:
                      exportData.devices[exportData.devices.length - 1].device[
                        i
                      ].description,
                    dateOfEnrolment: moment(
                      exportData.devices[exportData.devices.length - 1].device[
                        i
                      ].enrolmentInfo.dateOfEnrolment,
                    ).format('LLLL'),
                    status:
                      exportData.devices[exportData.devices.length - 1].device[
                        i
                      ].enrolmentInfo.status,
                    daysUsed:
                      exportData.devices[exportData.devices.length - 1].device[
                        i
                      ].daysUsed,
                    cost:
                      exportData.devices[exportData.devices.length - 1].device[
                        i
                      ].cost,
                    totalCost: exportData.totalCost,
                  };
                } else {
                  data[i] = {
                    deviceIdentifier:
                      exportData.devices[exportData.devices.length - 1].device[
                        i
                      ].deviceIdentifier,
                    description:
                      exportData.devices[exportData.devices.length - 1].device[
                        i
                      ].name,
                    name:
                      exportData.devices[exportData.devices.length - 1].device[
                        i
                      ].description,
                    dateOfEnrolment: moment(
                      exportData.devices[exportData.devices.length - 1].device[
                        i
                      ].enrolmentInfo.dateOfEnrolment,
                    ).format('LLLL'),
                    status:
                      exportData.devices[exportData.devices.length - 1].device[
                        i
                      ].enrolmentInfo.status,
                    daysUsed:
                      exportData.devices[exportData.devices.length - 1].device[
                        i
                      ].daysUsed,
                    cost:
                      exportData.devices[exportData.devices.length - 1].device[
                        i
                      ].cost,
                  };
                }
              }
            }

            this.setState({ exportDataList: data, loading: false }, () => {
              setTimeout(() => {
                this.csvLinkEl.current.link.click();
              });
            });
          } else {
            this.setState({
              loading: false,
              billingData: res.data.data,
            });
          }
        }
      })
      .catch(error => {
        this.setState({ loading: false });
        handleApiError(
          error,
          t('api_loadError', { label: t('label_Usage') }),
          t,
        );
      });
  }

  onChangeDateRange = (dates, dateString) => {
    let startTime = '00:00';
    let endTime = '00:00';

    let startTimeAndDate = moment(dateString[0] + ' ' + startTime);
    let endTimeAndDate = moment(dateString[1] + ' ' + endTime);
    this.setState({
      start: startTimeAndDate,
      end: endTimeAndDate,
    });

    localStorage.setItem('start', dateString[0]);
    localStorage.setItem('end', dateString[1]);
  };

  handleDomainChange(value) {
    this.setState({ tenantDomain: value }, () => {
      this.getData();
    });
  }

  getConfig() {
    const { t } = this.props;
    getUiConfig(this.config, t).then(uiConfig => {
      if (uiConfig !== undefined) {
        this.setState({
          isHideBillGenerationInSuperTenant:
            uiConfig.billing.isHideBillGenerationInSuperTenant,
          isHideBillGenerationInSubTenant:
            uiConfig.billing.isHideBillGenerationInSubTenant,
          isHideTotalCalculationInSuperTenant:
            uiConfig.billing.isHideTotalCalculationInSuperTenant,
          isHideTotalCalculationInSubTenant:
            uiConfig.billing.isHideTotalCalculationInSubTenant,
          isHideDomainSelectionInSuperTenant:
            uiConfig.billing.isHideDomainSelectionInSuperTenant,
          isHideDomainSelectionInSubTenant:
            uiConfig.billing.isHideDomainSelectionInSubTenant,
        });
      }
    });
  }

  dataFromListOfUsersState = () => {
    if (this.state.billingData.devices) {
      return this.state.billingData.devices;
    }
    return this.state.billingData;
  };

  disabledDate = current => {
    return current && current >= moment().endOf('day');
  };

  disabledRangeTime = (_, type) => {
    if (type === 'start') {
      return {
        disabledHours: () => this.range(0, 60).splice(4, 20),
        disabledMinutes: () => this.range(30, 60),
        disabledSeconds: () => [55, 56],
      };
    }
    return {
      disabledHours: () => this.range(0, 60).splice(20, 4),
      disabledMinutes: () => this.range(0, 31),
      disabledSeconds: () => [55, 56],
    };
  };

  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  viewDocuments() {
    this.setState({
      isViewDocuments: true,
    });
  }

  closeDocumentsModal = e => {
    this.setState({
      isViewDocuments: false,
    });
  };

  render() {
    const { t } = this.props;
    const data = this.state.exportDataList;
    const { loading } = this.state;
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = mm + '-' + dd + '-' + yyyy;
    const filename = 'Usage_' + today;
    let path = `/sub-metering-mgt/public/documents/${this.config.userDomain}/`;

    const headers = [
      { label: 'DEVICE_IDENTIFICATION', key: 'deviceIdentifier', width: 100 },
      { label: 'DESCRIPTION', key: 'description' },
      { label: 'NAME', key: 'name' },
      { label: 'DATE_OF_ENROLMENT', key: 'dateOfEnrolment' },
      { label: 'STATUS', key: 'status' },
      { label: 'DAYS_USED', key: 'daysUsed' },
      { label: 'COST', key: 'cost' },
      { label: 'TotalCost', key: 'totalCost' },
    ];

    const obj = info => {
      return (
        <p>
          <a key={info.name} href={path + info.path} download={info.name}>
            {info.name}
            <DownloadOutlined
              style={{ marginLeft: 5, cursor: 'pointer', color: '#4288BA' }}
            />
          </a>
        </p>
      );
    };

    return (
      <div>
        <HtmlComments permission={'/device-mgt/admin/devices/usage/view'} />
        {!this.config.excludedBillingTenants.includes(
          this.state.tenantDomain,
        ) ? (
          <Authorized
            scope={['perm:admin:usage:view']}
            yes={
              <div>
                <PageHeader
                  className={styles.pageHeader}
                  title={
                    <Title level={1}>
                      <span className={styles.icon}>
                        <NodeIndexOutlined />
                      </span>
                      {t('label_Usage')}
                    </Title>
                  }
                  ghost={false}
                  breadcrumb={
                    <BreadcrumbComponent
                      breadcrumbList={routes(this.config.appName, t)}
                    />
                  }
                />
                <div className={styles.content}>
                  <Row>
                    <Col className={styles.billPeriod}>Start date :</Col>
                    <Col>End date :</Col>
                  </Row>
                  <Row>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={13}
                      xl={7}
                      style={{
                        marginBottom: 5,
                        marginRight: 20,
                      }}
                    >
                      <RangePicker
                        disabledDate={this.disabledDate}
                        defaultValue={[this.state.start, this.state.end]}
                        onChange={this.onChangeDateRange}
                      />
                    </Col>

                    {(!this.state.isHideDomainSelectionInSuperTenant &&
                      this.config.userDomain === 'carbon.super') ||
                      (!this.state.isHideDomainSelectionInSubTenant &&
                        this.config.userDomain !== 'carbon.super') ? (
                      <Col
                        xs={24}
                        sm={18}
                        md={10}
                        lg={8}
                        xl={4}
                        style={{
                          marginBottom: 5,
                        }}
                      >
                        <Select
                          onChange={this.handleDomainChange}
                          defaultValue="carbon.super"
                          style={{ width: 160 }}
                        >
                          {this.state.tenantList.map(item => (
                            <Option key={item.domain}>{item.domain}</Option>
                          ))}
                        </Select>
                      </Col>
                    ) : null}
                    <Col
                      xs={24}
                      sm={10}
                      md={5}
                      lg={6}
                      xl={2}
                      style={{
                        marginBottom: 5,
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                          this.viewUsage();
                        }}
                      >
                        View Usage
                      </Button>
                    </Col>
                    <Col
                      xs={24}
                      sm={8}
                      md={8}
                      lg={6}
                      xl={2}
                      style={{
                        marginBottom: 5,
                        marginRight: 10,
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={this.exportData}
                      >
                        {' '}
                        Export Usage
                      </Button>
                      <CSVLink
                        filename={filename}
                        data={data}
                        headers={headers}
                        ref={this.csvLinkEl}
                      />
                    </Col>
                    <Col
                      xs={24}
                      sm={10}
                      md={5}
                      lg={6}
                      xl={3}
                      style={{
                        marginBottom: 5,
                        marginRight: 20,
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                          this.viewDocuments();
                        }}
                      >
                        My Documents
                      </Button>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={4}
                      xl={4}
                      style={{ marginRight: 0, marginTop: 5, marginBottom: 5 }}
                    >
                      {(this.state.billingData.totalCost &&
                        (!this.state.isHideTotalCalculationInSuperTenant &&
                          this.config.userDomain === 'carbon.super')) ||
                        (!this.state.isHideTotalCalculationInSubTenant &&
                          this.config.userDomain !== 'carbon.super') ? (
                        <p>
                          <b>Total Cost:</b> {this.state.billingData.totalCost}$
                        </p>
                      ) : null}
                    </Col>
                  </Row>
                  {loading && (
                    <div style={{ textAlign: 'center', marginTop: 5 }}>
                      <Spin style={{ textAlign: 'center' }} />
                    </div>
                  )}
                  {!loading && (
                    <Row
                      className={styles.tableContainer}
                      style={{
                        backgroundColor: '#ffffff',
                        borderRadius: 5,
                        marginTop: 35,
                      }}
                    >
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {this.state.billingData &&
                          this.state.billingData.devices &&
                          this.state.billingData.deviceCount &&
                          this.state.billingData.deviceCount != 0 ? (
                          <BillingCard
                            billingData={this.state.billingData}
                            start={this.state.start}
                            end={this.state.end}
                            tenantDomain={this.state.tenantDomain}
                          />
                        ) : (
                          <div className={singleDeviceStyles.container}>
                            <div>
                              <Empty />
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  )}
                  <br />
                </div>
                <div>
                  <Modal
                    title="My Documents"
                    open={this.state.isViewDocuments}
                    onCancel={this.closeDocumentsModal}
                    footer={null}
                    maskClosable={false}
                  >
                    <div className={styles.container}>
                      {this.documents &&
                        this.config.userDomain in this.documents &&
                        this.documents[this.config.userDomain] ? (
                        this.documents[this.config.userDomain].map(info => {
                          return (
                            <div
                              key={info.name}
                              style={{ textAlign: 'center' }}
                            >
                              {obj(info)}
                            </div>
                          );
                        })
                      ) : (
                        <Empty />
                      )}
                    </div>
                  </Modal>
                </div>
              </div>
            }
            no={
              <Result
                status="403"
                title={t('noPerm_accessPageTitle')}
                subTitle={t('noPerm_contactSysAdmin')}
              />
            }
          />
        ) : (
          <Result
            status="403"
            title={t('noPerm_accessPageTitle')}
            subTitle={t('noPerm_contactSysAdmin')}
          />
        )}
      </div>
    );
  }
}
Billing.contextType = RoutesContext;
export default withConfigContext(withTranslation()(Billing));
